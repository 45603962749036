export const directors = [
  "Aamir Khan",
  "Aaron Schneider",
  "Aaron Sorkin",
  "Abbas Kiarostami",
  "Abby Kohn",
  "Abdellatif Kechiche",
  "Abderrahmane Sissako",
  "Abdullah Oguz",
  "Abel Ferrara",
  "Abel Ferry",
  "Abolhassan Davoodi",
  "Achero Mañas",
  "Achim von Borries",
  "Adam Brooks",
  "Adam Elliot",
  "Adam Gierasch",
  "Adam MacDonald",
  "Adam McKay",
  "Adam Randall",
  "Adam Robitel",
  "Adam Shankman",
  "Adam Wingard",
  "Aditya Chopra",
  "Aditya Dhar",
  "Adolfo Aristarain",
  "Adrian Garcia Bogliano",
  "Adrian Grunberg",
  "Adriano Valerio",
  "Agnieszka Holland",
  "Agnieszka Smoczynska",
  "Agnès Jaoui",
  "Agnès Varda",
  "Agustí Villaronga",
  "Agustín Díaz Yanes",
  "Ahmad Reza Darvish",
  "Ahmet Katiksiz",
  "Ahmet Uluçay",
  "Aisling Walsh",
  "Aitor Arregi",
  "Akan Satayev",
  "Akhtem Seitablaev",
  "Aki Kaurismäki",
  "Akin Omotoso",
  "Akira Kurosawa",
  "Aku Louhimies",
  "Alain Berbérian",
  "Alain Berliner",
  "Alain Chabat",
  "Alain Corneau",
  "Alain Gsponer",
  "Alain Guiraudie",
  "Alain Resnais",
  "Alan J. Pakula",
  "Alan Metter",
  "Alan Parker",
  "Alan Rickman",
  "Alan Shapiro",
  "Alan Taylor",
  "Alan White",
  "Albert Dupontel",
  "Albert Hughes",
  "Albert Pintó",
  "Alberto Arvelo",
  "Alberto Rodríguez",
  "Alberto de Toro",
  "Aldo Baglio",
  "Alejandro Agresti",
  "Alejandro Amenábar",
  "Alejandro Brugués",
  "Alejandro Doria",
  "Alejandro G. Iñárritu",
  "Alejandro Hidalgo",
  "Alejandro Jodorowsky",
  "Alejandro Landes",
  "Alejandro Lozano",
  "Alejandro Monteverde",
  "Alejandro Montiel",
  "Aleksander Bach",
  "Aleksandr Dovzhenko",
  "Aleksandr Kott",
  "Aleksandr Rogozhkin",
  "Aleksandr Rou",
  "Aleksandr Sokurov",
  "Aleksandr Veledinskiy",
  "Aleksey Balabanov",
  "Aleksey German",
  "Aleksey Sidorov",
  "Alessandro Carloni",
  "Alessandro Genovesi",
  "Alessandro Guida",
  "Alessio Cremonini",
  "Alex Cox",
  "Alex Garland",
  "Alex Proyas",
  "Alex Rivera",
  "Alexander Korda",
  "Alexander Mackendrick",
  "Alexander Payne",
  "Alexander Witt",
  "Alexandre Aja",
  "Alexandre Astier",
  "Alexandre Avancini",
  "Alexandre Bustillo",
  "Alexandre Heboyan",
  "Alexandre Moratto",
  "Alexandre de La Patellière",
  "Alexandros Avranas",
  "Alexei Popogrebsky",
  "Alfonso Arau",
  "Alfonso Cuarón",
  "Alfonso Gomez-Rejon",
  "Alfred Hitchcock",
  "Ali Abbasi",
  "Ali Atay",
  "Ali Bilgin",
  "Ali Mosaffa",
  "Ali Soozandeh",
  "Ali Taner Baltaci",
  "Alice Rohrwacher",
  "Alice Winocour",
  "Alice Wu",
  "Allan A. Goldstein",
  "Allan Moyle",
  "Allen Coulter",
  "Alonso Ruizpalacios",
  "Alper Caglar",
  "Aluizio Abranches",
  "Amando de Ossorio",
  "Amat Escalante",
  "Amir Naderi",
  "Amirhossein Ashrafi",
  "Amit Ravindernath Sharma",
  "Amma Asante",
  "Ana Lily Amirpour",
  "Anatole Litvak",
  "Anders Thomas Jensen",
  "Anders Walter",
  "Andrea Arnold",
  "Andrea Berloff",
  "Andrea Bianchi",
  "Andrea De Sica",
  "Andrea Di Stefano",
  "Andreas Prochaska",
  "Andrei Tarkovsky",
  "Andrew Adamson",
  "Andrew Bujalski",
  "Andrew Currie",
  "Andrew Davis",
  "Andrew Dominik",
  "Andrew Fleming",
  "Andrew Haigh",
  "Andrew Lau",
  "Andrew Levitas",
  "Andrew Morahan",
  "Andrew Niccol",
  "Andrew Sipes",
  "Andrew Stanton",
  "Andrey Konchalovskiy",
  "Andrey Kravchuk",
  "Andrey Volgin",
  "Andrey Zvyagintsev",
  "Andrzej Bartkowiak",
  "Andrzej Sekula",
  "Andrzej Wajda",
  "André Hunebelle",
  "André Téchiné",
  "André Øvredal",
  "Andrés Baiz",
  "Andrés Wood",
  "Andy Cadiff",
  "Andy Fickman",
  "Andy Goddard",
  "Andy Hamilton",
  "Andy Muschietti",
  "Andy Serkis",
  "Andy Wilson",
  "Aneesh Chaganty",
  "Ang Lee",
  "Angel Gracia",
  "Angga Dwimas Sasongko",
  "Anh Hung Tran",
  "Aniruddha Roy Chowdhury",
  "Anna Boden",
  "Anna Muylaert",
  "Annabel Jankel",
  "Anne Fontaine",
  "Anno Saul",
  "Ant Timpson",
  "Anthony Bell",
  "Anthony Byrne",
  "Anthony Fabian",
  "Anthony Harvey",
  "Anthony Hickox",
  "Anthony Mann",
  "Anthony Maras",
  "Anthony Marciano",
  "Anthony Minghella",
  "Anthony Russo",
  "Anthony Scott Burns",
  "Anthony Waller",
  "Antoine Fuqua",
  "Anton Corbijn",
  "Anton Megerdichev",
  "Antoni Krauze",
  "Antonia Bird",
  "Antonin Baudry",
  "Antonio Manetti",
  "Antti J. Jokinen",
  "Antti-Jussi Annila",
  "Anubhav Sinha",
  "Anurag Basu",
  "Anurag Kashyap",
  "Apichatpong Weerasethakul",
  "April Mullen",
  "Arash Moayerian",
  "Argyris Papadimitropoulos",
  "Ariel Vromen",
  "Ariel Winograd",
  "Ariel Zeitoun",
  "Arlene Sanford",
  "Armando Iannucci",
  "Arnaud Desplechin",
  "Arnaud des Pallières",
  "Arne Feldhusen",
  "Arthur Hiller",
  "Arthur Penn",
  "Aryanto Yuniawan",
  "Asghar Farhadi",
  "Ash Brannon",
  "Ashkan Rahgozar",
  "Ashutosh Gowariker",
  "Asif Kapadia",
  "Assaf Bernstein",
  "Ate de Jong",
  "Atif Yilmaz",
  "Atil Inaç",
  "Atiq Rahimi",
  "Atom Egoyan",
  "Audrey Diwan",
  "Augustine Frizzell",
  "Aurelio Grimaldi",
  "Autumn de Wilde",
  "Ava DuVernay",
  "Awi Suryadi",
  "Axelle Laffont",
  "Aytaç Agirlar",
  "Babak Anvari",
  "Babak Najafi",
  "Babak Payami",
  "Bahman Ghobadi",
  "Bahram Tavakoli",
  "Baltasar Kormákur",
  "Banjong Pisanthanakun",
  "Baran bo Odar",
  "Barbara Bialowas",
  "Barbara Topsøe-Rothenborg",
  "Barry Levinson",
  "Barry Skolnick",
  "Barry Sonnenfeld",
  "Bart Layton",
  "Bartosz M. Kowalski",
  "Baz Luhrmann",
  "Bedran Güzel",
  "Beeban Kidron",
  "Behrouz Shoaybi",
  "Ben Affleck",
  "Ben Palmer",
  "Ben Stassen",
  "Ben Stiller",
  "Ben Wheatley",
  "Ben Younger",
  "Bene Dion Rajagukguk",
  "Benedict Andrews",
  "Benjamin Heisenberg",
  "Bennett Miller",
  "Benni Setiawan",
  "Benoît Jacquot",
  "Bernard Rose",
  "Bernardo Bertolucci",
  "Bernhard Wicki",
  "Bertrand Blier",
  "Bertrand Bonello",
  "Bertrand Tavernier",
  "Betty Thomas",
  "Bharat Nalluri",
  "Bi Gan",
  "Bibo Bergeron",
  "Bigas Luna",
  "Bill Condon",
  "Bill Forsyth",
  "Bill Paxton",
  "Bille August",
  "Billy Bob Thornton",
  "Billy Ray",
  "Billy Wilder",
  "Björn Runge",
  "Blake Edwards",
  "Bo Hu",
  "Bo Zenga",
  "Boaz Yakin",
  "Bob Clark",
  "Bob Fosse",
  "Bob Gale",
  "Bob Persichetti",
  "Bob Saget",
  "Bob Spiers",
  "Bobby Farrelly",
  "Bong Joon Ho",
  "Bora Dagtekin",
  "Boris Khlebnikov",
  "Borja Soler",
  "Brad Anderson",
  "Brad Bird",
  "Brad Furman",
  "Bradley Cooper",
  "Bradley Parker",
  "Brandon Camp",
  "Brandon Cronenberg",
  "Breck Eisner",
  "Brett Donowho",
  "Brett Leonard",
  "Brett Ratner",
  "Brett Simmons",
  "Brett Sullivan",
  "Brian A. Miller",
  "Brian Dannelly",
  "Brian De Palma",
  "Brian Desmond Hurst",
  "Brian G. Hutton",
  "Brian Helgeland",
  "Brian Henson",
  "Brian Kirk",
  "Brian Levant",
  "Brian Taylor",
  "Bruce Beresford",
  "Bruce McCulloch",
  "Bruce McDonald",
  "Bruce Robinson",
  "Bruno Corbucci",
  "Bruno Dumont",
  "Bruno Mattei",
  "Bruno Nuytten",
  "Bryan Bertino",
  "Bryan Singer",
  "Burak Aksak",
  "Burr Steers",
  "Buster Keaton",
  "Byeong-heon Lee",
  "Byeong-ki Ahn",
  "Byron Howard",
  "Byung-chun Min",
  "Byung-gil Jung",
  "Byung-woo Kim",
  "Béla Ternovszky",
  "C. Prem Kumar",
  "Cal Brunker",
  "Cameron Crowe",
  "Camille Delamarre",
  "Can Evrenol",
  "Can Ulkay",
  "Caradog W. James",
  "Carl Gottlieb",
  "Carl Rinsch",
  "Carl Theodor Dreyer",
  "Carles Torras",
  "Carlos Carrera",
  "Carlos Cuarón",
  "Carlos Marques-Marcet",
  "Carlos Reygadas",
  "Carlos Saura",
  "Carlos Sorin",
  "Carlos Vermut",
  "Carlota Pereda",
  "Carol Reed",
  "Caroline Labrèche",
  "Caroline Link",
  "Cary Joji Fukunaga",
  "Cate Shortland",
  "Catherine Breillat",
  "Catherine Hardwicke",
  "Cecil B. DeMille",
  "Cem Yilmaz",
  "Cesc Gay",
  "Chad Hartigan",
  "Chad Stahelski",
  "Chang-min Choo",
  "Chantal Akerman",
  "Chao-Bin Su",
  "Charles Chaplin",
  "Charles Crichton",
  "Charles Guard",
  "Charles Laughton",
  "Charles Martin Smith",
  "Charles Shyer",
  "Charlotte Wells",
  "Chazz Palminteri",
  "Cheol-soo Jang",
  "Chiwetel Ejiofor",
  "Chris Addison",
  "Chris Appelhans",
  "Chris Butler",
  "Chris Carter",
  "Chris Columbus",
  "Chris Gorak",
  "Chris Kraus",
  "Chris Nahon",
  "Chris Noonan",
  "Chris Weitz",
  "Christian Alvart",
  "Christian Carion",
  "Christian Desmares",
  "Christian Ditter",
  "Christian Duguay",
  "Christian Molina",
  "Christian Petzold",
  "Christian Schwochow",
  "Christian Tafdrup",
  "Christian Vincent",
  "Christian Zübert",
  "Christoffer Boe",
  "Christophe Barratier",
  "Christophe Gans",
  "Christophe Honoré",
  "Christopher Caldwell",
  "Christopher MacBride",
  "Christopher McQuarrie",
  "Christopher Morris",
  "Christopher Nolan",
  "Christopher Smith",
  "Chuan Lu",
  "Chuck Russell",
  "Chung-Hyun Lee",
  "Ciarán Foy",
  "Ciro Guerra",
  "Claire Denis",
  "Clare Kilner",
  "Claude Barras",
  "Claude Berri",
  "Claude Chabrol",
  "Claude Lelouch",
  "Claude Pinoteau",
  "Claude Sautet",
  "Claude Zidi",
  "Claudia Llosa",
  "Claudio Fäh",
  "Clay Kaytis",
  "Clea DuVall",
  "Clement Virgo",
  "Clint Eastwood",
  "Clive Barker",
  "Clovis Cornillac",
  "Clyde Bruckman",
  "Colin Brady",
  "Colin Minihan",
  "Collen Moore cook Turner",
  "Colm McCarthy",
  "Coralie Fargeat",
  "Corey Yuen",
  "Corrado Farina",
  "Cosimo Gomez",
  "Costa-Gavras",
  "Courtney Solomon",
  "Craig Gillespie",
  "Cristian Mungiu",
  "Cristina Gallego",
  "Curtis Hanson",
  "Cy Endfield",
  "Cyprian T. Olencki",
  "Cyrill Boss",
  "Cyrus Nowrasteh",
  "Cédric Jimenez",
  "Cédric Klapisch",
  "Céline Sciamma",
  "D.J. Caruso",
  "Daisuke Nishio",
  "Damiano D'Innocenzo",
  "Damiano Damiani",
  "Damien Chazelle",
  "Damien O'Donnell",
  "Damián Szifron",
  "Damon Beesley",
  "Dan Gilroy",
  "Dan Kwan",
  "Dan Mazer",
  "Dani Levy",
  "Dani de la Orden",
  "Dani de la Torre",
  "Daniel Alfredson",
  "Daniel Barber",
  "Daniel Benmayor",
  "Daniel Calparsoro",
  "Daniel Cohen",
  "Daniel Espinosa",
  "Daniel Grou",
  "Daniel Lee",
  "Daniel Monzón",
  "Daniel Prochaska",
  "Daniel Ribeiro",
  "Daniel Roby",
  "Daniel Sánchez Arévalo",
  "Danila Kozlovskiy",
  "Danis Tanovic",
  "Danishka Esterhazy",
  "Danny Boyle",
  "Danny Cannon",
  "Danny DeVito",
  "Danny Leiner",
  "Dante Lam",
  "Dany Boon",
  "Dario Argento",
  "Darius Marder",
  "Dariush Mehrjui",
  "Darren Aronofsky",
  "Darren Lynn Bousman",
  "Dave McKean",
  "David Anspaugh",
  "David Ayer",
  "David Bowers",
  "David Brooks",
  "David Bruckner",
  "David Casademunt",
  "David Charhon",
  "David Cronenberg",
  "David Dobkin",
  "David F. Sandberg",
  "David Fincher",
  "David Foenkinos",
  "David Frankel",
  "David Galán Galindo",
  "David Hackl",
  "David Hayter",
  "David Kerr",
  "David Koepp",
  "David Lean",
  "David Leitch",
  "David Leland",
  "David Lowery",
  "David Lynch",
  "David M. Rosenthal",
  "David Mackenzie",
  "David Mamet",
  "David Michôd",
  "David Mickey Evans",
  "David Mirkin",
  "David Moreau",
  "David Nutter",
  "David O. Russell",
  "David Oelhoffen",
  "David Pablos",
  "David Pastor",
  "David R. Ellis",
  "David Raymond",
  "David Schwimmer",
  "David Slade",
  "David Steiman",
  "David Trueba",
  "David Twohy",
  "David Wain",
  "David Weaver",
  "David Wnendt",
  "David Yates",
  "David Zucker",
  "Dean Craig",
  "Dean DeBlois",
  "Dean Devlin",
  "Dean Israelite",
  "Dean Parisot",
  "Dean Wright",
  "Debra Granik",
  "Declan Donnellan",
  "Declan Lowney",
  "Deepa Mehta",
  "Demián Rugna",
  "Denis Dercourt",
  "Denis Villeneuve",
  "Deniz Gamze Ergüven",
  "Dennie Gordon",
  "Dennis Gansel",
  "Dennis Iliadis",
  "Denys Arcand",
  "Denzel Washington",
  "Deon Taylor",
  "Deran Sarafian",
  "Derek Cianfrance",
  "Derek Lee",
  "Derek Tsang",
  "Derrick Borte",
  "Dervis Zaim",
  "Desiree Akhavan",
  "Destin Daniel Cretton",
  "Dewey Nicks",
  "Dexter Fletcher",
  "Dick Maas",
  "Didier Bourdon",
  "Diego Quemada-Díez",
  "Dietrich Brüggemann",
  "Dimitar Mitovski",
  "Dino Risi",
  "Dito Montiel",
  "Dmitriy Dyachenko",
  "Dmitriy Kiselev",
  "Dome Karukoski",
  "Domee Shi",
  "Dominic Cooke",
  "Dominic Sena",
  "Dominik Moll",
  "Don Bluth",
  "Don Chaffey",
  "Don Hall",
  "Don Mancini",
  "Don McKellar",
  "Don Siegel",
  "Don Taylor",
  "Donald Petrie",
  "Donato Carrisi",
  "Dong-hoon Choi",
  "Donovan Marsh",
  "Doris Dörrie",
  "Dorota Kobiela",
  "Doug Lefler",
  "Doug Liman",
  "Douglas Aarniokoski",
  "Douglas McGrath",
  "Drake Doremus",
  "Drew Goddard",
  "Drew Pearce",
  "Duccio Tessari",
  "Duke Johnson",
  "Duncan Jones",
  "Durul Taylan",
  "Dusan Makavejev",
  "Dustin Hoffman",
  "Dwight H. Little",
  "E. Elias Merhige",
  "Ebrahim Hatamikia",
  "Edgar Wright",
  "Edmund Goulding",
  "Edoardo Ponti",
  "Eduardo Casanova",
  "Edward Berger",
  "Edward Dmytryk",
  "Edward Yang",
  "Edward Zwick",
  "Egor Abramenko",
  "Egor Baranov",
  "Ehsan Biglari",
  "Eiichi Yamamoto",
  "Eldar Ryazanov",
  "Elem Klimov",
  "Eli Craig",
  "Eli Roth",
  "Elia Kazan",
  "Elio Petri",
  "Eliza Hittman",
  "Elizabeth Banks",
  "Elliot Silverstein",
  "Elliott Lester",
  "Elmo Nüganen",
  "Eléonore Pourriat",
  "Emanuele Crialese",
  "Emerald Fennell",
  "Emilio Martínez Lázaro",
  "Emilis Velyvis",
  "Emir Kusturica",
  "Emmanuelle Bercot",
  "Enki Bilal",
  "Enrique Urbizu",
  "Enzo Barboni",
  "Enzo G. Castellari",
  "Enzo Milioni",
  "Eran Creevy",
  "Ergin Orbey",
  "Eric Bress",
  "Eric Rochant",
  "Eric Summer",
  "Eric Valette",
  "Erick Zonca",
  "Ericson Core",
  "Erik Canuel",
  "Erik Poppe",
  "Erik Skjoldbjærg",
  "Eriprando Visconti",
  "Ernest Prakasa",
  "Ernest R. Dickerson",
  "Ernie Barbarash",
  "Ernst Lubitsch",
  "Ernst Marischka",
  "Ersoy Güler",
  "Ertem Egilmez",
  "Eshom Nelms",
  "Esteban Crespo",
  "Etan Cohen",
  "Ethan Coen",
  "Ettore Scola",
  "Eugenio Derbez",
  "Eugenio Martín",
  "Eugenio Mira",
  "Evan Morgan",
  "Eytan Fox",
  "Ezel Akay",
  "F. Gary Gray",
  "F.W. Murnau",
  "Fabián Bielinsky",
  "Fabrice du Welz",
  "Fajar Nugros",
  "Faozan Rizal",
  "Fardin Saheb-Zamani",
  "Farhad Safinia",
  "Farhan Akhtar",
  "Farren Blackburn",
  "Farzad Motamen",
  "Fatih Akin",
  "Fede Alvarez",
  "Federico Fellini",
  "Fedor Bondarchuk",
  "Felix Binder",
  "Felix Fuchssteiner",
  "Felix Herngren",
  "Felix van Groeningen",
  "Fenar Ahmad",
  "Feo Aladag",
  "Ferdinando Baldi",
  "Ferdinando Cito Filomarino",
  "Fereydoun Jeyrani",
  "Fernando Barreda Luna",
  "Fernando Di Leo",
  "Fernando Frias",
  "Fernando González Molina",
  "Fernando León de Aranoa",
  "Fernando Meirelles",
  "Fernando Trueba",
  "Ferzan Özpetek",
  "Florian David Fitz",
  "Florian Gallenberger",
  "Florian Henckel von Donnersmarck",
  "Florian Zeller",
  "Franc Roddam",
  "Francesca Gregorini",
  "Francesco Prosperi",
  "Francis Annan",
  "Francis Ford Coppola",
  "Francis Lawrence",
  "Francis Lee",
  "Francis Veber",
  "Franck Gastambide",
  "Franck Khalfoun",
  "Franco Nero",
  "Franco Zeffirelli",
  "Frank Capra",
  "Frank Coraci",
  "Frank Darabont",
  "Frank M. Calo",
  "Frank Marshall",
  "Frank Miller",
  "Frank Oz",
  "Franklin J. Schaffner",
  "Frant Gwo",
  "François Dupeyron",
  "François Girard",
  "François Ozon",
  "François Simard",
  "François Truffaut",
  "Fraser C. Heston",
  "Fred Cavayé",
  "Fred Schepisi",
  "Fred Walton",
  "Fred Zinnemann",
  "Frederik Louis Hviid",
  "Fritz Lang",
  "Fruit Chan",
  "Frédéric Forestier",
  "Fumihiko Sori",
  "Gabriel Axel",
  "Gabriela Tagliavini",
  "Gabriele Mainetti",
  "Gabriele Muccino",
  "Gabriele Salvatores",
  "Gail Mancuso",
  "Galder Gaztelu-Urrutia",
  "Gani Müjde",
  "Gareth Carrivick",
  "Gareth Edwards",
  "Gareth Evans",
  "Garry Marshall",
  "Garth Davis",
  "Garth Jennings",
  "Gary Alazraki",
  "Gary Chapman",
  "Gary Fleder",
  "Gary McKendry",
  "Gary Shore",
  "Gary Trousdale",
  "Gaspar Antillo",
  "Gaspar Noé",
  "Gastón Duprat",
  "Gauri Shinde",
  "Gavin Hood",
  "Gavin O'Connor",
  "Gavin Rothery",
  "Gayatri",
  "Gaëtan Brizzi",
  "Gen Sekiguchi",
  "Gene Saks",
  "Gennaro Nunziante",
  "Genndy Tartakovsky",
  "Geoffrey Sax",
  "George A. Romero",
  "George Clooney",
  "George Cukor",
  "George Dunning",
  "George Hickenlooper",
  "George Lucas",
  "George Marshall",
  "George Melford",
  "George Miller",
  "George Nolfi",
  "George P. Cosmatos",
  "George Roy Hill",
  "George Seaton",
  "George Sidney",
  "George Sluizer",
  "George Stevens",
  "Georges Franju",
  "Georges Lautner",
  "Georgiy Daneliya",
  "Gerald Kargl",
  "Gerald Potterton",
  "Gerard Barrett",
  "Gerardo Naranjo",
  "Gerhard Hahn",
  "Geun-seop Jeong",
  "Gianluca Leuzzi",
  "Gideon Raff",
  "Gil Junger",
  "Gil Kenan",
  "Gilles Lellouche",
  "Gilles Mimouni",
  "Gilles Paquet-Brenner",
  "Gillo Pontecorvo",
  "Gina S. Noer",
  "Gisaburô Sugii",
  "Giulio Petroni",
  "Giulio Ricciarelli",
  "Giuseppe Tornatore",
  "Glen Keane",
  "Glen Morgan",
  "Gonzalo López-Gallego",
  "Goran Dukic",
  "Gore Verbinski",
  "Gorô Miyazaki",
  "Gorô Taniguchi",
  "Graham Baker",
  "Graham Moore",
  "Granaz Moussavi",
  "Grant Harvey",
  "Grant Heslov",
  "Greg Marcks",
  "Greg McLean",
  "Greg Mottola",
  "Greg Tiernan",
  "Gregor Jordan",
  "Gregory Hoblit",
  "Gregory Jacobs",
  "Gregory La Cava",
  "Gregory Nava",
  "Greta Gerwig",
  "Guel Arraes",
  "Guillaume Canet",
  "Guillaume Gallienne",
  "Guillaume Pierret",
  "Guillem Morales",
  "Guillermo Arriaga",
  "Guillermo del Toro",
  "Gurinder Chadha",
  "Gus Van Sant",
  "Gustav Möller",
  "Gustavo Hernández",
  "Gustavo Loza",
  "Gustavo Taretto",
  "Guy Ferland",
  "Guy Hamilton",
  "Guy Nattiv",
  "Guy Ritchie",
  "György Pálfi",
  "Géla Babluani",
  "Gérard Corbiau",
  "Gérard Krawczyk",
  "Gérard Oury",
  "Götz Spielmann",
  "H. Tjut Djalil",
  "Ha Yoo",
  "Hadi Hajaig",
  "Hae-sung Song",
  "Hakan Algül",
  "Hal Ashby",
  "Hamid Nematollah",
  "Han Jae-rim",
  "Han-min Kim",
  "Hana Makhmalbaf",
  "Hang-jun Jang",
  "Hannes Holm",
  "Hannes Stöhr",
  "Hans Petter Moland",
  "Hans Weingartner",
  "Hans-Christian Schmid",
  "Hanu Raghavapudi",
  "Haoling Li",
  "Harald Reinl",
  "Harald Zwart",
  "Hardy Martins",
  "Hark Tsui",
  "Harmony Korine",
  "Harold Becker",
  "Harold Ramis",
  "Harry Bradbeer",
  "Haruo Sotozaki",
  "Hasan Karacadag",
  "Hatem Khraiche",
  "Hayao Miyazaki",
  "Hayato Date",
  "Hector Babenco",
  "Helmut Dietl",
  "Henri Verneuil",
  "Henri-Georges Clouzot",
  "Henrik Ruben Genz",
  "Henry Koster",
  "Henry Levin",
  "Henry Selick",
  "Hermine Huntgeburth",
  "Hernán Goldfrid",
  "Hettie Macdonald",
  "Hideaki Anno",
  "Hideo Nakata",
  "Higuchinsky",
  "Hirokazu Koreeda",
  "Hiromasa Yonebayashi",
  "Hiroshi Inagaki",
  "Hiroshi Teshigahara",
  "Hiroyuki Kitakubo",
  "Hiroyuki Morita",
  "Hiroyuki Okiura",
  "Hiroyuki Seshita",
  "Hiroyuki Yamashita",
  "Ho-joon Kim",
  "Homayoon Ass'adian",
  "Homayoun Ghanizadeh",
  "Hong Sang-soo",
  "Hosein Amiri Doumari",
  "Hossein Amini",
  "Houda Benyamina",
  "Houman Seyyedi",
  "Howard Hawks",
  "Hrishikesh Mukherjee",
  "Hu Guan",
  "Hugh Hudson",
  "Hugo Gélin",
  "Hun Jang",
  "Hunter Richards",
  "Hwan-kyung Lee",
  "Hwang Dong-hyuk",
  "Hyeon-jin Park",
  "Hyeong-Cheol Kang",
  "Hyeong-Joon Kim",
  "Hyun-seung Lee",
  "Hyung-rae Shim",
  "Hèctor Hernández Vicens",
  "Hélène Cattet",
  "Iain Softley",
  "Ian MacNaughton",
  "Icíar Bollaín",
  "Ida Panahandeh",
  "Ignacio Tatay",
  "Il Cho",
  "Il-gon Song",
  "Ilksen Basarir",
  "Ilya Naishuller",
  "Im Sang-soo",
  "Imtiaz Ali",
  "Indar Dzhendubaev",
  "Ingmar Bergman",
  "Ira Sachs",
  "Irvin Kershner",
  "Irving Pichel",
  "Isaac Florentine",
  "Isabel Coixet",
  "Isao Takahata",
  "Ishirô Honda",
  "Ismail Ciydem",
  "Issa López",
  "István Szabó",
  "Italo Zingarelli",
  "Ivan Engler",
  "Ivan Reitman",
  "J Blakeson",
  "J. Lee Thompson",
  "J.A. Bayona",
  "J.J. Abrams",
  "J.P. Schaefer",
  "JK Youn",
  "Jack Bender",
  "Jack Clayton",
  "Jack Fletcher",
  "Jackie Chan",
  "Jaco Van Dormael",
  "Jacob Tierney",
  "Jacques Audiard",
  "Jacques Becker",
  "Jacques Demy",
  "Jacques Deray",
  "Jacques Rivette",
  "Jacques Tati",
  "Jacques Tourneur",
  "Jae-Hoon Choi",
  "Jae-young Kwak",
  "Jafar Panahi",
  "Jake Scott",
  "Jake West",
  "Jakob M. Erwa",
  "Jalil Lespert",
  "Jalmari Helander",
  "James Algar",
  "James B. Harris",
  "James Cameron",
  "James Clavell",
  "James Cox",
  "James DeMonaco",
  "James Foley",
  "James Frawley",
  "James Gray",
  "James Griffiths",
  "James Gunn",
  "James Huth",
  "James Isaac",
  "James Ivory",
  "James Kent",
  "James L. Brooks",
  "James Mangold",
  "James Marsh",
  "James McTeigue",
  "James Oakley",
  "James Schamus",
  "James Wan",
  "James Ward Byrkit",
  "James Watkins",
  "James Whale",
  "James Wong",
  "Jamie Blanks",
  "Jamie Bradshaw",
  "Jamie M. Dagg",
  "Jan Belcl",
  "Jan Hrebejk",
  "Jan Komasa",
  "Jan Kounen",
  "Jan P. Matuszynski",
  "Jan Sverák",
  "Jan Troell",
  "Jan de Bont",
  "Jan-Ole Gerster",
  "Jane Campion",
  "Jang-Hoon Lee",
  "Jasmila Zbanic",
  "Jason Eisener",
  "Jason Howden",
  "Jason Krawczyk",
  "Jason Reitman",
  "Jason Woliner",
  "Jaume Balagueró",
  "Jaume Collet-Serra",
  "Javier Fesser",
  "Javier Fuentes-León",
  "Javier Ruiz Caldera",
  "Jay Baruchel",
  "Jay Chandrasekhar",
  "Jay Roach",
  "Jay Russell",
  "Jayro Bustamante",
  "Je-kyu Kang",
  "Jean Becker",
  "Jean Cocteau",
  "Jean Girault",
  "Jean Renoir",
  "Jean Vigo",
  "Jean-Baptiste Andrea",
  "Jean-Claude Lauzon",
  "Jean-Claude Van Damme",
  "Jean-François Richet",
  "Jean-Jacques Annaud",
  "Jean-Jacques Beineix",
  "Jean-Loup Felicioli",
  "Jean-Luc Godard",
  "Jean-Marc Vallée",
  "Jean-Marie Poiré",
  "Jean-Paul Rappeneau",
  "Jean-Paul Salomé",
  "Jean-Pierre Améris",
  "Jean-Pierre Dardenne",
  "Jean-Pierre Jeunet",
  "Jean-Pierre Melville",
  "Jean-Stéphane Sauvaire",
  "Jeannot Szwarc",
  "Jee-woon Kim",
  "Jeethu Joseph",
  "Jeff Baena",
  "Jeff Chan",
  "Jeff Fowler",
  "Jeff Kanew",
  "Jeff Nichols",
  "Jeff Renfroe",
  "Jeff Wadlow",
  "Jeffrey Nachmanoff",
  "Jemaine Clement",
  "Jen Soska",
  "Jennifer Kent",
  "Jennifer Lynch",
  "Jeong-beom Lee",
  "Jeong-hyang Lee",
  "Jeong-woo Park",
  "Jeremiah S. Chechik",
  "Jeremy Dyson",
  "Jeremy Gillespie",
  "Jeremy Haft",
  "Jerry Zucker",
  "Jerusha Hess",
  "Jerzy Hoffman",
  "Jesse Peretz",
  "Jessie Nelson",
  "Jesús Franco",
  "Jez Butterworth",
  "Ji-hoon Kim",
  "Ji-woo Jung",
  "Jieho Lee",
  "Jim Abrahams",
  "Jim Henson",
  "Jim Jarmusch",
  "Jim Malone",
  "Jim Mickle",
  "Jim Sharman",
  "Jim Sheridan",
  "Jing Wu",
  "Jingle Ma",
  "Joachim Rønning",
  "Joachim Trier",
  "Joann Sfar",
  "Jodie Foster",
  "Joe Carnahan",
  "Joe Charbanic",
  "Joe Cornish",
  "Joe D'Amato",
  "Joe Johnston",
  "Joe Mantello",
  "Joe Penna",
  "Joe Wright",
  "Joel Coen",
  "Joel Crawford",
  "Joel Schumacher",
  "Joel Zwick",
  "Johannes Roberts",
  "John Badham",
  "John Boorman",
  "John C. Donkin",
  "John Cameron Mitchell",
  "John Carney",
  "John Carpenter",
  "John Cassavetes",
  "John Crowley",
  "John Curran",
  "John Dahl",
  "John Duigan",
  "John Fawcett",
  "John Ford",
  "John Frankenheimer",
  "John G. Avildsen",
  "John Glen",
  "John Glenn",
  "John Gray",
  "John H. Lee",
  "John Herzfeld",
  "John Hillcoat",
  "John Hughes",
  "John Huston",
  "John Landis",
  "John Lasseter",
  "John Lee Hancock",
  "John Lounsbery",
  "John Luessenhop",
  "John Mackenzie",
  "John Maclean",
  "John Madden",
  "John Mathew Matthan",
  "John McTiernan",
  "John Michael McDonagh",
  "John Milius",
  "John Moore",
  "John Ottman",
  "John Pogue",
  "John Poliquin",
  "John R. Leonetti",
  "John Schlesinger",
  "John Schultz",
  "John Singleton",
  "John Sturges",
  "John Wells",
  "John Whitesell",
  "John Woo",
  "Johnnie To",
  "Joko Anwar",
  "Jon Amiel",
  "Jon Avnet",
  "Jon Cassar",
  "Jon Favreau",
  "Jon Harris",
  "Jon Knautz",
  "Jon Lucas",
  "Jon M. Chu",
  "Jon Poll",
  "Jon S. Baird",
  "Jon Turteltaub",
  "Jon Watts",
  "Jon Wright",
  "Jonas Elmer",
  "Jonas Åkerlund",
  "Jonathan Baker",
  "Jonathan Dayton",
  "Jonathan Demme",
  "Jonathan English",
  "Jonathan Glazer",
  "Jonathan Hensleigh",
  "Jonathan Kaplan",
  "Jonathan King",
  "Jonathan Levine",
  "Jonathan Lynn",
  "Jonathan Mostow",
  "Jonathan Sobol",
  "Jonathan Teplitzky",
  "Jong-Yeol Baek",
  "Jong-bin Yoon",
  "Jonás Cuarón",
  "Joo-hwan Kim",
  "Joon-Hwan Jang",
  "Joon-ik Lee",
  "Jordan Peele",
  "Jordan Scott",
  "Jorge Blanco",
  "Jorge Furtado",
  "Jorge Michel Grau",
  "Jorge R. Gutiérrez",
  "Jorma Taccone",
  "Josef von Sternberg",
  "Joseph Kosinski",
  "Joseph L. Mankiewicz",
  "Joseph Losey",
  "Joseph Mensch",
  "Joseph Ruben",
  "Joseph Sargent",
  "Joseph Vilsmaier",
  "Joseph Zito",
  "Josh Boone",
  "Josh Cooley",
  "Josh Gordon",
  "Josh Greenbaum",
  "Josh Trank",
  "Joshua Marston",
  "Josie Rourke",
  "Joss Whedon",
  "José Luis Cuerda",
  "José Padilha",
  "Juan Carlos Fresnadillo",
  "Juan Carlos Maneglia",
  "Juan Carlos Medina",
  "Juan José Campanella",
  "Juan Pablo Rebella",
  "Juan Piquer Simón",
  "Juan Solanas",
  "Juanfer Andrés",
  "Juanma Bajo Ulloa",
  "Juho Kuosmanen",
  "Jules Bass",
  "Jules Dassin",
  "Julia Ducournau",
  "Julian Gilbey",
  "Julian Jarrold",
  "Julian Pölsler",
  "Julian Schnabel",
  "Juliano Dornelles",
  "Julie Taymor",
  "Julien Duvivier",
  "Julien Leclercq",
  "Julien Magnat",
  "Julien Rambaldi",
  "Julien Temple",
  "Julio Medem",
  "Julius Avery",
  "Juliusz Machulski",
  "Jun Fukuda",
  "Jun'ichi Satô",
  "Jung Bum-shik",
  "Junpei Mizusaki",
  "Just Jaeckin",
  "Just Philippot",
  "Justin Chadwick",
  "Justin Kerrigan",
  "Justin Kurzel",
  "Justin Lin",
  "Jérémy Clapin",
  "Jérôme Enrico",
  "Jérôme Salle",
  "Jörg Buttgereit",
  "Jûzô Itami",
  "Kaare Andrews",
  "Kabir Khan",
  "Kaige Chen",
  "Kamal Tabrizi",
  "Kambuzia Partovi",
  "Kamil Aydin",
  "Kaneto Shindô",
  "Kanji Wakabayashi",
  "Kantemir Balagov",
  "Kar-Wai Wong",
  "Karan Johar",
  "Karen Shakhnazarov",
  "Kari Skogland",
  "Karim Aïnouz",
  "Kartal Tibet",
  "Karyn Kusama",
  "Kasi Lemmons",
  "Kasper Barfoed",
  "Kathrine Windfeld",
  "Kathryn Bigelow",
  "Katsuhiro Ôtomo",
  "Katsuhito Ishii",
  "Kazuaki Kiriya",
  "Kazuki Ômori",
  "Keenen Ivory Wayans",
  "Keiichi Hara",
  "Keishi Otomo",
  "Kelly Asbury",
  "Kelly Makin",
  "Kelly Reichardt",
  "Ken Annakin",
  "Ken Hughes",
  "Ken Kwapis",
  "Ken Loach",
  "Ken Marino",
  "Ken Russell",
  "Ken Scott",
  "Kenji Misumi",
  "Kenji Mizoguchi",
  "Kenji Nagasaki",
  "Kenneth Branagh",
  "Kenneth Lonergan",
  "Kenta Fukasaku",
  "Kerem Deren",
  "Kerry Conran",
  "Ketche",
  "Kevin Altieri",
  "Kevin Connolly",
  "Kevin Costner",
  "Kevin Greutert",
  "Kevin Kölsch",
  "Kevin Lima",
  "Kevin Macdonald",
  "Kevin Munroe",
  "Kevin R. Adams",
  "Kevin Reynolds",
  "Kevin Smith",
  "Kihachi Okamoto",
  "Kim Chapiron",
  "Kim Druzhinin",
  "Kim Ki-duk",
  "Kim Nguyen",
  "Kim Sung-hoon",
  "Kim Yong-hoon",
  "Kimo Stamboel",
  "King Vidor",
  "Kiranraj K",
  "Kirill Serebrennikov",
  "Kirk Jones",
  "Kirsten Sheridan",
  "Kit Ryan",
  "Kivanç Baruönü",
  "Kiyoshi Kurosawa",
  "Klaus Härö",
  "Kleber Mendonça Filho",
  "Klim Shipenko",
  "Koji Hashimoto",
  "Kon Ichikawa",
  "Konstantin Ershov",
  "Konstantin Khabenskiy",
  "Kore-eda Hirokazu",
  "Kornél Mundruczó",
  "Kresten Vestbjerg Andersen",
  "Kris Pearn",
  "Krishna D.K.",
  "Kristian Levring",
  "Kristoffer Nyholm",
  "Krzysztof Kieslowski",
  "Kunihiko Yuyama",
  "Kuntz Agus",
  "Kwang Il Han",
  "Kwang-Hyun Park",
  "Kwang-shik Kim",
  "Kyle Newacheck",
  "Kyung-taek Kwak",
  "Kôji Morimoto",
  "Kôji Shiraishi",
  "Ladislao Vajda",
  "Ladj Ly",
  "Laetitia Colombani",
  "Lake Bell",
  "Lamberto Bava",
  "Lana Wachowski",
  "Larisa Shepitko",
  "Larry Charles",
  "Larry Clark",
  "Lars Klevberg",
  "Lars Kraume",
  "Lars von Trier",
  "Larysa Kondracki",
  "Lasse Hallström",
  "Lasse Spang Olsen",
  "Laurence Dunmore",
  "Laurent Bouhnik",
  "Laurent Cantet",
  "Laurent Tirard",
  "Lawrence Kasdan",
  "Leander Haußmann",
  "Lee Chang-dong",
  "Lee Cronin",
  "Lee Hae-jun",
  "Lee Sang-geun",
  "Lee Tamahori",
  "Lee Toland Krieger",
  "Lee Unkrich",
  "Lee Unkrich, Adrian Molina",
  "Leigh Whannell",
  "Len Wiseman",
  "Lennart Ruff",
  "Lenny Abrahamson",
  "Leo McCarey",
  "Leo Zhang",
  "Leon Ichaso",
  "Leonard Nimoy",
  "Leonid Gaidai",
  "Leos Carax",
  "Leslie H. Martinson",
  "Levan Bakhia",
  "Levent Semerci",
  "Lewis Gilbert",
  "Lewis Milestone",
  "Lexi Alexander",
  "Liam Gavin",
  "Liam O'Donnell",
  "Liesl Tommy",
  "Liev Schreiber",
  "Liliana Cavani",
  "Lin Oeding",
  "Lina Wertmüller",
  "Lindsay Anderson",
  "Lisa Azuelos",
  "Lisa Cholodenko",
  "Lluís Quílez",
  "Lokesh Kanagaraj",
  "Lone Scherfig",
  "Louis Clichy",
  "Louis Leterrier",
  "Louis Malle",
  "Luc Besson",
  "Luca Guadagnino",
  "Luca Miniero",
  "Luchino Visconti",
  "Lucile Hadzihalilovic",
  "Lucio Fulci",
  "Lucky Kuswandi",
  "Lucky McKee",
  "Lucrecia Martel",
  "Lucía Puenzo",
  "Ludovic Bernard",
  "Luigi Comencini",
  "Luigi Cozzi",
  "Luis Buñuel",
  "Luis Estrada",
  "Luis García Berlanga",
  "Luis Llosa",
  "Luis Mandoki",
  "Luis Ortega",
  "Luis Piedrahita",
  "Luis Puenzo",
  "Lukas Dhont",
  "Lukas Feigelfeld",
  "Lukas Moodysson",
  "Lukasz Kosmicki",
  "Lukasz Palkowski",
  "Lynne Ramsay",
  "László Nemes",
  "Léa Pool",
  "M.J. Bassett",
  "Mabrouk El Mechri",
  "Madhavan",
  "Madonna",
  "Maggie Gyllenhaal",
  "Magnus Martens",
  "Mahiro Maeda",
  "Mahsun Kirmizigül",
  "Majid Majidi",
  "Makoto Kamiya",
  "Makoto Shinkai",
  "Malcolm D. Lee",
  "Malcolm Venville",
  "Malgorzata Szumowska",
  "Mamoru Hosoda",
  "Mamoru Oshii",
  "Mani Haghighi",
  "Manouchehr Hadi",
  "Manuel Martín Cuenca",
  "Mar Targarona",
  "Marc Abraham",
  "Marc Caro",
  "Marc Evans",
  "Marc Forster",
  "Marc Meyers",
  "Marc Rothemund",
  "Marc Webb",
  "Marcel Barrena",
  "Marcel Camus",
  "Marcel Carné",
  "Marcello Fondato",
  "Marcelo Piñeyro",
  "Marcin Wrona",
  "Marco Bellocchio",
  "Marco Berger",
  "Marco D'Amore",
  "Marco Ferreri",
  "Marco Kreuzpaintner",
  "Marco Schnabel",
  "Marco Tullio Giordana",
  "Marcus H. Rosenmüller",
  "Marcus Nispel",
  "Marek Koterski",
  "Maren Ade",
  "Margarethe von Trotta",
  "Mari Okada",
  "Maria Ripoll",
  "Maria Schrader",
  "Mariano Cohn",
  "Marie Kreutzer",
  "Marielle Heller",
  "Marina de Van",
  "Mario Bava",
  "Mario Gariazzo",
  "Mario Monicelli",
  "Marius A. Markevicius",
  "Marjane Satrapi",
  "Mark A. Lewis",
  "Mark Burton",
  "Mark Helfrich",
  "Mark Herman",
  "Mark L. Lester",
  "Mark Mylod",
  "Mark Osborne",
  "Mark Palansky",
  "Mark Penney",
  "Mark Raso",
  "Mark Robson",
  "Mark Romanek",
  "Mark Rosman",
  "Mark Tonderai",
  "Mark Waters",
  "Mark Williams",
  "Markus Goller",
  "Markus Schleinzer",
  "Martha Coolidge",
  "Martin Brest",
  "Martin Campbell",
  "Martin Gero",
  "Martin Koolhoven",
  "Martin McDonagh",
  "Martin Provost",
  "Martin Rosen",
  "Martin Scorsese",
  "Martin Zandvliet",
  "Martín Hodara",
  "Marvin Kren",
  "Mary Harron",
  "Maryam Keshavarz",
  "Maryam Moghadam",
  "Marzieh Makhmalbaf",
  "Marçal Forés",
  "Masaaki Yuasa",
  "Masahiro Andô",
  "Masahiro Hosoda",
  "Masaki Kobayashi",
  "Masashi Koizuka",
  "Masayuki",
  "Masayuki Suô",
  "Masoud Dehnamaki",
  "Masud Kimiai",
  "Mathieu Kassovitz",
  "Mathieu Turi",
  "Mathur Goswami",
  "Matt Angel",
  "Matt Brown",
  "Matt Dillon",
  "Matt Palmer",
  "Matt Piedmont",
  "Matt Reeves",
  "Matt Ross",
  "Matteo Garrone",
  "Matthew Heineman",
  "Matthew Ross",
  "Matthew Vaughn",
  "Matthew Warchus",
  "Matthias Hoene",
  "Matthias Schweighöfer",
  "Maurice Pialat",
  "Max Adams",
  "Max Färberböck",
  "Max Joseph",
  "Max Ophüls",
  "Maximilian Erlenwein",
  "May el-Toukhy",
  "Maziar Miri",
  "Maïmouna Doucouré",
  "Maïwenn",
  "McG",
  "Meghna Gulzar",
  "Mehdi Avaz",
  "Mehmet Ada Öztekin",
  "Mehmet Kurtulus",
  "Mehran Ahmadi",
  "Mehran Modiri",
  "Mel Brooks",
  "Mel Damski",
  "Mel Gibson",
  "Mel Smith",
  "Mel Stuart",
  "Melina Matsoukas",
  "Menhaj Huda",
  "Merian C. Cooper",
  "Mert Baykal",
  "Metin Erksan",
  "Mia Hansen-Løve",
  "Michael Anderson",
  "Michael Apted",
  "Michael Bay",
  "Michael Brandt",
  "Michael Caton-Jones",
  "Michael Cimino",
  "Michael Crichton",
  "Michael Cristofer",
  "Michael Cuesta",
  "Michael Curtiz",
  "Michael David Pate",
  "Michael Dougherty",
  "Michael Dowse",
  "Michael Engler",
  "Michael Goldbach",
  "Michael Grandage",
  "Michael Greenspan",
  "Michael Haneke",
  "Michael Herbig",
  "Michael Herz",
  "Michael Hoffman",
  "Michael Lehmann",
  "Michael Lembeck",
  "Michael Lockshin",
  "Michael Mann",
  "Michael McGowan",
  "Michael Moore",
  "Michael Oblowitz",
  "Michael Powell",
  "Michael Radford",
  "Michael Rianda",
  "Michael S. Ojeda",
  "Michael Sarnoski",
  "Michael Schaack",
  "Michael Spierig",
  "Michael Steiner",
  "Michael Sucsy",
  "Michael Winner",
  "Michael Winterbottom",
  "Michaël R. Roskam",
  "Michel Franco",
  "Michel Gondry",
  "Michel Hazanavicius",
  "Michel Leclerc",
  "Michel Ocelot",
  "Michelangelo Antonioni",
  "Michelangelo Frammartino",
  "Michele Massimo Tarantini",
  "Michele Placido",
  "Michele Soavi",
  "Mick Garris",
  "Mick Jackson",
  "Miguel Arteta",
  "Miguel Sapochnik",
  "Miguel Ángel Vivas",
  "Mikael Håfström",
  "Mikael Salomon",
  "Mike Barker",
  "Mike Binder",
  "Mike Clattenburg",
  "Mike Figgis",
  "Mike Hodges",
  "Mike Judge",
  "Mike Leigh",
  "Mike Marvin",
  "Mike McCoy",
  "Mike Mills",
  "Mike Newell",
  "Mike Nichols",
  "Mikhail Kalatozov",
  "Mikkel Munch-Fals",
  "Mikkel Nørgaard",
  "Milos Forman",
  "Mimi Leder",
  "Mira Nair",
  "Miranda July",
  "Mitja Okorn",
  "Mitsuo Hashimoto",
  "Mohammad Hossein Latifi",
  "Mohammad Hossein Mahdavian",
  "Mohammad Rasoulof",
  "Mohammad Reza Aslani",
  "Mohsen Amiryoussefi",
  "Mohsen Makhmalbaf",
  "Mona Achache",
  "Moon-saeng Kim",
  "Morgan Matthews",
  "Morgan O'Neill",
  "Mori Masaki",
  "Morten Tyldum",
  "Morton DaCosta",
  "Mostafa Kiayee",
  "Motoyoshi Oda",
  "Mouly Surya",
  "Moustapha Akkad",
  "Muharrem Gulmez",
  "Munehisa Sakai",
  "Mustafa Altioklar",
  "Mustafa Sevki Dogan",
  "Muye Wen",
  "Måns Mårlind",
  "Mélanie Laurent",
  "Na Hong-jin",
  "Nacer Khemir",
  "Nacho Cerdà",
  "Nacho G. Velilla",
  "Nacho Vigalondo",
  "Nadia Tass",
  "Nadine Labaki",
  "Nagisa Ôshima",
  "Naji Abu Nowar",
  "Nancy Meyers",
  "Nanni Moretti",
  "Naoko Yamada",
  "Naomi Kawase",
  "Narges Abyar",
  "Nash Edgerton",
  "Natalie Krinsky",
  "Nate Parker",
  "Nathan Greno",
  "Natuk Baytan",
  "Navot Papushado",
  "Neeraj Ghaywan",
  "Neeraj Pandey",
  "Neil Burger",
  "Neil Jordan",
  "Neil LaBute",
  "Neil Marshall",
  "Neill Blomkamp",
  "Nelson McCormick",
  "Nesli Çölgeçen",
  "Nia DaCosta",
  "Niall Johnson",
  "Nic Mathieu",
  "Nicholas Hytner",
  "Nicholas Jarecki",
  "Nicholas McCarthy",
  "Nicholas Meyer",
  "Nicholas Ray",
  "Nick Cassavetes",
  "Nick Hamm",
  "Nick Hurran",
  "Nick Love",
  "Nick Moore",
  "Nick Murphy",
  "Nick Park",
  "Nick Powell",
  "Nicolai Fuglsig",
  "Nicolas Bedos",
  "Nicolas Benamou",
  "Nicolas Cuche",
  "Nicolas Gessner",
  "Nicolas Roeg",
  "Nicolas Winding Refn",
  "Nicole Garcia",
  "Nicolás López",
  "Niels Arden Oplev",
  "Niels Mueller",
  "Nigel Cole",
  "Niki Caro",
  "Nikita Argunov",
  "Nikita Mikhalkov",
  "Nikkhil Advani",
  "Nikolai Müllerschön",
  "Nikolaj Arcel",
  "Nikolay Lebedev",
  "Nima Javidi",
  "Nimród Antal",
  "Nitesh Tiwari",
  "Noah Baumbach",
  "Noboru Iguchi",
  "Nobuhiko Ôbayashi",
  "Noel Clarke",
  "Nora Fingscheidt",
  "Nora Twomey",
  "Norio Tsuruta",
  "Norman Jewison",
  "Nuri Bilge Ceylan",
  "Oh-Seung Kwon",
  "Ol Parker",
  "Olaf Lubaszenko",
  "Olatunde Osunsanmi",
  "Ole Bornedal",
  "Ole Christian Madsen",
  "Oleg Stepchenko",
  "Oleg Trofim",
  "Oliver Hirschbiegel",
  "Oliver Kienle",
  "Oliver Parker",
  "Oliver Stone",
  "Olivier Assayas",
  "Olivier Dahan",
  "Olivier Marchal",
  "Olivier Megaton",
  "Olivier Nakache",
  "Omar Naim",
  "Onur Bilgetay",
  "Onur Ünlü",
  "Oren Uziel",
  "Orhan Aksoy",
  "Oriol Paulo",
  "Orson Welles",
  "Oskar Roehler",
  "Osman Sinav",
  "Otto Preminger",
  "Oz Perkins",
  "Ozan Açiktan",
  "P.J. Hogan",
  "Pablo Agüero",
  "Pablo Berger",
  "Pablo Larraín",
  "Pablo Trapero",
  "Paco León",
  "Paco Plaza",
  "Paddy Considine",
  "Panah Panahi",
  "Panos Cosmatos",
  "Pantelis Voulgaris",
  "Paolo Genovese",
  "Paolo Sorrentino",
  "Paolo Virzì",
  "Park Chan-wook",
  "Park Hoon-jung",
  "Pascal Chaumeil",
  "Pascal Laugier",
  "Patrice Chéreau",
  "Patrice Laliberté",
  "Patrice Leconte",
  "Patricia Riggen",
  "Patricia Rozema",
  "Patrick Alessandrin",
  "Patrick Brice",
  "Patrick Hughes",
  "Patrick Imbert",
  "Patrick Lussier",
  "Patrick Vollrath",
  "Patryk Vega",
  "Patty Jenkins",
  "Patxi Amezcua",
  "Paul Bolger",
  "Paul Downs Colaizzo",
  "Paul Feig",
  "Paul Greengrass",
  "Paul Haggis",
  "Paul Hunter",
  "Paul King",
  "Paul Mazursky",
  "Paul McGuigan",
  "Paul Michael Glaser",
  "Paul Schrader",
  "Paul Thomas Anderson",
  "Paul Tibbitt",
  "Paul Verhoeven",
  "Paul W.S. Anderson",
  "Paul Wegener",
  "Paul Weiland",
  "Paulo Morelli",
  "Pavel Chukhray",
  "Pavel Lungin",
  "Pawel Pawlikowski",
  "Payman Maadi",
  "Pedro Almodóvar",
  "Penny Marshall",
  "Pernille Fischer Christensen",
  "Pete Docter",
  "Pete Docter, Ronnie Del Carmen",
  "Pete Travis",
  "Peter Berg",
  "Peter Bogdanovich",
  "Peter Brook",
  "Peter Cattaneo",
  "Peter Chelsom",
  "Peter Cornwell",
  "Peter Del Monte",
  "Peter Farrelly",
  "Peter Flinth",
  "Peter Greenaway",
  "Peter Hewitt",
  "Peter Howitt",
  "Peter Hutchings",
  "Peter Hyams",
  "Peter Jackson",
  "Peter Kosminsky",
  "Peter Landesman",
  "Peter Lepeniotis",
  "Peter Lord",
  "Peter MacDonald",
  "Peter Medak",
  "Peter Mullan",
  "Peter R. Hunt",
  "Peter Segal",
  "Peter Stebbings",
  "Peter Thorwarth",
  "Peter Webber",
  "Peter Weir",
  "Peter Yates",
  "Peyman Ghassemkhani",
  "Peyton Reed",
  "Phil Alden Robinson",
  "Phil Claydon",
  "Phil Joanou",
  "Phil Lord",
  "Phil Morrison",
  "Phil Nibbelink",
  "Phil Traill",
  "Philip Barantini",
  "Philip G. Atwell",
  "Philip Kaufman",
  "Philipp Stölzl",
  "Philippe Claudel",
  "Philippe Falardeau",
  "Philippe Grimond",
  "Philippe Lacheau",
  "Philippe Le Guay",
  "Philippe Lioret",
  "Philippe de Chauveron",
  "Phillip Noyce",
  "Phyllida Lloyd",
  "Pidi Baiq",
  "Pier Paolo Pasolini",
  "Pierre Coffin",
  "Pierre Morel",
  "Pierre Salvadori",
  "Pietro Germi",
  "Pietro Marcello",
  "Pif",
  "Pil-sung Yim",
  "Pino Van Lamsweerde",
  "Pitof",
  "Pouran Derakhshandeh",
  "Prashanth Neel",
  "Preston Sturges",
  "Priyadarshan",
  "Putrama Tuta",
  "Pyotr Buslov",
  "Quentin Dupieux",
  "Quentin Tarantino",
  "R. Balki",
  "Rachel Talalay",
  "Rachid Bouchareb",
  "Radu Jude",
  "Radu Mihaileanu",
  "Rahi Anil Barve",
  "Rainer Werner Fassbinder",
  "Raja Gosnell",
  "Raja Menon",
  "Rajkumar Hirani",
  "Rajkumar Santoshi",
  "Rakeysh Omprakash Mehra",
  "Rakhshan Banietemad",
  "Ralf Huettner",
  "Ralph Bakshi",
  "Ralph Fiennes",
  "Ram Kumar",
  "Raman Hui",
  "Rambod Javan",
  "Ramesh Sippy",
  "Randall Wallace",
  "Ranjit Tewari",
  "Raoul Peck",
  "Raoul Walsh",
  "Rasool Mollagholi Poor",
  "Rasoul Sadrameli",
  "Rawson Marshall Thurber",
  "Ray Goossens",
  "Ray Kellogg",
  "Raúl Arévalo",
  "Rebecca Hall",
  "Rebecca Miller",
  "Reed Morano",
  "Remi Weekes",
  "Renato De Maria",
  "Renny Harlin",
  "René Cardona",
  "René Clément",
  "René Goscinny",
  "René Laloux",
  "Reza Attaran",
  "Reza Bagher",
  "Reza Dormishian",
  "Reza Mirkarimi",
  "Rian Johnson",
  "Ric Roman Waugh",
  "Riccardo Milani",
  "Rich Moore",
  "Richard Attenborough",
  "Richard Ayoade",
  "Richard Benjamin",
  "Richard Berry",
  "Richard Brooks",
  "Richard Curtis",
  "Richard Donner",
  "Richard Eyre",
  "Richard Fleischer",
  "Richard Franklin",
  "Richard Glatzer",
  "Richard J. Lewis",
  "Richard Kelly",
  "Richard Kwietniowski",
  "Richard Lester",
  "Richard Linklater",
  "Richard Loncraine",
  "Richard Marquand",
  "Richard Schenkman",
  "Richard Shepard",
  "Rick Moranis",
  "Rick Rosenthal",
  "Ricky Gervais",
  "Ridley Scott",
  "Riley Stearns",
  "Rintarô",
  "Riri Riza",
  "Rishab Shetty",
  "Ritesh Batra",
  "Rizal Mantovani",
  "Roar Uthaug",
  "Rob Bowman",
  "Rob Cohen",
  "Rob Hedden",
  "Rob Letterman",
  "Rob Marshall",
  "Rob Reiner",
  "Rob Savage",
  "Rob Thomas",
  "Rob W. King",
  "Rob Zombie",
  "Robert Aldrich",
  "Robert Altman",
  "Robert B. Weide",
  "Robert Benton",
  "Robert Bresson",
  "Robert Clouse",
  "Robert Connolly",
  "Robert D. Krzykowski",
  "Robert De Niro",
  "Robert Eggers",
  "Robert Hamer",
  "Robert Harmon",
  "Robert Houston",
  "Robert Longo",
  "Robert Mulligan",
  "Robert Redford",
  "Robert Rodriguez",
  "Robert Rossen",
  "Robert Schwentke",
  "Robert Stevenson",
  "Robert Wiene",
  "Robert Wise",
  "Robert Young",
  "Robert Z. Leonard",
  "Robert Zemeckis",
  "Roberto Benigni",
  "Roberto De Feo",
  "Roberto Rossellini",
  "Robin Aubert",
  "Robin Budd",
  "Robin Campillo",
  "Robin Pront",
  "Robin Wright",
  "Rocky Soraya",
  "Rod Lurie",
  "Rodrigo Cortés",
  "Rodrigo García",
  "Rodrigo Grande",
  "Rodrigo Plá",
  "Rodrigo Sorogoyen",
  "Roger Allers",
  "Roger Donaldson",
  "Roger Gual",
  "Roger Kumble",
  "Roger Michell",
  "Roger Spottiswoode",
  "Roger Vadim",
  "Roland Emmerich",
  "Roland Joffé",
  "Rolf Schübel",
  "Romain Gavras",
  "Roman Coppola",
  "Roman Polanski",
  "Ron Clements",
  "Ron Howard",
  "Ron Shelton",
  "Ronald Neame",
  "Roni Ezra",
  "Ronny Yu",
  "Rosalind Ross",
  "Roschdy Zem",
  "Rose Bosch",
  "Rose Glass",
  "Rowan Joffe",
  "Roxanne Benjamin",
  "Roy Andersson",
  "Roy Ward Baker",
  "Ruairi Robinson",
  "Ruben Alves",
  "Ruben Östlund",
  "Rudy Soedjarwo",
  "Ruggero Deodato",
  "Rupert Goold",
  "Rupert Sanders",
  "Rupert Wainwright",
  "Rupert Wyatt",
  "Russell Crowe",
  "Russell Mulcahy",
  "Ryûhei Kitamura",
  "Ryûsuke Hamaguchi",
  "Régis Roinsard",
  "Régis Wargnier",
  "Rémi Bezançon",
  "Rémy Belvaux",
  "S. Craig Zahler",
  "S.S. Rajamouli",
  "Sacha Gervasi",
  "Saeed Roustayi",
  "Saeed Soheili",
  "Safi Yazdanian",
  "Safy Nebbou",
  "Saket Chaudhary",
  "Sally El Hosaini",
  "Sally Potter",
  "Salvatore Samperi",
  "Sam Fell",
  "Sam Levinson",
  "Sam Mendes",
  "Sam Peckinpah",
  "Sam Raimi",
  "Sam Taylor-Johnson",
  "Sam Wood",
  "Samira Makhmalbaf",
  "Sandra Nettelbeck",
  "Sang-Jin Kim",
  "Sang-ho Yeon",
  "Sang-yong Lee",
  "Sang-yoon Lim",
  "Sanjay Gadhvi",
  "Sanjay Leela Bhansali",
  "Sanping Han",
  "Santiago Mitre",
  "Santiago Segura",
  "Sara Colangelo",
  "Sarah Gavron",
  "Sarah Polley",
  "Sarah Smith",
  "Sarik Andreasyan",
  "Satoshi Kon",
  "Satyajit Ray",
  "Saul Dibb",
  "Scott Cooper",
  "Scott Derrickson",
  "Scott Hicks",
  "Scott Mann",
  "Sean Durkin",
  "Sean Ellis",
  "Sean Garrity",
  "Sean McNamara",
  "Sean Penn",
  "Sebastian Meise",
  "Sebastian Schipper",
  "Sebastián Borensztein",
  "Sebastián Cordero",
  "Sebastián Lelio",
  "Sebastián Silva",
  "Seijun Suzuki",
  "Selçuk Aydemir",
  "Semih Kaplanoglu",
  "Seong Hun Kim",
  "Seong-Hu Park",
  "Serdar Akar",
  "Sergei Bodrov",
  "Sergei Eisenstein",
  "Sergey Bondarchuk",
  "Sergey Mokritskiy",
  "Sergio Castellitto",
  "Sergio Corbucci",
  "Sergio Leone",
  "Sergio Martino",
  "Sergio Pablos",
  "Sergio Tovar Velarde",
  "Serif Gören",
  "Sermiyan Midyat",
  "Serra Yilmaz",
  "Seth Gordon",
  "Seung-Won Lee",
  "Seung-wan Ryu",
  "Severin Fiala",
  "Shahram Mokri",
  "Shane Acker",
  "Shane Black",
  "Shane Meadows",
  "Sharon Maguire",
  "Shawn Levy",
  "Shawn Linden",
  "Shekhar Kapur",
  "Sheldon Lettich",
  "Sheng Ding",
  "Shigeyasu Yamauchi",
  "Shimit Amin",
  "Shin'ichirô Ueda",
  "Shin'ichirô Ushijima",
  "Shin'ichirô Watanabe",
  "Shin'ya Tsukamoto",
  "Shin-yeon Won",
  "Shinji Aramaki",
  "Shinsuke Sato",
  "Shirin Neshat",
  "Shona Auerbach",
  "Shoojit Sircar",
  "Shunji Iwai",
  "Shôhei Imamura",
  "Shûsuke Kaneko",
  "Sian Heder",
  "Sidney J. Furie",
  "Sidney Lumet",
  "Silvia Brunelli",
  "Silvio Soldini",
  "Simon Curtis",
  "Simon Hunter",
  "Simon Kinberg",
  "Simon Stone",
  "Simon Verhoeven",
  "Simon Wells",
  "Simon West",
  "Simon Wincer",
  "Sinan Çetin",
  "Sion Sono",
  "Siu-Tung Ching",
  "Sngmoo Lee",
  "Sofia Coppola",
  "Soheil Beiraghi",
  "Soi Cheang",
  "Sophie Barthes",
  "Sophie Hyde",
  "Sorush Sehhat",
  "Spike Jonze",
  "Spike Lee",
  "Sriram Raghavan",
  "Stanislaw Bareja",
  "Stanley Donen",
  "Stanley Kramer",
  "Stanley Kubrick",
  "Stanley Tong",
  "Stefan Fjeldmark",
  "Stefan Ruzowitzky",
  "Stefano Mordini",
  "Stefano Sollima",
  "Stefen Fangmeier",
  "Stelvio Massi",
  "Stephan Elliott",
  "Stephan Lacant",
  "Stephen Chbosky",
  "Stephen Chow",
  "Stephen Daldry",
  "Stephen Frears",
  "Stephen Gaghan",
  "Stephen Herek",
  "Stephen Hopkins",
  "Stephen Merchant",
  "Stephen Norrington",
  "Stephen Sommers",
  "Steve 'Spaz' Williams",
  "Steve Beck",
  "Steve Bendelack",
  "Steve Box",
  "Steve Buscemi",
  "Steve Carr",
  "Steve Carver",
  "Steve McQueen",
  "Steve Miner",
  "Steve Pink",
  "Steve Rash",
  "Steven C. Miller",
  "Steven Caple Jr.",
  "Steven Knight",
  "Steven Kostanski",
  "Steven Quale",
  "Steven S. DeKnight",
  "Steven Silver",
  "Steven Soderbergh",
  "Steven Spielberg",
  "Stewart Hendler",
  "Stuart Gordon",
  "Stuart Hazeldine",
  "Stuart Rosenberg",
  "Stuart Townsend",
  "Sturla Gunnarsson",
  "Stéphane Aubier",
  "Stéphane Berla",
  "Stéphane Brizé",
  "Stéphane Rybojad",
  "Su-chang Kong",
  "Sudha Kongara",
  "Sujoy Ghosh",
  "Sunao Katabuchi",
  "Sung-hee Jo",
  "Sung-ho Hong",
  "Sung-su Kim",
  "Susanna Fogel",
  "Susanna White",
  "Susanne Bier",
  "Sven Unterwaldt Jr.",
  "Sydney Pollack",
  "Sydney Sibilia",
  "Sylvain Chomet",
  "Sylvester Stallone",
  "Sönke Wortmann",
  "Søren Kragh-Jacobsen",
  "T.J. Gnanavel",
  "Tadayoshi Yamamuro",
  "Tae-gyun Kim",
  "Taichi Ishidate",
  "Taika Waititi",
  "Takahiro Ômori",
  "Takao Okawara",
  "Takashi Miike",
  "Takashi Shimizu",
  "Takashi Yamazaki",
  "Takashi Ôtsuka",
  "Takeru Nakajima",
  "Takeshi Kitano",
  "Takeshi Nozue",
  "Tamra Davis",
  "Tanya Wexler",
  "Tao Hai",
  "Tarsem Singh",
  "Tassos Boulmetis",
  "Tate Taylor",
  "Tatsuya Ishihara",
  "Tatsuya Nagamine",
  "Tayfun Güneyer",
  "Taylor Hackford",
  "Taylor Sheridan",
  "Ted Kotcheff",
  "Teddy Chan",
  "Ten Shimoyama",
  "Tensai Okamura",
  "Terence Young",
  "Terrence Malick",
  "Terry George",
  "Terry Gilliam",
  "Terry Jones",
  "Terry Zwigoff",
  "Tetsuro Kodama",
  "Tetsurô Araki",
  "Tetsuya Nakashima",
  "Tetsuya Nomura",
  "Thea Sharrock",
  "Theodore Melfi",
  "Thomas Jahn",
  "Thomas Kail",
  "Thomas Schlamme",
  "Thomas Sieben",
  "Thomas Stuber",
  "Thomas Vinterberg",
  "Thor Freudenthal",
  "Ti West",
  "Tibor Takács",
  "Tigmanshu Dhulia",
  "Til Schweiger",
  "Tim Burton",
  "Tim Fehlbaum",
  "Tim Fywell",
  "Tim Hill",
  "Tim Miller",
  "Tim Robbins",
  "Tim Story",
  "Timo Tjahjanto",
  "Timo Vuorensola",
  "Timur Bekmambetov",
  "Tinto Brass",
  "Toa Fraser",
  "Tobe Hooper",
  "Tobi Baumann",
  "Tobias Lindholm",
  "Toby Meakins",
  "Tod Browning",
  "Todd Haynes",
  "Todd Phillips",
  "Todd Solondz",
  "Tolga Karaçelik",
  "Tolga Örnek",
  "Tom George",
  "Tom Harper",
  "Tom Hooper",
  "Tom Kuntz",
  "Tom McCarthy",
  "Tom McCharty",
  "Tom McGrath",
  "Tom Shankland",
  "Tom Stoppard",
  "Tom Tykwer",
  "Tom Vaughan",
  "Tomas Alfredson",
  "Tomm Moore",
  "Tommy Lee Jones",
  "Tommy Lee Wallace",
  "Tommy O'Haver",
  "Tommy Wirkola",
  "Tomohiko Itô",
  "Tomás Gutiérrez Alea",
  "Tonino Valerii",
  "Tono Errando",
  "Tony Bancroft",
  "Tony Bill",
  "Tony Cervone",
  "Tony Elliott",
  "Tony Gatlif",
  "Tony Giglio",
  "Tony Gilroy",
  "Tony Goldwyn",
  "Tony Kaye",
  "Tony Randel",
  "Tony Scott",
  "Toshio Matsumoto",
  "Toshiyuki Kubooka",
  "Toyoo Ashida",
  "Travis Knight",
  "Trey Edward Shults",
  "Trey Parker",
  "Trish Sie",
  "Troy Duffy",
  "Troy Nixey",
  "Tunç Basaran",
  "Tyler Shields",
  "Ugur Yücel",
  "Ui-seok Jo",
  "Uli Edel",
  "Ulli Lommel",
  "Ulrich Seidl",
  "Umberto Lenzi",
  "Umesh Shukla",
  "Umur Turagay",
  "Upi Avianto",
  "Ursula Meier",
  "Uwe Boll",
  "Vadim Perelman",
  "Vahid Jalilvand",
  "Valeriy Todorovskiy",
  "Valeska Grisebach",
  "Vaughn Stein",
  "Vetrimaaran",
  "Vic Armstrong",
  "Vicco von Bülow",
  "Vicente Villanueva",
  "Victor Cook",
  "Victor Fleming",
  "Victor Levin",
  "Victor Salva",
  "Vikas Bahl",
  "Vikramaditya Motwane",
  "Vince Marcello",
  "Vincent Gallo",
  "Vincent Paronnaud",
  "Vincenzo Natali",
  "Virginie Despentes",
  "Vishal Bhardwaj",
  "Vishnuvardhan",
  "Vittorio De Sica",
  "Vladimir Menshov",
  "Volker Schlöndorff",
  "Václav Marhoul",
  "Víctor Erice",
  "W.S. Van Dyke",
  "Wagner Moura",
  "Wally Pfister",
  "Walt Becker",
  "Walter Hill",
  "Walter Murch",
  "Walter Salles",
  "Warren Beatty",
  "Wash Westmoreland",
  "Wayne Kramer",
  "Wen Jiang",
  "Werner Herzog",
  "Wes Anderson",
  "Wes Ball",
  "Wes Craven",
  "Whit Stillman",
  "Will Gluck",
  "Will Wernick",
  "William A. Seiter",
  "William Brent Bell",
  "William Cottrell",
  "William Friedkin",
  "William Heins",
  "William Malone",
  "William Monahan",
  "William Oldroyd",
  "William Phillips",
  "William Wyler",
  "Wilson Yip",
  "Wim Wenders",
  "Wladyslaw Pasikowski",
  "Wojciech Has",
  "Wojciech Smarzowski",
  "Wolfgang Becker",
  "Wolfgang Büld",
  "Wolfgang Murnberger",
  "Wolfgang Petersen",
  "Wolfgang Reitherman",
  "Won-Chan Hong",
  "Won-Tae Lee",
  "Woo-Ping Yuen",
  "Woo-Suk Kang",
  "Woo-ri Bang",
  "Woo-seok Yang",
  "Woody Allen",
  "Wregas Bhanuteja",
  "Xaver Schwarzenberger",
  "Xavier Beauvois",
  "Xavier Dolan",
  "Xavier Gens",
  "Xavier Legrand",
  "Xavier Villaverde",
  "Xiaogang Feng",
  "Xuan Liang",
  "Yang Ik-joon",
  "Yann Demange",
  "Yann Gozlan",
  "Yann Samuell",
  "Yannick Dahan",
  "Yasemin Samdereli",
  "Yash Chopra",
  "Yasuhiro Yoshiura",
  "Yasujirô Ozu",
  "Yavuz Turgul",
  "Yi'nan Diao",
  "Yilmaz Erdogan",
  "Yimou Zhang",
  "Yong-hwa Kim",
  "Yoon Sunghyun",
  "Yoon-Seong Kang",
  "Yorgos Lanthimos",
  "Yoshiaki Kawajiri",
  "Yoshifumi Kondô",
  "Yoshihiro Nishimura",
  "Yoshihiro Ueda",
  "You-min Seo",
  "Young-Tak Kim",
  "Younuts",
  "Yu Yang",
  "Yuen Fai Ng",
  "Yuriy Bykov",
  "Yves Robert",
  "Yôji Yamada",
  "Yôjirô Takita",
  "Yüksel Aksu",
  "Zach Helm",
  "Zack Snyder",
  "Zack Whedon",
  "Zak Hilditch",
  "Zal Batmanglij",
  "Zaza Urushadze",
  "Zeki Demirkubuz",
  "Zeki Ökten",
  "Zev Berman",
  "Zhan Xiao",
  "Zhangke Jia",
  "Zoya Akhtar",
  "Zübeyr Sasmaz",
  "Zülfü Livaneli",
  "Álex de la Iglesia",
  "Álvaro Brechner",
  "Ángel Gómez Hernández",
  "Çagan Irmak",
  "Édouard Molinaro",
  "Éric Lartigau",
  "Éric Rohmer",
  "Étienne Chatiliez",
  "Ömer Faruk Sorak",
  "Ömer Vargi",
  "Ömür Atay",
  "Özcan Alper",
  "Özer Feyzioglu",
  "Özer Kiziltan",
  "Özhan Eren",
];
