export const links = [
    {
      id: 1,
      url: "/",
      text: "Home",
    },
    /*{
      id: 2,
      url: "/about",
      text: "About",
    },*/
    {
      id: 3,
      url: "/Movietest",
      text: "Take The Test",
    },
    {
      id:4,
      url: "/blog",
      text:"Blog",
    },
    {
      id:5,
      url: "/quiz",
      text:"Weekly Quiz",
    }
  ];