export const actors = [
  "50 Cent",
  "A.C. Peterson",
  "A.J. Cook",
  "AJ Bowen",
  "AJ Meijer",
  "Aamir Khan",
  "Aaran Thomas",
  "Aaron Abrams",
  "Aaron Ashmore",
  "Aaron Douglas",
  "Aaron Eckhart",
  "Aaron Harris",
  "Aaron Hilmer",
  "Aaron Paul",
  "Aaron Poole",
  "Aaron Taylor-Johnson",
  "Abbas Alijome",
  "Abbas Astaneh",
  "Abbas Nazeri",
  "Abbas Sayah",
  "Abbi Jacobson",
  "Abbie Cornish",
  "Abbie Murphy",
  "Abdel Ahmed Ghili",
  "Abdelhamid Aktouche",
  "Abdolali Hoseinali",
  "Abdolrahman Bagheri",
  "Abel Dolz Doval",
  "Abel Jafri",
  "Abel Tripaldi",
  "Abhay Deol",
  "Abhin Galeya",
  "Abhishek Bachchan",
  "Abigail Breslin",
  "Abimana Aryasatya",
  "Abolfazl Ahankhah",
  "Abra",
  "Abraham Attah",
  "Acha Septriasa",
  "Achmad Megantara",
  "Achyuth Kumar",
  "Ada Philine Stappenbeck",
  "Adam Arkin",
  "Adam Bakri",
  "Adam Baldwin",
  "Adam Bay",
  "Adam Bousdoukos",
  "Adam Brody",
  "Adam Butcher",
  "Adam Deacon",
  "Adam Devine",
  "Adam DiMarco",
  "Adam Driver",
  "Adam James",
  "Adam LaVorgna",
  "Adam Pawlikowski",
  "Adam Sandler",
  "Adam Sevani",
  "Adam Trese",
  "Adam Turczyk",
  "Adam West",
  "Adam Woronowicz",
  "Adama Niane",
  "Adamo Dionisi",
  "Adan Jodorowsky",
  "Adar Beck",
  "Addison Christie",
  "Addison Timlin",
  "Adeel Akhtar",
  "Adel Bencherif",
  "Adelaide Clemens",
  "Adelfa Calvo",
  "Adesh Prasad",
  "Adewale Akinnuoye-Agbaje",
  "Adhisty Zara",
  "Adil Hussain",
  "Adile Nasit",
  "Adinda Thomas",
  "Adinia Wirasti",
  "Adithya Bhaskar",
  "Adnan Maral",
  "Adolfo Celi",
  "Adolfo Jiménez Castro",
  "Adolphe Menjou",
  "Adria Arjona",
  "Adrian Alonso",
  "Adrian G. Griffiths",
  "Adrian Grenier",
  "Adrian Holmes",
  "Adrian Hoven",
  "Adrian Zaremba",
  "Adriana Altaras",
  "Adriana Asti",
  "Adriana Caselotti",
  "Adriana Mocca",
  "Adriana Paz",
  "Adriana Ugarte",
  "Adriano Giannini",
  "Adriano Tardiolo",
  "Adrien Borel",
  "Adrien Brody",
  "Adrienne Servantie",
  "Adrià Salazar",
  "Adèle Exarchopoulos",
  "Adèle Haenel",
  "Afiya Bennett",
  "Afsaneh Bayegan",
  "Afshin Hashemi",
  "Afshin Khorshid Bakhtiari",
  "Agame Malembo-Emene",
  "Agata Buzek",
  "Agata Kulesza",
  "Agata Trzebuchowska",
  "Agathe Natanson",
  "Agathe Rousselle",
  "Aghniny Haque",
  "Agne Grudyte",
  "Agnes Brekke",
  "Agnes Bruckner",
  "Agnes Kittelsen",
  "Agnes Moorehead",
  "Agnese Graziani",
  "Agnieszka Dygant",
  "Agnieszka Mandat",
  "Agnieszka Zulewska",
  "Agniya Kuznetsova",
  "Agnès Jaoui",
  "Agostino Salvietti",
  "Agustí Villaronga",
  "Agyness Deyn",
  "Ahmad Khan Mahmoodzada",
  "Ahmaogak Sweeney",
  "Ahmed Ahmed Poor",
  "Ahmed Sylla",
  "Ahmet Kural",
  "Ahmet Mekin",
  "Ahmet Mümtaz Taylan",
  "Ahmet Rifat Sungar",
  "Ahmet Ugurlu",
  "Ahmet Yilmaz",
  "Ahmet Çevik",
  "Ahn Nae-sang",
  "Ahna Capri",
  "Ahney Her",
  "Ahu Türkpençe",
  "Ai Kakuma",
  "Ai Kobayashi",
  "Ai Maeda",
  "Ai Matsubara",
  "Aicha Abadir",
  "Aida Mohammadkhani",
  "Aidan Gillen",
  "Aidan Quinn",
  "Aidan Turner",
  "Aiden Longworth",
  "Aiko Nagayama",
  "Aimee Graham",
  "Aishwarya Rai Bachchan",
  "Aisling Franciosi",
  "Aitana Sánchez-Gijón",
  "Aixa Villagrán",
  "Ajay Devgn",
  "Ajay Naidu",
  "Akari Kitô",
  "Akbar Abdi",
  "Akbar Zanjanpour",
  "Akemi Okamura",
  "Akemi Yamaguchi",
  "Akhilesh Jaiswal",
  "Aki Maeda",
  "Aki Takejô",
  "Akihiko Hirata",
  "Akihiro Miwa",
  "Akiji Kobayashi",
  "Akiko Iwase",
  "Akiko Wakabayashi",
  "Akio Ôtsuka",
  "Akira Emoto",
  "Akira Ishida",
  "Akira Ishihama",
  "Akira Kamiya",
  "Akira Kubo",
  "Akira Takarada",
  "Akira Terao",
  "Aksel Hennie",
  "Akshay Kumar",
  "Akshaye Khanna",
  "Aku Hirviniemi",
  "Al Cliver",
  "Al Ernest Garcia",
  "Al Pacino",
  "Al Sapienza",
  "Al White",
  "Alain Chabat",
  "Alain Cuny",
  "Alain Delon",
  "Alain Gagnol",
  "Alain Hernández",
  "Alain Jouffroy",
  "Alain Lathière",
  "Alain Lino Mic Eli Bastien",
  "Alakina Mann",
  "Alan Alda",
  "Alan Arkin",
  "Alan Badel",
  "Alan Chávez",
  "Alan Cumming",
  "Alan Daicz",
  "Alan Davies",
  "Alan Ford",
  "Alan Howard",
  "Alan Mak",
  "Alan Randolph Scott",
  "Alan Rickman",
  "Alan Ruck",
  "Alan Scarfe",
  "Alan Smyth",
  "Alan Tudyk",
  "Alan Van Sprang",
  "Alan Webb",
  "Alana Haim",
  "Alanna Ubach",
  "Alaska",
  "Alastair Sim",
  "Alba August",
  "Alba Baptista",
  "Alba Ribas",
  "Alba Rohrwacher",
  "Alban Ivanov",
  "Alban Lenoir",
  "Alban Ukaj",
  "Albert Brooks",
  "Albert Carbó",
  "Albert Delpy",
  "Albert Dupontel",
  "Albert Finney",
  "Albert Rémy",
  "Albert Steinrück",
  "Albert Uderzo",
  "Alberto Ammann",
  "Alberto Di Stasio",
  "Alberto Jiménez",
  "Alberto Morin",
  "Alberto Romea",
  "Alberto San Juan",
  "Alberto de Mendoza",
  "Albrecht Schuch",
  "Alden Ehrenreich",
  "Aldis Hodge",
  "Aldo Baglio",
  "Aldo Bufi Landi",
  "Aldo Fabrizi",
  "Aldo Giuffrè",
  "Aldo Puglisi",
  "Aldo Ray",
  "Aldo Silvani",
  "Aldo Valletti",
  "Aldona Struzik",
  "Alec Baldwin",
  "Alec Cawthorne",
  "Alec Guinness",
  "Alec Newman",
  "Alec Secareanu",
  "Aleisha Allen",
  "Alejandro Agresti",
  "Alejandro Jodorowsky",
  "Alejandro Rey",
  "Alejandro de la Madrid",
  "Alejo Sauras",
  "Aleksandar Jovanovic",
  "Aleksander Bardini",
  "Aleksandr Antonov",
  "Aleksandr Baluev",
  "Aleksandr Chutko",
  "Aleksandr Demidov",
  "Aleksandr Demyanenko",
  "Aleksandr Domogarov",
  "Aleksandr Dulerayn",
  "Aleksandr Fatyushin",
  "Aleksandr Gorchilin",
  "Aleksandr Kaydanovskiy",
  "Aleksandr Khvylya",
  "Aleksandr Korshunov",
  "Aleksandr Mikhaylov",
  "Aleksandr Ptushko",
  "Aleksandr Robak",
  "Aleksandr Samoylenko",
  "Aleksandr Shvorin",
  "Aleksandr Voytinskiy",
  "Aleksandr Yatsenko",
  "Aleksandra Cwen",
  "Aleksandra Konieczna",
  "Aleksei Smirnov",
  "Aleksey Batalov",
  "Aleksey Chadov",
  "Aleksey Fateev",
  "Aleksey Glazyrin",
  "Aleksey Guskov",
  "Aleksey Kopashov",
  "Aleksey Kravchenko",
  "Aleksey Maklakov",
  "Aleksey Panin",
  "Aleksey Petrenko",
  "Aleksey Poluyan",
  "Aleksey Rozin",
  "Aleksey Serebryakov",
  "Alessandro Borghi",
  "Alessandro Gassmann",
  "Alessandro Momo",
  "Alessandro Nivola",
  "Alessandro Preziosi",
  "Alessandro Siani",
  "Alessandro Tedeschi",
  "Alessia Cara",
  "Alessio Boni",
  "Alessio Praticò",
  "Alex Abbad",
  "Alex Bisconti",
  "Alex Brendemühl",
  "Alex Descas",
  "Alex Esmail",
  "Alex Etel",
  "Alex Hassell",
  "Alex Haw",
  "Alex Jennings",
  "Alex Lutz",
  "Alex Macqueen",
  "Alex Palmer",
  "Alex Pettyfer",
  "Alex Reid",
  "Alex Rocco",
  "Alex Russell",
  "Alex Sharp",
  "Alex Veadov",
  "Alex Vincent",
  "Alex Winter",
  "Alex Wolff",
  "Alexa Brunner",
  "Alexa Davalos",
  "Alexa Demie",
  "Alexa PenaVega",
  "Alexander Beyer",
  "Alexander Fehling",
  "Alexander Godunov",
  "Alexander Gould",
  "Alexander Granach",
  "Alexander Held",
  "Alexander Ludwig",
  "Alexander Morton",
  "Alexander Payne",
  "Alexander Petrov",
  "Alexander Scheer",
  "Alexander Scourby",
  "Alexander Siddig",
  "Alexander Skarsgård",
  "Alexander Zale",
  "Alexandra Daddario",
  "Alexandra Dinu",
  "Alexandra Jiménez",
  "Alexandra Maria Lara",
  "Alexandra Moen",
  "Alexandra Shipp",
  "Alexandra Stewart",
  "Alexandre Astier",
  "Alexandre Bailly",
  "Alexandre Courtès",
  "Alexandre Hamidi",
  "Alexandre Rignault",
  "Alexandre Rodrigues",
  "Alexandre Tharaud",
  "Alexandria Maria Lara",
  "Alexandru Dabija",
  "Alexandru Potocean",
  "Alexis Díaz de Villegas",
  "Alexis Knapp",
  "Alexis Loret",
  "Alexis Manenti",
  "Alexis Smith",
  "Alexis Zegerman",
  "Alexxis Lemire",
  "Alfie Allen",
  "Alfie Bass",
  "Alfonso Arau",
  "Alfonso Arfi",
  "Alfonso Cuarón",
  "Alfonso Herrera",
  "Alfonso Mejía",
  "Alfonso Tort",
  "Alfred Abel",
  "Alfred Molina",
  "Alfredo Castro",
  "Algenis Perez Soto",
  "Ali Akbar",
  "Ali Atay",
  "Ali Bakhsi",
  "Ali Cobrin",
  "Ali Dehkordi",
  "Ali Düsenkalkar",
  "Ali Fazal",
  "Ali Larter",
  "Ali Mosaffa",
  "Ali Nassirian",
  "Ali Osivand",
  "Ali Reza Shoja'noori",
  "Ali Sen",
  "Ali Sivandi",
  "Ali Soleymani",
  "Ali Suliman",
  "Ali Çatalbas",
  "Alia Bhatt",
  "Alia Shawkat",
  "Alican Akman",
  "Alice Belaïdi",
  "Alice Brady",
  "Alice Braga",
  "Alice David",
  "Alice Englert",
  "Alice Evans",
  "Alice Eve",
  "Alice Feetham",
  "Alice Isaaz",
  "Alice Krige",
  "Alice Lee",
  "Alice Lowe",
  "Alice Pagani",
  "Alice Pol",
  "Alice Sapritch",
  "Alice Taglioni",
  "Alicia Silverstone",
  "Alicia Vikander",
  "Alicia Witt",
  "Alicia del Lago",
  "Alicia von Rittberg",
  "Alicja Bachleda",
  "Alida Valli",
  "Alighiero Noschese",
  "Alina Lanina",
  "Alina Levshin",
  "Aline Küppenheim",
  "Alireza Aghakhani",
  "Alireza Phoenix",
  "Alireza Sani Far",
  "Alisa Freyndlikh",
  "Alisha Weir",
  "Alison Brie",
  "Alison Crosbie",
  "Alison Doody",
  "Alison Lohman",
  "Alison Pill",
  "Alison Sudol",
  "Alison Whelan",
  "Alistair Abell",
  "Alla Sokolova",
  "Allah-Morad Rashtian",
  "Allan Edwall",
  "Allan Gildea",
  "Allan Kolman",
  "Allen Garfield",
  "Allen Hughes",
  "Allen Leech",
  "Allie Mickelson",
  "Allison Dawn Doiron",
  "Allison Janney",
  "Allison Williams",
  "Ally Sheedy",
  "Almanta Suska",
  "Almira Tursyn",
  "Almudena Amor",
  "Alondra Hidalgo",
  "Alonna Shaw",
  "Alper Kul",
  "Alper Saldiran",
  "Altan Erkekli",
  "Alwara Höfels",
  "Alyson Bath",
  "Alyson Court",
  "Alysson Paradis",
  "Amaia Aberasturi",
  "Amaia Salamanca",
  "Amala Paul",
  "Amanda Brooks",
  "Amanda Bynes",
  "Amanda Crew",
  "Amanda Langlet",
  "Amanda Peet",
  "Amanda Plummer",
  "Amanda Redman",
  "Amanda Seyfried",
  "Amanda Smith",
  "Amber Heard",
  "Amber Midthunder",
  "Amber Tamblyn",
  "Amber Valletta",
  "Ameneh Passand",
  "America Ferrera",
  "Amin Hayayee",
  "Amin Simiar",
  "Amina Maher",
  "Amir Aghaee",
  "Amir Arsalan Alebouyeh",
  "Amir Baay",
  "Amir Chegini",
  "Amir Farrokh Hashemian",
  "Amir Jadidi",
  "Amir Jafari",
  "Amir Talai",
  "Amira Casar",
  "Amira Chemakhi",
  "Amitabh Bachchan",
  "Amjad Khan",
  "Aml Ameen",
  "Ammar Tafti Dehghan",
  "Amol Parashar",
  "Amole Gupte",
  "Amr Waked",
  "Amrish Puri",
  "Amy Adams",
  "Amy Brenneman",
  "Amy Doyle",
  "Amy Forsyth",
  "Amy Irving",
  "Amy Landecker",
  "Amy Poehler",
  "Amy Ryan",
  "Amy Schumer",
  "Amy Sedaris",
  "Amy Seimetz",
  "Amy Smart",
  "Amédée",
  "An Nguyen",
  "Ana Claudia Talancón",
  "Ana Fernández",
  "Ana López Mercado",
  "Ana Polvorosa",
  "Ana Rita Gurgel",
  "Ana Torrent",
  "Ana Wagener",
  "Ana de Armas",
  "Anahita Afshar",
  "Anamaria Marinca",
  "Anamaria Vartolomei",
  "Anand Gandhi",
  "Anand Tiwari",
  "Ananda George",
  "Anapola Mushkadiz",
  "Anastasia Hille",
  "Anastasia Phillips",
  "Anastasiya Nemolyaeva",
  "Anatoliy Belyy",
  "Anatoliy Kotenyov",
  "Anatoliy Papanov",
  "Anatoliy Solonitsyn",
  "Anaïs Demoustier",
  "Anaïs Reboux",
  "Anders Baasmo",
  "Anders Danielsen Lie",
  "Anders Juul",
  "Anders Matthesen",
  "Anders W. Berthelsen",
  "Anders Ølholm",
  "Andi Feldmann",
  "Andie MacDowell",
  "Andoni Gracia",
  "Andre Braugher",
  "Andrea Acatto",
  "Andrea Checchi",
  "Andrea Di Stefano",
  "Andrea Duro",
  "Andrea Eckert",
  "Andrea Frigerio",
  "Andrea García-Huidobro",
  "Andrea Gherpelli",
  "Andrea Heick Gadeberg",
  "Andrea Martin",
  "Andrea Occhipinti",
  "Andrea Osvárt",
  "Andrea Riseborough",
  "Andrea Sartoretti",
  "Andrea Tariang",
  "Andrea Vergara",
  "Andreas Adam",
  "Andreas Leupold",
  "Andreas Lust",
  "Andreas Schröders",
  "Andreas Wilson",
  "Andreas Wisniewski",
  "Andrei Abrikosov",
  "Andrew Ableson",
  "Andrew Adote",
  "Andrew Francis",
  "Andrew Garfield",
  "Andrew Kevin Walker",
  "Andrew Marton",
  "Andrew Miller",
  "Andrew Philpot",
  "Andrew Rannells",
  "Andrew Robinson",
  "Andrew Scott",
  "Andrew Shim",
  "Andrew Simpson",
  "Andrey Bykov",
  "Andrey Gusev",
  "Andrey Isaenko",
  "Andrey Khalimon",
  "Andrey Merzlikin",
  "Andrey Mironov",
  "Andrey Myagkov",
  "Andrey Shalopa",
  "Andrey Smirnov",
  "Andrey Smolyakov",
  "Andros Perugorría",
  "Andrzej Chyra",
  "Andrzej Grabowski",
  "Andrzej Konopka",
  "Andrzej Seweryn",
  "André 3000",
  "André Berley",
  "André Bervil",
  "André Bonzel",
  "André Dussollier",
  "André Hennicke",
  "André Holland",
  "André Marcon",
  "André Ramiro",
  "André S. Labarthe",
  "André Szymanski",
  "André Wilms",
  "Andréa Ferréol",
  "Andréa Parisy",
  "Andrés Blanco",
  "Andrés Gertrúdix",
  "Andrés Parra",
  "Andrés Pazos",
  "Andy Devine",
  "Andy Garcia",
  "Andy J. Forest",
  "Andy Lau",
  "Andy Lauer",
  "Andy Nyman",
  "Andy On",
  "Andy Powers",
  "Andy Romano",
  "Andy Samberg",
  "Andy Serkis",
  "Anette Støvelbæk",
  "Aneurin Barnard",
  "Anfisa Vistingauzen",
  "Ang Phula Sherpa",
  "Angela Bassett",
  "Angela Finocchiaro",
  "Angela Griffin",
  "Angela Lansbury",
  "Angela Winkler",
  "Angelina Jolie",
  "Angeline Ball",
  "Angga Yunanda",
  "Angie Dickinson",
  "Angie Harmon",
  "Angourie Rice",
  "Angus Macfadyen",
  "Angus Sampson",
  "Angélica Aragón",
  "Angélica Sancio",
  "Ania Marson",
  "Ania Pieroni",
  "Anil Dhawan",
  "Anil Kapoor",
  "Anita Date-Kelkar",
  "Anita Ekberg",
  "Anita Laurenzi",
  "Anita Strindberg",
  "Aniya Wendel",
  "Anja Kling",
  "Anja Savcic",
  "Anjelica Huston",
  "Anka Graczyk",
  "Ankhnyam Ragchaa",
  "Ann Eleonora Jørgensen",
  "Ann Reinking",
  "Ann Sheridan",
  "Ann Zacharias",
  "Ann-Margret",
  "Anna Ammirati",
  "Anna Baryshnikov",
  "Anna Bederke",
  "Anna Blomeier",
  "Anna Bonaiuto",
  "Anna Borkowska",
  "Anna Chancellor",
  "Anna Chincho Serrano",
  "Anna Ciepielewska",
  "Anna Falchi",
  "Anna Faris",
  "Anna Fischer",
  "Anna Foglietta",
  "Anna Friel",
  "Anna Galiena",
  "Anna Hutchison",
  "Anna Jimskaia",
  "Anna Kanakis",
  "Anna Karina",
  "Anna Katharina Schwabroh",
  "Anna Kendrick",
  "Anna Lizaran",
  "Anna Loos",
  "Anna Magnani",
  "Anna Maria Mühe",
  "Anna Maria Rizzoli",
  "Anna Maria Rosati",
  "Anna Massey",
  "Anna Mouglalis",
  "Anna Nagata",
  "Anna Nakagawa",
  "Anna Paquin",
  "Anna Prucnal",
  "Anna Próchniak",
  "Anna Schinz",
  "Anna Sisková",
  "Anna Stieblich",
  "Anna Thomson",
  "Anna Tsuchiya",
  "Anna Wing",
  "Anna Zinnemann",
  "Anna-Maria Sieklucka",
  "Annabel Kershaw",
  "Annabella Piugattuk",
  "Annabella Sciorra",
  "Annabelle Wallis",
  "Annalee Jefferies",
  "Annalise Basso",
  "Anne Alvaro",
  "Anne Archer",
  "Anne Bancroft",
  "Anne Baxter",
  "Anne Bennent",
  "Anne Bobby",
  "Anne Brochet",
  "Anne Consigny",
  "Anne Dorval",
  "Anne Hathaway",
  "Anne Heche",
  "Anne Le Ny",
  "Anne Louise Hassing",
  "Anne Parillaud",
  "Anne Suzuki",
  "Anne Sørensen",
  "Anne Vernon",
  "Anne Wiazemsky",
  "Anne Winters",
  "Anne Yernaux",
  "Anne-Marie Duff",
  "Anne-Marie Loop",
  "Anne-Sophie Briest",
  "Annemarie Düringer",
  "Annette Bening",
  "Anni-Kristiina Juuso",
  "Annie Belle",
  "Annie Corley",
  "Annie Girardot",
  "Annie Mumolo",
  "Annie Potts",
  "Annie Rose Buckley",
  "Annie Wu",
  "Annielo Mele",
  "Annika Wedderkopp",
  "Annu Kapoor",
  "Annu Mari",
  "Anny Duperey",
  "Anouk Aimée",
  "Anouk Whissell",
  "Ansel Elgort",
  "Ansiba",
  "Anson Mount",
  "Anthony Anderson",
  "Anthony Bajon",
  "Anthony Chau-Sang Wong",
  "Anthony Edwards",
  "Anthony Franciosa",
  "Anthony Gonzalez",
  "Anthony Heald",
  "Anthony Hopkins",
  "Anthony LaPaglia",
  "Anthony Luke Lucero",
  "Anthony Mackie",
  "Anthony Michael Hall",
  "Anthony Perkins",
  "Anthony Quayle",
  "Anthony Quinn",
  "Anthony Steel",
  "Antje Traue",
  "Antoine Basler",
  "Antoine Bertrand",
  "Antoine Bourseiller",
  "Antoine Monot Jr.",
  "Antoine Olivier Pilon",
  "Antoine Reinartz",
  "Antoine Saint-John",
  "Antoine Vitez",
  "Antoine du Merle",
  "Anton Adasinsky",
  "Anton Diffring",
  "Anton Lesser",
  "Anton Pampushnyy",
  "Anton Shagin",
  "Anton Spieker",
  "Anton Vasilev",
  "Anton Walbrook",
  "Anton Yelchin",
  "Anton von Lucke",
  "Antonella Antinori",
  "Antonella Attili",
  "Antonella Carone",
  "Antonella Interlenghi",
  "Antoni Królikowski",
  "Antonia Campbell-Hughes",
  "Antonia De Michelis",
  "Antonia San Juan",
  "Antonia Santilli",
  "Antonia Zegers",
  "Antonio Aakeel",
  "Antonio Albanese",
  "Antonio Ballerio",
  "Antonio Banderas",
  "Antonio Benedicti",
  "Antonio Bolívar",
  "Antonio Catania",
  "Antonio Cupo",
  "Antonio Dechent",
  "Antonio Gasalla",
  "Antonio Lanza",
  "Antonio Passalia",
  "Antonio Prester",
  "Antonio Resines",
  "Antonio Salines",
  "Antonio Velázquez",
  "Antonio de la Torre",
  "António Fonseca",
  "Anuk Steffen",
  "Anupam Kher",
  "Anushka Sharma",
  "Anushka Shetty",
  "Anya Taylor-Joy",
  "Anémone",
  "Aoi Miyazaki",
  "Aoi Nakajima",
  "Aoi Yûki",
  "Aorere Paki",
  "Aparna Balamurali",
  "April Clough",
  "Apti Magamaev",
  "Araba Walton",
  "Aras Bulut Iynemli",
  "Arash Ashtiani",
  "Arash Marandi",
  "Arastoosohrabinya",
  "Arata Furuta",
  "Arata Iura",
  "Araz M. Shirmohamadi",
  "Archana Jois",
  "Archie Lal",
  "Archie Panjabi",
  "Archil Gomiashvili",
  "Ariadna Gil",
  "Ariadne Welter",
  "Ariana Greenblatt",
  "Ariane Labed",
  "Ariane Schnug",
  "Arianna Gorini",
  "Arie Kriting",
  "Arieh Worthalter",
  "Ariel Casas",
  "Ariel Levy",
  "Ariel Mateluna",
  "Ariel Tatum",
  "Arielle Carver-O'Neill",
  "Arielle Dombasle",
  "Arielle Kebbel",
  "Arifin Putra",
  "Ario Bayu",
  "Aris Servetalis",
  "Arita Shahrzad",
  "Arjun Das",
  "Arkadiusz Jakubik",
  "Arlen Escarpeta",
  "Arlene Dahl",
  "Arlene Francis",
  "Arletty",
  "Arly Jover",
  "Armand Assante",
  "Armand Schultz",
  "Armand Verdure",
  "Armando Arriola",
  "Armando Brancia",
  "Armando De Razza",
  "Armando Espitia",
  "Armie Hammer",
  "Armin Mueller-Stahl",
  "Armin Rohde",
  "Arnaud Valois",
  "Arndt Schwering-Sohnrey",
  "Arno Frisch",
  "Arnold Lucy",
  "Arnold Oceng",
  "Arnold Schwarzenegger",
  "Arsenio Castellanos",
  "Arshad Warsi",
  "Arsher Ali",
  "Arsinée Khanjian",
  "Arswendy Bening Swara",
  "Art Hindle",
  "Art LaFleur",
  "Art Parkinson",
  "Art Stevens",
  "Arta Dobroshi",
  "Arthur Abraham",
  "Arthur Dupont",
  "Arthur Fogel",
  "Arthur Kennedy",
  "Arthur O'Connell",
  "Arthur Rankin Jr.",
  "Arthur Rosson",
  "Artie Lange",
  "Artur Smolyaninov",
  "Artur Zmijewski",
  "Arturo Castro",
  "Arturo Paglia",
  "Arturo de Córdova",
  "Artus",
  "Artyom Bogucharskiy",
  "Artyom Bystrov",
  "Artyom Tsukanov",
  "Ary Abittan",
  "Aryana Engineer",
  "Arzhang Amirfazli",
  "Asa Butterfield",
  "Asaka Seto",
  "Asami",
  "Asami Imai",
  "Asami Mizukawa",
  "Ash Brannon",
  "Asha Mehrabi",
  "Asha Sharath",
  "Ashanti",
  "Asher Angel",
  "Asher Miles Fallica",
  "Ashkan Khatibi",
  "Ashkan Koshanejad",
  "Ashleigh Gryzko",
  "Ashley Jensen",
  "Ashley Judd",
  "Ashley Laurence",
  "Ashley Sommers",
  "Ashley St. George",
  "Ashley Thomas",
  "Ashley Tisdale",
  "Ashley Walters",
  "Ashton Holmes",
  "Ashton Kutcher",
  "Ashton Sanders",
  "Asia Argento",
  "Asia Ortega",
  "Asier Etxeandia",
  "Asier Flores",
  "Asim Chaudhry",
  "Asjha Cooper",
  "Asli Bekiroglu",
  "Asli Tandogan",
  "Assa Sylla",
  "Assita Ouedraogo",
  "Assumpta Serna",
  "Astrid Bergès-Frisbey",
  "Asuman Dabak",
  "Ata Demirer",
  "Athenea Mata",
  "Ato Blankson-Wood",
  "Atossa Leoni",
  "Atsuko Tanaka",
  "Attila Borlan",
  "Attilia Boschetti",
  "Aubrey Plaza",
  "Aud Egede-Nissen",
  "Audrey Dana",
  "Audrey Fleurot",
  "Audrey Hepburn",
  "Audrey Tautou",
  "August Diehl",
  "August Zirner",
  "Auli'i Cravalho",
  "Aura Garrido",
  "Aure Atika",
  "Aurelia Nolin",
  "Aurora Cornu",
  "Aurora Giovinazzo",
  "Aurora Quattrocchi",
  "Aurora Ribero",
  "Aurora Walker",
  "Aurore Clément",
  "Aurélien Recoing",
  "Aurélien Wiik",
  "Austin Abrams",
  "Austin Butler",
  "Austin Stowell",
  "Ava Morse",
  "Ava Pickard",
  "Avery Brooks",
  "Avery Tiiu Essex",
  "Avery Whitted",
  "Avin Manshadi",
  "Avin Poor Raoufi",
  "Awa Sene Sarr",
  "Axel Jodorowsky",
  "Axel Milberg",
  "Axel Stein",
  "Axelle Laffont",
  "Aya Endô",
  "Aya Hirano",
  "Aya Hisakawa",
  "Aya Irizuki",
  "Aya Kokumai",
  "Aya Okamoto",
  "Ayaka Onoue",
  "Ayako Fujitani",
  "Ayako Wakao",
  "Ayami Kakiuchi",
  "Ayana Sakai",
  "Ayana Taketatsu",
  "Ayane Sakura",
  "Ayano Fukuda",
  "Ayano Shiraishi",
  "Ayberk Pekcan",
  "Ayda Aksel",
  "Ayda Sadeqi",
  "Aydemir Akbas",
  "Ayesha Kapoor",
  "Aylin Tezel",
  "Ayse Altay",
  "Ayse Aslan",
  "Ayse Ünal",
  "Aysen Aydemir",
  "Aysen Gruda",
  "Ayu Kitaura",
  "Ayumi Ito",
  "Ayumu Nakajima",
  "Ayushmann Khurrana",
  "Ayça Bingöl",
  "Azadeh Nobahari",
  "Azadeh Samadi",
  "Azhy Robertson",
  "Azita Rayeji",
  "Aziz Acar",
  "Azizeh Mohamadi",
  "Aïssa Maïga",
  "BD Wong",
  "Baard Owe",
  "Babak Ansari",
  "Babak Hamidian",
  "Babak Karimi",
  "Babak Mirzakhani",
  "Babek Ahmed Poor",
  "Bae Doona",
  "Baek Dong-hyeon",
  "Bahar Pars",
  "Bahar Soomekh",
  "Bahare Afshari",
  "Bahare Seddiqi",
  "Bahareh Rahnama",
  "Bahman Ghobadi",
  "Bahman Mofid",
  "Bahram Afshari",
  "Bahram Ebrahimi",
  "Bahram Radan",
  "Bahri Beyat",
  "Bai Ling",
  "Baihe Bai",
  "Bailee Madison",
  "Bailey Anne Borders",
  "Bajram Severdzan",
  "Baki Davrak",
  "Banafsheh-Shay Modaressi",
  "Banks Repeta",
  "Banlop Lomnoi",
  "Baoqiang Wang",
  "Baotian Li",
  "Baptiste Sornin",
  "Baran Kosari",
  "Baran Rasoulof",
  "Barbara Anne Constable",
  "Barbara Bach",
  "Barbara Bel Geddes",
  "Barbara Bouchet",
  "Barbara Burska",
  "Barbara Chichiarelli",
  "Barbara Cupisti",
  "Barbara Dennek",
  "Barbara Flynn",
  "Barbara Gordon",
  "Barbara Hershey",
  "Barbara Jefford",
  "Barbara Laage",
  "Barbara Leigh-Hunt",
  "Barbara Lourens",
  "Barbara Magnolfi",
  "Barbara Nedeljakova",
  "Barbara O'Neil",
  "Barbara Stanwyck",
  "Barbara Steele",
  "Barbara Sukowa",
  "Barbara Valentin",
  "Barbie Hsu",
  "Baris Bagci",
  "Barkhad Abdi",
  "Barkhad Abdirahman",
  "Barnaby Metschurat",
  "Barnaby Thompson",
  "Barney Clark",
  "Barrie Nelson",
  "Barry Bostwick",
  "Barry Cook",
  "Barry Fitzgerald",
  "Barry Foster",
  "Barry G. Bernson",
  "Barry Humphries",
  "Barry Keoghan",
  "Barry Norton",
  "Barry Pepper",
  "Barry Sullivan",
  "Bartlomiej Topa",
  "Bartosz Bielenia",
  "Bartu Küçükçaglayan",
  "Basak Köklükaya",
  "Basil Rathbone",
  "Bastian Pastewka",
  "Batyste Fleurial",
  "Baya Rehaz",
  "Baykali Ganambarr",
  "Bayron Lebli",
  "Bayu Skak",
  "Bea Segura",
  "Beata Fido",
  "Beatrice Manowski",
  "Beatriz Batarda",
  "Beau Billingslea",
  "Beau Bridges",
  "Beau Daniels",
  "Beau Gadsdon",
  "Bebe",
  "Bebe Neuwirth",
  "Becky",
  "Bee Vang",
  "Begoña Vargas",
  "Behnaz Jafari",
  "Behnoosh Tabatabaei",
  "Behrouz Shoaybi",
  "Behrouz Vossoughi",
  "Behtash Sanaeeha",
  "Behzad Dorani",
  "Bel Powley",
  "Bela Lugosi",
  "Belinda Busch",
  "Bella Heathcote",
  "Belçim Bilgin",
  "Belén Cuesta",
  "Belén Fabra",
  "Belén Rueda",
  "Ben Affleck",
  "Ben Barnes",
  "Ben Becker",
  "Ben Bishop",
  "Ben Burtt",
  "Ben Chaplin",
  "Ben Cross",
  "Ben Daon",
  "Ben Foster",
  "Ben Gazzara",
  "Ben Gluck",
  "Ben Hardy",
  "Ben Johnson",
  "Ben Kingsley",
  "Ben McKenzie",
  "Ben Mendelsohn",
  "Ben Miller",
  "Ben Schwartz",
  "Ben Sharpsteen",
  "Ben Stiller",
  "Ben Walker",
  "Ben Whishaw",
  "Ben Winchell",
  "Bendt Rothe",
  "Benedetta Porcaroli",
  "Benedict Cumberbatch",
  "Benedict Wong",
  "Benedikt Erlingsson",
  "Bengal",
  "Bengt C.W. Carlsson",
  "Bengt Ekerot",
  "Benicio Del Toro",
  "Benjamin Bratt",
  "Benjamin Kitter",
  "Benjamin Lavernhe",
  "Benjamin Renner",
  "Benjamin Rocher",
  "Benjamin Voisin",
  "Benjamin Walker",
  "Benjamin Weir",
  "Benjamin Wilkinson",
  "Benno Fürmann",
  "Benno Rosskopf",
  "Bennu Yildirimlar",
  "Benny Hill",
  "Benoît Ferreux",
  "Benoît Magimel",
  "Benoît Philippon",
  "Benoît Poelvoorde",
  "Benoît Régent",
  "Beom-su Lee",
  "Berat Efe Parlar",
  "Bergüzar Korel",
  "Bernadette Lafont",
  "Bernard Alane",
  "Bernard Blier",
  "Bernard Campan",
  "Bernard Farcy",
  "Bernard Giraudeau",
  "Bernard Lee",
  "Bernard Verley",
  "Bernard-Pierre Donnadieu",
  "Bernat Saumell",
  "Bernd Daktari Lorenz",
  "Bernhard Bettermann",
  "Bernhard Goetzke",
  "Bernhard Wicki",
  "Bernice Stegers",
  "Bernie Coulson",
  "Bertha Ruiz",
  "Bertil Guve",
  "Bertram Bisgaard Enevoldsen",
  "Beth Goulart",
  "Beto Cuevas",
  "Betsy Rue",
  "Betsy Russell",
  "Bette Davis",
  "Bette Midler",
  "Betty Hutton",
  "Beulah Bondi",
  "Beulah Koale",
  "Beverley Mitchell",
  "Beverly D'Angelo",
  "Beyaz",
  "Bhupesh Singh",
  "Bianca Hello",
  "Bianca Kajlich",
  "Biancamaria D'Amato",
  "Biassini Segura",
  "Bibi Andersson",
  "Bibi Naceri",
  "Biff McGuire",
  "Bijou Phillips",
  "Bill Bailey",
  "Bill Bernstein",
  "Bill Bryden",
  "Bill Camp",
  "Bill Cobbs",
  "Bill Fagerbakke",
  "Bill Farmer",
  "Bill Goldberg",
  "Bill Hader",
  "Bill Hunter",
  "Bill McKinney",
  "Bill Milner",
  "Bill Murray",
  "Bill Nighy",
  "Bill Nunn",
  "Bill Paxton",
  "Bill Pullman",
  "Bill Roberts",
  "Bill Sage",
  "Bill Skarsgård",
  "Bill Smitrovich",
  "Bill Tung",
  "Bill Wise",
  "Billy Barratt",
  "Billy Bob Thornton",
  "Billy Boyd",
  "Billy Brown",
  "Billy Burke",
  "Billy Connolly",
  "Billy Crudup",
  "Billy Crystal",
  "Billy Dee Williams",
  "Billy Howle",
  "Billy Magnussen",
  "Billy Ray Cyrus",
  "Billy Zane",
  "Bin Shimada",
  "Bing Bai",
  "Bingbing Fan",
  "Bingbing Li",
  "Binnur Kaya",
  "Birgit Doll",
  "Birgit Minichmayr",
  "Birgit von Rönn",
  "Birgitta Pettersson",
  "Birgitta Valberg",
  "Birgitte Federspiel",
  "Birol Ünel",
  "Bita Farahi",
  "Bjarne Mädel",
  "Björk",
  "Björn Granath",
  "Björn Stein",
  "Black Rebel Motorcycle Club",
  "Blake Harrison",
  "Blake Lively",
  "Blake Shelton",
  "Blake Woodruff",
  "Blanca Guerra",
  "Blanca Portillo",
  "Blanca Suárez",
  "Blanche Gardin",
  "Blandine Lenoir",
  "Blanka Györfi-Tóth",
  "Blayne Weaver",
  "Blondin Miguel",
  "Blu Mankuma",
  "Bo Burnham",
  "Bo Derek",
  "Bo Hopkins",
  "Bo Huang",
  "Bob Balaban",
  "Bob Buchholz",
  "Bob Geldof",
  "Bob Gunton",
  "Bob Hoskins",
  "Bob Newhart",
  "Bob Odenkirk",
  "Bob Peterson",
  "Bob the Cat",
  "Bobby Cannavale",
  "Bobby Moore",
  "Bobby Naderi",
  "Bobby Rhodes",
  "Bodil Jørgensen",
  "Bodil Kjer",
  "Bodil Rosing",
  "Boguslaw Linda",
  "Boguslawa Pawelec",
  "Bojana Novakovic",
  "Bok-hwa Baek",
  "Bokeem Woodbine",
  "Bolek Polívka",
  "Boluwatife Treasure Bankole",
  "Boman Irani",
  "Bong Joon Ho",
  "Bonnie Bedelia",
  "Bonnie Hunt",
  "Booboo Stewart",
  "Bora Altas",
  "Bora Todorovic",
  "Boran Jing",
  "Boris Aljinovic",
  "Boris Bokir",
  "Boris Gulyarin",
  "Boris Isakovic",
  "Boris Karloff",
  "Boris Ler",
  "Boris Lyoskin",
  "Boris Nevzorov",
  "Boris Plotnikov",
  "Boris Zakhava",
  "Borys Szyc",
  "Borzou Arjmand",
  "Bourvil",
  "Boyd Holbrook",
  "Boyd Kirkland",
  "Bozhao Wang",
  "Brad Davis",
  "Brad Dourif",
  "Brad Garrett",
  "Brad Harris",
  "Brad Loree",
  "Brad Pitt",
  "Brad Renfro",
  "Bradley Cooper",
  "Bradley Sawatzky",
  "Bradley Whitford",
  "Brahim Hadjadj",
  "Brandon López",
  "Brandon McGibbon",
  "Brandon Perea",
  "Brandon Ratcliff",
  "Brandon Salim",
  "Brandon Scott",
  "Brandon Smith",
  "Brandon T. Jackson",
  "Brandy Norwood",
  "Branka Katic",
  "Branko Djuric",
  "Brays Efe",
  "Breckin Meyer",
  "Brenda Blethyn",
  "Brenda Fricker",
  "Brenda Vaccaro",
  "Brendan Fletcher",
  "Brendan Fraser",
  "Brendan Gleeson",
  "Brendan Kerkvliet",
  "Breno Mello",
  "Brent Briscoe",
  "Brent Carver",
  "Brenton Thwaites",
  "Bret McKenzie",
  "Brett Donahue",
  "Brett Sullivan",
  "Bri Fiocca",
  "Brian Barber",
  "Brian Caspe",
  "Brian Cox",
  "Brian Dennehy",
  "Brian Downey",
  "Brian Geraghty",
  "Brian Glover",
  "Brian Howe",
  "Brian Keith",
  "Brian Lykke",
  "Brian Milligan",
  "Brian O'Halloran",
  "Brian Presley",
  "Brian Tee",
  "Brian Thompson",
  "Brian Tyree Henry",
  "Briana Evigan",
  "Briana Shann",
  "Brianne Tju",
  "Bridget Fonda",
  "Bridget Moynahan",
  "Brie Larson",
  "Brigid Tierney",
  "Brigitte Bardot",
  "Brigitte Fossey",
  "Brigitte Helm",
  "Brigitte Lahaie",
  "Brigitte Lin",
  "Brigitte Mira",
  "Brigitte Roüan",
  "Brion James",
  "Brionne Davis",
  "Brit Marling",
  "Britt Ekland",
  "Britt Robertson",
  "Brittany Allen",
  "Brittany Murphy",
  "Brittany Snow",
  "Bronislaw Wroclawski",
  "Bront Palarae",
  "Bronte Carmichael",
  "Brontis Jodorowsky",
  "Brooke Adams",
  "Brooke Shields",
  "Brooklynn Proulx",
  "Bruce Allpress",
  "Bruce Altman",
  "Bruce Bennett",
  "Bruce Beresford",
  "Bruce Cabot",
  "Bruce Davison",
  "Bruce Dern",
  "Bruce Gleeson",
  "Bruce Greenwood",
  "Bruce Lee",
  "Bruce McCulloch",
  "Bruce McGill",
  "Bruce Payne",
  "Bruce Ramsay",
  "Bruce Reitherman",
  "Bruce Robinson",
  "Bruce Spence",
  "Bruce Timm",
  "Bruce Willis",
  "Bruna Cusí",
  "Bruna González",
  "Bruno Cremer",
  "Bruno Forzani",
  "Bruno Gagliasso",
  "Bruno Ganz",
  "Bruno Kirby",
  "Bruno Ortensi",
  "Bruno Podalydès",
  "Bruno S.",
  "Bruno Salomone",
  "Bruno Todeschini",
  "Bruno Zanin",
  "Bryan Baker",
  "Bryan Clark",
  "Bryan Cranston",
  "Bryan Mialoundama",
  "Bryce Dallas Howard",
  "Brycen Hall",
  "Buba Bayour",
  "Buck Henry",
  "Bud Cort",
  "Bud Spencer",
  "Bugra Gülsoy",
  "Bulle Ogier",
  "Bunga Citra Lestari",
  "Bunjaku Han",
  "Bunshi Katsura Vi",
  "Burak Sergen",
  "Burak Yigit",
  "Burcu Biricik",
  "Burghart Klaußner",
  "Burl Ives",
  "Burn Gorman",
  "Burt Kwouk",
  "Burt Lancaster",
  "Burt Reynolds",
  "Burt Ward",
  "Burt Young",
  "Burçin Bildik",
  "Busta Rhymes",
  "Buster Keaton",
  "Buzz Kilman",
  "Byeong-eun Park",
  "Byron Barr",
  "Byron Howard",
  "Byun Hee-Bong",
  "Byung-ho Son",
  "Byung-seo Kim",
  "Bárbara Colen",
  "Bárbara Goenaga",
  "Bárbara Lennie",
  "Béatrice Dalle",
  "Béatrice Romand",
  "Bérénice Bejo",
  "Börje Ahlstedt",
  "Bülent Kayabas",
  "Büsra Develi",
  "Büsra Pekin",
  "C. Thomas Howell",
  "C.B. Seay",
  "C.J. Thomason",
  "CCH Pounder",
  "Cab Calloway",
  "Caerthan Banks",
  "Caglar Ertugrul",
  "Cailee Spaeny",
  "Caio Almeida",
  "Caio Blat",
  "Caio Junqueira",
  "Caitríona Balfe",
  "Caity Lotz",
  "Caleb Ellsworth-Clark",
  "Caleb Emery",
  "Caleb Landry Jones",
  "Callan McAuliffe",
  "Callan Mulvey",
  "Callie Hernandez",
  "Callum Blue",
  "Callum Keith Rennie",
  "Calu Rivero",
  "Cam Gigandet",
  "Cameron Bright",
  "Cameron Diaz",
  "Cameron Mitchell",
  "Camila Morrone",
  "Camila Márdila",
  "Camilla Belle",
  "Camille Lellouche",
  "Camille Lou",
  "Camille Natta",
  "Camille Sullivan",
  "Can Ulkay",
  "Can Yilmaz",
  "Canan Erguder",
  "Candela Fernández",
  "Candela Peña",
  "Candice Bergen",
  "Candy Clark",
  "Caner Cindoruk",
  "Caner Özyurtlu",
  "Cansel Elcin",
  "Cansu Aktay",
  "Cansu Dere",
  "Caoilfhionn Dunne",
  "Capucine",
  "Cara Buono",
  "Cara Delevingne",
  "Caren Pistorius",
  "Carey Lowell",
  "Carey Mulligan",
  "Carice van Houten",
  "Caridad de Laberdesque",
  "Carina Lau",
  "Carl Anton Koch",
  "Carl Benton Reid",
  "Carl Boese",
  "Carl Gottlieb",
  "Carl Miller",
  "Carl Möhner",
  "Carl Reiner",
  "Carl Weathers",
  "Carla Gugino",
  "Carla Juri",
  "Carla Marlier",
  "Carla Quevedo",
  "Carlee Ryski",
  "Carlo Battisti",
  "Carlo Buccirosso",
  "Carlo Calderone",
  "Carlo Cecchi",
  "Carlo Chionetti",
  "Carlo De Mejo",
  "Carlo Kitzlinger",
  "Carlo Mucari",
  "Carlo Verdone",
  "Carlos Acosta",
  "Carlos Areces",
  "Carlos Bardem",
  "Carlos Chajon",
  "Carlos Gallardo",
  "Carlos Latre",
  "Carlos Martínez Baena",
  "Carlos Martínez López",
  "Carlos Padilla",
  "Carlos PenaVega",
  "Carlos Peralta",
  "Carlos Villarías",
  "Carlotta Wittig",
  "Carly Pope",
  "Carly Rae Jepsen",
  "Carme Elias",
  "Carmela Sazio",
  "Carmen Beato",
  "Carmen Ejogo",
  "Carmen Machi",
  "Carmen Maura",
  "Carmen Scarpitta",
  "Carmiña Martínez",
  "Carol Duarte",
  "Carol Kane",
  "Carole Bouquet",
  "Carole Franck",
  "Carole Lombard",
  "Carolina Bang",
  "Carolina Hsu",
  "Carolina Pelleritti",
  "Caroline Aaron",
  "Caroline Cellier",
  "Caroline Dhavernas",
  "Caroline Ducey",
  "Caroline Goodall",
  "Caroline Laurence",
  "Caroline Peters",
  "Carrie Coon",
  "Carrie Fisher",
  "Carrie Henn",
  "Carrie Keranen",
  "Carrie-Anne Moss",
  "Carroll Baker",
  "Carter Jenkins",
  "Cary Elwes",
  "Cary Grant",
  "Cary-Hiroyuki Tagawa",
  "Caryn Peterson",
  "Cas Anvar",
  "Casey Affleck",
  "Casper Christensen",
  "Casper Liang",
  "Cassandra Forêt",
  "Cassandra Violet",
  "Cassi Thomson",
  "Cassie Ventura",
  "Catalina Saavedra",
  "Catalina Sandino Moreno",
  "Cate Blanchett",
  "Catherine An",
  "Catherine Battistone",
  "Catherine Bégin",
  "Catherine Chan",
  "Catherine Demongeot",
  "Catherine Deneuve",
  "Catherine Frot",
  "Catherine Jacob",
  "Catherine Keener",
  "Catherine Marchal",
  "Catherine McCormack",
  "Catherine O'Hara",
  "Catherine Salée",
  "Catherine Schell",
  "Catherine Spaak",
  "Catherine Walker",
  "Catherine Wilkening",
  "Catherine Zeta-Jones",
  "Cathy Cavadini",
  "Cathy Moriarty",
  "Cathy Rosier",
  "Catinca Untaru",
  "Catriona MacColl",
  "Cecil Parker",
  "Cecilia Cheung",
  "Cecilia Roth",
  "Cedric the Entertainer",
  "Celeste Holm",
  "Celia Imrie",
  "Celia Johnson",
  "Celia Rowlson-Hall",
  "Celile Toyon Uysal",
  "Celina Jade",
  "Celina Martin",
  "Celina Peter",
  "Celso Franco",
  "Cem Davran",
  "Cem Gelinoglu",
  "Cem Yilmaz",
  "Cem Özer",
  "Cemal Hünal",
  "Cemal Toktas",
  "Cemre Ebuzziya",
  "Cengiz Bozkurt",
  "Cengiz Daner",
  "Cesar De León",
  "Cesar Ramos",
  "Cesar Romero",
  "Cesáreo Quezadas 'Pulgarcito'",
  "Ceyda Düvenci",
  "Cezary Pazura",
  "Cezmi Baskin",
  "Chad Faust",
  "Chad Michael Collins",
  "Chad Michael Murray",
  "Chad R. Davis",
  "Chadwick Boseman",
  "Chae-yeong Yu",
  "Chance Hurstfield",
  "Chandler Canterbury",
  "Chandra West",
  "Chang Chen",
  "Chang Jung Lim",
  "Chang-Seok Ko",
  "Channing Tatum",
  "Chantal Goya",
  "Chantal Lauby",
  "Chantal Quesnelle",
  "Chao Deng",
  "Charlbi Dean",
  "Charlene Choi",
  "Charles Aznavour",
  "Charles Berling",
  "Charles Boyer",
  "Charles Bronson",
  "Charles Carroll",
  "Charles Chaplin",
  "Charles Dance",
  "Charles Denner",
  "Charles Durning",
  "Charles Edwards",
  "Charles Fleischer",
  "Charles Gray",
  "Charles Haid",
  "Charles Lanyer",
  "Charles Laughton",
  "Charles Le Clainche",
  "Charles Martin Smith",
  "Charles Napier",
  "Charles Ruggles",
  "Charles S. Dutton",
  "Charles Sturridge",
  "Charles Vaillant",
  "Charles Vanel",
  "Charles Winninger",
  "Charley Grapewin",
  "Charlie",
  "Charlie Cox",
  "Charlie Creed-Miles",
  "Charlie Day",
  "Charlie Heaton",
  "Charlie Hunnam",
  "Charlie Kaufman",
  "Charlie Nelson",
  "Charlie Plummer",
  "Charlie Sheen",
  "Charlie Shotwell",
  "Charlie Tahan",
  "Charlie Yeung",
  "Charlize Theron",
  "Charlotte Eugène Guibeaud",
  "Charlotte Gainsbourg",
  "Charlotte Le Bon",
  "Charlotte Munck",
  "Charlotte Rampling",
  "Charlotte St-Martin",
  "Charlotte Sullivan",
  "Charlotte Vandermeersch",
  "Charlton Heston",
  "Charlyne Yi",
  "Charo Dolz Doval",
  "Chas Allen",
  "Chazz Palminteri",
  "Checco Zalone",
  "Chechu Salgado",
  "Cheech Marin",
  "Chelsea Islan",
  "Chen Li",
  "Cheol-min Park",
  "Cher",
  "Cherie Chung",
  "Cherie Lunghi",
  "Cherry Miko",
  "Cheryl Hines",
  "Chester",
  "Chete Lera",
  "Chevy Chase",
  "Chi McBride",
  "Chi-Kin Kwok",
  "Chiara Mastroianni",
  "Chibundu Orukwowu",
  "Chico Marx",
  "Chie Nakamura",
  "Chief Dan George",
  "Chieko Baishô",
  "Chieko Higashiyama",
  "Chieko Matsubara",
  "Chieko Misaka",
  "Chiharu Niiyama",
  "Chika Sakamoto",
  "Chikage Awashima",
  "Chikao Ôtsuka",
  "China Zorrilla",
  "Chinatsu Akasaki",
  "Chinatsu Nakayama",
  "Ching Wan Lau",
  "Chino Darín",
  "Chishû Ryû",
  "Chiwetel Ejiofor",
  "Chizuru Ikewaki",
  "Chloe Coleman",
  "Chloe Rose",
  "Chloe Webb",
  "Chloé Coulloud",
  "Chloë Grace Moretz",
  "Chloë Sevigny",
  "Cho Jin-woong",
  "Cho Seung-woo",
  "Cho Yeo-jeong",
  "Choi Deok-moon",
  "Choi Min-sik",
  "Choi Woo-sik",
  "Chow Yun-Fat",
  "Chris Ayres",
  "Chris Barrie",
  "Chris Brown",
  "Chris Chalk",
  "Chris Colfer",
  "Chris Conner",
  "Chris Cooper",
  "Chris Coppola",
  "Chris Diamantopoulos",
  "Chris Elliott",
  "Chris Evans",
  "Chris Farley",
  "Chris Hemsworth",
  "Chris Jai Alex",
  "Chris Johnson",
  "Chris Klein",
  "Chris New",
  "Chris Noth",
  "Chris O'Donnell",
  "Chris O'Dowd",
  "Chris Owen",
  "Chris Penn",
  "Chris Pine",
  "Chris Pontius",
  "Chris Pratt",
  "Chris Renaud",
  "Chris Rock",
  "Chris Sanders",
  "Chris Sarandon",
  "Chris Udvarnoky",
  "Chris Williams",
  "Christa Denton",
  "Christa Théret",
  "Christel Pedrinelli",
  "Christian Alers",
  "Christian Bale",
  "Christian Berkel",
  "Christian Borromeo",
  "Christian Camargo",
  "Christian Clavier",
  "Christian Cooke",
  "Christian Distefano",
  "Christian Duurvoort",
  "Christian Friedel",
  "Christian Malheiros",
  "Christian Marin",
  "Christian Patey",
  "Christian Redl",
  "Christian Roberts",
  "Christian Slater",
  "Christian Stevens",
  "Christian Tasche",
  "Christian Tramitz",
  "Christian Ulmen",
  "Christian von Aster",
  "Christiane Hörbiger",
  "Christiane Paul",
  "Christin Rankins",
  "Christina Applegate",
  "Christina Eleusiniotis",
  "Christina Hecke",
  "Christina Hendricks",
  "Christina Jastrzembska",
  "Christina Nigra",
  "Christina Ricci",
  "Christine Ebadi",
  "Christine Fabréga",
  "Christine Hakim",
  "Christine Hargreaves",
  "Christine Jirku",
  "Christine Kain",
  "Christine Noonan",
  "Christine Paul",
  "Christo Jivkov",
  "Christoph Koncz",
  "Christoph Letkowski",
  "Christoph Luser",
  "Christoph Maria Herbst",
  "Christoph Pieczynski",
  "Christoph Waltz",
  "Christophe Lourdelet",
  "Christophe Maratier",
  "Christophe Odent",
  "Christophe Paou",
  "Christopher Abbott",
  "Christopher Adamson",
  "Christopher Bevins",
  "Christopher Carley",
  "Christopher Convery",
  "Christopher Doyle",
  "Christopher Eccleston",
  "Christopher Egan",
  "Christopher Fitzgerald",
  "Christopher George",
  "Christopher Guard",
  "Christopher Guest",
  "Christopher Heatherington",
  "Christopher Heyerdahl",
  "Christopher J. Hart",
  "Christopher Kirby",
  "Christopher Lambert",
  "Christopher Lee",
  "Christopher Lloyd",
  "Christopher Lowell",
  "Christopher McDonald",
  "Christopher Meloni",
  "Christopher Miller",
  "Christopher Mintz-Plasse",
  "Christopher Plummer",
  "Christopher Reeve",
  "Christopher Walken",
  "Chuan-jun Wang",
  "Chuck Cooper",
  "Chuck Norris",
  "Chulpan Khamatova",
  "Chun Sun",
  "Chun Zhang",
  "Chunchuna Villafañe",
  "Chunibala Devi",
  "Chus Lampreave",
  "Chus Pereiro",
  "Chuxiao Qu",
  "Châu Belle Dinh",
  "Chôchô Miyako",
  "Ciaran Owens",
  "Ciarán Hinds",
  "Cicely Browne",
  "Cihan Ercan",
  "Cihat Bütün",
  "Cillian Murphy",
  "Cindy Crawford",
  "Cindy Sampson",
  "Cinzia Monreale",
  "Ciro Guerra",
  "Citra Prima",
  "Claes Bang",
  "Claire Bloom",
  "Claire Danes",
  "Claire Forlani",
  "Claire Foy",
  "Claire Holt",
  "Claire Julien",
  "Claire Keim",
  "Claire Maurier",
  "Claire Rushbrook",
  "Claire Trevor",
  "Clancy Brown",
  "Clara Calamai",
  "Clara Galle",
  "Clara Lago",
  "Clara Lee",
  "Clara Moraleda",
  "Clare Hammond",
  "Clare Higgins",
  "Clark Duke",
  "Clark Gable",
  "Claude Baz Moussawbaa",
  "Claude Brasseur",
  "Claude Chamberlain",
  "Claude Gensac",
  "Claude Jade",
  "Claude Laydu",
  "Claude Legault",
  "Claude Lulé",
  "Claude Maki",
  "Claude Perron",
  "Claude Rains",
  "Claude Rich",
  "Claudette Colbert",
  "Claudia Angelique Rademaker",
  "Claudia Candusso",
  "Claudia Cantero",
  "Claudia Cardinale",
  "Claudia Celedón",
  "Claudia Ferri",
  "Claudia Gerini",
  "Claudia Ieremia",
  "Claudia Lander-Duke",
  "Claudia Martini",
  "Claudia Michelsen",
  "Claudia Placer",
  "Claudine Auger",
  "Claudine Longet",
  "Claudio Amendola",
  "Claudio Bigagli",
  "Claudio Bisio",
  "Claudio Brook",
  "Claudio Camaso",
  "Claudio Cassinelli",
  "Claudio Fragasso",
  "Claudio Gora",
  "Claudio Santamaria",
  "Clayton Rohner",
  "Clea DuVall",
  "Cleavant Derricks",
  "Cleavon Little",
  "Clelia Matania",
  "Clemens Scheitz",
  "Clemens Schick",
  "Cleo King",
  "Cleopatra Coleman",
  "Clif Prowse",
  "Cliff De Young",
  "Cliff Robertson",
  "Clifford Bañagale",
  "Clifton Collins Jr.",
  "Clifton James",
  "Clifton Powell",
  "Clifton Webb",
  "Clint Bickham",
  "Clint Eastwood",
  "Clint Howard",
  "Clive Merrison",
  "Clive Owen",
  "Clotilde Hesme",
  "Clotilde Mollet",
  "Clovis Cornillac",
  "Clémence Poésy",
  "Clément Harari",
  "Clémentine Grenier",
  "Cobie Smulders",
  "Cole Hauser",
  "Coleen Gray",
  "Colette Descombes",
  "Colin Blakely",
  "Colin Blumenau",
  "Colin Clive",
  "Colin Farrell",
  "Colin Firth",
  "Colin Ford",
  "Colin Hanks",
  "Colin Jost",
  "Colin Morgan",
  "Colin O'Donoghue",
  "Collosseo",
  "Colm Feore",
  "Colm Meaney",
  "Colman Domingo",
  "Coluche",
  "Columbus Short",
  "Common",
  "Conchita Airoldi",
  "Conchita Pérez",
  "Connie Nielsen",
  "Connor Price",
  "Conrad Veidt",
  "Conrad Vernon",
  "Constance Wu",
  "Consuelo De Haviland",
  "Consuelo Gómez",
  "Cooper Hoffman",
  "Coral Browne",
  "Coral Puente",
  "Coralie Russier",
  "Coralie Trinh Thi",
  "Coralina Cataldi-Tassoni",
  "Corbin Bernsen",
  "Corbin Bleu",
  "Corbin Reid",
  "Corentin Fila",
  "Corey Burton",
  "Corey Feldman",
  "Corey Hawkins",
  "Cori Gonzalez-Macuer",
  "Corinna Everson",
  "Corinna Harfouch",
  "Corinne Cléry",
  "Corinne Foxx",
  "Corinne Marchand",
  "Cornel Wilde",
  "Cornelia Froboess",
  "Cornelia Gröschel",
  "Cory Evans",
  "Cory Gruter-Andrew",
  "Corynne Charbit",
  "Cosimo Cinieri",
  "Cosmo Jarvis",
  "Costas Mandylor",
  "Courteney Cox",
  "Courtney B. Vance",
  "Craig Bierko",
  "Craig Fairbrass",
  "Craig Ferguson",
  "Craig Parkinson",
  "Craig Roberts",
  "Craig Robinson",
  "Craig Sheffer",
  "Craig T. Nelson",
  "Crispin Glover",
  "Cristian De Asis",
  "Cristian Mercado",
  "Cristian Solimeno",
  "Cristian Valencia",
  "Cristiana Capotondi",
  "Cristiana Dell'Anna",
  "Cristiano Caccamo",
  "Cristiano Sassella",
  "Cristina Chiriac",
  "Cristina Marsillach",
  "Cristina Sánchez Pascual",
  "Cristián de la Fuente",
  "Crystal Reed",
  "Csaba Czene",
  "Csongor Kassai",
  "Cuba Gooding Jr.",
  "Cuifen Cao",
  "Cunxin Pu",
  "Curd Jürgens",
  "Curt Bois",
  "Cush Jumbo",
  "Cut Meyriska",
  "Cut Mini Theo",
  "Cybill Shepherd",
  "Cynthia Erivo",
  "Cyrielle Clair",
  "Cyril Cusack",
  "Cyril Raffaelli",
  "Cyril Roy",
  "Cyrus Abidi",
  "Cécile de France",
  "Cédric Kahn",
  "Céline Beugnot",
  "Céline Sallette",
  "César Bordón",
  "César Burner",
  "César Troncoso",
  "D.B. Sweeney",
  "DJ Dallenbach",
  "DMX",
  "Dabney Coleman",
  "Dacre Montgomery",
  "Dady Brieva",
  "Dae-Hoon Jeong",
  "Daekyum Ahn",
  "Dafne Keen",
  "Dagmar Manzel",
  "Dagmara Dominczyk",
  "Dahong Ni",
  "Daiki Yamashita",
  "Dainius Kazlauskas",
  "Daisuke Gôri",
  "Daisuke Namikawa",
  "Daisuke Ryû",
  "Daisy Bevan",
  "Daisy Donovan",
  "Daisy Granados",
  "Daisy Ridley",
  "Daizaburo Arakawa",
  "Dakar",
  "Dakota Blue Richards",
  "Dakota Fanning",
  "Dakota Johnson",
  "Dal-su Oh",
  "Dale Dickey",
  "Dale Whibley",
  "Dali Benssalah",
  "Dalia Hernández",
  "Dalila Di Lazzaro",
  "Dallas Roberts",
  "Dalton Vigh",
  "Damian Chapa",
  "Damian Lewis",
  "Damien Boisseau",
  "Damien Bonnard",
  "Damien Jouillerot",
  "Damián Alcázar",
  "Damián Canduci",
  "Damon Daunno",
  "Damon Wayans",
  "Damon Wayans Jr.",
  "Damson Idris",
  "Dan Aykroyd",
  "Dan Beirne",
  "Dan Byrd",
  "Dan Fogler",
  "Dan Futterman",
  "Dan Hedaya",
  "Dan Kuenster",
  "Dan Leis",
  "Dan Monahan",
  "Dan O'Herlihy",
  "Dan Riba",
  "Dan Stevens",
  "Dan Sturkie",
  "Dana Andrews",
  "Dana Davis",
  "Dana Delany",
  "Dana Fuchs",
  "Dana Wynter",
  "Dana de Celis",
  "Danay Garcia",
  "Dandan Song",
  "Dane Cook",
  "Dane DeHaan",
  "Dani",
  "Dani Popescu",
  "Dani Rovira",
  "Daniel Aráoz",
  "Daniel Auteuil",
  "Daniel Boulanger",
  "Daniel Brocklebank",
  "Daniel Brühl",
  "Daniel Cauchy",
  "Daniel Ceccaldi",
  "Daniel Chacón",
  "Daniel Craig",
  "Daniel Dae Kim",
  "Daniel Day-Lewis",
  "Daniel Diemer",
  "Daniel Durant",
  "Daniel Duval",
  "Daniel Emilfork",
  "Daniel Eric Gold",
  "Daniel Fanego",
  "Daniel Genoud",
  "Daniel Giménez Cacho",
  "Daniel Grao",
  "Daniel Gélin",
  "Daniel Henshall",
  "Daniel Hill",
  "Daniel Ivernel",
  "Daniel Kaluuya",
  "Daniel Küblböck",
  "Daniel Mananta",
  "Daniel Mays",
  "Daniel Pommereulle",
  "Daniel Prévost",
  "Daniel Radcliffe",
  "Daniel Richter",
  "Daniel Sarky",
  "Daniel Scheinert",
  "Daniel St. Pierre",
  "Daniel Stern",
  "Daniel Tovar",
  "Daniel Webber",
  "Daniel Wu",
  "Daniel York",
  "Daniel von Bargen",
  "Daniela Bianchi",
  "Daniela Rocca",
  "Daniela Thomas",
  "Daniela Vega",
  "Daniella Kertesz",
  "Danielle Bisutti",
  "Danielle Darrieux",
  "Danielle Macdonald",
  "Danielle Proulx",
  "Danila Kozlovskiy",
  "Danilo Mattei",
  "Danièle Girard",
  "Danny Aiello",
  "Danny DeVito",
  "Danny Denzongpa",
  "Danny Dyer",
  "Danny Elfman",
  "Danny Glover",
  "Danny Hoch",
  "Danny Huston",
  "Danny Lee",
  "Danny Lloyd",
  "Danny McBride",
  "Danny Sapani",
  "Danny Trejo",
  "Danuta Stenka",
  "Dany Boon",
  "Dany Robin",
  "Daoming Chen",
  "Daphne Patakia",
  "Dar Salim",
  "Dara Singh Randhawa",
  "Darby Camp",
  "Daria Nicolodi",
  "Daria Widawska",
  "Darina Boonchu",
  "Darine Hamze",
  "Dario Argento",
  "Dario D'Ambrosi",
  "Dariusz Biskupski",
  "Darlan Cunha",
  "Darren McGavin",
  "Darryl F. Zanuck",
  "Darsheel Safary",
  "Dartanian Sloan",
  "Daryl Hannah",
  "Daryl McCormack",
  "Darío Grandinetti",
  "Dave Bautista",
  "Dave Chappelle",
  "Dave Franco",
  "Dave Goelz",
  "Dave Johns",
  "Dave Thomas",
  "Dave Wittenberg",
  "Daveigh Chase",
  "David Alan Basche",
  "David Alexander",
  "David Arquette",
  "David Atrakchi",
  "David Belle",
  "David Bennent",
  "David Boreanaz",
  "David Bornstein",
  "David Bowie",
  "David Bradley",
  "David Brandon",
  "David Carradine",
  "David Copperfield",
  "David Corenswet",
  "David Cronenberg",
  "David Crow",
  "David Crowley",
  "David Della Rocco",
  "David Dencik",
  "David Duchovny",
  "David Duggan",
  "David Edwin Knight",
  "David Emge",
  "David Farrar",
  "David Foley",
  "David Gebigke",
  "David Graf",
  "David Gulpilil",
  "David Hand",
  "David Harbour",
  "David Hayman",
  "David Heap",
  "David Hemmings",
  "David Henrie",
  "David Herman",
  "David Hess",
  "David Hewlett",
  "David Huddleston",
  "David Huffman",
  "David James",
  "David Janssen",
  "David Jensen",
  "David Kaye",
  "David Kelly",
  "David Kross",
  "David Lee Smith",
  "David Leitch",
  "David Lovgren",
  "David Matranga",
  "David Morrissey",
  "David Morse",
  "David N. Weiss",
  "David Naughton",
  "David Niven",
  "David O'Hara",
  "David Oyelowo",
  "David Rauchenberger",
  "David Rawle",
  "David Rintoul",
  "David Roper",
  "David Schwimmer",
  "David Schütter",
  "David Silverman",
  "David Spade",
  "David Strathairn",
  "David Sust",
  "David Tennant",
  "David Thewlis",
  "David Threlfall",
  "David Tomlinson",
  "David Verdaguer",
  "David Villalpando",
  "David Wald",
  "David Walliams",
  "David Warbeck",
  "David Warner",
  "David Wenham",
  "David Wiberg",
  "David Wissak",
  "David Wood",
  "David Zucker",
  "Davide Calgaro",
  "Davood Fathali Beigi",
  "Davor Dujmovic",
  "Dawid Ogrodnik",
  "Day Mesquita",
  "Dayo Okeniyi",
  "DeForest Kelley",
  "DeRay Davis",
  "Dea Panendra",
  "Dean Andrews",
  "Dean Fujioka",
  "Dean Geyer",
  "Dean Lennox Kelly",
  "Dean Martin",
  "Dean Mumford",
  "Dean Norris",
  "Dean Shek",
  "Dean Stockwell",
  "Dean-Charles Chapman",
  "Debbie Harry",
  "Debbie Honeywood",
  "Debbie Podowski",
  "Debbie Reynolds",
  "Debby Bishop",
  "Debby Ryan",
  "Debo Andryos",
  "Deborah Foreman",
  "Deborah Kara Unger",
  "Deborah Kerr",
  "Deborah Raffin",
  "Deborah Wastell",
  "Debra Messing",
  "Debra Winger",
  "Dee Wallace",
  "Deems Taylor",
  "Deepak Dobriyal",
  "Deepika Padukone",
  "Dejan Cukic",
  "Del Negro",
  "Delia Casanova",
  "Delia Garcés",
  "Delphine Chanéac",
  "Delphine Chuillot",
  "Delphine Seyrig",
  "Delroy Lindo",
  "Demet Akbag",
  "Demet Evgar",
  "Demet Gül",
  "Demet Sener",
  "Demi Moore",
  "Demir Karahan",
  "Demián Bichir",
  "Denden",
  "Denholm Elliott",
  "Denis Lavant",
  "Denis Lawson",
  "Denis Moschitto",
  "Denis Ménochet",
  "Denise Darcel",
  "Denise Fraga",
  "Denise Grey",
  "Denise Richards",
  "Deniz Baysal",
  "Dennis Farina",
  "Dennis Haysbert",
  "Dennis Hopper",
  "Dennis Price",
  "Dennis Quaid",
  "Dennis Storhøi",
  "Dennis Widmyer",
  "Denny Sumargo",
  "Denzel Washington",
  "Denzel Whitaker",
  "Deobia Oparei",
  "Deogratias Huerta",
  "Derek Jacobi",
  "Derek Jarman",
  "Derek John Drescher",
  "Derek Lee",
  "Derek Luke",
  "Derek Magyar",
  "Derek Richardson",
  "Dermot Mulroney",
  "Derya Alabora",
  "Derya Ayverdi",
  "Desi Lydic",
  "Desmond Harrington",
  "Detlev Buck",
  "Dev Patel",
  "Devid Striesow",
  "Devon Aoki",
  "Devon Bostick",
  "Devon Graye",
  "Devon Sawa",
  "Devrim Yakut",
  "Devyn McDowell",
  "Dexter Fletcher",
  "Dhanush",
  "Dharmendra",
  "Dhia Cristiani",
  "Diahnne Abbott",
  "Dian Sastrowardoyo",
  "Diana Del Bufalo",
  "Diana Hardcastle",
  "Diana Mariscal",
  "Diana Muldaur",
  "Diana Rigg",
  "Diana Staehly",
  "Diane Baker",
  "Diane Keaton",
  "Diane Kruger",
  "Diane Ladd",
  "Diane Lane",
  "Diane Venora",
  "Dianna Agron",
  "Dianne Wiest",
  "Dibyendu Bhattacharya",
  "Dick Hogan",
  "Dick Van Dyke",
  "Dick York",
  "Dida Maia",
  "Didier Bourdon",
  "Didier Pain",
  "Diego Abatantuono",
  "Diego Cataño",
  "Diego Cortina Autrey",
  "Diego Fontecilla",
  "Diego Klattenhoff",
  "Diego Luna",
  "Diego Martín",
  "Diego Peretti",
  "Diego Starosta",
  "Dieter Berner",
  "Dieter Landuris",
  "Dieter Laser",
  "Diether Krebs",
  "Dima Chigaryov",
  "Dimas Aditya",
  "Dimitri Bilov",
  "Dimitri Storoge",
  "Dimitris Georgalas",
  "Dina Korzun",
  "Dina Meyer",
  "Dinara Drukarova",
  "Dino Abbrescia",
  "Dino Athanassiou",
  "Dino Bajrovic",
  "Dino Jaksic",
  "Dion Wiyoko",
  "Diora Baird",
  "Dirk Bogarde",
  "Disha Patani",
  "Dishita Sehgal",
  "Dita Parlo",
  "Divya Dutta",
  "Djebril Zonga",
  "Djemel Barek",
  "Djimon Hounsou",
  "Dmitriy Dyuzhev",
  "Dmitriy Nazarov",
  "Dmitriy Orlov",
  "Dmitriy Ulyanov",
  "Dmitriy Vasilev",
  "Dmytro Andriyovich Gachkov",
  "Do-bin Baek",
  "Doga Zeynep Doguslu",
  "Dogu Demirkol",
  "Dolly Ahluwalia",
  "Dolly Scal",
  "Dolly Wells",
  "Dolores Fonzi",
  "Dolores Heredia",
  "Dolores Moran",
  "Dolph Lundgren",
  "Dolunay Soysert",
  "Dom DeLuise",
  "Domhnall Gleeson",
  "Dominic Cooper",
  "Dominic Philie",
  "Dominic Purcell",
  "Dominic West",
  "Dominique Besnehard",
  "Dominique Blanc",
  "Dominique Blanchar",
  "Dominique Davray",
  "Dominique Farrugia",
  "Dominique Frot",
  "Dominique Horwitz",
  "Dominique Lavanant",
  "Dominique McElligott",
  "Dominique Michel",
  "Dominique Pinon",
  "Dominique Sanda",
  "Dominique Tipper",
  "Domiziana Giordano",
  "Don Cheadle",
  "Don Francks",
  "Don Gordon",
  "Don Hall",
  "Don Harvey",
  "Don McKellar",
  "Don Rickles",
  "Don Taylor",
  "Don Thompson",
  "Donald O'Brien",
  "Donald O'Connor",
  "Donald Pleasence",
  "Donald Sutherland",
  "Donatas Banionis",
  "Donatas Ivanauskas",
  "Dong-hwi Lee",
  "Dong-il Sung",
  "Dong-in Cho",
  "Dong-jun Kim",
  "Dongyu Zhou",
  "Donna Murphy",
  "Donna Reed",
  "Donnie Wahlberg",
  "Donnie Yen",
  "Donny Alamsyah",
  "Donovan Cook",
  "Dora Doll",
  "Doria Tillier",
  "Dorian Gray",
  "Dorian Le Clech",
  "Dorota Stalinska",
  "Dorothy Atkinson",
  "Dorothy Comingore",
  "Dorothy Duffy",
  "Dorothy Elias-Fahn",
  "Dorothée Berryman",
  "Dorothée Blanck",
  "Dot Cosgrove",
  "Doudou Gueye Thiaw",
  "Doug Bradley",
  "Doug E. Doug",
  "Doug Hutchison",
  "Doug Jones",
  "Doug Stone",
  "Dougie McConnell",
  "Douglas Booth",
  "Douglas Henshall",
  "Douglas Hodge",
  "Douglas Silva",
  "Douglas Smith",
  "Dougray Scott",
  "Dragos Bucur",
  "Drake Cook",
  "Drew Barrymore",
  "Du-shim Ko",
  "Duane Jones",
  "Duilio Del Prete",
  "Dulfi Al-Jabouri",
  "Dulquer Salmaan",
  "Duncan MacNeil",
  "Dunja Sowinetz",
  "Dustin Hoffman",
  "Dwayne Johnson",
  "Dwight Schultz",
  "Dwight Yoakam",
  "Dyan Cannon",
  "Dylan Baker",
  "Dylan McDermott",
  "Dylan Minnette",
  "Dylan Moran",
  "Dylan O'Brien",
  "Dylan Schmid",
  "Dylan Smith",
  "Déborah François",
  "Déborah Lukumuena",
  "Déborah Révy",
  "Eamon Farren",
  "Earl Minor",
  "Ebbe Rode",
  "Eberhard Auriga",
  "Ebrahim Amini",
  "Ebru Ceylan",
  "Ed Begley",
  "Ed Begley Jr.",
  "Ed Harris",
  "Ed Helms",
  "Ed Lauter",
  "Ed O'Ross",
  "Ed Skrein",
  "Ed Speleers",
  "Ed Stoppard",
  "Ed Westwick",
  "Edda Köchl",
  "Edda Seippel",
  "Eddie Albert",
  "Eddie Axberg",
  "Eddie Baroo",
  "Eddie Barth",
  "Eddie Izzard",
  "Eddie Marsan",
  "Eddie Murphy",
  "Eddie Redmayne",
  "Eden Dambrine",
  "Eden Lawrence",
  "Eden Villavicencio",
  "Edgar Buchanan",
  "Edgar Ramírez",
  "Edgar Wright",
  "Edilson Lino",
  "Edison Chen",
  "Edita Malovcic",
  "Edith Scob",
  "Edmond O'Brien",
  "Edmund Goulding",
  "Edmund Gwenn",
  "Edmund Moeschke",
  "Edmund Purdom",
  "Edna Best",
  "Edna Purviance",
  "Edoardo Gabbriellini",
  "Edoardo Leo",
  "Edoardo Pesce",
  "Edouard Baer",
  "Eduard Abalov",
  "Eduard Fernández",
  "Eduard Izotov",
  "Eduardo Antuña",
  "Eduardo Blanco",
  "Eduardo Minett",
  "Eduardo Noriega",
  "Eduardo Verástegui",
  "Edward Arnold",
  "Edward Ashley",
  "Edward Asner",
  "Edward Burns",
  "Edward Fox",
  "Edward Furlong",
  "Edward G. Robinson",
  "Edward Herrmann",
  "Edward James Olmos",
  "Edward Norton",
  "Edwige Fenech",
  "Edwin Wright",
  "Eero Aho",
  "Efraín Solís",
  "Efrem Zimbalist Jr.",
  "Efsun Akkurt",
  "Ege Kökenli",
  "Ege Tanman",
  "Egy Fedly",
  "Eihi Shiina",
  "Eiji Okada",
  "Eijirô Tôno",
  "Eiko Masuyama",
  "Eiko Matsuda",
  "Eiko Miyoshi",
  "Eileen Atkins",
  "Eileen Davies",
  "Eileen Walsh",
  "Eilidh Rankin",
  "Eion Bailey",
  "Eita Nagayama",
  "Eiza González",
  "Ekaterina Samsonov",
  "Ekaterina Vilkova",
  "Ekateryna Rak",
  "Ekin Koç",
  "El Hedi ben Salem",
  "Elaine Hendrix",
  "Elaine Jin",
  "Elcin Atamgüc",
  "Eldar Ryazanov",
  "Elden Henson",
  "Eleanor David",
  "Eleanor Parker",
  "Eleanor Tomlinson",
  "Eleazar Reygadas",
  "Elena Altieri",
  "Elena Anaya",
  "Elena Ballesteros",
  "Elena Lietti",
  "Elena Lyadova",
  "Elena Lyons",
  "Elena Morozova",
  "Elena Proklova",
  "Elena Radonicich",
  "Elena Sofia Ricci",
  "Eleonora Brown",
  "Eleonora Giovanardi",
  "Elham Saboktakin",
  "Eli Goree",
  "Eli Marienthal",
  "Eli Roth",
  "Eli Wallach",
  "Eliane Gagnon",
  "Elias Koteas",
  "Elias Schwarz",
  "Eliazar Jimenez",
  "Eligio Meléndez",
  "Elijah Smith",
  "Elijah Wood",
  "Elio Germano",
  "Elio Marcuzzo",
  "Elisabeth Depardieu",
  "Elisabeth Moss",
  "Elisabeth Shue",
  "Elisabeth Trissenaar",
  "Elisabeth Ventura",
  "Elisabeth Wiedemann",
  "Elissa Knight",
  "Elit Iscan",
  "Eliza Bennett",
  "Eliza Rycembel",
  "Eliza Scanlen",
  "Elizabeth Banks",
  "Elizabeth Berridge",
  "Elizabeth Berrington",
  "Elizabeth Bourgine",
  "Elizabeth Debicki",
  "Elizabeth Gracen",
  "Elizabeth Hurley",
  "Elizabeth McGovern",
  "Elizabeth Mitchell",
  "Elizabeth Olsen",
  "Elizabeth Taylor",
  "Elizaveta Boyarskaya",
  "Elke Sommer",
  "Ella Balinska",
  "Ella Ballentine",
  "Ella Josephine Lund Nilsson",
  "Ella Purnell",
  "Ella Rumpf",
  "Ellar Coltrane",
  "Elle Fanning",
  "Ellen Barkin",
  "Ellen Burstyn",
  "Ellen Crawford",
  "Ellen DeGeneres",
  "Ellen Dorrit Petersen",
  "Ellen Greene",
  "Ellen Widmann",
  "Ellen Wong",
  "Elli Tringou",
  "Elliot Cowan",
  "Elliot Lazar",
  "Elliot Page",
  "Elliott Crosset Hove",
  "Elliott Gould",
  "Ellise Chappell",
  "Elly Curtis",
  "Elmar Wepper",
  "Elmo Nüganen",
  "Elnaz Shakerdoost",
  "Elodie Harrod",
  "Elodie Yung",
  "Elpidia Carrillo",
  "Elsa Lanchester",
  "Elsa Sophie Gambard",
  "Elsa Zylberstein",
  "Elton John",
  "Elvira Mínguez",
  "Elvira Onetto",
  "Ely Galleani",
  "Elya Baskin",
  "Elyas M'Barek",
  "Elzbieta Czyzewska",
  "Elçin Sangu",
  "Eman Xor Oña",
  "Emanuele Maria Di Stefano",
  "Embeth Davidtz",
  "Emel Sayin",
  "Emeric Pressburger",
  "Emi Shinohara",
  "Emi Takei",
  "Emil Hass Christensen",
  "Emil Jannings",
  "Emil Marwa",
  "Emil Tarding",
  "Emile Hirsch",
  "Emilia Clarke",
  "Emilia Fox",
  "Emilia Jones",
  "Emiliano Zurita",
  "Emilie de Ravin",
  "Emilio Buale",
  "Emilio Echevarría",
  "Emilio Estevez",
  "Emilio Gavira",
  "Emilio Gutiérrez Caba",
  "Emilio Rivera",
  "Emily Baldoni",
  "Emily Beecham",
  "Emily Blunt",
  "Emily Browning",
  "Emily Cox",
  "Emily Hampshire",
  "Emily May Jampel",
  "Emily Mortimer",
  "Emily Perkins",
  "Emily Ratajkowski",
  "Emily Watson",
  "Emin Ceylan",
  "Emir Ali Dogrul",
  "Emir Kusturica",
  "Emma Bading",
  "Emma Bell",
  "Emma Bunton",
  "Emma Campbell",
  "Emma Cleasby",
  "Emma Gramatica",
  "Emma Greenwell",
  "Emma Karwandy",
  "Emma King",
  "Emma Louise Webb",
  "Emma Penella",
  "Emma Roberts",
  "Emma Shorey",
  "Emma Stone",
  "Emma Suárez",
  "Emma Thompson",
  "Emma Vilarasau",
  "Emma Watson",
  "Emma Wiklund",
  "Emma de Caunes",
  "Emmanuel Affadzi",
  "Emmanuel Jal",
  "Emmanuel Schwartz",
  "Emmanuelle Bercot",
  "Emmanuelle Béart",
  "Emmanuelle Devos",
  "Emmanuelle Laborit",
  "Emmanuelle Riva",
  "Emmanuelle Seigner",
  "Emmanuelle Vaugier",
  "Emmy Rossum",
  "Emory Cohen",
  "Emre Karademir",
  "Emre Karayel",
  "Emre Kinay",
  "Endy Arfian",
  "Engin Akyürek",
  "Engin Günaydin",
  "Engin Senkan",
  "Enman Gao",
  "Ennio Fantastichini",
  "Enric Auquer",
  "Enrico Colantoni",
  "Enrico Lo Verso",
  "Enrico Maria Salerno",
  "Enrique Castillo",
  "Enrique Diaz",
  "Enrique Irazoqui",
  "Enrique Rambal",
  "Enrique Villén",
  "Enzo Cannavale",
  "Enzo Cilenti",
  "Enzo Staiola",
  "Enzo Tarascio",
  "Ercan Kesal",
  "Erdal Besikçioglu",
  "Erdal Tosun",
  "Erdal Özyagcilar",
  "Erdem Yener",
  "Erden Telemisov",
  "Erdil Yasaroglu",
  "Ergun Kuyucu",
  "Eri Watanabe",
  "Eric Balfour",
  "Eric Bana",
  "Eric Baugin",
  "Eric Bogosian",
  "Eric Borsuk",
  "Eric Braeden",
  "Eric Cantona",
  "Eric Elmosnino",
  "Eric Febrian",
  "Eric Godon",
  "Eric Herson-Macarel",
  "Eric Idle",
  "Eric Mabius",
  "Eric Osborne",
  "Eric Radomski",
  "Eric Schweig",
  "Eric Stoltz",
  "Eric Stuart",
  "Eric Sykes",
  "Eric Tsang",
  "Erica Ellis",
  "Erica Xia-Hou",
  "Erich Wildpret",
  "Erich von Stroheim",
  "Erik Paaske",
  "Erika Christensen",
  "Erika Linder",
  "Erika Marozsán",
  "Erika Oda",
  "Erika Sawajiri",
  "Eriko Hatsune",
  "Erin Cummings",
  "Erin Karpluk",
  "Erin Richards",
  "Erin Wasson",
  "Erina Mano",
  "Erinn Hayes",
  "Eriq Ebouaney",
  "Erkan Can",
  "Erkan Kolçak Köstendil",
  "Erland Josephson",
  "Ernest B. Schoedsack",
  "Ernest Borgnine",
  "Ernest Prakasa",
  "Ernesto Alonso",
  "Ernesto Alterio",
  "Ernesto Gómez Cruz",
  "Ernie Hudson",
  "Erno Crisa",
  "Ernst Deutsch",
  "Ernst Jacobi",
  "Ernst Pittschau",
  "Ernst Stötzner",
  "Ernst Umhauer",
  "Ernst-Herbert Albertsen",
  "Errol Flynn",
  "Ersin Arici",
  "Ersin Korkut",
  "Ertan Saban",
  "Erwin Connelly",
  "Erwin Leder",
  "Eryk Lubos",
  "Esben Smed",
  "Eslinda Núñez",
  "Espen Sandberg",
  "Essie Davis",
  "Esteban Carvajal-Alegria",
  "Esteban Roel",
  "Estela Inda",
  "Ester Birch",
  "Esther Anil",
  "Esther Gohourou",
  "Esther Purves-Smith",
  "Ethan Coen",
  "Ethan Embry",
  "Ethan Hawke",
  "Etsushi Toyokawa",
  "Ettore Garofolo",
  "Eugene Collier",
  "Eugene Hutz",
  "Eugene Levy",
  "Eugene Roche",
  "Eugene Silvain",
  "Eugenio Derbez",
  "Eul-boon Kim",
  "Eun Won-jae",
  "Eun-Kyung Shin",
  "Eun-ju Lee",
  "Eun-pyo Jeong",
  "Eunjin Kang",
  "Eusebio Poncela",
  "Eva Bartok",
  "Eva Birthistle",
  "Eva Bourne",
  "Eva Cobo",
  "Eva Gabor",
  "Eva Green",
  "Eva Llorach",
  "Eva Marie Saint",
  "Eva Mattes",
  "Eva Mendes",
  "Eva Renzi",
  "Eva Siva",
  "Eva Tennear",
  "Eva Whittaker",
  "Evan Bird",
  "Evan Jonigkeit",
  "Evan Rachel Wood",
  "Evan Richards",
  "Evangeline Lilly",
  "Eve Arden",
  "Evelyn Hamann",
  "Evelyne Brochu",
  "Everett McGill",
  "Evgenia Brik",
  "Evgeniy Gerchakov",
  "Evgeniy Leonov",
  "Evgeniy Mironov",
  "Evgeniy Sidikhin",
  "Evgeniy Tsyganov",
  "Evgeniy Zharikov",
  "Evi Marandi",
  "Evi Saoulidou",
  "Ewa Fröling",
  "Ewa Krzyzewska",
  "Ewa Pokas",
  "Ewa Strömberg",
  "Ewa Szykulska",
  "Ewan McGregor",
  "Ewen Bremner",
  "Eysan Özhim",
  "Eythor Gudjonsson",
  "Ezequiel Cipols",
  "Ezgi Mola",
  "Ezio Greggio",
  "Ezra Miller",
  "Ezzatolah Entezami",
  "F. Murray Abraham",
  "Fabian Busch",
  "Fabian Hinrichs",
  "Fabio Audi",
  "Fabio D'Innocenzo",
  "Fabio Testi",
  "Fabiola Zamora",
  "Fabrice Luchini",
  "Fabrizia Sacchi",
  "Fabrizio Bentivoglio",
  "Fabrizio Ferracane",
  "Fabrizio Rongione",
  "Fachry Albar",
  "Fadil Garan",
  "Faegh Mohamadi",
  "Fahadh Faasil",
  "Fahri Yardim",
  "Fairuza Balk",
  "Fajar Bustomi",
  "Fakhri Khorvash",
  "Falk Hentschel",
  "Famke Janssen",
  "Fan Liao",
  "Fan Xu",
  "Fang Yin",
  "Fanny Ardant",
  "Fanny Mallette",
  "Fanny Valette",
  "Fantin Ravat",
  "Fantine Harduin",
  "Farah Zeynep Abdullah",
  "Farahnaz Safari",
  "Faramarz Gharibian",
  "Farbod Masoud Vaziri",
  "Fares Fares",
  "Farhad Aslani",
  "Farhad Ghaemian",
  "Farhad Kheradmand",
  "Farhan Akhtar",
  "Farid Sajjadi Hosseini",
  "Farida Benkhetache",
  "Farida Jalal",
  "Farideh Sepah Mansour",
  "Fariz Alfarazi",
  "Farley Granger",
  "Farrokh Shoja",
  "Fatemah Motamed-Aria",
  "Fatemeh Cherag Akhar",
  "Fathia Youssouf",
  "Fatima Sana Shaikh",
  "Fatma Ceylan",
  "Fausto Russo Alesi",
  "Fay Bainter",
  "Fay Ripley",
  "Fay Wray",
  "Faye Dunaway",
  "Faye Marsay",
  "Faye Wong",
  "Fayssal Bazzi",
  "Federico Cecere",
  "Federico Ielapi",
  "Federico Luppi",
  "Federico Pitzalis",
  "Federico Silva",
  "Fedi Nuril",
  "Fedja van Huêt",
  "Fedor Bondarchuk",
  "Fedor Fedotov",
  "Fei Ren",
  "Fele Martínez",
  "Felecia Angelle",
  "Felice Jankell",
  "Felicity Jones",
  "Felix Bressart",
  "Felix Kammerer",
  "Felix Lemburo",
  "Femi Benussi",
  "Fengliang Yang",
  "Fengyi Zhang",
  "Feodor Chaliapin Jr.",
  "Ferdia Walsh-Peelo",
  "Fereshteh Sadre Orafaiy",
  "Ferhan Sensoy",
  "Ferit Faraji",
  "Fernand Charpin",
  "Fernand Ledoux",
  "Fernanda Montenegro",
  "Fernando Becerril",
  "Fernando Cayo",
  "Fernando Cuautle",
  "Fernando Fernán Gómez",
  "Fernando Guillén Cuervo",
  "Fernando Ramos da Silva",
  "Fernando Rey",
  "Fernando Tielve",
  "Fernando Trueba",
  "Ferran Terraza",
  "Feyyaz Yigit",
  "Fhi Fan",
  "Fikret Kuskan",
  "Filip Berg",
  "Filip Sovagovic",
  "Filipp Avdeev",
  "Filipp Yankovskiy",
  "Filippo Nigro",
  "Filippo Scotti",
  "Finn Little",
  "Finn Wittrock",
  "Finn Wolfhard",
  "Fiona Dourif",
  "Fiona Shaw",
  "Fionn Whitehead",
  "Fionnula Flanagan",
  "Fiore Argento",
  "Firat Ayverdi",
  "Firat Dogruloglu",
  "Firat Tanis",
  "Firouz Kiani",
  "Fisayo Akinade",
  "Fizuli Caferof",
  "Flavio Bucci",
  "Flavio Parenti",
  "Flora Robson",
  "Florence Clery",
  "Florence Garland",
  "Florence Lee",
  "Florence Pugh",
  "Florencia Colucci",
  "Florian Bartholomäi",
  "Florian David Fitz",
  "Florian Lukas",
  "Florian Stetter",
  "Florian Teichtmeister",
  "Floriane Daniel",
  "Florijan Ajdini",
  "Florinda Bolkan",
  "Flávia Gusmão",
  "Folco Lulli",
  "Folker Bohnet",
  "Ford Beebe Jr.",
  "Forest Whitaker",
  "Forouzan Jamshidnejad",
  "Forrest Tucker",
  "Fran Kranz",
  "Franc Ashman",
  "Franc Roddam",
  "Franca Marzi",
  "Franca Pasut",
  "France Rumilly",
  "Frances Conroy",
  "Frances McDormand",
  "Frances O'Connor",
  "Frances Sternhagen",
  "Frances de la Tour",
  "Francesc Colomer",
  "Francesc Garrido",
  "Francesc Orella",
  "Francesca Annis",
  "Francesca Bertini",
  "Francesca Neri",
  "Francesca Nunzi",
  "Francesca d'Aloja",
  "Francesco Anniballi",
  "Francesco Casale",
  "Francesco Golisano",
  "Francesco Pellegrino",
  "Francesco Russo",
  "Francesco Villano",
  "Francine Bergé",
  "Francine Racette",
  "Francis Capra",
  "Francis Huster",
  "Francis Renaud",
  "Francisco Barreiro",
  "Francisco Bertín",
  "Francisco Gattorno",
  "Francisco Pedrasa",
  "Francisco Rabal",
  "Francisco Reyes",
  "Franck Ekinci",
  "Franck Gastambide",
  "Franck Khalfoun",
  "Franck Vestiel",
  "Franco Branciaroli",
  "Franco Citti",
  "Franco Garofalo",
  "Franco Interlenghi",
  "Franco Merli",
  "Franco Nero",
  "Franco Volpi",
  "Frank Adu",
  "Frank Braña",
  "Frank C. Turner",
  "Frank Finlay",
  "Frank Giering",
  "Frank Glaubrecht",
  "Frank Grillo",
  "Frank Harper",
  "Frank Hvam",
  "Frank Langella",
  "Frank Lovejoy",
  "Frank Madigan",
  "Frank Moore",
  "Frank Morgan",
  "Frank Overton",
  "Frank Oz",
  "Frank Paur",
  "Frank Reicher",
  "Frank Todaro",
  "Frank Vincent",
  "Frank Vosper",
  "Frank Wolff",
  "Franka Potente",
  "Frankie Corio",
  "Frankie Muniz",
  "Frankie Pain",
  "Franklin Dyall",
  "Franky G",
  "Frantz Turner",
  "Franz Rogowski",
  "Franz-Otto Krüger",
  "Franziska Weisz",
  "François Arnaud",
  "François Berléand",
  "François Bégaudeau",
  "François Civil",
  "François Cluzet",
  "François Damiens",
  "François De Brigode",
  "François Hadji-Lazaro",
  "François Lafarge",
  "François Leterrier",
  "François Maisongrosse",
  "François Périer",
  "François Truffaut",
  "Françoise Berd",
  "Françoise Bertin",
  "Françoise Christophe",
  "Françoise Dorléac",
  "Françoise Fabian",
  "Françoise Lebrun",
  "Françoise Seigner",
  "Françoise Verley",
  "Fred Cavayé",
  "Fred Clark",
  "Fred Draper",
  "Fred MacMurray",
  "Fred Melamed",
  "Fred Schepisi",
  "Fred Thompson",
  "Fred Ulysse",
  "Fred Ward",
  "Fred Willard",
  "Fred Williamson",
  "Freddie Crowder",
  "Freddie Fletcher",
  "Freddie Highmore",
  "Freddie Jones",
  "Freddie Prinze Jr.",
  "Freddie Stroma",
  "Freddy Rodríguez",
  "Frederic Forrest",
  "Frederick Lau",
  "Frederick Stafford",
  "Frederikke Dahl Hansen",
  "Fredric March",
  "Fredrik Grøndahl",
  "Freida Pinto",
  "Friederike Becht",
  "Friedrich Feher",
  "Friedrich Mücke",
  "Fritz Karl",
  "Fritz Wepper",
  "Friğrik Şór Friğriksson",
  "Frunzik Mkrtchyan",
  "Frédéric Andréi",
  "Frédéric Auburtin",
  "Frédéric Diefenthal",
  "Frédéric Franchitti",
  "Frédéric Gorny",
  "Frédéric Pfluger",
  "Frédéric Pierrot",
  "Frédérique Feder",
  "Frédérique Meininger",
  "Fulton Mackay",
  "Fulya Zenginer",
  "Fumi Hirano",
  "Fumi Nikaidô",
  "Fumiko Orikasa",
  "Fumio Watanabe",
  "Funda Eryigit",
  "Fyodor Dunayevsky",
  "Fábio Assunção",
  "Félix Lefebvre",
  "Félix Moati",
  "Félix Rotaeta",
  "Féodor Atkine",
  "Füsun Demirel",
  "G.K. Bowes",
  "G.W. Bailey",
  "Gabino Diego",
  "Gabriel Almirón",
  "Gabriel Bateman",
  "Gabriel Byrne",
  "Gabriel Cosmin Urdes",
  "Gabriel D'Almeida Freitas",
  "Gabriel Gabrio",
  "Gabriel Garko",
  "Gabriel LaBelle",
  "Gabriel Macht",
  "Gabriel Mann",
  "Gabriel Rush",
  "Gabriel Weinstein",
  "Gabriela Izcovich",
  "Gabriela Lopez",
  "Gabriela Maria Schmeide",
  "Gabriele Ferzetti",
  "Gabriele Lavia",
  "Gabriele Tinti",
  "Gabrielle Anwar",
  "Gabrielle Haugh",
  "Gabrielle Rose",
  "Gabrielle Sanz",
  "Gabrielle Union",
  "Gaby Hoffmann",
  "Gad Elmaleh",
  "Gael Garcia Bernal",
  "Gael García Bernal",
  "Gage Munroe",
  "Gaia Weiss",
  "Gail Patrick",
  "Gajraj Rao",
  "Gal Gadot",
  "Galatea Ranzi",
  "Galina Tyunina",
  "Gang Dong-won",
  "Gang Wu",
  "Ganjirô Nakamura",
  "Gar Moore",
  "Garance Le Guillermic",
  "Garance Marillier",
  "Garazi Urkola",
  "Garfield Wilson",
  "Garret Dillahunt",
  "Garrett Hedlund",
  "Garrett M. Brown",
  "Gary Bakewell",
  "Gary Busey",
  "Gary Cockrell",
  "Gary Cole",
  "Gary Cooper",
  "Gary Goldman",
  "Gary Grubbs",
  "Gary Lewis",
  "Gary Lockwood",
  "Gary Maitland",
  "Gary Oldman",
  "Gary Raymond",
  "Gary Sinise",
  "Gary Stretch",
  "Gaspard Manesse",
  "Gaspard Schlatter",
  "Gaspard Ulliel",
  "Gaston Modot",
  "Gastone Moschin",
  "Gastón Cocchiarale",
  "Gastón Duprat",
  "Gastón Pauls",
  "Gattlin Griffith",
  "Gayatri Joshi",
  "Gayle Rankin",
  "Gaylen Ross",
  "Gaël Morel",
  "Ge Hu",
  "Geena Davis",
  "Geert Van Rampelberg",
  "Geir Vegar Hoel",
  "Gelareh Abbassi",
  "Gemma Arterton",
  "Gemma Chan",
  "Gemma Jones",
  "Gena Rowlands",
  "Gene Bervoets",
  "Gene Hackman",
  "Gene Kelly",
  "Gene Lockhart",
  "Gene Tierney",
  "Gene Wilder",
  "Genesis Rodriguez",
  "Genevieve O'Reilly",
  "Geneviève Bujold",
  "Geneviève Fontanel",
  "Geneviève Grad",
  "Geneviève Mnich",
  "Geneviève Page",
  "Geoffrey Arend",
  "Geoffrey Lewis",
  "Geoffrey Rush",
  "Geordy Couturiau",
  "Georg Friedrich",
  "George Ackles",
  "George Babluani",
  "George Buza",
  "George C. Scott",
  "George Carlin",
  "George Carroll",
  "George Chakiris",
  "George Clooney",
  "George Cukor",
  "George Eastman",
  "George Gaynes",
  "George Harrison",
  "George Hilton",
  "George Kennedy",
  "George L. Lewis",
  "George Lazenby",
  "George Lopez",
  "George MacKay",
  "George Macready",
  "George Maryan",
  "George O'Brien",
  "George Raft",
  "George Sanders",
  "George Segal",
  "Georges Adet",
  "Georges Corraface",
  "Georges Du Fresne",
  "Georges Géret",
  "Georges Poujouly",
  "Georges Siatidis",
  "Georgia Groome",
  "Georgie Henley",
  "Georgina Reilly",
  "Georgiy Kropachyov",
  "Ger Duany",
  "Geraint Wyn Davies",
  "Gerald R. Molen",
  "Geraldine Chaplin",
  "Geraldine James",
  "Geraldine Page",
  "Geraldine Viswanathan",
  "Gerard Butler",
  "Gerard Hendrick",
  "Gerardo Giacinti",
  "Gerardo Taracena",
  "Gerd Oswald",
  "Gerdy Zint",
  "Gergely Trócsányi",
  "Gerhard Liebmann",
  "Geri Horner",
  "Germaine Delbat",
  "Germán Palacios",
  "Gerry Becker",
  "Gerry Robert Byrne",
  "Gert Fröbe",
  "Gert Raudsep",
  "Gertrud Arnold",
  "Gertrude Welcker",
  "Gethin Anthony",
  "Ghassan Massoud",
  "Ghilherme Lobo",
  "Ghita Bhebhita",
  "Ghita Nørby",
  "Gholam-Hossein Lotfi",
  "Ghorban Ali Naderi",
  "Gi-woong Park",
  "Giacomo Ferrara",
  "Giacomo Poretti",
  "Gian Maria Volontè",
  "Giancarlo Commare",
  "Giancarlo Giannini",
  "Giancarlo Martini",
  "Giancarlo Prete",
  "Gianfelice Imparato",
  "Gianluigi Chirizzi",
  "Gianmarco Saurino",
  "Gianni Garko",
  "Gianni Santuccio",
  "Gianni Vastarella",
  "Gideon Shemer",
  "Gig Young",
  "Gigi Coursigny",
  "Gil Bellows",
  "Gil Birmingham",
  "Gil Darnell",
  "Gilbert Melki",
  "Gilbert Sicotte",
  "Gilbert Wahiakeron",
  "Gilberto Moura",
  "Gilles Cohen",
  "Gilles Lellouche",
  "Gilles Margaritis",
  "Gillian Anderson",
  "Gina Bernard Forbes",
  "Gina Carano",
  "Gina Gershon",
  "Gina La Piana",
  "Gina Lollobrigida",
  "Gina Mastronicola",
  "Gina McKee",
  "Gina Moreno",
  "Gina Rodriguez",
  "Gina Torres",
  "Ginette Leclerc",
  "Ginette Reno",
  "Ginevra Antona",
  "Ginevra Francesconi",
  "Ginnifer Goodwin",
  "Giorgi Nakashidze",
  "Giorgia Moll",
  "Giorgio Albertazzi",
  "Giorgio Cantarini",
  "Giorgio Careccia",
  "Giorgio Cataldi",
  "Giorgos Foundas",
  "Giovani Florido",
  "Giovanna Bozzolo",
  "Giovanna Mezzogiorno",
  "Giovanni Frezza",
  "Giovanni Lombardo Radice",
  "Giovanni Ribisi",
  "Giovanni Storti",
  "Gisela Hertwig",
  "Gisela Uhlen",
  "Giselda Castrini",
  "Gisella Anastasia",
  "Gisèle Casadesus",
  "Gisèle Echevarría",
  "Giulia Lazzarini",
  "Giuliano Gemma",
  "Giulietta Masina",
  "Giulietta Rebeggiani",
  "Giulio Bosetti",
  "Giulio Fochetti",
  "Giulio Parengkuan",
  "Giulio Pranno",
  "Giuseppe Aiello",
  "Giuseppe Battiston",
  "Giuseppe Cederna",
  "Giuseppe Sanfelice",
  "Giuseppe Sulfaro",
  "Giustino Durano",
  "Gladys George",
  "Glen Berry",
  "Glen Cavender",
  "Glen Hansard",
  "Glen Keane",
  "Glenda Jackson",
  "Glenn Close",
  "Glenn Ford",
  "Glikeriya Bogdanova-Chesnokova",
  "Gloria Cristal",
  "Gloria Grahame",
  "Gloria Guida",
  "Gloria Reuben",
  "Gloria Stuart",
  "Gloria Swanson",
  "Glynis Davies",
  "Glynis Johns",
  "Go Kyung-Pyo",
  "Go Min-Si",
  "Go Soo",
  "Gode Benedix",
  "Godehard Giese",
  "Godfrey Tearle",
  "Gohar Kheirandish",
  "Gohki Kashiyama",
  "Goibibi Ziadolahyeva",
  "Golab Adineh",
  "Golshifteh Farahani",
  "Gong Li",
  "Gong Yoo",
  "Gonzalo Cubero",
  "Gonzalo Suárez",
  "Gonzalo Uriarte",
  "Gonzalo Vega",
  "Gonzalo Vega Jr.",
  "Goran Visnjic",
  "Gordana Vukres",
  "Gordon Alexander",
  "Gordon Pinsent",
  "Gore Vidal",
  "Gorô Kishitani",
  "Gorô Naya",
  "Gosha Kutsenko",
  "Gottfried John",
  "Gou Maeda",
  "Gouri Kishan",
  "Gowan Didi",
  "Gowtham Selvaraj",
  "Goya Toledo",
  "Goyo Jiménez",
  "Goûchy Boy",
  "Grace Caroline Currey",
  "Grace Delrue",
  "Grace Gummer",
  "Grace Jones",
  "Grace Kelly",
  "Grace Lynn Kung",
  "Graciela Borges",
  "Graciela Tenenbaum",
  "Gracy Singh",
  "Graham Beckel",
  "Graham Bushnell",
  "Graham Chapman",
  "Graham Greene",
  "Graham McTavish",
  "Graham Norton",
  "Graham Verchere",
  "Grand Corps Malade",
  "Gray Powell",
  "Graziella Galvani",
  "Grazyna Szapolowska",
  "Greg Ayres",
  "Greg Cruttwell",
  "Greg Grunberg",
  "Greg Kinnear",
  "Greg Manwaring",
  "Greg Tiernan",
  "Gregor Bloéb",
  "Gregory Cox",
  "Gregory Dayton",
  "Gregory Hines",
  "Gregory Mann",
  "Gregory Peck",
  "Gregory Smith",
  "Greta Fernández",
  "Greta Garbo",
  "Greta Gerwig",
  "Greta Scacchi",
  "Greta Schröder",
  "Gretchen Mol",
  "Greyson Erik Pendry",
  "Griffin Dunne",
  "Griffin O'Neal",
  "Grigoriy Aleksandrov",
  "Grigoriy Dobrygin",
  "Griselda Siciliani",
  "Groucho Marx",
  "Grégoire Colin",
  "Grégoire Leprince-Ringuet",
  "Guangjie Li",
  "Guanlin Ji",
  "Guglielmo Aru",
  "Guglielmo Barnabò",
  "Guglielmo Pinelli",
  "Gugu Mbatha-Raw",
  "Guilied Lopez",
  "Guillaume Bouchède",
  "Guillaume Briat",
  "Guillaume Canet",
  "Guillaume Depardieu",
  "Guillaume Gallienne",
  "Guillaume Laurin",
  "Guillaume de Tonquédec",
  "Guillermo Barrientos",
  "Guillermo Diaz",
  "Guillermo Estrella",
  "Guillermo Francella",
  "Guillermo Garcia",
  "Guillermo Lasheras",
  "Guillermo Pfening",
  "Guillermo Toledo",
  "Gunnar Björnstrand",
  "Gunnel Lindblom",
  "Guoqiang Tang",
  "Gurinder Chadha",
  "Gus Van Sant",
  "Gustaf Hammarsten",
  "Gustaf Skarsgård",
  "Gustav De Waele",
  "Gustav Fischer Kjærulff",
  "Gustav Fröhlich",
  "Gustav Knuth",
  "Gustav Lindh",
  "Gustav von Wangenheim",
  "Gustavo Alonso",
  "Gustavo Egelhaaf",
  "Gustavo Muñoz",
  "Gustaw Holoubek",
  "Guy Decomble",
  "Guy Grosso",
  "Guy Jenkin",
  "Guy Pearce",
  "Guy Stockwell",
  "Guylaine Schlumberger",
  "Guğrún Gísladóttir",
  "Gwei Lun-Mei",
  "Gwen Eckhaus",
  "Gwenaëlle Simon",
  "Gwi-hwa Choi",
  "Gwilym Lee",
  "Gwyneth Paltrow",
  "Gyu-Woon Jung",
  "Gyula Bodrogi",
  "Gábor Czap",
  "Géraldine Nakache",
  "Géraldine Pailhas",
  "Gérard Blain",
  "Gérard Darmon",
  "Gérard Depardieu",
  "Gérard Jugnot",
  "Gérard Lanvin",
  "Géza Röhrig",
  "Gô Katô",
  "Gökçe Bahadir",
  "Görkem Kasal",
  "Götz George",
  "Götz Otto",
  "Gülayse Erkoc",
  "Günes Sensoy",
  "Günter Meisner",
  "Gürkan Uygun",
  "Gürsoy Gemec",
  "Güven Kiraç",
  "Güzin Özipek",
  "Ha Ji-Won",
  "Ha Jung-woo",
  "Ha-ryong Lim",
  "Ha-seon Park",
  "Habib Boufares",
  "Habib Rezaei",
  "Hadi Hejazifar",
  "Hadi Ka-Koush",
  "Hadi Saeedi",
  "Hadis Foladvand",
  "Hae-Jin Yoo",
  "Hae-Kyun Jung",
  "Hae-hyo Kwon",
  "Hafsia Herzi",
  "Hai-Qing",
  "Hailee Steinfeld",
  "Haing S. Ngor",
  "Hakan Boyav",
  "Hakan Karsak",
  "Hakan Ural",
  "Hakeem Kae-Kazim",
  "Hakim Faris",
  "Haldun Boysan",
  "Hale Soygazi",
  "Haley Bennett",
  "Haley Bishop",
  "Haley Joel Osment",
  "Halil Ergün",
  "Halina Gryglaszewska",
  "Halina Kowalska",
  "Halina Reijn",
  "Halit Akçatepe",
  "Halle Berry",
  "Haluk Bilginer",
  "Halvar Björk",
  "Hamed Behdad",
  "Hamid Aghazi",
  "Hamid Djavadan",
  "Hamid Farrokhnezhad",
  "Hamid Reza Azarang",
  "Hamidreza Saeedikhah",
  "Hamilton Luske",
  "Han Gi-Joong",
  "Han Hyo-joo",
  "Han Suk-kyu",
  "Hana Sugisaki",
  "Hanaa Bouchaib",
  "Hang-Seon Jang",
  "Hanieh Tavassoli",
  "Hank Azaria",
  "Hanna Schygulla",
  "Hannah Al Rashid",
  "Hannah Emily Anderson",
  "Hannah Herzsprung",
  "Hannah John-Kamen",
  "Hannah Taylor Gordon",
  "Hannah Ware",
  "Hanne Aagesen",
  "Hannelore Elsner",
  "Hannelore Klauber-Laursen",
  "Hanno Koffler",
  "Hanno Pöschl",
  "Hanns Zischler",
  "Hans Carl Mueller",
  "Hans Escher",
  "Hans Hirschmüller",
  "Hans Löw",
  "Hans Meyer",
  "Hans Noiman",
  "Hans Olav Brenner",
  "Hans Sternberg",
  "Hans-Jochen Wagner",
  "Hans-Peter Korff",
  "Hansjörg Felmy",
  "Hanssel Casillas",
  "Hanyu Zhang",
  "Hao Chen",
  "Hao Ou",
  "Hao Zheng",
  "Hape Kerkeling",
  "Hara Kotsali",
  "Harald Lundt",
  "Hardy Krüger",
  "Hari Nef",
  "Harland Williams",
  "Harley Bird",
  "Harley Cross",
  "Harold Bergman",
  "Harold Perrineau",
  "Harold Ramis",
  "Harold Whitaker",
  "Haroun Tazieff",
  "Harpo Marx",
  "Harriet Andersson",
  "Harriet Owen",
  "Harriet Walter",
  "Harris Dickinson",
  "Harrison Ford",
  "Harry Andrews",
  "Harry Connick Jr.",
  "Harry Crocker",
  "Harry Dean Stanton",
  "Harry Guardino",
  "Harry Hadden-Paton",
  "Harry Lennix",
  "Harry Myers",
  "Harry Shum Jr.",
  "Harry Simpson",
  "Harry Stockwell",
  "Harry Treadaway",
  "Harry Van Gorkum",
  "Harshaali Malhotra",
  "Hart Bochner",
  "Hartley Power",
  "Haru Kuroki",
  "Haruhiko Katô",
  "Haruka Tomatsu",
  "Haruko Sugimura",
  "Harumi Shuhama",
  "Haruo Tanaka",
  "Harvey Atkin",
  "Harvey Guillén",
  "Harvey Keitel",
  "Harvey Korman",
  "Harvey Stephens",
  "Hasan Ali Mete",
  "Hasbiye Günay",
  "Hassan Darabi",
  "Hassan Mutlag Al-Maraiyeh",
  "Hassan Nebhan",
  "Hassan Pourshirazi",
  "Hassan Sadeghi",
  "Hassan Tantai",
  "Hassina Burgan",
  "Hatice Aslan",
  "Hatsuo Yamaya",
  "Hattie Morahan",
  "Havva Saglam",
  "Haya Harareet",
  "Hayat Van Eck",
  "Hayato Ichihara",
  "Hayden Christensen",
  "Hayden Panettiere",
  "Haydée Politoff",
  "Hayley Atwell",
  "Hayley McElhinney",
  "Hayley Squires",
  "Hazar Ergüçlü",
  "Heath Ledger",
  "Heather Craney",
  "Heather Graham",
  "Heather Matarazzo",
  "Heather-Jay Jones",
  "Hector Elizondo",
  "Hector Pappaert",
  "Hediyeh Tehrani",
  "Hee Jae",
  "Hee-jin Choi",
  "Hee-kyung Jin",
  "Hee-soon Park",
  "Heidi von Palleske",
  "Heike Makatsch",
  "Heiner Lauterbach",
  "Heino Ferch",
  "Heinz Moog",
  "Heinz Rühmann",
  "Helen Hunt",
  "Helen McCrory",
  "Helen Mirren",
  "Helen Slater",
  "Helena Albergaria",
  "Helena Bielicic",
  "Helena Bonham Carter",
  "Helena Miquel",
  "Helena Rojo",
  "Helena Zengel",
  "Helga Liné",
  "Helge Schneider",
  "Helmut Berger",
  "Helmut Döring",
  "Helmut Griem",
  "Helmut Qualtinger",
  "Hemendra Dandotiya",
  "Hendrik Toompere Sr.",
  "Hengameh Ghaziani",
  "Henning Moritzen",
  "Henri Attal",
  "Henri Garcin",
  "Henri Gruel",
  "Henri Génès",
  "Henri Labussière",
  "Henri Pagnoncelli",
  "Henri Serre",
  "Henri Storck",
  "Henri-Jacques Huet",
  "Henrik Dahl",
  "Henrik Lundström",
  "Henrik Malberg",
  "Henrik Rafaelsen",
  "Henry Beckman",
  "Henry Bergman",
  "Henry Cavill",
  "Henry Czerny",
  "Henry Fonda",
  "Henry Golding",
  "Henry Goodman",
  "Henry Rollins",
  "Henry Thomas",
  "Henry Travers",
  "Heo Sung-tae",
  "Herb Caillouet",
  "Herb Edelman",
  "Herbert Grönemeyer",
  "Herbert Knaup",
  "Herbert Lom",
  "Herbert Marshall",
  "Herbert Nordrum",
  "Herjunot Ali",
  "Hernán Mendoza",
  "Hertel Minkner",
  "Hervé Sogne",
  "Hideaki Anno",
  "Hideaki Itô",
  "Hideaki Nitani",
  "Hidenobu Kiuchi",
  "Hideo Nakaizumi",
  "Hideo Sakaki",
  "Hidetaka Yoshioka",
  "Hidetoshi Nishijima",
  "Hie-bong Jo",
  "Hiei Kimura",
  "Hikari Mitsushima",
  "Hilary Duff",
  "Hilary Mason",
  "Hilary Swank",
  "Hilda Hashempour",
  "Hill Harper",
  "Hille Beseler",
  "Hilmar Thate",
  "Hilmi Sözer",
  "Himesh Patel",
  "Hinako Saeki",
  "Hindi Zahra",
  "Hinnerk Schönemann",
  "Hippolyte Girardot",
  "Hiram Keller",
  "Hiro Kanagawa",
  "Hiro Shimono",
  "Hiroaki Hirata",
  "Hiroaki Iwanaga",
  "Hiroaki Kawatsure",
  "Hiroki Hasegawa",
  "Hiroko Berghauer",
  "Hiroko Itô",
  "Hiroko Ôshima",
  "Hiromi Tsuru",
  "Hiroshi Abe",
  "Hiroshi Hamasaki",
  "Hiroshi Koizumi",
  "Hiroshi Mikami",
  "Hirotaka Suzuoki",
  "Hiroyuki Kawase",
  "Hiroyuki Miyasako",
  "Hiroyuki Okiura",
  "Hiroyuki Sanada",
  "Hisako Kyôda",
  "Hisashi Igawa",
  "Hisashi Yoshizawa",
  "Hitomi Kuroki",
  "Hitomi Satô",
  "Hitoshi Takagi",
  "Ho-jeong Yu",
  "Ho-jin Chun",
  "Ho-jung Kim",
  "Hocine Rifahi",
  "Holger Löwenadler",
  "Holland Roden",
  "Holliday Grainger",
  "Holly Goss",
  "Holly Hunter",
  "Holly Valance",
  "Holmes Osborne",
  "Holt McCallany",
  "Homa Rusta",
  "Homayoun Ershadi",
  "Hong Chen",
  "Hong-Chi Lee",
  "Hongbo Jiang",
  "Honglei Sun",
  "Honor Blackman",
  "Honor Kneafsey",
  "Honoré Bostel",
  "Hootan Shakiba",
  "Hope Davis",
  "Horacio Salinas",
  "Horst Buchholz",
  "Hosein Yari",
  "Hossain Emadeddin",
  "Hossain Sabzian",
  "Hossein Abedini",
  "Hossein Farzi-Zad",
  "Hossein Mahjoub",
  "Hossein Moharami",
  "Hossein Rahmani Manesh",
  "Hossein Rezai",
  "Houman Seyyedi",
  "Howard Da Silva",
  "Howard Ross",
  "Howie Seago",
  "Hrant Alianak",
  "Hringur Ingvarsson",
  "Hrithik Roshan",
  "Hubert Deschamps",
  "Hubert Koundé",
  "Hubertus Bengsch",
  "Hudhail Al-Amir",
  "Hugh Bonneville",
  "Hugh Dancy",
  "Hugh Dillon",
  "Hugh Edwards",
  "Hugh Grant",
  "Hugh Griffith",
  "Hugh Jackman",
  "Hugh Laurie",
  "Hugh Marlowe",
  "Hugh Quarshie",
  "Hugh Walsh",
  "Hugh Welchman",
  "Hugo Arana",
  "Hugo Arbues",
  "Hugo Silva",
  "Hugo Stiglitz",
  "Hugo Weaving",
  "Hui-Lou Chen",
  "Huike Zhang",
  "Huiwen Zhang",
  "Huma Qureshi",
  "Humberto Carrão",
  "Humphrey Bogart",
  "Husnija Hasimovic",
  "Hussein Salameh Al-Sweilhiyeen",
  "Huub Stapel",
  "Hwan Lee",
  "Hwang Jung-min",
  "Hyang-gi Kim",
  "Hye-Sook Go",
  "Hye-ja Kim",
  "Hye-jin Han",
  "Hye-jin Jeon",
  "Hyein Park",
  "Hyeon-soo Kim",
  "Hyeong-jin Kong",
  "Hyo-hee Dong",
  "Hyuk-ho Kwon",
  "Hyun Bin",
  "Hyun-Sung Jang",
  "Hyun-Woo Lee",
  "Hyun-Wook Lee",
  "Hyun-min Kwon",
  "Hyunri",
  "Héctor Alterio",
  "Héctor Colomé",
  "Hélène Duc",
  "Hélène Florent",
  "Hélène Vincent",
  "Hélène Zimmer",
  "Héléna Manson",
  "Hülya Tuglu",
  "Hümeyra",
  "Hüseyin Avni Danyal",
  "Iain Glen",
  "Iain Morris",
  "Iain Rea",
  "Ian Anderson",
  "Ian Bannen",
  "Ian Charleson",
  "Ian Hart",
  "Ian Hendry",
  "Ian Holm",
  "Ian Kelly",
  "Ian McCulloch",
  "Ian McKellen",
  "Ian McNeice",
  "Ian McShane",
  "Ian Mongrain",
  "Ian Nelms",
  "Ian Pirie",
  "Ian Richardson",
  "Ian Somerhalder",
  "Iben Hjejle",
  "Ibrahim Ahmed",
  "Ibrahim Büyükak",
  "Ibrahima Gueye",
  "Ice Cube",
  "Ice-T",
  "Ichirô Nagai",
  "Ichirô Sugai",
  "Icíar Bollaín",
  "Ida Engvoll",
  "Ida Lupino",
  "Idil Firat",
  "Idil Üner",
  "Idris Elba",
  "Iemasa Kayumi",
  "Ieroklis Michaelidis",
  "Iga Cembrzynska",
  "Igal Naor",
  "Ignacio Huang",
  "Ignacio López Tarso",
  "Ignat Daniltsev",
  "Igor Chernevich",
  "Igor Fomchenko",
  "Igor Shirokov",
  "Ihsan Yüce",
  "Ike Ogut",
  "Ikki Sawamura",
  "Iko Uwais",
  "Ikranagara",
  "Ikue Ôtani",
  "Ilanah Cami-Goursolas",
  "Ildikó Bánsági",
  "Ileana D'Cruz",
  "Ileana Simova",
  "Ilenia Pastorelli",
  "Iliza Shlesinger",
  "Ilkay Saran",
  "Ilker Kizmaz",
  "Ilkka Koivula",
  "Ilona Béres",
  "Ilse Salas",
  "Ilyas Salman",
  "Im Yoon-ah",
  "Imanol Arias",
  "Imelda Staunton",
  "Imogen Kogge",
  "Imogen Poots",
  "In-Hyeong Gang",
  "In-hwan Park",
  "In-mun Kim",
  "Ina Claire",
  "India Eisley",
  "Indraneil Sengupta",
  "Inga Jankauskaite",
  "Inge Landgut",
  "Inge Maux",
  "Ingeborga Dapkunaite",
  "Ingetraud Hinze",
  "Ingrid Bergman",
  "Ingrid Caven",
  "Ingrid Rubio",
  "Ingrid Stassner",
  "Ingrid Thulin",
  "Ingvar Sigurdsson",
  "Inma Alcántara",
  "Inma Cuesta",
  "Inna Churikova",
  "Innokentiy Smoktunovskiy",
  "Inés Sastre",
  "Ioan Gruffudd",
  "Iola Evans",
  "Iona Morris",
  "Iosif Diego Pirvu",
  "Iqbaal Dhiafakhri Ramadhan",
  "Iran Outari",
  "Irandhir Santos",
  "Irem Sak",
  "Irena Orska",
  "Irene Azuela",
  "Irene Cara",
  "Irene Dunne",
  "Irene Ferreiro",
  "Irene Miracle",
  "Irene Papas",
  "Irina Björklund",
  "Irina Demick",
  "Irina Gorbacheva",
  "Irina Ivkina",
  "Irina Muravyova",
  "Irina Potapenko",
  "Irina Saltykova",
  "Irina Starshenbaum",
  "Irineo Alvarez",
  "Iris Böhm",
  "Irm Hermann",
  "Irrfan Khan",
  "Irving Metzman",
  "Irène Jacob",
  "Isa Danieli",
  "Isaac Hayes",
  "Isaach De Bankolé",
  "Isabel Coixet",
  "Isabel Lucas",
  "Isabel Tellería",
  "Isabela Merced",
  "Isabella Ferrari",
  "Isabella Laughland",
  "Isabella O'Sullivan",
  "Isabella Ragonese",
  "Isabella Rossellini",
  "Isabella Soupart",
  "Isabelle Adjani",
  "Isabelle Candelier",
  "Isabelle Carré",
  "Isabelle Corey",
  "Isabelle De Funès",
  "Isabelle Fuhrman",
  "Isabelle Giami",
  "Isabelle Huppert",
  "Isabelle Nanty",
  "Isabelle Nélisse",
  "Isabelle Renauld",
  "Isabelle Spade",
  "Isaiah Michalski",
  "Isaiah Mustafa",
  "Isaiah Washington",
  "Isela Vega",
  "Ishirô Honda",
  "Isild Le Besco",
  "Isla Fisher",
  "Ismael Fritschi",
  "Ismael Martínez",
  "Ismail Hacioglu",
  "Isolda Dychauk",
  "Israel Broussard",
  "Israel Elejalde",
  "Israel Matseke-Zulu",
  "Issa Perica",
  "Issei Miyazaki",
  "Issey Takahashi",
  "István Göz",
  "Isuzu Yamada",
  "Itay Tiran",
  "Itir Esen",
  "Itsuji Itao",
  "Ivan Batarev",
  "Ivan Bobrov",
  "Ivan Desny",
  "Ivan Dobronravov",
  "Ivan Kokorin",
  "Ivan Lapikov",
  "Ivan Massagué",
  "Ivan Petrushinov",
  "Ivan Rassimov",
  "Ivana Baquero",
  "Ivana Milicevic",
  "Ivica Vidovic",
  "Ivo Garrani",
  "Iván Chavero",
  "Iván Marcos",
  "Iwa K.",
  "Iwar Wiklander",
  "Iwona Bielska",
  "Izaac Wang",
  "Izabela Vidovic",
  "Izabella Scorupco",
  "Izidor Serban",
  "Izudin Bajrovic",
  "Izumi Sawada",
  "Izzy Diaz",
  "Izzy Meikle-Small",
  "Izán Corchero",
  "Izïa Higelin",
  "J. Mackye Gruber",
  "J. Trevor Edmond",
  "J.A. Preston",
  "J.D. Cannon",
  "J.J. Johnson",
  "J.K. Simmons",
  "J.R. Yenque",
  "J.T. Walsh",
  "JB Blanc",
  "JJ Feild",
  "JR Bourne",
  "Jacek Braciak",
  "Jacek Koman",
  "Jacek Lomnicki",
  "Jacek Rozenek",
  "Jacinda Barrett",
  "Jacir Eid Al-Hwietat",
  "Jack Albertson",
  "Jack Benny",
  "Jack Black",
  "Jack Carpenter",
  "Jack Carson",
  "Jack Davenport",
  "Jack Doolan",
  "Jack Dylan Grazer",
  "Jack Elam",
  "Jack Fox",
  "Jack Gilford",
  "Jack Hawkins",
  "Jack Hedley",
  "Jack Huston",
  "Jack Kehoe",
  "Jack Lemmon",
  "Jack Lowden",
  "Jack MacGowran",
  "Jack Madigan",
  "Jack Magner",
  "Jack McBrayer",
  "Jack McElhone",
  "Jack Nicholson",
  "Jack Nielen",
  "Jack O'Connell",
  "Jack Oakie",
  "Jack Palance",
  "Jack Reynor",
  "Jack Stokes",
  "Jack Taylor",
  "Jack Thompson",
  "Jack Warden",
  "Jack Warner",
  "Jack Whitehall",
  "Jacki Weaver",
  "Jackie Burroughs",
  "Jackie Chan",
  "Jackie Coogan",
  "Jackie Cooper",
  "Jackie Earle Haley",
  "Jackie Gleason",
  "Jackson Bond",
  "Jackson Lou",
  "Jackson Robert Scott",
  "Jackson Yee",
  "Jacky Ido",
  "Jacob Batalon",
  "Jacob Elordi",
  "Jacob Lohmann",
  "Jacob Matschenz",
  "Jacob Tremblay",
  "Jacob Vargas",
  "Jacobo Sánchez",
  "Jacopo Olmo Antinori",
  "Jacqueline Andere",
  "Jacqueline Bisset",
  "Jacqueline Laurent",
  "Jacqueline McKenzie",
  "Jacqueline Poelvoorde-Pappaert",
  "Jacques Balutin",
  "Jacques Doniol-Valcroze",
  "Jacques Dynam",
  "Jacques Frantz",
  "Jacques François",
  "Jacques Gamblin",
  "Jacques Jouanneau",
  "Jacques Morel",
  "Jacques Nolot",
  "Jacques Perrin",
  "Jacques Tati",
  "Jacques Villeret",
  "Jacques Weber",
  "Jada Pinkett Smith",
  "Jaden Smith",
  "Jae Head",
  "Jae-Hyun Cho",
  "Jae-ho Song",
  "Jae-hong Ahn",
  "Jae-mo Ahn",
  "Jae-un Lee",
  "Jae-yeong Jeong",
  "Jae-yong Lee",
  "Jaeden Martell",
  "Jafar Panahi",
  "Jag Sanghera",
  "Jagoda Kaloper",
  "Jahi Di'Allo Winston",
  "Jai Courtney",
  "Jaime King",
  "Jaime Pressly",
  "Jaime Winstone",
  "Jaime de Hoyos",
  "Jake Green",
  "Jake Gyllenhaal",
  "Jake Hoffman",
  "Jake Johnson",
  "Jake Muxworthy",
  "Jake T. Austin",
  "Jakob Cedergren",
  "Jakob Davies",
  "Jakob Oftebro",
  "Jakob Salvati",
  "Jakub Gierszal",
  "Jamani",
  "Jamel Debbouze",
  "James Allen McCune",
  "James Arnold Taylor",
  "James Aubrey",
  "James Badge Dale",
  "James Bloor",
  "James Booth",
  "James Brolin",
  "James Buckley",
  "James Caan",
  "James Cagney",
  "James Coburn",
  "James Corden",
  "James Cosmo",
  "James Cromwell",
  "James D'Arcy",
  "James Dean",
  "James Doohan",
  "James Earl Jones",
  "James Farentino",
  "James Fleet",
  "James Fox",
  "James Franco",
  "James Gandolfini",
  "James Garner",
  "James Gilbert",
  "James Gleason",
  "James Larkin",
  "James Laurenson",
  "James MacArthur",
  "James Marsden",
  "James Marsters",
  "James Mason",
  "James McArdle",
  "James McAvoy",
  "James Nesbitt",
  "James Norton",
  "James Olson",
  "James Paxton",
  "James Purefoy",
  "James Quinn Markey",
  "James Ransone",
  "James Rebhorn",
  "James Remar",
  "James Robertson Justice",
  "James Russell",
  "James Sikking",
  "James Spader",
  "James Stewart",
  "James Thierrée",
  "James Tien",
  "James Whitmore",
  "James Wilby",
  "James Woods",
  "Jamie Bell",
  "Jamie Campbell Bower",
  "Jamie Chung",
  "Jamie Dornan",
  "Jamie Draven",
  "Jamie Foxx",
  "Jamie Lee Curtis",
  "Jamileh Sheikhi",
  "Jamshid Hashempur",
  "Jamshid Mashayekhi",
  "Jan Bijvoet",
  "Jan Cornet",
  "Jan Decorte",
  "Jan Josef Liefers",
  "Jan Kounen",
  "Jan Lindner",
  "Jan Machulski",
  "Jan Munroe",
  "Jan Nowicki",
  "Jan Pinkava",
  "Jan Sterling",
  "Jan Tesarz",
  "Jan-Gregor Kremp",
  "Jana Pallaske",
  "Jane Adams",
  "Jane Alexander",
  "Jane Birkin",
  "Jane Darwell",
  "Jane Fonda",
  "Jane Galloway Heitz",
  "Jane Gilchrist",
  "Jane Greer",
  "Jane Lynch",
  "Jane March",
  "Jane Marken",
  "Jane Patterson",
  "Jane Seymour",
  "Jane Wyman",
  "Janeane Garofalo",
  "Janek Rieke",
  "Janel Moloney",
  "Janelle Monáe",
  "Janet Agren",
  "Janet Gaynor",
  "Janet Gunn",
  "Janet Leigh",
  "Janet McTeer",
  "Janet Suzman",
  "Jang Dong-Gun",
  "Jang Hyuk",
  "Jang Hyuk-jin",
  "Jang Young-Nam",
  "Janina Traczykówna",
  "Janine Darcey",
  "Janne Hyytiäinen",
  "Jannik Schümann",
  "Jannis Niewöhner",
  "January Jones",
  "Januel Mercado",
  "Janusz Gajos",
  "Janusz Rewinski",
  "Japtej Singh",
  "Jared Bush",
  "Jared Gilman",
  "Jared Harris",
  "Jared Leto",
  "Jarinpattra Rueangram",
  "Jarl Kulle",
  "Jarno Berardi",
  "Jaroslav Dusek",
  "Jasmin Gerat",
  "Jasmina Ali",
  "Jasmine Trinca",
  "Jasna Djuricic",
  "Jasna Fritzi Bauer",
  "Jason Barry",
  "Jason Bateman",
  "Jason Beghe",
  "Jason Behr",
  "Jason Biggs",
  "Jason Clarke",
  "Jason Cope",
  "Jason Derulo",
  "Jason Dohring",
  "Jason Drucker",
  "Jason Flemyng",
  "Jason Isaacs",
  "Jason James Richter",
  "Jason Lee",
  "Jason Marsden",
  "Jason Mitchell",
  "Jason Momoa",
  "Jason Patric",
  "Jason Robards",
  "Jason Schwartzman",
  "Jason Scott Lee",
  "Jason Segel",
  "Jason Statham",
  "Jason Sudeikis",
  "Jasper Newell",
  "Jasper Pääkkönen",
  "Javad Ezati",
  "Javier Abad",
  "Javier Bardem",
  "Javier Beltrán",
  "Javier Berger",
  "Javier Cámara",
  "Javier Drolas",
  "Javier Gutiérrez",
  "Javier Lombardo",
  "Javier Mariscal",
  "Javier Pereira",
  "Javier Rey",
  "Javier Ruiz Caldera",
  "Javier Ríos",
  "Javiera Bravo",
  "Javon 'Wanna' Walton",
  "Jay Baker",
  "Jay Baruchel",
  "Jay C. Flippen",
  "Jay Chandrasekhar",
  "Jay Chou",
  "Jay Duplass",
  "Jay Hernandez",
  "Jaya Bachchan",
  "Jaycee Chan",
  "Jaye Davidson",
  "Jaylin Webb",
  "Jayma Mays",
  "Jayne Atkinson",
  "Je-Heon Choi",
  "Je-mun Yun",
  "Jean Arthur",
  "Jean Benguigui",
  "Jean Carmet",
  "Jean Carson",
  "Jean Dasté",
  "Jean Debucourt",
  "Jean Desailly",
  "Jean Dujardin",
  "Jean Gabin",
  "Jean Gascon",
  "Jean Gaven",
  "Jean Hagen",
  "Jean Heywood",
  "Jean Keraudy",
  "Jean Lefebvre",
  "Jean Marais",
  "Jean Marsh",
  "Jean Martin",
  "Jean Peters",
  "Jean Pierre Noher",
  "Jean Poiret",
  "Jean Pélégri",
  "Jean Reno",
  "Jean Riveyre",
  "Jean Rochefort",
  "Jean Seberg",
  "Jean Servais",
  "Jean Simmons",
  "Jean Smart",
  "Jean Sorel",
  "Jean Topart",
  "Jean Yanne",
  "Jean d'Ormesson",
  "Jean-Baptiste Maunier",
  "Jean-Baptiste Tabourin",
  "Jean-Claude Brialy",
  "Jean-Claude Carrière",
  "Jean-Claude Donda",
  "Jean-Claude Dreyfus",
  "Jean-Claude Drouot",
  "Jean-Claude Guilbert",
  "Jean-Claude Van Damme",
  "Jean-François Stévenin",
  "Jean-Hugues Anglade",
  "Jean-Louis Barrault",
  "Jean-Louis Trintignant",
  "Jean-Luc Bideau",
  "Jean-Luc Couchard",
  "Jean-Luc Godard",
  "Jean-Marc Barr",
  "Jean-Marie Lamour",
  "Jean-Michel Balthazar",
  "Jean-Nicolas Verreault",
  "Jean-Paul Belmondo",
  "Jean-Paul Roussillon",
  "Jean-Paul Rouve",
  "Jean-Philippe Vine",
  "Jean-Philippe Écoffey",
  "Jean-Pierre Bacri",
  "Jean-Pierre Cargol",
  "Jean-Pierre Cassel",
  "Jean-Pierre Darroussin",
  "Jean-Pierre Jeunet",
  "Jean-Pierre Kalfon",
  "Jean-Pierre Lorit",
  "Jean-Pierre Léaud",
  "Jean-Pierre Marielle",
  "Jean-Pierre Martins",
  "Jean-Pierre Rambal",
  "Jean-Pierre Zola",
  "Jean-Yves Berteloot",
  "Jeanne Balibar",
  "Jeanne Bates",
  "Jeanne Disson",
  "Jeanne Moreau",
  "Jeanne Tripplehorn",
  "Jee-Young Kim",
  "Jeff Anderson",
  "Jeff Banks",
  "Jeff Bennett",
  "Jeff Bridges",
  "Jeff Cohen",
  "Jeff Daniel Phillips",
  "Jeff Daniels",
  "Jeff Fahey",
  "Jeff Garlin",
  "Jeff Geddis",
  "Jeff Goldblum",
  "Jeff J.J. Authors",
  "Jeff Kahn",
  "Jeff Newitt",
  "Jeff Roop",
  "Jeff Rowe",
  "Jeffery Rifflard",
  "Jeffrey Bowyer-Chapman",
  "Jeffrey Dean Morgan",
  "Jeffrey Donovan",
  "Jeffrey Ho",
  "Jeffrey Hunter",
  "Jeffrey Jones",
  "Jeffrey Men",
  "Jeffrey Pierce",
  "Jeffrey Tambor",
  "Jeffrey Thomas",
  "Jeffrey Wright",
  "Jehnny Beth",
  "Jella Haase",
  "Jemaine Clement",
  "Jemima Rooper",
  "Jemima West",
  "Jemma Moore",
  "Jena Malone",
  "Jeni Ross",
  "Jenjira Pongpas",
  "Jenna B. Kelly",
  "Jenna Dewan",
  "Jenna Ortega",
  "Jennica Fulton",
  "Jennifer Aniston",
  "Jennifer Carpenter",
  "Jennifer Connelly",
  "Jennifer Coolidge",
  "Jennifer Decker",
  "Jennifer Drake",
  "Jennifer Dundas",
  "Jennifer Ehle",
  "Jennifer Garner",
  "Jennifer Hudson",
  "Jennifer Jason Leigh",
  "Jennifer Lawrence",
  "Jennifer Lien",
  "Jennifer Lopez",
  "Jennifer Love Hewitt",
  "Jennifer Morrison",
  "Jennifer O'Neill",
  "Jennifer Rubin",
  "Jennifer Saunders",
  "Jennifer Shirley",
  "Jennifer Tilly",
  "Jennifer Ulrich",
  "Jennifer Yuh Nelson",
  "Jenny Agutter",
  "Jenny Laird",
  "Jenny Slate",
  "Jenny Wright",
  "Jens Albinus",
  "Jens Hultén",
  "Jensen Ackles",
  "Jensen Daggett",
  "Jeon Bae-soo",
  "Jeon Do-yeon",
  "Jeon Gook-hwan",
  "Jeon Jong-seo",
  "Jeon Yeo-bin",
  "Jeong Min Park",
  "Jeong-myeong Cheon",
  "Jeong-nam Choi",
  "Jeong-soon Park",
  "Jeremias Herskovits",
  "Jeremy Davies",
  "Jeremy Irons",
  "Jeremy Irvine",
  "Jeremy Kemp",
  "Jeremy McWilliams",
  "Jeremy Northam",
  "Jeremy Piven",
  "Jeremy Renner",
  "Jeremy Sheffield",
  "Jeremy Sisto",
  "Jeremy Strong",
  "Jeremy Sumpter",
  "Jeremy Swift",
  "Jeremy Sylvers",
  "Jeremy T. Thomas",
  "Jeremy Theobald",
  "Jericca Cleland",
  "Jermaine Hopkins",
  "Jero García",
  "Jeroen Krabbé",
  "Jerome Flynn",
  "Jerome Kurnia",
  "Jerry Holland",
  "Jerry Lewis",
  "Jerry Nelson",
  "Jerry O'Connell",
  "Jerry Stiller",
  "Jerry Zucker",
  "Jerzy Gudejko",
  "Jerzy Kosinski",
  "Jerzy Radziwilowicz",
  "Jerzy Skolimowski",
  "Jerzy Stuhr",
  "Jerzy Trela",
  "Jerónimo Barón",
  "Jesper Asholt",
  "Jesper Christensen",
  "Jesper Møller",
  "Jesse Borrego",
  "Jesse Bradford",
  "Jesse Corti",
  "Jesse Eisenberg",
  "Jesse McCartney",
  "Jesse Metcalfe",
  "Jesse Moss",
  "Jesse Plemons",
  "Jessica Alba",
  "Jessica Barden",
  "Jessica Biel",
  "Jessica Boone",
  "Jessica Brown Findlay",
  "Jessica Capshaw",
  "Jessica Chastain",
  "Jessica Cressy",
  "Jessica De Gouw",
  "Jessica Dinnage",
  "Jessica Harper",
  "Jessica Hynes",
  "Jessica Lange",
  "Jessica Lindsey",
  "Jessica Lowndes",
  "Jessica Mila",
  "Jessica Paré",
  "Jessica Schwarz",
  "Jessica Simpson",
  "Jessica Stroup",
  "Jessica Tandy",
  "Jessie Buckley",
  "Jessie Robins",
  "Jessie Royce Landis",
  "Jessika Cardinahl",
  "Jessika Williams",
  "Jesús Bonilla",
  "Jesús Caba",
  "Jesús Castro",
  "Jesús Meza",
  "Jesús Ochoa",
  "Jet Li",
  "Jeté Laurence",
  "Jhon Narváez",
  "Ji Chang-Wook",
  "Ji Liu",
  "Ji-Eun Lim",
  "Ji-Seok Seo",
  "Ji-Yeon Park",
  "Ji-eun Lee",
  "Ji-hee Jin",
  "Ji-hwan Park",
  "Ji-min Kwak",
  "Ji-ru Sung",
  "Ji-yeon Choi",
  "Jiang Du",
  "Jiannis Moustos",
  "Jianxin Huang",
  "Jiji Bû",
  "Jill Ireland",
  "Jill Schoelen",
  "Jill St. John",
  "Jill Wagner",
  "Jillian Bell",
  "Jim Backus",
  "Jim Belushi",
  "Jim Breuer",
  "Jim Broadbent",
  "Jim Carrey",
  "Jim Carter",
  "Jim Caviezel",
  "Jim Cummings",
  "Jim Dunk",
  "Jim Farley",
  "Jim Handley",
  "Jim Henson",
  "Jim Hutton",
  "Jim Kelly",
  "Jim Malone",
  "Jim Norton",
  "Jim Parsons",
  "Jim Rash",
  "Jim Sturgess",
  "Jim Varney",
  "Jim Ward",
  "Jimmi Simpson",
  "Jimmy Reddington",
  "Jimmy Shergill",
  "Jimmy Smits",
  "Jimmy T. Murakami",
  "Jimmy Wong",
  "Jin Goo",
  "Jin Seon-kyu",
  "Jin Zhang",
  "Jin-mo Joo",
  "Jin-young Jung",
  "Jing Dong Liang",
  "Jing Wu",
  "Jingchun Wang",
  "Jingjing Qu",
  "Jingwu Ma",
  "Jinpachi Nezu",
  "Jisca Kalvanda",
  "Jitsuko Yoshimura",
  "Jo Hartley",
  "Jo Jae-yoon",
  "Jo Jung-Suk",
  "Jo Marr",
  "Jo Seung-yeon",
  "Jo Van Fleet",
  "JoJo",
  "Joachim Fuchsberger",
  "Joachim Król",
  "Joakim Nätterqvist",
  "Joan Allen",
  "Joan Chen",
  "Joan Crawford",
  "Joan Cusack",
  "Joan Fontaine",
  "Joan Greenwood",
  "Joan Plowright",
  "Joana Preiss",
  "Joanna Cassidy",
  "Joanna Kulig",
  "Joanna Lumley",
  "Joanne Dru",
  "Joanne Froggatt",
  "Joaquim de Almeida",
  "Joaquin Phoenix",
  "Joaquín Cosio",
  "Jocelyn Brando",
  "Jocelyn DeBoer",
  "Jocelyn Quivrin",
  "Jochen Nickel",
  "Jodelle Ferland",
  "Jodie Comer",
  "Jodie Foster",
  "Jodie Turner-Smith",
  "Jodie Whittaker",
  "Joe Alwyn",
  "Joe Anderson",
  "Joe Belcher",
  "Joe Breen",
  "Joe Bugner",
  "Joe Cole",
  "Joe Don Baker",
  "Joe Flaherty",
  "Joe Flynn",
  "Joe Fria",
  "Joe Johnston",
  "Joe Keaton",
  "Joe Ksander",
  "Joe Odagiri",
  "Joe Pantoliano",
  "Joe Pesci",
  "Joe Romersa",
  "Joe Russo",
  "Joe Siffleet",
  "Joe Silver",
  "Joe Swanberg",
  "Joe Taslim",
  "Joe Thomas",
  "Joe Viterelli",
  "Joel Basman",
  "Joel Coen",
  "Joel Courtney",
  "Joel David Moore",
  "Joel Edgerton",
  "Joel Fry",
  "Joel Grey",
  "Joel Jackson",
  "Joel Kinnaman",
  "Joel McCrea",
  "Joely Richardson",
  "Joey King",
  "Joey Travolta",
  "JoeyStarr",
  "Joffrey Platel",
  "Johan Gotthardt Olsen",
  "Johan Heldenbergh",
  "Johan Libéreau",
  "Johann von Bülow",
  "Johanna Gastdorf",
  "Johanna Wokalek",
  "Johanna ter Steege",
  "Johanne Louise Schmidt",
  "Johanne-Marie Tremblay",
  "Johannes Holopainen",
  "Johannes Krisch",
  "Johannes Silberschneider",
  "Johannes Zeiler",
  "John Abraham",
  "John Agar",
  "John Alderton",
  "John Altamura",
  "John Barrymore",
  "John Belushi",
  "John Billingsley",
  "John Boles",
  "John Boyega",
  "John Bradley",
  "John Bruno",
  "John Buckwalter",
  "John C. McGinley",
  "John C. Reilly",
  "John Cameron Mitchell",
  "John Candy",
  "John Carradine",
  "John Carroll Lynch",
  "John Cassavetes",
  "John Cassisi",
  "John Castle",
  "John Cazale",
  "John Cena",
  "John Cho",
  "John Cleese",
  "John Corbett",
  "John Cusack",
  "John D'Leo",
  "John Dall",
  "John David Washington",
  "John Doman",
  "John Fiedler",
  "John Forsythe",
  "John Fraser",
  "John Fujioka",
  "John Gallagher Jr.",
  "John Gavin",
  "John Getz",
  "John Gielgud",
  "John Goodman",
  "John Gumley-Mason",
  "John Halas",
  "John Hannah",
  "John Hawkes",
  "John Haycraft",
  "John Heard",
  "John Henshaw",
  "John Hillerman",
  "John Hodgman",
  "John Hollingworth",
  "John Hubley",
  "John Hurt",
  "John Kahrs",
  "John Krasinski",
  "John Larroquette",
  "John Leguizamo",
  "John Lennon",
  "John Lithgow",
  "John Lloyd Fillingham",
  "John Lone",
  "John Lurie",
  "John Lynch",
  "John Magaro",
  "John Mahoney",
  "John Malkovich",
  "John Matthews",
  "John McEnery",
  "John McGiver",
  "John Megna",
  "John Meillon",
  "John Michael Higgins",
  "John Mills",
  "John Musker",
  "John Neville",
  "John Nolan",
  "John Ortiz",
  "John Osborne",
  "John Paul Tremblay",
  "John Payne",
  "John Phillip Law",
  "John Pingayak",
  "John Polson",
  "John Pyper-Ferguson",
  "John Rafter Lee",
  "John Randolph",
  "John Ratzenberger",
  "John Rhys-Davies",
  "John Richardson",
  "John Ridgely",
  "John Ritter",
  "John Savage",
  "John Saxon",
  "John Sharian",
  "John Shea",
  "John Simm",
  "John Sparkes",
  "John Steiner",
  "John Stevenson",
  "John Thaw",
  "John Thomson",
  "John Travolta",
  "John Turturro",
  "John Vernon",
  "John Waters",
  "John Wayne",
  "John Williams",
  "John Witherspoon",
  "John Woo",
  "John Wray",
  "Johnny Amaro",
  "Johnny Depp",
  "Johnny Flynn",
  "Johnny Hallyday",
  "Johnny Huang",
  "Johnny Knoxville",
  "Johnny Sekka",
  "Johnny Vekris",
  "Johnny Yong Bosch",
  "Jolanta Umecka",
  "Jole Silvani",
  "Jon Adams",
  "Jon Bernthal",
  "Jon Bon Jovi",
  "Jon Daly",
  "Jon Favreau",
  "Jon Finch",
  "Jon Garaño",
  "Jon Hamm",
  "Jon Lovitz",
  "Jon Polito",
  "Jon Tenney",
  "Jon Voight",
  "Jonah Hauer-King",
  "Jonah Hill",
  "Jonas Chernick",
  "Jonas Dassler",
  "Jonas Jägermeyr",
  "Jonas Karlsson",
  "Jonas Nay",
  "Jonathan Banks",
  "Jonathan Bennett",
  "Jonathan Cherry",
  "Jonathan D. Mellor",
  "Jonathan Freeman",
  "Jonathan Haagensen",
  "Jonathan Jackson",
  "Jonathan Lambert",
  "Jonathan Pryce",
  "Jonathan Race",
  "Jonathan Rhys Meyers",
  "Jonathan Roumie",
  "Jonathan Sadowski",
  "Jonathan Safran Foer",
  "Jonathan Scott-Taylor",
  "Jonathan Taylor Thomas",
  "Jonathan Velasquez",
  "Jonathan Watton",
  "Jonathan Winters",
  "Jong-Sik Nam",
  "Jong-hak Baek",
  "Jong-ho Kim",
  "Jong-ryol Choi",
  "Jong-woo Park",
  "Jonny Brugh",
  "Jonny Lee Miller",
  "Jonny Weston",
  "Joo Won",
  "Joon Lee",
  "Joon-Young Lee",
  "Joon-ho Chung",
  "Joon-ho Huh",
  "Jordan Gavaris",
  "Jordan Nagai",
  "Jordan Routledge",
  "Jordan Todosey",
  "Jordana Brewster",
  "Jordana Largy",
  "Jordi Mollà",
  "Jorge Bolani",
  "Jorge Garcia",
  "Jorge Julião",
  "Jorge Lendeborg Jr.",
  "Jorge Molina",
  "Jorge Perugorría",
  "Jorge Rivero",
  "Jorge Sanz",
  "Jorge-Yamam Serrano",
  "Joris Jarsky",
  "Jose Coronado",
  "Jose Luis Garcia",
  "Jose Mari Goenaga",
  "Jose Rijo",
  "Josef Altin",
  "Josef Bierbichler",
  "Josef Hader",
  "Josefine Preuß",
  "Josep Maria Pou",
  "Joseph",
  "Joseph A. Carpenter",
  "Joseph Blatchley",
  "Joseph Calleia",
  "Joseph Cotten",
  "Joseph Fiennes",
  "Joseph Friend",
  "Joseph Gordon-Levitt",
  "Joseph Julian Soria",
  "Joseph Lee",
  "Joseph Lorenz",
  "Joseph Mawle",
  "Joseph Melito",
  "Joseph P. McGlynn",
  "Joseph Ruskin",
  "Joseph Schildkraut",
  "Joseph Sikora",
  "Joseph Wiseman",
  "Josette Day",
  "Josh Baker",
  "Josh Brener",
  "Josh Brolin",
  "Josh Charles",
  "Josh Cruddas",
  "Josh Dean",
  "Josh Duhamel",
  "Josh Gad",
  "Josh Hamilton",
  "Josh Hartnett",
  "Josh Holloway",
  "Josh Hutcherson",
  "Josh Lucas",
  "Josh O'Connor",
  "Josh Peck",
  "Joshua Jackson",
  "Joshua McIvor",
  "Joshua Peace",
  "Joshua Suherman",
  "Josiane Balasko",
  "Joss Ackland",
  "José Acosta",
  "José Baviera",
  "José Bódalo",
  "José Calvo",
  "José Canalejas",
  "José Elías Moreno",
  "José Ferrer",
  "José Gallardo",
  "José Garcia",
  "José Gras",
  "José Isbert",
  "José Legarreta",
  "José Lewgoy",
  "José Luis Aguirre 'Trotsky'",
  "José Luis Ferrer",
  "José Luis García-Pérez",
  "José Luis Gómez",
  "José Luis López Vázquez",
  "José Luis Moreno",
  "José Manuel Poga",
  "José María Yazpik",
  "José María de Tavira",
  "José Mota",
  "José Pedro Bujaruz",
  "José Sacristán",
  "José Thelman",
  "José de Luna",
  "José Ángel Egido",
  "Joséphine Japy",
  "Joséphine Lebas-Joly",
  "Joséphine Sanz",
  "Jovan Adepo",
  "Jovan Jovanovic",
  "Jovanka Vuckovic",
  "Joy Boushel",
  "Joy Bryant",
  "Joyce Carey",
  "Joyce Ilg",
  "Joyce Van Patten",
  "Jsu Garcia",
  "Ju Ji-Hoon",
  "Ju Jin-Mo",
  "Ju-bong Gi",
  "Juan Carlos Aduviri",
  "Juan Carlos Colombo",
  "Juan Carlos Tabío",
  "Juan Daniel García Treviño",
  "Juan Diego",
  "Juan Diego Botto",
  "Juan Díaz",
  "Juan Echanove",
  "Juan Eduardo Palacios",
  "Juan Ferrara",
  "Juan José Ballesta",
  "Juan López-Tagle",
  "Juan Margallo",
  "Juan Minujín",
  "Juan Pablo Gil",
  "Juan Pablo Raba",
  "Juan Ramón López",
  "Juan Viadas",
  "Juana Acosta",
  "Juanjo Artero",
  "Judah Lewis",
  "Judah Nelson",
  "Judd Hirsch",
  "Judd Nelson",
  "Jude Hill",
  "Jude Law",
  "Judi Dench",
  "Judith Anderson",
  "Judith Barsi",
  "Judith Godrèche",
  "Judith O'Dea",
  "Judith Roberts",
  "Judith Vittet",
  "Judy Davis",
  "Judy Geeson",
  "Judy Greer",
  "Judy Parfitt",
  "Jue Huang",
  "Juhan Ulfsak",
  "Jule Ronstedt",
  "Jules Berry",
  "Jules Dassin",
  "Julia Dietze",
  "Julia Greer",
  "Julia Hummer",
  "Julia Jentsch",
  "Julia Kijowska",
  "Julia Koschitz",
  "Julia Ormond",
  "Julia Roberts",
  "Julia Sarah Stone",
  "Julia Sawalha",
  "Julia Stiles",
  "Julia Stockler",
  "Julia Wieniawa-Narkiewicz",
  "Julian Arahanga",
  "Julian Dennison",
  "Julian Fellowes",
  "Julian Glover",
  "Julian Harris",
  "Julian McMahon",
  "Julian Richings",
  "Julian Sands",
  "Julian Weigend",
  "Julian West",
  "Juliane Köhler",
  "Julianna Guill",
  "Julianna Layne",
  "Julianne Moore",
  "Julianne Nicholson",
  "Julie Agnete Vang",
  "Julie Andrews",
  "Julie Benz",
  "Julie Christiansen",
  "Julie Christie",
  "Julie Condra",
  "Julie Delpy",
  "Julie Depardieu",
  "Julie Durand",
  "Julie Estelle",
  "Julie Gayet",
  "Julie Hagerty",
  "Julie Harris",
  "Julie Kavner",
  "Julie LeBreton",
  "Julie R. Ølgaard",
  "Julie Vollono",
  "Julie Walters",
  "Julie-Marie Parmentier",
  "Julien Arruti",
  "Julien Bertheau",
  "Julien Boisselier",
  "Julien Carette",
  "Julien Guiomar",
  "Julien Maury",
  "Julien Seri",
  "Julien Temple",
  "Juliet Berto",
  "Juliet Ellis",
  "Juliet Rylance",
  "Julieta Cardinali",
  "Julieta Serrano",
  "Juliette Binoche",
  "Juliette Lewis",
  "Juliette Mayniel",
  "Julio De Grazia",
  "Julio Diaz",
  "Julio Peña",
  "Julius Weckauf",
  "Julián Giraldo",
  "Julián López",
  "Jun Fukuda",
  "Jun Hamamura",
  "Jun Hu",
  "Jun Ji-hyun",
  "Jun Kunimura",
  "Jun Shison",
  "Jun Tatara",
  "Jun Tazaki",
  "Jun'ichi Okada",
  "Jun-Ho Lee",
  "Jun-yeong Jang",
  "June Diane Raphael",
  "June Squibb",
  "Jung Eun-chae",
  "Jung Hae-in",
  "Jung Suh",
  "Jung Woo-sung",
  "Jung Yu-mi",
  "Jung-min Hwang",
  "Junko Iwao",
  "Junko Takeuchi",
  "Juno Temple",
  "Junya Fukuda",
  "Junyi Zhang",
  "Juri Ueno",
  "Jussi Vatanen",
  "Justice Smith",
  "Justin Bartha",
  "Justin Braine",
  "Justin Chatwin",
  "Justin Doran",
  "Justin Fletcher",
  "Justin Henry",
  "Justin Long",
  "Justin Pierce",
  "Justin Theroux",
  "Justin Timberlake",
  "Justin Whalin",
  "Justina Humpf",
  "Justine Waddell",
  "Justus von Dohnányi",
  "Jyoti Malshe",
  "Jyoti Subhash",
  "Jérémie Renier",
  "Jérémie Segard",
  "Jérémy Kapone",
  "Jérôme Chappatte",
  "Jóhannes Haukur Jóhannesson",
  "Józef Pawlowski",
  "Jô Shishido",
  "Jörg Schüttauf",
  "Júlia Lemmertz",
  "Jûkichi Uno",
  "Jûrôta Kosugi",
  "Jürgen Prochnow",
  "Jürgen Tonkel",
  "Jürgen Vogel",
  "Jüri Järvet",
  "Ka-Tung Lam",
  "Kaaris",
  "Kacey Mottet Klein",
  "Kad Merad",
  "Kadeem Hardison",
  "Kadir Inanir",
  "Kadir Savun",
  "Kadir Çermik",
  "Kagiso Kuypers",
  "Kaho Nakamura",
  "Kahraman Kiral",
  "Kai Inowaki",
  "Kai-Peter Malina",
  "Kaiji Tang",
  "Kais Setti",
  "Kaitlyn Bernard",
  "Kaitlyn Chalmers-Rizzato",
  "Kaitlyn Dever",
  "Kajol",
  "Kal Penn",
  "Kal So-won",
  "Kaleb Horn",
  "Kalina Jedrusik",
  "Kalki Koechlin",
  "Kamal Haasan",
  "Kamand Amirsoleimani",
  "Kamatari Fujiwara",
  "Kambiz Dirbaz",
  "Kambuzia Partovi",
  "Kamil Larin",
  "Kamran Adl",
  "Kamran Dehghan",
  "Kamyar Sheisi",
  "Kan'an Kiani",
  "Kana Hanazawa",
  "Kanae Itô",
  "Kanata Hongô",
  "Kandido Uranga",
  "Kane Hodder",
  "Kang Ha-neul",
  "Kang Hye-jeong",
  "Kang Shin-il",
  "Kang-woo Kim",
  "Kangana Ranaut",
  "Kanu Bannerjee",
  "Kaoru Yachigusa",
  "Kappei Yamaguchi",
  "Kara Hayward",
  "Karan Soni",
  "Kareem Abdul-Jabbar",
  "Kareena Kapoor",
  "Karel Hermánek Jr.",
  "Karel Roden",
  "Karen Allen",
  "Karen Gillan",
  "Karen Lancaume",
  "Karen Martínez",
  "Karen Miyama",
  "Karen Quintero",
  "Karen Strassman",
  "Karen Taylor",
  "Kari Matchett",
  "Kari Sylwan",
  "Karidja Touré",
  "Karim Leklou",
  "Karin Dor",
  "Karin Konoval",
  "Karin Neuhäuser",
  "Karin Schubert",
  "Karin Springer",
  "Karin Viard",
  "Karin Well",
  "Karina",
  "Karina Arroyave",
  "Karina Smulders",
  "Karina Suwandhi",
  "Karina Testa",
  "Karine Vanasse",
  "Karine de Mirbeck",
  "Karisma Kapoor",
  "Karl Glusman",
  "Karl Hardman",
  "Karl Malden",
  "Karl Markovics",
  "Karl Theobald",
  "Karl Urban",
  "Karl Walter Sprungala",
  "Karl Zinny",
  "Karla Cecilia Alvarado",
  "Karla Souza",
  "Karlheinz Böhm",
  "Karlheinz Hackl",
  "Karoline Herfurth",
  "Karoline Schuch",
  "Karra Elejalde",
  "Karthi",
  "Karuna Bannerjee",
  "Karyn Kusama",
  "Kasi Lemmons",
  "Kasia Adamik",
  "Kasia Smutniak",
  "Kaspar Velberg",
  "Kasumi Arimura",
  "Kat Dennings",
  "Katarzyna Bujakiewicz",
  "Kate Ashfield",
  "Kate Beahan",
  "Kate Beckinsale",
  "Kate Bosworth",
  "Kate Dickie",
  "Kate Greenhouse",
  "Kate Hudson",
  "Kate Lynch",
  "Kate Magowan",
  "Kate Mara",
  "Kate Micucci",
  "Kate Nelligan",
  "Kate Reid",
  "Kate Winslet",
  "Kate del Castillo",
  "Katee Sackhoff",
  "Katharina Brauren",
  "Katharina Müller-Elmau",
  "Katharina Schöde",
  "Katharina Schüttler",
  "Katharina Thalbach",
  "Katharine Hepburn",
  "Katharine Houghton",
  "Katharine Isabelle",
  "Katharine Ross",
  "Katherine Heigl",
  "Katherine Helmond",
  "Katherine Jenkins",
  "Katherine Parkinson",
  "Katherine Waterston",
  "Katheryn Winnick",
  "Kathir",
  "Kathleen Byron",
  "Kathleen Gati",
  "Kathleen Harrison",
  "Kathleen McDermott",
  "Kathleen Munroe",
  "Kathleen Quinlan",
  "Kathleen Turner",
  "Kathrin Resetarits",
  "Kathryn Hahn",
  "Kathryn McGuire",
  "Kathryn Mullen",
  "Kathryn Newton",
  "Kathryn Prescott",
  "Kathy Baker",
  "Kathy Bates",
  "Kati Outinen",
  "Katia Pascariu",
  "Katie Amanda Keane",
  "Katie Chang",
  "Katie Douglas",
  "Katie Holmes",
  "Katie Jarvis",
  "Katie Proctor",
  "Katie Stuart",
  "Katja Danowski",
  "Katja Flint",
  "Katja Riemann",
  "Katja Rupé",
  "Katrin Cartlidge",
  "Katrin Sass",
  "Katrin Schaake",
  "Katrina Bowden",
  "Katrina Kaif",
  "Katrine Greis-Rosenthal",
  "Katsuhiko Sasaki",
  "Katsuhiro Ôtomo",
  "Katsuichi Nakayama",
  "Katsumune Ishida",
  "Katsunosuke Hori",
  "Katsuyuki Shinohara",
  "Katy Barker",
  "Katy Jurado",
  "Katy Perry",
  "Kawsar Al Haddad",
  "Kay Francis",
  "Kay Kay Menon",
  "Kaya Akkaya",
  "Kaya Scodelario",
  "Kayo Matsuo",
  "Kayoko Kishimoto",
  "Kazem Mojdehi",
  "Kazuaki Nagaya",
  "Kazue Fukiishi",
  "Kazuhiko Inoue",
  "Kazuki Kitamura",
  "Kazuki Ômori",
  "Kazuko Yoshiyuki",
  "Kazunari Ninomiya",
  "Kazuya Aoyama",
  "Kazuya Nakai",
  "Kazuya Tsurumaki",
  "Ke Huy Quan",
  "Keagan Kang",
  "Keanu Reeves",
  "Keean Johnson",
  "Keegan Allen",
  "Keegan-Michael Key",
  "Keeley Hawes",
  "Keenan Tracey",
  "Keenan Wynn",
  "Keenen Ivory Wayans",
  "Kei Fujiwara",
  "Kei Horie",
  "Kei Kobayashi",
  "Kei Satô",
  "Keiji Sada",
  "Keiju Kobayashi",
  "Keiko Awaji",
  "Keiko Matsuzaka",
  "Keiko Tomita",
  "Keiko Tsushima",
  "Keiko Yokozawa",
  "Keir Dullea",
  "Keir O'Donnell",
  "Keira Knightley",
  "Keith Carradine",
  "Keith David",
  "Keke Palmer",
  "Kelan Guy",
  "Kellan Lutz",
  "Kellie Martin",
  "Kelly Adams",
  "Kelly Clarkson",
  "Kelly Hu",
  "Kelly Macdonald",
  "Kelly McGillis",
  "Kelly Preston",
  "Kelly Reilly",
  "Kelly Rowan",
  "Kelly Rowland",
  "Kelsey Asbille",
  "Kelsey Grammer",
  "Kelvin Harrison Jr.",
  "Kemal Sunal",
  "Kemp Powers",
  "Ken Campbell",
  "Ken Davitian",
  "Ken Duken",
  "Ken Foree",
  "Ken Kaneko",
  "Ken Kirzinger",
  "Ken Leung",
  "Ken Marshall",
  "Ken Nakamoto",
  "Ken Ogata",
  "Ken Russell",
  "Ken Stott",
  "Ken Takakura",
  "Ken Tanaka",
  "Ken Watanabe",
  "Ken'ichi Hagiwara",
  "Ken'ichi Matsuyama",
  "Kenan Ece",
  "Kenan Imirzalioglu",
  "Kenan Thompson",
  "Kenan Çoban",
  "Kengo Kôra",
  "Kenji Haga",
  "Kenji Matsuda",
  "Kenji Misumi",
  "Kenji Mizuhashi",
  "Kenji Sawada",
  "Kenji Utsumi",
  "Kenjirô Ishiyama",
  "Kenjirô Ohmori",
  "Kenna Pickard",
  "Kennedi Clements",
  "Kenneth Branagh",
  "Kenneth Cranham",
  "Kenneth Hadley",
  "Kenneth More",
  "Kenneth Welsh",
  "Kenshô Ono",
  "Kenta Kiritani",
  "Kenta Miyake",
  "Kentarô Shimazu",
  "Kenya Sawada",
  "Kerem Bürsin",
  "Keri Russell",
  "Kerr Smith",
  "Kerry Bishé",
  "Kerry Butler",
  "Kerry Condon",
  "Kerry Fox",
  "Kerry Washington",
  "Kesheng Lei",
  "Kestutis Stasys Jakstas",
  "Kesun Loder",
  "Kevan Ohtsji",
  "Kevin Bacon",
  "Kevin Balmore",
  "Kevin Bernhardt",
  "Kevin Conroy",
  "Kevin Corrigan",
  "Kevin Costner",
  "Kevin Dillon",
  "Kevin Heffernan",
  "Kevin J. O'Connor",
  "Kevin James",
  "Kevin Janssens",
  "Kevin Jarre",
  "Kevin Kline",
  "Kevin Lee",
  "Kevin McCarthy",
  "Kevin McDonald",
  "Kevin McKidd",
  "Kevin Parent",
  "Kevin Pavlovic",
  "Kevin Peter Hall",
  "Kevin Pollak",
  "Kevin Spacey",
  "Kevin T. Collins",
  "Kevin Zegers",
  "Khalid Abdalla",
  "Khalid Maadour",
  "Khalil Kain",
  "Khazar Ma'soomi",
  "Khodabakhsh Defaei",
  "Ki Hong Lee",
  "Ki-Hong Woo",
  "Ki-joo Jin",
  "Ki-woo Lee",
  "Kiara Advani",
  "Kichiemon Nakamura",
  "Kid Cudi",
  "Kiddy Smile",
  "Kiefer Sutherland",
  "Kiera Allen",
  "Kieran Culkin",
  "Kieran Hodgson",
  "Kieran O'Brien",
  "Kiernan Shipka",
  "Kiersten Warren",
  "Kierston Wareing",
  "Kiichi Nakai",
  "Kiko Mizuhara",
  "Killian Coyle",
  "Kim Ah-jung",
  "Kim Basinger",
  "Kim Bo-Min",
  "Kim Bo-kyung",
  "Kim Bodnia",
  "Kim Byeong-Ok",
  "Kim Cattrall",
  "Kim Coates",
  "Kim Da-mi",
  "Kim Dickens",
  "Kim Eui-sung",
  "Kim Go-eun",
  "Kim Greist",
  "Kim Guk-Hee",
  "Kim Hae-sook",
  "Kim Hee-ra",
  "Kim Hee-won",
  "Kim Hong-pa",
  "Kim Hunter",
  "Kim Hye-su",
  "Kim Hye-yoon",
  "Kim Hyun-joo",
  "Kim Hyun-sook",
  "Kim In-kwon",
  "Kim Kap-su",
  "Kim Ki-duk",
  "Kim Mattheson",
  "Kim Min-hee",
  "Kim Myung-gon",
  "Kim Novak",
  "Kim Ok-bin",
  "Kim Roberts",
  "Kim Rossi Stuart",
  "Kim Sae-ron",
  "Kim Sang-kyung",
  "Kim Seo-hyeong",
  "Kim Seol",
  "Kim Soo-Kyung",
  "Kim Soo-hyun",
  "Kim Sung-ryung",
  "Kim Sungkyu",
  "Kim Tae-hoon",
  "Kim Tae-ri",
  "Kim Tae-woo",
  "Kim Uylenbroek",
  "Kim Yeo-jin",
  "Kim Yoo-jeong",
  "Kim Yoon-seok",
  "Kim Young-min",
  "Kim Yu-Mi",
  "Kimberley Nixon",
  "Kimberly Stringer",
  "Kimika Yoshino",
  "Kimiko Ikegami",
  "Kin Sugai",
  "Kin Wah Chew",
  "King Ampaw",
  "King Donovan",
  "Kinga Preis",
  "Kinji Fukasaku",
  "Kinuyo Tanaka",
  "Kip Pardue",
  "Kippei Shîna",
  "Kirill Zaytsev",
  "Kirin Kiki",
  "Kirk Acevedo",
  "Kirk Douglas",
  "Kirk Wise",
  "Kirron Kher",
  "Kirsten Andreasen",
  "Kirsten Dunst",
  "Kirsten Sheridan",
  "Kirsten Zien",
  "Kirti Kulhari",
  "Kishore Kumar G.",
  "Kishori Ballal",
  "Kit Harington",
  "Kitty Carlisle",
  "Kitty O'Beirne",
  "Kivanç Tatlitug",
  "Kiyohiko Shibukawa",
  "Kiyoshi Kobayashi",
  "Kkobbi Kim",
  "Klaus Büchner",
  "Klaus Kinski",
  "Klaus Löwitsch",
  "Klaus Maria Brandauer",
  "Klaus Mulbjerg",
  "Klaus Wennemann",
  "Klaus-Michael Grüber",
  "Kleber Mendonça Filho",
  "Knut Berger",
  "Ko Shibasaki",
  "Kodi Smit-McPhee",
  "Kogarashi Wakasugi",
  "Koichi Nakamura",
  "Koji Hashimoto",
  "Koki Uchiyama",
  "Kokinji Katsura",
  "Kokoro Kikuchi",
  "Kong Chu",
  "Kong Kuwata",
  "Konkona Sen Sharma",
  "Konstantin Khabenskiy",
  "Konstantin Kryukov",
  "Konstantin Lavronenko",
  "Koo Kyo-hwan",
  "Kostja Ullmann",
  "Kotarô Daigo",
  "Kote Tolordava",
  "Kotoe Hatsui",
  "Kotoha Hiroyama",
  "Kotone Furukawa",
  "Kotono Mitsuishi",
  "Kourosh Tahami",
  "Koyu Rankin",
  "Koyuki",
  "Kris Hitchen",
  "Kris Kristofferson",
  "Kris Lemche",
  "Kris Wu",
  "Kriss Dosanjh",
  "Kristanna Loken",
  "Kristen Bell",
  "Kristen Connolly",
  "Kristen Hager",
  "Kristen Schaal",
  "Kristen Stewart",
  "Kristen Wiig",
  "Krister Henriksson",
  "Kristin Booth",
  "Kristin Chenoweth",
  "Kristin Kreuk",
  "Kristin Scott Thomas",
  "Kristina Adolphson",
  "Kristina Pimenova",
  "Kristina Wayborn",
  "Kristjan Üksküla",
  "Kristoff Raczynski",
  "Kristına Nováková",
  "Krysten Ritter",
  "Krystia Mova",
  "Krystyna Janda",
  "Krzysztof Globisz",
  "Krzysztof Kowalewski",
  "Kseniya Rappoport",
  "Kudret Sabanci",
  "Kulle Westphal",
  "Kumail Nanjiani",
  "Kumiko Asô",
  "Kumiko Ôba",
  "Kumud Mishra",
  "Kun Chen",
  "Kunal Kemmu",
  "Kunihiko Mitamura",
  "Kunihiko Yuyama",
  "Kuniko Miyake",
  "Kuno Becker",
  "Kuo-Chu Chang",
  "Kurt Fuller",
  "Kurt Gerron",
  "Kurt Russell",
  "Kurtwood Smith",
  "Kurume Arisaka",
  "Kurôemon Onoe",
  "Kwak Do-won",
  "Kwang Soo Cha",
  "Kwon Sang-woo",
  "Kyara Uchida",
  "Kyla Kenedy",
  "Kyle Catlett",
  "Kyle Chandler",
  "Kyle Gallner",
  "Kyle MacLachlan",
  "Kyle Schmid",
  "Kylian Dheret",
  "Kylie Minogue",
  "Kyoko Hasegawa",
  "Kyra Sedgwick",
  "Kyung-hyun Min",
  "Kyung-jin Lee",
  "Kyôko Fukada",
  "Kyôko Kagawa",
  "Kyôko Kishida",
  "Kyôko Koizumi",
  "Kyôsuke Yabe",
  "Kátia Lund",
  "Kåre Hedebrant",
  "Kévin Mischel",
  "Kévin Razy",
  "Kíla Lord Cassidy",
  "Kôbun Shizuno",
  "Kôichi Yamadera",
  "Kôji Imada",
  "Kôji Mitsui",
  "Kôji Morimoto",
  "Kôji Nanbara",
  "Kôji Takahashi",
  "Kôji Tsuruta",
  "Kôji Yakusho",
  "Kôjiro Shimizu",
  "Kôki Maeda",
  "Kôsuke Toyohara",
  "LL Cool J",
  "LaKeith Stanfield",
  "Laara Sadiq",
  "Ladj Ly",
  "Lady Gaga",
  "Lady Rowlands",
  "Ladya Cheryl",
  "Laetitia Casta",
  "Laia Costa",
  "Laia Marull",
  "Laine Megaw",
  "Lainie Kazan",
  "Lake Bell",
  "Lale Mansur",
  "Lali Gonzalez",
  "Lambert Wilson",
  "Lamberto Maggiorani",
  "Lana Wood",
  "Lance Guest",
  "Lance Henriksen",
  "Lancelot Roch",
  "Landon Liboiron",
  "Lannick Gautry",
  "Lanshan Luo",
  "Lara Dutta",
  "Lara Pulver",
  "Lara Robinson",
  "Lari White",
  "Larry Gates",
  "Larry Hagman",
  "Larry Morey",
  "Lars Brygmann",
  "Lars Eidinger",
  "Lars Gmehling",
  "Lars Mikkelsen",
  "Lars Nordh",
  "Lars Passgård",
  "Lars Ranthe",
  "Lars Rudolph",
  "Lashana Lynch",
  "Lasse Fogelstrøm",
  "Laudya Cynthia Bella",
  "Laura Allen",
  "Laura Antonelli",
  "Laura Bailey",
  "Laura Betti",
  "Laura Boujenah",
  "Laura Brousseau",
  "Laura Castrillón",
  "Laura Cepeda",
  "Laura Dean",
  "Laura Dern",
  "Laura Drasbæk",
  "Laura Freeman",
  "Laura Galán",
  "Laura Gemser",
  "Laura Haddock",
  "Laura Harring",
  "Laura Harris",
  "Laura Johnson",
  "Laura Linney",
  "Laura Morante",
  "Laura Post",
  "Laura Ramsey",
  "Laura Regan",
  "Laura Smet",
  "Laura Trotter",
  "Laura Vasiliu",
  "Laure Calamy",
  "Lauren Bacall",
  "Lauren Birkell",
  "Lauren Cohan",
  "Lauren German",
  "Lauren Graham",
  "Lauren Lee Smith",
  "Lauren London",
  "Lauren Ridloff",
  "Lauren Tempany",
  "Laurence Arné",
  "Laurence Badie",
  "Laurence Fishburne",
  "Laurence Fox",
  "Laurence Leboeuf",
  "Laurence Naismith",
  "Laurence Olivier",
  "Laurence de Monaghan",
  "Laurent Grévill",
  "Laurent Lafitte",
  "Laurent Lucas",
  "Laurent Stocker",
  "Laurent Terzieff",
  "Laurie Holden",
  "Laurie Metcalf",
  "Lawrence A. Bonney",
  "Lawrence Bayne",
  "Lawrence Dane",
  "Lawrence Mortorff",
  "Layla Hakim",
  "Layla Walet Mohamed",
  "Laysla De Oliveira",
  "Lazar Ristovski",
  "Lazare Herson-Macarel",
  "Laïs Salameh",
  "Lea Massari",
  "Lea Thompson",
  "Lea van Acken",
  "Leah Lewis",
  "Leandra Leal",
  "Leandro Firmino",
  "Leandro Taub",
  "Leanne Best",
  "Lech Dyblik",
  "Lech Lotocki",
  "Lee Barnett",
  "Lee Bo-young",
  "Lee Byung-hun",
  "Lee Curreri",
  "Lee Da-wit",
  "Lee Dong-Hee",
  "Lee Dong-yong",
  "Lee El",
  "Lee Eol",
  "Lee Ga-Kyung",
  "Lee Grant",
  "Lee Hanee",
  "Lee Hee-joon",
  "Lee Ingleby",
  "Lee J. Cobb",
  "Lee Jehoon",
  "Lee Jin-Wook",
  "Lee Joon-Gi",
  "Lee Jung-Jin",
  "Lee Jung-hyun",
  "Lee Jung-jae",
  "Lee Marvin",
  "Lee Meriwether",
  "Lee Min-ki",
  "Lee Pace",
  "Lee Remick",
  "Lee Seung-Gyu",
  "Lee Sun-kyun",
  "Lee Sung-min",
  "Lee Unkrich",
  "Lee Van Cleef",
  "Lee Yeong-ae",
  "Lee Yoo-mi",
  "Leehom Wang",
  "Leelee Sobieski",
  "Leeon Jones",
  "Leif Sylvester",
  "Leigh Whannell",
  "Leila Hatami",
  "Leila Hyams",
  "Leili Rashidi",
  "Leland Orser",
  "Leland Palmer",
  "Lembit Ulfsak",
  "Len Cariou",
  "Lena Endre",
  "Lena Headey",
  "Lena Klenke",
  "Lena Nyman",
  "Lena Sabine Berg",
  "Lene Maria Christensen",
  "Leni Tanzer",
  "Lenn Kudrjawizki",
  "Lennie James",
  "Leo Dolan",
  "Leo Fitzpatrick",
  "Leo G. Carroll",
  "Leo Genn",
  "Leo McKern",
  "Leo Morimoto",
  "Leo Rossi",
  "Leon",
  "Leon Lai",
  "Leon Niemczyk",
  "Leonard Frey",
  "Leonard Nimoy",
  "Leonard Pietraszak",
  "Leonard Scheicher",
  "Leonard Whiting",
  "Leonardo Alonso",
  "Leonardo Cimino",
  "Leonardo DiCaprio",
  "Leonardo Guerra",
  "Leonardo Larocca",
  "Leonardo Lidi",
  "Leonardo Ortizgris",
  "Leonardo Sbaraglia",
  "Leonid Barats",
  "Leonid Gromov",
  "Leonid Kuravlyov",
  "Leonid Mozgovoy",
  "Leonid Yarmolnik",
  "Leonie Benesch",
  "Leonor Varela",
  "Leonor Watling",
  "Leonora Balcarce",
  "Leopold Stokowski",
  "Leopoldo Trieste",
  "Les Mabaleka",
  "Leslaw Zurek",
  "Lesley Ann Warren",
  "Lesley Hart",
  "Lesley Manville",
  "Lesley Nicol",
  "Lesley Sharp",
  "Lesley-Anne Down",
  "Leslie Ash",
  "Leslie Banks",
  "Leslie Bibb",
  "Leslie Cheung",
  "Leslie Hope",
  "Leslie Nielsen",
  "Leslie Odom Jr.",
  "Leticia Brédice",
  "Leticia Dolera",
  "Letizia Ciampa",
  "Lev Gorn",
  "Levan Gabriadze",
  "Levent Aras",
  "Levent Öktem",
  "Levente Molnár",
  "Levi Eisenblätter",
  "Levi Miller",
  "Lew Ayres",
  "Lewis Black",
  "Lewis MacDougall",
  "Lewis McAskie",
  "Lewis McGibbon",
  "Lewis Pullman",
  "Lex Barker",
  "Lex Cassar",
  "Lexa Doig",
  "Lexi Ainsworth",
  "Leyla Lydia Tugutlu",
  "León Orlandianyi",
  "Li Sun",
  "Li Zhang",
  "Lia McHugh",
  "Liam Aiken",
  "Liam Cunningham",
  "Liam Hemsworth",
  "Liam McMahon",
  "Liam Neeson",
  "Liana Liberato",
  "Liane Balaban",
  "Lianella Carell",
  "Libero De Rienzo",
  "Liberto Rabal",
  "Libuse Safránková",
  "Libya Pugh",
  "Licia Maglietta",
  "Lidia Alfonsi",
  "Lidiya Oleksandrivna Savka",
  "Liev Schreiber",
  "Liisa Koppel",
  "Lijo Mol Jose",
  "Lil Dagover",
  "Lil Rel Howery",
  "Lila Kedrova",
  "Lilay Huser",
  "Lilian Naef",
  "Lilien Batman",
  "Lilith Stangenberg",
  "Lilla Brignone",
  "Lillete Dubey",
  "Lilli Carati",
  "Lilli Palmer",
  "Lillian Gish",
  "Lillo Brancato",
  "Lilly Wachowski",
  "Lilo Baur",
  "Lilou Fogli",
  "Lily Atkinson",
  "Lily Cole",
  "Lily Collins",
  "Lily Franky",
  "Lily James",
  "Lily-Rose Depp",
  "Lim Soo-jung",
  "Limara Meneses",
  "Lin Shaye",
  "Lin-Manuel Miranda",
  "Lina Gennari",
  "Lina Leandersson",
  "Linda Bassett",
  "Linda Blair",
  "Linda Cardellini",
  "Linda Fiorentino",
  "Linda González",
  "Linda Hamilton",
  "Linda Hardy",
  "Linda Henry",
  "Linda Hunt",
  "Linda Kiani",
  "Linda Larkin",
  "Linda Manz",
  "Linda McGuire",
  "Linda Zilliacus",
  "Lindsay Crouse",
  "Lindsay Karamoh",
  "Lindsay Lohan",
  "Lindsay Sloane",
  "Line Kruse",
  "Linh-Dan Pham",
  "Linnea Quigley",
  "Lino Capolicchio",
  "Lino Ventura",
  "Linus Roache",
  "Lio Tipton",
  "Lionel Abelanski",
  "Lionel Barrymore",
  "Lionel Jeffries",
  "Lionnel Astier",
  "Lior Ashkenazi",
  "Lisa Berry",
  "Lisa Bonet",
  "Lisa Boyle",
  "Lisa Goodman",
  "Lisa Hagmeister",
  "Lisa Hannigan",
  "Lisa Harrow",
  "Lisa Haydon",
  "Lisa Houle",
  "Lisa Jane Persky",
  "Lisa Kreuzer",
  "Lisa Kudrow",
  "Lisa Loven Kongsli",
  "Lisa Ray",
  "Lisa Spoonauer",
  "Lisa Vicari",
  "Lisa Yang",
  "Lisanne Falk",
  "Lisbeth Movin",
  "Lise Lamétrie",
  "Lise Roy",
  "Liubomiras Laucevicius",
  "Liuchun Yang",
  "Liv Corfixen",
  "Liv Esmår Dannemann",
  "Liv Lisa Fries",
  "Liv Tyler",
  "Liv Ullmann",
  "Livia Renner",
  "Livio Badurina",
  "Liz Giles",
  "Liza Colón-Zayas",
  "Liza Minnelli",
  "Lizhen Ma",
  "Ljubica Adzovic",
  "Llorenç González",
  "Lloyd Avery II",
  "Lloyd Bridges",
  "Lloyd Kaufman",
  "Lloyd Owen",
  "Llum Barrera",
  "Lluís Homar",
  "Loan Chabanol",
  "Logan Creran",
  "Logan Hannan",
  "Logan Hawkes",
  "Logan Huffman",
  "Logan Lerman",
  "Logan Marshall-Green",
  "Lois Chiles",
  "Lois Hall",
  "Lola Cardona",
  "Lola Dueñas",
  "Lola Gaos",
  "Lola Le Lann",
  "Loles León",
  "Lolita Davidovich",
  "Lolita Sevilla",
  "Lone Fleming",
  "Loni Anderson",
  "Loredana Cannata",
  "Loredana Nusciak",
  "Loredana Solfizi",
  "Lorelei Linklater",
  "Lorella Cravotta",
  "Loren Dean",
  "Lorenzo Ausilia-Foret",
  "Lorenzo Ferro",
  "Lorenzo Richelmy",
  "Loreto Mauleón",
  "Loreto Peralta",
  "Loretta Devine",
  "Lori Martin",
  "Lori Petty",
  "Lorraine Bracco",
  "Lorraine De Selle",
  "Lorraine Gary",
  "Lorraine Nicholson",
  "Lorraine Pilkington",
  "Lorànt Deutsch",
  "Lothaire Bluteau",
  "Lothar Chamski",
  "Lotte Andersen",
  "Lotte Ledl",
  "Lotte Lenya",
  "Lotte Verbeek",
  "Lou Castel",
  "Lou Ferrigno",
  "Lou Romano",
  "Lou de Laâge",
  "Louane Emera",
  "Louis Calhern",
  "Louis Clichy",
  "Louis Ferreira",
  "Louis Garrel",
  "Louis Gossett Jr.",
  "Louis Hofmann",
  "Louis Jourdan",
  "Louis Klamroth",
  "Louis Koo",
  "Louis Lefebvre",
  "Louis Malle",
  "Louis Partridge",
  "Louis Prima",
  "Louis Tripp",
  "Louis Wolheim",
  "Louis de Funès",
  "Louis-Do de Lencquesaing",
  "Louisa Colpeyn",
  "Louise Bennett",
  "Louise Blachère",
  "Louise Bourgoin",
  "Louise Cardoso",
  "Louise Fletcher",
  "Louise Lemoine Torrès",
  "Louise Monot",
  "Louise Orry-Diquéro",
  "Louise Portal",
  "Louka Meliava",
  "Louka Minnella",
  "Lourdes de Oliveira",
  "Loveleen Tandan",
  "Loyd Bateman",
  "Lu Xuan",
  "Luana Giuliani",
  "Luana Piovani",
  "Lubna Azabal",
  "Luc Dardenne",
  "Luc Merenda",
  "Luc Roeg",
  "Luc Thuillier",
  "Luca Chikovani",
  "Luca Marinelli",
  "Lucas Aranda",
  "Lucas Black",
  "Lucas Ferraro",
  "Lucas Gregorowicz",
  "Lucas Hedges",
  "Lucas Oranmian",
  "Lucas Till",
  "Luchs von Kyffhäuserbach",
  "Luci Christian",
  "Luciana Falcón",
  "Luciana Paluzzi",
  "Luciano Catenacci",
  "Luciano Federico",
  "Lucie Aron",
  "Lucie Mannheim",
  "Lucie Zhang",
  "Lucien Frégis",
  "Lucille Ball",
  "Lucille La Verne",
  "Lucretia Love",
  "Lucy Boynton",
  "Lucy Cohu",
  "Lucy Davis",
  "Lucy Decoutere",
  "Lucy Fry",
  "Lucy Gordon",
  "Lucy Hale",
  "Lucy Liu",
  "Lucy Miller",
  "Lucy Russell",
  "Lucy Walters",
  "Lucía Pollán",
  "Ludacris",
  "Ludi Lin",
  "Ludivine Sagnier",
  "Ludovica Martino",
  "Ludovico Girardello",
  "Luenell",
  "Luigi Calagna",
  "Luigi Diberti",
  "Luigi Lo Cascio",
  "Luigi Pistilli",
  "Luing Andrews",
  "Luis Brandoni",
  "Luis Callejo",
  "Luis Fernando Peña",
  "Luis Gerardo Méndez",
  "Luis Gnecco",
  "Luis Guzmán",
  "Luis Hostalot",
  "Luis Luque",
  "Luis Rego",
  "Luis Tosar",
  "Luis Varela",
  "Luis Zahera",
  "Luisana Lopilato",
  "Luise Helm",
  "Luise Heyer",
  "Luiz Carlos Vasconcelos",
  "Lukas Haas",
  "Lukas Miko",
  "Lukas Schwarz",
  "Lukas Vergara",
  "Lukasz Simlat",
  "Luke Albright",
  "Luke Bracey",
  "Luke Evans",
  "Luke Kirby",
  "Luke Mably",
  "Luke Pitzrick",
  "Luke Treadaway",
  "Luke Wilson",
  "Lukerya Ilyashenko",
  "Lukman Sardi",
  "Lumi Cavazos",
  "Lun Ai",
  "Lupita Tovar",
  "Lupo Barbiero",
  "Luran Ahmeti",
  "Lutesha",
  "Luz Palazón",
  "Luàna Bajrami",
  "Lya Lys",
  "Lyda Salmonova",
  "Lydia Wilson",
  "Lynda Day George",
  "Lynda Gravatt",
  "Lynn",
  "Lynn Collins",
  "Lynn Ferguson",
  "Lynn Gilmartin",
  "Lynn Lowry",
  "Lynn Redgrave",
  "Lynn Xiong",
  "Lynn-Holly Johnson",
  "Lynne Perrie",
  "Lynsey Taylor Mackay",
  "Lysette Anthony",
  "Lyubov Agapova",
  "Lyubov Aksyonova",
  "Lyubov Dobrzhanskaya",
  "Lyudmila Gurchenko",
  "Lyudmila Polyakova",
  "Lyudmila Saveleva",
  "Lyudmila Tselikovskaya",
  "Lázaro Ramos",
  "Lærke Winther",
  "Léa Drucker",
  "Léa Garcia",
  "Léa Seydoux",
  "Léna Skerla",
  "Lúcia Romano",
  "M. Emmet Walsh",
  "M. Shirzad",
  "Ma Dong-seok",
  "Maarten Stevenson",
  "Maaya Sakamoto",
  "Mabel Cadena",
  "Mabel Rivera",
  "Macarena García",
  "Macarena Gómez",
  "Macaulay Culkin",
  "Macdonald Carey",
  "Macha Grenon",
  "Macha Méril",
  "Machiko Kyô",
  "Machiko Ono",
  "Maciej Musialowski",
  "Maciej Póltorak",
  "Maciej Stuhr",
  "Mack Swain",
  "Mackenyu",
  "Mackenzie Davis",
  "Mackenzie Foy",
  "Maddie Hasson",
  "Maddie Ziegler",
  "Madeleine Blaustein",
  "Madeleine Carroll",
  "Madeleine Robinson",
  "Madeleine Sami",
  "Madeleine Stowe",
  "Madeline Carroll",
  "Madeline Kahn",
  "Madhavan",
  "Madison Davenport",
  "Madison Ingoldsby",
  "Madison Wolfe",
  "Madjid Niroumand",
  "Madonna",
  "Mads Broe Andersen",
  "Mads Koudal",
  "Mads Mikkelsen",
  "Mae Clarke",
  "Maedeh Erteghaei",
  "Maeve Jinkings",
  "Mafalda Carbonell",
  "Magali Noël",
  "Magaly Solier",
  "Magda Schneider",
  "Magdalena Czerwinska",
  "Magdalena Lamparska",
  "Maggie Cheung",
  "Maggie Grace",
  "Maggie Gyllenhaal",
  "Maggie Q",
  "Maggie Smith",
  "Magnus Krepper",
  "Magnus Millang",
  "Mahdi Pakdel",
  "Mahershala Ali",
  "Mahesh Kanual",
  "Mahesh Manjrekar",
  "Mahie Gill",
  "Mahin Shahabi",
  "Mahir Ipek",
  "Mahiro Takasugi",
  "Mahito Tsujimura",
  "Mahmoud Azim",
  "Mahmud Azizi",
  "Mahnaz Afshar",
  "Mahshid Afsharzadeh",
  "Mahsun Kirmizigül",
  "Mahtab Keramati",
  "Mahtab Nasirpour",
  "Mahtab Servati",
  "Mai Hosho",
  "Maika Monroe",
  "Maiken Pius",
  "Maiko Kawakami",
  "Maimouna N'Diaye",
  "Maisie Williams",
  "Maja Ostaszewska",
  "Makar Tikhomirov",
  "Makayla Rose Hilli",
  "Makiko Esumi",
  "Makio Inoue",
  "Makis Papadimitriou",
  "Makita Samba",
  "Mako",
  "Makoto Ashikawa",
  "Makoto Nonomura",
  "Makram Khoury",
  "Maksim Abrosimov",
  "Maksim Konovalov",
  "Maksim Lagashkin",
  "Maksim Matveev",
  "Maksim Munzuk",
  "Mala Emde",
  "Malaika Wakoli-Abigaba",
  "Malcolm McDowell",
  "Malcolm Sinclair",
  "Malena Alterio",
  "Malena Lucia Lodahl",
  "Malena Villa",
  "Malgorzata Foremniak",
  "Malgorzata Kozuchowska",
  "Malgorzata Zabkowska",
  "Malik Bentalha",
  "Malik Diouf",
  "Malik Zidi",
  "Malin Akerman",
  "Malonn Lévana",
  "Mamaengaroa Kerr-Bell",
  "Mami Fujioka",
  "Mami Koyama",
  "Mamoru Miyano",
  "Man-Tat Ng",
  "Man-sik Jeong",
  "Manaka Iwami",
  "Manal Issa",
  "Mandeep Dhillon",
  "Mandy Moore",
  "Mandy Patinkin",
  "Manfred Zapatka",
  "Mani Haghighi",
  "Mania Akbari",
  "Manikandan K.",
  "Manjot Singh",
  "Manju Warrier",
  "Manny Perez",
  "Manoj Bajpayee",
  "Manoj Pahwa",
  "Manolo Cardona",
  "Manolo Fábregas",
  "Manolo Morán",
  "Manolo Solo",
  "Manon Lanclos",
  "Manu Tupou",
  "Manuel Bernal",
  "Manuel Gil",
  "Manuel Lozano",
  "Manuel Morón",
  "Manuel Rodal",
  "Manuel Tallafé",
  "Manuel Vignau",
  "Manuela Martelli",
  "Manuela Velasco",
  "Manuela Vellés",
  "Mara Krupp",
  "Marat Basharov",
  "Marc Anthony",
  "Marc Beaupré",
  "Marc Bischoff",
  "Marc Blucas",
  "Marc Diraison",
  "Marc Domènech",
  "Marc Duret",
  "Marc Hosemann",
  "Marc Lawrence",
  "Marc Michel",
  "Marc Porel",
  "Marc Silverstein",
  "Marc Strange",
  "Marc Swint",
  "Marc Warren",
  "Marc Wootton",
  "Marc Zinga",
  "Marc de Jonge",
  "Marc-André Boulanger",
  "Marc-André Grondin",
  "Marc-Marvin Israel",
  "Marcel Dalio",
  "Marcel Fraval",
  "Marcel Iures",
  "Marcella Rovena",
  "Marcelle Faure-Bertin",
  "Marcello Fonte",
  "Marcello Mastroianni",
  "Marcello Morante",
  "Marcello Pagliero",
  "Marcelo Alonso",
  "Marcia Gay Harden",
  "Marcin Dorocinski",
  "Marcin Kowalczyk",
  "Marco Antonio Aguirre",
  "Marco Boriero",
  "Marco Calamandrei",
  "Marco D'Amore",
  "Marco Giallini",
  "Marco Hofschneider",
  "Marco Leonardi",
  "Marco Manetti",
  "Marcos Hernández",
  "Marcos Martínez",
  "Marcos Ruiz",
  "Marcus Hutton",
  "Marcus Thomas",
  "Marcuz Jess Petersen",
  "Mare Winningham",
  "Marek Kondrat",
  "Maren Eggert",
  "Marg Helgenberger",
  "Marga López",
  "Margaret Avery",
  "Margaret Livingston",
  "Margaret Lockwood",
  "Margaret Markov",
  "Margaret Mazzantini",
  "Margaret Sullavan",
  "Margaret Wycherly",
  "Margarete Schön",
  "Margarete Tiesel",
  "Margaretha Krook",
  "Margarethe von Trotta",
  "Margarita Isabel",
  "Margarita Kenéfic",
  "Margarita Terekhova",
  "Margherita Buy",
  "Margherita Caruso",
  "Margie Newton",
  "Margit Carstensen",
  "Margo Stilley",
  "Margot Kidder",
  "Margot Robbie",
  "Maria Bakalova",
  "Maria Bello",
  "Maria Bonnevie",
  "Maria Bosco",
  "Maria Conchita Alonso",
  "Maria Doyle Kennedy",
  "Maria Ehrich",
  "Maria Falconetti",
  "Maria Fernanda Cândido",
  "Maria Grazia Cucinotta",
  "Maria Heiskanen",
  "Maria Hofstätter",
  "Maria Järvenhelmi",
  "Maria Menounos",
  "Maria Pakulnis",
  "Maria Pia Casilio",
  "Maria Pitillo",
  "Maria Rodríguez Soto",
  "Maria Roman",
  "Maria Rosaria Omaggio",
  "Maria Schell",
  "Maria Schrader",
  "Maria Simon",
  "Maria Thelma Smáradóttir",
  "Maria de Medeiros",
  "Marian Dziedziel",
  "Mariana Cordero",
  "Mariana Di Girólamo",
  "Mariana Loyola",
  "Mariana Malamud",
  "Mariangela Melato",
  "Marianne Denicourt",
  "Marianne Jean-Baptiste",
  "Marianne Koch",
  "Mariano Venancio",
  "Maribel Verdú",
  "Maricel Álvarez",
  "Marie Bos",
  "Marie Bäumer",
  "Marie Cardinal",
  "Marie Dubois",
  "Marie Déa",
  "Marie Gillain",
  "Marie Gruber",
  "Marie Laforêt",
  "Marie Maskell",
  "Marie Narbonne",
  "Marie Rivière",
  "Marie Versini",
  "Marie Zielcke",
  "Marie-Ange Casta",
  "Marie-Anne Chazel",
  "Marie-Christine Adam",
  "Marie-Christine Barrault",
  "Marie-Christine Descouard",
  "Marie-Evelyne Lessard",
  "Marie-France Boyer",
  "Marie-France Pisier",
  "Marie-Josée Croze",
  "Marie-Laure Dougnac",
  "Marie-Lou Sellem",
  "Marie-Sophie Ferdane",
  "Mariel Hemingway",
  "Mariela Díaz",
  "Marii Weichsler",
  "Marika Green",
  "Mariko Ogawa",
  "Mariko Okada",
  "Mariko Shinoda",
  "Marilyn Chambers",
  "Marilyn Eastman",
  "Marilyn Ghigliotti",
  "Marilyn Lima",
  "Marilyn Monroe",
  "Marina Adzhubei",
  "Marina Andruix",
  "Marina Foïs",
  "Marina Gatell",
  "Marina Inoue",
  "Marina Massironi",
  "Marina Salas",
  "Marina Vasileva",
  "Marina Zudina",
  "Marina de Tavira",
  "Marine Vacth",
  "Marinela Dekic",
  "Marino Ballón",
  "Mario Adorf",
  "Mario Brega",
  "Mario Casas",
  "Mario Cutini",
  "Mario David",
  "Mario Garriba",
  "Mario Guerra",
  "Mario Iván Martínez",
  "Mario Pirrello",
  "Mario Pisu",
  "Mario Sponzo",
  "Mario Van Peebles",
  "Mario Vitale",
  "Mario Zuniga Benavides",
  "Marion Bailey",
  "Marion Chabassol",
  "Marion Cotillard",
  "Marion Mack",
  "Marion Stalens",
  "Marisa Berenson",
  "Marisa Laurito",
  "Marisa Merlini",
  "Marisa Paredes",
  "Marisa Tomei",
  "Mariska Hargitay",
  "Marisol Nichols",
  "Marissa Anita",
  "Marius Goring",
  "Mariya Fomina",
  "Mariya Kuznetsova",
  "Mariya Mironova",
  "Mariya Poezzhaeva",
  "Mariya Poroshina",
  "Mariya Zhukova",
  "Mariétou Touré",
  "Marjan Ettefaghian",
  "Marjane Satrapi",
  "Mark Addy",
  "Mark Aspinall",
  "Mark Boone Junior",
  "Mark Bringelson",
  "Mark Burns",
  "Mark Dacascos",
  "Mark Damon",
  "Mark Gatiss",
  "Mark Gustafson",
  "Mark Hamill",
  "Mark Harmon",
  "Mark Herrier",
  "Mark Huberman",
  "Mark Humphrey",
  "Mark Leonard Winter",
  "Mark Lester",
  "Mark Lindsay",
  "Mark Margolis",
  "Mark McKinney",
  "Mark O'Brien",
  "Mark Rolston",
  "Mark Ruffalo",
  "Mark Rylance",
  "Mark Schrier",
  "Mark Stevens",
  "Mark Stewart",
  "Mark Strong",
  "Mark Wahlberg",
  "Mark Williams",
  "Mark Wingett",
  "Mark-Paul Gosselaar",
  "Markees Christmas",
  "Markus Knüfken",
  "Markus Krojer",
  "Markus Majowski",
  "Markus Rygaard",
  "Markus Schleinzer",
  "Markéta Irglová",
  "Marlee Matlin",
  "Marlen Kruse",
  "Marlene Dietrich",
  "Marley Shelton",
  "Marlon Brando",
  "Marlon Dance-Hooi",
  "Marlon Joubert",
  "Marlon Wayans",
  "Marlène Jobert",
  "Marpessa Dawn",
  "Marsha Mason",
  "Marsha Timothy",
  "Marshall Manesh",
  "Marta Blanc",
  "Marta Etura",
  "Marta Fernández Muro",
  "Marta Mazurek",
  "Martha MacIsaac",
  "Martha Vickers",
  "Martin Balsam",
  "Martin Brambach",
  "Martin Buch",
  "Martin Clunes",
  "Martin Compston",
  "Martin Donovan",
  "Martin Dubreuil",
  "Martin Freeman",
  "Martin Greis-Rosenthal",
  "Martin Hancock",
  "Martin Henderson",
  "Martin LaSalle",
  "Martin Landau",
  "Martin Lawrence",
  "Martin McCann",
  "Martin Milner",
  "Martin Potter",
  "Martin Rapold",
  "Martin Roach",
  "Martin Shaw",
  "Martin Sheen",
  "Martin Short",
  "Martin Starr",
  "Martin Stringer",
  "Martin Swabey",
  "Martin Udvarnoky",
  "Martin Wallström",
  "Martin Watier",
  "Martina García",
  "Martina Gedeck",
  "Martina Gusman",
  "Martine Audrain",
  "Martine Ferrière",
  "Martine McCutcheon",
  "Martiño Rivas",
  "Marton Csokas",
  "Marty Feldman",
  "Martyn Ford",
  "Martín Adjemián",
  "Maru Valdivielso",
  "Maruschka Detmers",
  "Marwan Kenzari",
  "Mary Apick",
  "Mary Astor",
  "Mary Elizabeth Mastrantonio",
  "Mary Elizabeth Winstead",
  "Mary Gibbs",
  "Mary Kate Schellhardt",
  "Mary Kay Bergman",
  "Mary McCormack",
  "Mary McDonnell",
  "Mary Riggans",
  "Mary Steenburgen",
  "Mary Stuart Masterson",
  "Mary Tyler Moore",
  "Mary Ure",
  "Mary-Louise Parker",
  "Maryam Akbari",
  "Maryam Hamid",
  "Maryam Kazemi",
  "Maryam Moghadam",
  "Maryam Palizban",
  "Maryam Zaree",
  "Maryam d'Abo",
  "Maryana Spivak",
  "Maryiam Palvin Almani",
  "Marzieh Vafamehr",
  "Marziyeh Rezaei",
  "María Asquerino",
  "María Barranco",
  "María Casares",
  "María Elena Arpón",
  "María León",
  "María Luisa García",
  "María Marull",
  "María Mercedes Coroy",
  "María Mercedes Villagra",
  "María Onetto",
  "María Pedraza",
  "María Rojo",
  "María Salazar",
  "María Soledad Rodríguez",
  "María Teresa Rivas",
  "María Valverde",
  "María Varod",
  "Marília Pêra",
  "Masaaki Daimon",
  "Masaaki Ôkura",
  "Masaharu Fukuyama",
  "Masaharu Satô",
  "Masahiko Nishimura",
  "Masahiro Kobayashi",
  "Masahiro Motoki",
  "Masahiro Takashima",
  "Masakane Yonekura",
  "Masaki Okada",
  "Masako Nozawa",
  "Masami Nagasawa",
  "Masane Tsukayama",
  "Masanobu Andô",
  "Masanobu Katsumura",
  "Masanobu Takashima",
  "Masasa Mbangeni",
  "Masato Hagiwara",
  "Masatoshi Nagase",
  "Masaya Katô",
  "Masaya Takahashi",
  "Masayuki Deai",
  "Masayuki Mori",
  "Masayuki Shionoya",
  "Mason Gooding",
  "Mason Lee",
  "Masoud Rayegany",
  "Massi Mrowat",
  "Massimiliano Caroletti",
  "Massimiliano Tortora",
  "Massimo Ceccherini",
  "Massimo Foschi",
  "Massimo Girotti",
  "Massimo Troisi",
  "Massimo Venier",
  "Massoumeh Naderi",
  "Masud Zandbegleh",
  "Mateo Chiarino",
  "Mateusz Banasiuk",
  "Mateusz Damiecki",
  "Mateusz Kosciukiewicz",
  "Matheus Nachtergaele",
  "Mathias Malzieu",
  "Mathieu Amalric",
  "Mathieu Kassovitz",
  "Mathilda May",
  "Mathilde Auneveux",
  "Mathilde Ollivier",
  "Mathilde Seigner",
  "Matilda Anna Ingrid Lutz",
  "Matilda De Angelis",
  "Matilde Gioli",
  "Matilde Piana",
  "Matt Bomer",
  "Matt Costello",
  "Matt Damon",
  "Matt Dillon",
  "Matt Ellis",
  "Matt Fowler",
  "Matt Frewer",
  "Matt Helm",
  "Matt LeBlanc",
  "Matt Levett",
  "Matt Lucas",
  "Matt Malloy",
  "Matt O'Leary",
  "Matt Passmore",
  "Matt Smith",
  "Matt Stokoe",
  "Matt Stone",
  "Matteo Oscar Giuggioli",
  "Matteo Pilati",
  "Matthew Broderick",
  "Matthew Chamberlain",
  "Matthew Davis",
  "Matthew Edison",
  "Matthew Ferguson",
  "Matthew Fox",
  "Matthew Goode",
  "Matthew Gray Gubler",
  "Matthew Lillard",
  "Matthew Macfadyen",
  "Matthew McConaughey",
  "Matthew Mercer",
  "Matthew Modine",
  "Matthew Morales",
  "Matthew Ninaber",
  "Matthew Page",
  "Matthew Rhys",
  "Matthew Sunderland",
  "Matthew Zuk",
  "Matthias Fuchs",
  "Matthias Habich",
  "Matthias Schoenaerts",
  "Matthias Schweighöfer",
  "Matthieu Chedid",
  "Matthieu Delaporte",
  "Matthieu Schaller",
  "Matvey Lykov",
  "Matvey Novikov",
  "Matías Quer",
  "Maud Adams",
  "Maudy Ayunda",
  "Maurane",
  "Maureen O'Hara",
  "Maureen O'Sullivan",
  "Maureen Stapleton",
  "Maurice Barrier",
  "Maurice Beerblock",
  "Maurice Bénichou",
  "Maurice Chevit",
  "Maurice Dean Wint",
  "Maurice Evans",
  "Maurice Pialat",
  "Maurice Risch",
  "Maurice Ronet",
  "Maurice Schutz",
  "Maurizio Micheli",
  "Maury Chaykin",
  "Maury Sterling",
  "Mavie Hörbiger",
  "Max Born",
  "Max Boublil",
  "Max Brebant",
  "Max Ernst",
  "Max Hubacher",
  "Max Irons",
  "Max Martini",
  "Max Minghella",
  "Max Parodi",
  "Max Phipps",
  "Max Riemelt",
  "Max Ryan",
  "Max Schreck",
  "Max Thieriot",
  "Max Thommes",
  "Max von Sydow",
  "Max von der Groeben",
  "Maxim Gaudette",
  "Maxim Mehmet",
  "Maxime Collin",
  "Maxime Foerste",
  "Maxime Godart",
  "Maximilian Brückner",
  "Maximilian Schell",
  "Maximiliano Ghione",
  "Maxine Audley",
  "Maxwell Simba",
  "May Dlamini",
  "May Whitty",
  "Maya Banno",
  "Maya Christie",
  "Maya Rudolph",
  "Maya Sansa",
  "Maya Zapata",
  "Mayko Nguyen",
  "Mayte Sanchez",
  "Mayu Matsuoka",
  "Mayu Suzuki",
  "Mayumi Izuka",
  "Mayumi Tanaka",
  "Mazhar Alanson",
  "Maziar Lorestani",
  "Maïwenn",
  "Mbulelo Grootboom",
  "McCaul Lombardi",
  "Mckenna Grace",
  "Me Me Lai",
  "Meagan Good",
  "Meat Loaf",
  "Medi Sadoun",
  "Meena",
  "Meg Foster",
  "Meg Ryan",
  "Megan Charpentier",
  "Megan Fox",
  "Megan Leonard",
  "Megan Park",
  "Megan du Plessis",
  "Meghan Heffern",
  "Megi Kobaladze",
  "Megs Jenkins",
  "Megumi Hayashibara",
  "Megumi Odaka",
  "Megumi Ogata",
  "Megumi Okina",
  "Mehdi Ahmadi",
  "Mehdi Ahmadpour",
  "Mehdi Bajestani",
  "Mehdi Nebbou",
  "Mehmet Ali Erbil",
  "Mehmet Amca",
  "Mehmet Cerrahoglu",
  "Mehmet Emin Toprak",
  "Mehmet Eryilmaz",
  "Mehmet Günsür",
  "Mehmet Kurtulus",
  "Mehran Ahmadi",
  "Mehran Kashani",
  "Mehran Modiri",
  "Mehraveh Sharifinia",
  "Mehrdad Ahankhah",
  "Mehrdad Sedighian",
  "Meinhard Neumann",
  "Meisa Kuroki",
  "Mekhi Phifer",
  "Mel B",
  "Mel Brooks",
  "Mel Ferrer",
  "Mel Gibson",
  "Melanie C",
  "Melanie Lenz",
  "Melanie Mayron",
  "Melike Güner",
  "Melina Mercouri",
  "Melinda Dillon",
  "Melis Babadag",
  "Melis Birkan",
  "Melisa Sözen",
  "Melissa Benoist",
  "Melissa Fahn",
  "Melissa George",
  "Melissa Jalali",
  "Melissa Leo",
  "Melissa McCarthy",
  "Melissa Prophet",
  "Melissa Sue Anderson",
  "Melody Anderson",
  "Melora Walters",
  "Meltem Cumbul",
  "Melvil Poupaud",
  "Melvyn Douglas",
  "Mena Suvari",
  "Merab Ninidze",
  "Meral Orhonsay",
  "Meral Zeren",
  "Meray Ülgen",
  "Mercedes Morán",
  "Mercedes Quinteros",
  "Meret Becker",
  "Merila Zare'i",
  "Merna Kennedy",
  "Merrie Lynn Ross",
  "Merritt Patterson",
  "Mert Firat",
  "Mert Turak",
  "Mervyn Johns",
  "Mervyn LeRoy",
  "Merwin Mondesir",
  "Meryl Streep",
  "Mesut Akusta",
  "Mete Horozoglu",
  "Metin Akpinar",
  "Metin Serezli",
  "Metztli Adamina",
  "Mews Small",
  "Mi-kyeong Yang",
  "Mi-yeon Lee",
  "Mia Farrow",
  "Mia Goth",
  "Mia Kirshner",
  "Mia Lyhne",
  "Mia Sara",
  "Mia Skäringer",
  "Mia Stallard",
  "Mia Wasikowska",
  "Micaela Nevárez",
  "Micaela Ramazzotti",
  "Michael Adamthwaite",
  "Michael Aherne",
  "Michael Ansara",
  "Michael Arata",
  "Michael B. Jordan",
  "Michael Badalucco",
  "Michael Bates",
  "Michael Beran",
  "Michael Berryman",
  "Michael Biehn",
  "Michael Bowen",
  "Michael Brandon",
  "Michael Buscemi",
  "Michael C. Hall",
  "Michael C. Maronna",
  "Michael Caine",
  "Michael Cera",
  "Michael Chiklis",
  "Michael Clarke Duncan",
  "Michael Constantine",
  "Michael Crichton",
  "Michael David",
  "Michael Dobson",
  "Michael Donovan",
  "Michael Dorman",
  "Michael Douglas",
  "Michael Ealy",
  "Michael Eklund",
  "Michael Fassbender",
  "Michael Fox",
  "Michael Fuith",
  "Michael Gambon",
  "Michael Garza",
  "Michael Gill",
  "Michael Graham Cox",
  "Michael Hadley",
  "Michael Haigney",
  "Michael Hegner",
  "Michael Herbig",
  "Michael Hinz",
  "Michael Hough",
  "Michael Ironside",
  "Michael J Rogers",
  "Michael J. Fox",
  "Michael J. Pollard",
  "Michael J. Reynolds",
  "Michael Jai White",
  "Michael Jayston",
  "Michael Jeter",
  "Michael Keaton",
  "Michael Kenneth Williams",
  "Michael Kitchen",
  "Michael Kopsa",
  "Michael Landes",
  "Michael Lerner",
  "Michael Lonsdale",
  "Michael Madsen",
  "Michael Mando",
  "Michael Martin",
  "Michael Massee",
  "Michael McDonald",
  "Michael McElhatton",
  "Michael McKean",
  "Michael Mendl",
  "Michael Michele",
  "Michael Murphy",
  "Michael Nuccio",
  "Michael Nyqvist",
  "Michael Palin",
  "Michael Parks",
  "Michael Paré",
  "Michael Peña",
  "Michael Pitt",
  "Michael Preston",
  "Michael Rapaport",
  "Michael Raysses",
  "Michael Redgrave",
  "Michael Rennie",
  "Michael Rianda",
  "Michael Riley",
  "Michael Rispoli",
  "Michael Rooker",
  "Michael Rosenbaum",
  "Michael Sacks",
  "Michael Schaack",
  "Michael Scholes",
  "Michael Shanks",
  "Michael Shannon",
  "Michael Sheen",
  "Michael Smiley",
  "Michael Strong",
  "Michael Stuhlbarg",
  "Michael Taliferro",
  "Michael Thomas",
  "Michael Vartan",
  "Michael Wildman",
  "Michael Wincott",
  "Michael Winslow",
  "Michael Winterbottom",
  "Michael Wittenborn",
  "Michael York",
  "Michael Zegen",
  "Michaela Watkins",
  "Michal Koterski",
  "Michal Lupa",
  "Michal Meyer",
  "Michal Milowicz",
  "Michal Zebrowski",
  "Michalina Labacz",
  "Michalina Olszanska",
  "Michaël Cohen",
  "Michaël Grégorio",
  "Michaël Youn",
  "Michel Auclair",
  "Michel Aumont",
  "Michel Beaudry",
  "Michel Blanc",
  "Michel Bouquet",
  "Michel Briguet",
  "Michel Constantin",
  "Michel Côté",
  "Michel Debord",
  "Michel Galabru",
  "Michel Gondry",
  "Michel Hazanavicius",
  "Michel Joelsas",
  "Michel Modo",
  "Michel Muller",
  "Michel Nabokoff",
  "Michel Perron",
  "Michel Piccoli",
  "Michel Robin",
  "Michel Semeniako",
  "Michel Serrault",
  "Michel Simon",
  "Michel Subor",
  "Michel Vuillermoz",
  "Michela Giraud",
  "Michele Morrone",
  "Michele Placido",
  "Michele Savoia",
  "Micheline Dax",
  "Micheline Francey",
  "Micheline Lanctôt",
  "Micheline Rolla",
  "Michelle Dockery",
  "Michelle La",
  "Michelle Monaghan",
  "Michelle Nolden",
  "Michelle Pfeiffer",
  "Michelle Rodriguez",
  "Michelle Ryan",
  "Michelle Trachtenberg",
  "Michelle Williams",
  "Michelle Ye",
  "Michelle Yeoh",
  "Micheál Richardson",
  "Michiel Huisman",
  "Michiyo Aratama",
  "Michiyo Kogure",
  "Michiyo Yasuda",
  "Michèle Caucheteux",
  "Michèle Laroque",
  "Michèle Mercier",
  "Michèle Morgan",
  "Mickey Cottrell",
  "Mickey Knox",
  "Mickey Rourke",
  "Mickey Sumner",
  "Mie Hama",
  "Mieko Harada",
  "Miguel Alvarez",
  "Miguel Arancibia",
  "Miguel Bosé",
  "Miguel Dedovich",
  "Miguel Ferrer",
  "Miguel Inclán",
  "Miguel Molina",
  "Miguel Zúñiga",
  "Miguel Ángel Fuentes",
  "Mihai Comanoiu",
  "Miho Nakayama",
  "Mika Hijii",
  "Mikael Birkkjær",
  "Mikael Persbrandt",
  "Mikako Ichikawa",
  "Mike Anscombe",
  "Mike Colter",
  "Mike Disa",
  "Mike Fearnley",
  "Mike Hodges",
  "Mike Johnson",
  "Mike Maguire",
  "Mike Mitchell",
  "Mike Myers",
  "Mike O'Brian",
  "Mike Smith",
  "Mike Tyson",
  "Mike Vitar",
  "Mike Wilmot",
  "Mikhail Baryshnikov",
  "Mikhail Bychkov",
  "Mikhail Filipchuk",
  "Mikhail Korobochkin",
  "Mikhail Nazvanov",
  "Mikhail Piotrovsky",
  "Mikhail Pugovkin",
  "Mikhail Zharov",
  "Miki Esparbé",
  "Miki Fujitani",
  "Miki Imai",
  "Miki Irie",
  "Miki Jinbo",
  "Miki Mizuno",
  "Miki Nakatani",
  "Mikijirô Hira",
  "Mikkel Boe Følsgaard",
  "Miklós Benedek",
  "Mila Kunis",
  "Mila Parély",
  "Milad Rahimi",
  "Milan Peschel",
  "Milena Dravic",
  "Milena Dreißig",
  "Milena Radulovic",
  "Milena Smit",
  "Miles Fisher",
  "Miles Mander",
  "Miles O'Keeffe",
  "Miles Teller",
  "Miles Thompson",
  "Milhem Cortaz",
  "Milinko Ametovic Beganovic",
  "Milla Jovovich",
  "Millaray Lobos",
  "Mille Dinesen",
  "Millie Bobby Brown",
  "Milo O'Shea",
  "Milo Ventimiglia",
  "Milos Bikovic",
  "Milou Van Groesen",
  "Milton Velasquez",
  "Milvia Marigliano",
  "Mimi Kuzyk",
  "Mimi Maynard",
  "Mimi Rogers",
  "Mimsy Farmer",
  "Min Je",
  "Min Kim",
  "Min Namkoong",
  "Min-Jung Bae",
  "Min-ah Park",
  "Min-ho Hwang",
  "Min-soo Jo",
  "Min-yeong Kim",
  "Mina Mohammad Khani",
  "Mina Sadati",
  "Mina Tander",
  "Minami Takayama",
  "Minase Yashiro",
  "Mindy Kalling",
  "Ming-Na Wen",
  "Minnie Driver",
  "Minoo Sharifi",
  "Minori Chihara",
  "Minori Terada",
  "Minoru Chiaki",
  "Minoru Ôki",
  "Minzhi Wei",
  "Miori Takimoto",
  "Miou-Miou",
  "Miquel Fernández",
  "Miquel García Borda",
  "Mira Sorvino",
  "Mirai Shida",
  "Miranda July",
  "Miranda Otto",
  "Miranda Richardson",
  "Mirco Nontschew",
  "Mirei Oguchi",
  "Mireille Darc",
  "Mireille Enos",
  "Mirella Pascual",
  "Mirhadi Tayebi",
  "Miriam Díaz-Aroca",
  "Miriam Hopkins",
  "Miriam Leone",
  "Miriam Margolyes",
  "Miriam Morgenstern",
  "Miriam Shor",
  "Miriam Stein",
  "Mirjam Novak",
  "Mirjana Jokovic",
  "Miroslav Donutil",
  "Miroslava",
  "Miroslaw Baka",
  "Miroslaw Zbrojewicz",
  "Mirta Ibarra",
  "Misa Shimizu",
  "Misa Uehara",
  "Misaki Itô",
  "Misako Renbutsu",
  "Misako Watanabe",
  "Mischa Auer",
  "Mischa Barton",
  "Misha Gabriel Hamilton",
  "Misha Meskhi",
  "Missy Peregrym",
  "Mitchell Ryan",
  "Mitchell Slaggert",
  "Mitchell Whitfield",
  "Mithun Chakraborty",
  "Mitsuki Tanimura",
  "Mitsuko Baishô",
  "Mitsuko Mito",
  "Mitsuo Iwata",
  "Mitsutaka Itakura",
  "Miya Muqi",
  "Miyoko Shôji",
  "Miyu Irino",
  "Mo Han",
  "Mobina Karimi",
  "Mohamad Ali Keshavarz",
  "Mohamad Karim Rahmati",
  "Mohamad Reza Sharifinia",
  "Mohamed Akhzam",
  "Mohamed Bouich",
  "Mohamed Fellag",
  "Mohamed Grayaâ",
  "Mohamed Yeslem Mousse",
  "Mohammad Ahmadi",
  "Mohammad Amir Naji",
  "Mohammad Asarian",
  "Mohammad Berahmani",
  "Mohammad Dezfulizadeh",
  "Mohammad Fathi",
  "Mohammad Hassan Madjooni",
  "Mohammad Kart",
  "Mohammad Kasebi",
  "Mohammad Reza Forutan",
  "Mohammad Reza Golzar",
  "Mohammad Reza Maleki",
  "Mohammad Reza Shaban-Noori",
  "Mohammad Reza Shirkhanloo",
  "Mohammad Valizadegan",
  "Mohammad-Ali Shadman",
  "Mohanlal",
  "Mohd. Zeeshan Ayyub",
  "Mohit Raina",
  "Mohsen Avid",
  "Mohsen Kafili",
  "Mohsen Kiayee",
  "Mohsen Makhmalbaf",
  "Mohsen Ramezani",
  "Mohsen Shah-Ebrahimi",
  "Mohsen Tanabandeh",
  "Moira Brooker",
  "Moira Kelly",
  "Moira Shearer",
  "Mojgan Faramarzi",
  "Molly Blixt Egelind",
  "Molly Gordon",
  "Molly Parker",
  "Molly Picon",
  "Molly Ringwald",
  "Molly Shannon",
  "Momoko Kôchi",
  "Momoko Shimizu",
  "Mona Singh",
  "Mone Kamishiraishi",
  "Moneca Stori",
  "Monia Chokri",
  "Monica Bellucci",
  "Monica Bleibtreu",
  "Monica Keena",
  "Monica Potter",
  "Monica Viegas",
  "Monica Vitti",
  "Montgomery Clift",
  "Moo-Hyun Jang",
  "Moon Bloodgood",
  "Moon Chae-Won",
  "Moon Geun-young",
  "Moon So-ri",
  "Moon-hee Na",
  "Moon-su Lee",
  "Moran Atias",
  "Morena Baccarin",
  "Morfydd Clark",
  "Morgan Allen",
  "Morgan Freeman",
  "Morgan Marinne",
  "Moritz Bleibtreu",
  "Moritz Klaus",
  "Morjana Alaoui",
  "Mort Shuman",
  "Morten Burian",
  "Morten Kerrn Nielsen",
  "Mostafa Hashemzehi",
  "Mostafa Tari",
  "Mostafa Zamani",
  "Mothusi Magano",
  "Moustache",
  "Mozhan Marnò",
  "Mrunal Thakur",
  "Mu-Yeol Kim",
  "Mu-saeng Kim",
  "Muhammed Yasir Berkli",
  "Muhammet Uzuner",
  "Muharrem Bayrak",
  "Mukesh Rishi",
  "Munetaka Aoki",
  "Munro Chambers",
  "Murat Basoglu",
  "Murat Cemcir",
  "Murat Garibagaoglu",
  "Murat Han",
  "Murat Saraçoglu",
  "Murat Serezli",
  "Murat Ünalmis",
  "Muriel Robin",
  "Muriel Santa Ana",
  "Musa Torkizadeh",
  "Mustafa Alabora",
  "Mustafa Avkiran",
  "Mustafa Kamel",
  "Mustafa Ugurlu",
  "Muzaffer Göçmen",
  "Muzaffer Özdemir",
  "Muzakki Ramdhan",
  "MyAnna Buring",
  "Mykelti Williamson",
  "Myles Truitt",
  "Mylène Demongeot",
  "Mylène Farmer",
  "Mylène Jampanoï",
  "Myriam Boyer",
  "Myrna Loy",
  "Märt Avandi",
  "Måns Herngren",
  "Médina Diarra",
  "Médina El Aidi-Azouni",
  "Mélanie Bernier",
  "Mélanie Doutey",
  "Mélanie Laurent",
  "Mélanie Thierry",
  "Mélissa Désormeaux-Poulin",
  "Mélusine Mayance",
  "Mía Maestro",
  "Mónica Cervera",
  "Mónica Del Carmen",
  "Mónica López",
  "Mónica Villa",
  "Müfit Kayacan",
  "Müge Ulusoy",
  "Müjde Ar",
  "Münir Özkul",
  "Müsfik Kenter",
  "N'Bushe Wright",
  "N.T. Rama Rao Jr.",
  "Na Chul",
  "Na-Na OuYang",
  "Na-ra Lee",
  "Nabil Saleh",
  "Nacho López",
  "Nacho Martínez",
  "Nacho Vigalondo",
  "Naci Tasdögen",
  "Nader Fallah",
  "Nadereh Abdelahyeva",
  "Nadezhda Markina",
  "Nadezhda Mikhalkova",
  "Nadia Farès",
  "Nadia Rinaldi",
  "Nadia Tereszkiewicz",
  "Nadia de Santiago",
  "Nadine Labaki",
  "Nadine Nortier",
  "Nadine Velazquez",
  "Nadir Saribacak",
  "Nadira Babbar",
  "Nadja Tiller",
  "Nadja Uhl",
  "Nafise Jafar-Mohammadi",
  "Nahuel Pérez Biscayart",
  "Naian González Norvind",
  "Najwa Nimri",
  "Nam Joo-hyuk",
  "Nam-gil Kim",
  "Nam-mi Kang",
  "Nan Yu",
  "Nana Mori",
  "Nanako Matsushima",
  "Nanami Sakuraba",
  "Nancho Novo",
  "Nancy Allen",
  "Nancy Brilli",
  "Nancy Kovack",
  "Nancy Kyes",
  "Nancy Olson",
  "Nancy Travis",
  "Nandish Singh Sandhu",
  "Nane Germon",
  "Nanni Moretti",
  "Nanou Garcia",
  "Nao Ômori",
  "Naoki Tatsuta",
  "Naomi Ackie",
  "Naomi Nishida",
  "Naomi Scott",
  "Naomi Watts",
  "Naomie Harris",
  "Naoto Ogata",
  "Naoto Takenaka",
  "Napakpapha Nakprasitte",
  "Narain",
  "Narges Rashidi",
  "Nargess Mamizadeh",
  "Narilya Gulmongkolpech",
  "Narumi Kayashima",
  "Nasar Annuz",
  "Naseeruddin Shah",
  "Naser Hashemi",
  "Naser Malek Motiee",
  "Naser Omuni",
  "Nasim Adabi",
  "Nasrin Babaei",
  "Nasrin Sotoudeh",
  "Nassar",
  "Nassim Abdi",
  "Nassim Hassaïni",
  "Nastassja Kinski",
  "Nat Pendleton",
  "Nat Wolff",
  "Natacha Lindinger",
  "Natacha Régnier",
  "Natalia Avelon",
  "Natalia Azahara",
  "Natalia Barcelò",
  "Natalia Borisova",
  "Natalia Reyes",
  "Natalia Santiago",
  "Natalia Tena",
  "Natalia Verbeke",
  "Natalia de Molina",
  "Natalie Brown",
  "Natalie Dessay",
  "Natalie Dormer",
  "Natalie Imbruglia",
  "Natalie Krill",
  "Natalie Mendoza",
  "Natalie Portman",
  "Natalie Press",
  "Natalie Wood",
  "Nataliya Vdovina",
  "Natalya Arkhangelskaya",
  "Natalya Baranova",
  "Natalya Bondarchuk",
  "Natalya Rudakova",
  "Natalya Sedykh",
  "Natalya Surkova",
  "Natalya Varley",
  "Natascha McElhone",
  "Natasha Calis",
  "Natasha Gregson Wagner",
  "Natasha Hovey",
  "Natasha Liu Bordizzo",
  "Natasha Richardson",
  "Natasha Yarovenko",
  "Natassia Malthe",
  "Nate Parker",
  "Nathalia Acevedo",
  "Nathalie Baye",
  "Nathalie Boltt",
  "Nathalie Cox",
  "Nathalie Delon",
  "Nathalie Issa",
  "Nathalie Pascaud",
  "Nathalie Poza",
  "Nathalie Richard",
  "Nathalie Roussel",
  "Nathan Fillion",
  "Nathan Gamble",
  "Nathan Lane",
  "Nathan Meister",
  "Nathan Phillips",
  "Nathan Stewart-Jarrett",
  "Nathan West",
  "Nathaniel Arcand",
  "Nathaniel Brown",
  "Nathaniel Parker",
  "Natja Brunckhorst",
  "Natsuki Aikawa",
  "Natsuki Hanae",
  "Natsuna",
  "Naveen Andrews",
  "Navid Mohammadzadeh",
  "Navid Negahban",
  "Nawazuddin Siddiqui",
  "Nayim Saifi",
  "Nayla Sophie Brehmer",
  "Nazan Kesal",
  "Nazan Saatçi",
  "Nazanin Bayati",
  "Nazanin Boniadi",
  "Nazmi Kirik",
  "Neal McDonough",
  "Necati Bilgiç",
  "Necati Sasmaz",
  "Ned Beatty",
  "Ned Bellamy",
  "Ned Dennehy",
  "Ned Wills",
  "Neels van Jaarsveld",
  "Neena Gupta",
  "Neeraj Kabi",
  "Neeraj Pandey",
  "Neeral Mulchandani",
  "Negar Javaherian",
  "Negar Shaghaghi",
  "Neil Brown Jr.",
  "Neil Maskell",
  "Neil Newbon",
  "Neil Nitin Mukesh",
  "Neil Patrick Harris",
  "Nejat Altinsoy",
  "Nejat Isler",
  "Nela Lucic",
  "Nele Trebs",
  "Nell Cattrysse",
  "Nelly Benedetti",
  "Nelly Borgeaud",
  "Nelly Frijda",
  "Nelly Pappaert",
  "Nelofer Pazira",
  "Nelsan Ellis",
  "Nematollah Gorji",
  "Nene",
  "Nenji Kobayashi",
  "Nerea Camacho",
  "Nergis Öztürk",
  "Nery Arredondo",
  "Nestor Serrano",
  "Neus Asensi",
  "Nevra Serezli",
  "Ni Ni",
  "Nia Long",
  "Nia Vardalos",
  "Niamh Algar",
  "Nichelle Nichols",
  "Nicholas Ball",
  "Nicholas Brendon",
  "Nicholas Campbell",
  "Nicholas Clay",
  "Nicholas D'Agosto",
  "Nicholas Farrell",
  "Nicholas Guest",
  "Nicholas Hoult",
  "Nicholas Kadi",
  "Nicholas Saputra",
  "Nicholas Tse",
  "Nicholas Tucci",
  "Nick Adams",
  "Nick Alachiotis",
  "Nick Cassavetes",
  "Nick Damici",
  "Nick Frost",
  "Nick Hamm",
  "Nick Jonas",
  "Nick Moran",
  "Nick Nolte",
  "Nick Offerman",
  "Nick Ormerod",
  "Nick Park",
  "Nick Reding",
  "Nick Stabile",
  "Nick Stahl",
  "Nickolay Hadjiminev",
  "Nicky Katt",
  "Nico García",
  "Nico Le Phat Tan",
  "Nicodim Ungureanu",
  "Nicol Williamson",
  "Nicola Di Pinto",
  "Nicola Lanza",
  "Nicola Reynolds",
  "Nicolai Cleve Broch",
  "Nicolas Bro",
  "Nicolas Buysse",
  "Nicolas Cage",
  "Nicolas Cazalé",
  "Nicolas Duvauchelle",
  "Nicolas Giraud",
  "Nicolas Marié",
  "Nicolas Roeg",
  "Nicolas Wanczycki",
  "Nicole Berger",
  "Nicole Fong",
  "Nicole Garcia",
  "Nicole Grimaudo",
  "Nicole Kidman",
  "Nicole Ladmiral",
  "Nicole Marischka",
  "Nicole Muñoz",
  "Nicole de Boer",
  "Nicoletta Braschi",
  "Nicolás Furtado",
  "Nicolás Martínez",
  "Niels Arestrup",
  "Niels Schneider",
  "Nieve de Medina",
  "Nieves Navarro",
  "Nigel Havers",
  "Nigel Lindsay",
  "Nigel Pivaro",
  "Nigel Terry",
  "Nigel Whitmey",
  "Nihal Yalçin",
  "Nijirô Murakami",
  "Nikbakht Noruz",
  "Niki List",
  "Nikita Mikhalkov",
  "Nikita Tarasov",
  "Nikki Blonsky",
  "Nikki Reed",
  "Nikohl Boosheri",
  "Nikolai Okhlopkov",
  "Nikolai Witschl",
  "Nikolaj Coster-Waldau",
  "Nikolaj Lie Kaas",
  "Nikolay Burlyaev",
  "Nikolay Cherkasov",
  "Nikolay Grinko",
  "Nikolay Kutuzov",
  "Nikolay Sergeev",
  "Nikolay Shrayber",
  "Nikos Papadopoulos",
  "Nil Karaibrahimgil",
  "Nilbio Torres",
  "Nilgün Nazli",
  "Nils Poppe",
  "Nimrat Kaur",
  "Nina Arianda",
  "Nina Dobrev",
  "Nina Doroshina",
  "Nina Grebeshkova",
  "Nina Hoss",
  "Nina Kunzendorf",
  "Nina Meurisse",
  "Nina Pens Rode",
  "Nina Petri",
  "Nina Sunevic",
  "Ninetto Davoli",
  "Nino Castelnuovo",
  "Nino Manfredi",
  "Niousha Alipour",
  "Nirina Zubir",
  "Nis Bank-Mikkelsen",
  "Nisa Sofiya Aksongur",
  "Nita-Josée Hanna",
  "Nizam Schiller",
  "Noah Centineo",
  "Noah Emmerich",
  "Noah Jupe",
  "Noah Taylor",
  "Noah Wiseman",
  "Nobu Kanaoka",
  "Nobuhiko Okamoto",
  "Nobuhiro Nishihara",
  "Nobuhiro Suwa",
  "Nobuko Miyamoto",
  "Nobuko Otowa",
  "Nobuo Kaneko",
  "Noel Clarke",
  "Noghre Asadi",
  "Non",
  "Noomi Rapace",
  "Noorin Gulamgaus",
  "Nora Gregor",
  "Nora Navas",
  "Nora Ricci",
  "Nora Tschirner",
  "Nora-Jane Noone",
  "Norah Jones",
  "Norberto Gonzalo",
  "Noriaki Sugiyama",
  "Norihei Miki",
  "Noriko Hidaka",
  "Noriko Sakai",
  "Norm MacDonald",
  "Norma Aleandro",
  "Norma Bengell",
  "Norma Crane",
  "Norman Ferguson",
  "Norman Reedus",
  "Nosrat Partovi",
  "Nouhe Hamady Bari",
  "Nozomu Sasaki",
  "Noé Hernández",
  "Noémie Lvovsky",
  "Noémie Merlant",
  "Noémie Schmidt",
  "Numan Acar",
  "Nunzia Schiano",
  "Nur Fettahoglu",
  "Nur Sürer",
  "Nuri Bilge Ceylan",
  "Nuria Herrero",
  "Nutsa Kukhianidze",
  "O'Shea Jackson Jr.",
  "O.J. Simpson",
  "Oakes Fegley",
  "Octavia Spencer",
  "Octavio E. Rodriguez",
  "Odd-Magnus Williamson",
  "Oded Fehr",
  "Odessa Young",
  "Oguzhan Koç",
  "Oh Yeong-su",
  "Oh-seong Yu",
  "Oka Antara",
  "Okan Bayülgen",
  "Okan Yalabik",
  "Oksana Akinshina",
  "Oksana Ivanivna Sklyarenko",
  "Oksana Orlan",
  "Olaf Lubaszenko",
  "Olatz López Garmendia",
  "Ole Thestrup",
  "Oleg Basilashvili",
  "Oleg Efremov",
  "Oleg Ivenko",
  "Oleg Menshikov",
  "Oleg Vasilkov",
  "Oleg Yankovskiy",
  "Olga Aroseva",
  "Olga Baclanova",
  "Olga Karlatos",
  "Olga Kurylenko",
  "Olga Mironova",
  "Olgierd Lukaszewicz",
  "Olgun Simsek",
  "Olimpia Malai",
  "Oliver Bodart",
  "Oliver Driver",
  "Oliver Jackson",
  "Oliver Kalkofe",
  "Oliver Korittke",
  "Oliver Maltman",
  "Oliver Masucci",
  "Oliver Platt",
  "Oliver Powell",
  "Oliver Reed",
  "Oliver Schmitz",
  "Oliver Stokowski",
  "Olivia Bonamy",
  "Olivia Colman",
  "Olivia Cooke",
  "Olivia Grant",
  "Olivia Hussey",
  "Olivia Magnani",
  "Olivia Munn",
  "Olivia Ruiz",
  "Olivia Taylor Dudley",
  "Olivia Thirlby",
  "Olivia Wilde",
  "Olivia Williams",
  "Olivia d'Abo",
  "Olivia de Havilland",
  "Olivier Barthélémy",
  "Olivier Bonnaud",
  "Olivier Gourmet",
  "Olivier Marchal",
  "Olivier Perrier",
  "Olivier Rabourdin",
  "Olly Alexander",
  "Om Puri",
  "Omar Benson Miller",
  "Omar Elba",
  "Omar Epps",
  "Omar Shargawi",
  "Omar Sharif",
  "Omar Sy",
  "Omar Valdés",
  "Omero Antonutti",
  "Omid Djalili",
  "Omid Memar",
  "Ona Grauer",
  "Ondrej Vetchı",
  "Onni Tommila",
  "Onur Saylak",
  "Oona Roche",
  "Oona-Devi Liebich",
  "Ophelia Lovibond",
  "Ophélie Winter",
  "Oprah Winfrey",
  "Orazio Orlando",
  "Orhan Gencer",
  "Orion Acaba",
  "Orla Fitzgerald",
  "Orlando Bloom",
  "Orlando Tobón",
  "Orsolya Tóth",
  "Orson Bean",
  "Orson Welles",
  "Orto Ignatiussen",
  "Osamu Ogasawara",
  "Osamu Saka",
  "Oscar Isaac",
  "Oscar Martínez",
  "Oscar Pearce",
  "Oscar Steer",
  "Osheen Jones",
  "Oshiro Maeda",
  "Oskar Homolka",
  "Oskar Werner",
  "Osman Alyanak",
  "Osman Cavci",
  "Ossie Davis",
  "Osvaldo Benavides",
  "Otar Saralidze",
  "Ottavia Piccolo",
  "Otto Grünmandl",
  "Otto Preminger",
  "Otto Sander",
  "Otto Waalkes",
  "Otto Wernicke",
  "Ouassini Embarek",
  "Oulaya Amamra",
  "Ovidiu Balan",
  "Owain Yeoman",
  "Owen Myre",
  "Owen Vaccaro",
  "Owen Wilson",
  "Ozan Bilen",
  "Ozan Güven",
  "Paapa Essiedu",
  "Pablo Derqui",
  "Pablo Echarri",
  "Pablo Galán",
  "Pablo Guevara",
  "Pablo Molinero",
  "Pablo Rago",
  "Pablo Rosso",
  "Pablo Stoll",
  "Pablo Álvarez Rubio",
  "Paco León",
  "Paco Manzanedo",
  "Paco Plaza",
  "Paddy Considine",
  "Paddy Wallace",
  "Paige O'Hara",
  "Palina Rojinski",
  "Pam Grier",
  "Pamela Adlon",
  "Pamela Anderson",
  "Pamela Brown",
  "Pamela Flores",
  "Pamela Reed",
  "Pamela Tiffin",
  "Pamela Tola",
  "Pandora Colin",
  "Pankaj Tripathi",
  "Pantea Bahram",
  "Pantea Panahiha",
  "Panya Yimmumphai",
  "Paola Giannini",
  "Paola Guidi",
  "Paola Lara",
  "Paolo Bonacelli",
  "Paolo Calabresi",
  "Paolo Malco",
  "Paolo Stoppa",
  "Paolo Strippoli",
  "Paprika Steen",
  "Parambrata Chattopadhyay",
  "Pardis Ahmadieh",
  "Pare Gol Atashjameh",
  "Paresh Rawal",
  "Parinaz Izadyar",
  "Paris Hilton",
  "Parisa Fitz-Henley",
  "Parivash Nazarieh",
  "Park Bo-young",
  "Park Bom",
  "Park Chan-wook",
  "Park Hae-il",
  "Park Hae-joon",
  "Park Hoon",
  "Park In-Soo",
  "Park Jin-young",
  "Park Seo-joon",
  "Park Shin-Hye",
  "Park Sung-woong",
  "Park Won-sang",
  "Parker Posey",
  "Parminder Nagra",
  "Parsa Pirouzfar",
  "Parvaneh Ahmadi",
  "Parviz Fanizadeh",
  "Parviz Parastui",
  "Parviz Shahinkhou",
  "Pascal Benezech",
  "Pascal Bongard",
  "Pascal Duquenne",
  "Pascal Ferone",
  "Pascal Greggory",
  "Pascal Légitimus",
  "Pascal Persiano",
  "Pasha Ebrahimi",
  "Pasquale Aleardi",
  "Pasupathy",
  "Pat Boone",
  "Pat Carroll",
  "Pat Healy",
  "Pat Hingle",
  "Pat Thomson",
  "Patch Darragh",
  "Patcharawan Patarakijjanon",
  "Patrice Bélanger",
  "Patrice Chéreau",
  "Patrice Melennec",
  "Patricia Arquette",
  "Patricia Bethune",
  "Patricia Clarkson",
  "Patricia Neal",
  "Patricia Volny",
  "Patrick Bauchau",
  "Patrick Bergin",
  "Patrick Bruel",
  "Patrick Carroll",
  "Patrick Chilvers",
  "Patrick Dempsey",
  "Patrick Dewaere",
  "Patrick Fontana",
  "Patrick Fugit",
  "Patrick Güldenberg",
  "Patrick Horgan",
  "Patrick Huard",
  "Patrick Joseph Byrnes",
  "Patrick L. Reyes",
  "Patrick Ligardes",
  "Patrick Magee",
  "Patrick McGoohan",
  "Patrick Mercado",
  "Patrick Mille",
  "Patrick Mower",
  "Patrick Renna",
  "Patrick Stewart",
  "Patrick Swayze",
  "Patrick Warburton",
  "Patrick Wilson",
  "Patrick Wymark",
  "Patrick d'Assumçao",
  "Patrizia Terreno",
  "Patrizio Francioni",
  "Patton Oswalt",
  "Patty Shepard",
  "Paul Alhäuser",
  "Paul Anderson",
  "Paul Beauvais",
  "Paul Benjamin",
  "Paul Bettany",
  "Paul Brannigan",
  "Paul Brizzi",
  "Paul Busche",
  "Paul Dano",
  "Paul Dooley",
  "Paul Fox",
  "Paul Frankeur",
  "Paul Freeman",
  "Paul Giamatti",
  "Paul Glauer",
  "Paul Greco",
  "Paul Hampton",
  "Paul Hamy",
  "Paul Henreid",
  "Paul Higgins",
  "Paul Hilton",
  "Paul Hébert",
  "Paul Jesson",
  "Paul Kalkbrenner",
  "Paul Kaye",
  "Paul Lukas",
  "Paul McCartney",
  "Paul McGann",
  "Paul Mercier",
  "Paul Mercurio",
  "Paul Mescal",
  "Paul Meurisse",
  "Paul Muller",
  "Paul Newman",
  "Paul Nicholls",
  "Paul Popplewell",
  "Paul Raci",
  "Paul Reiser",
  "Paul Rhys",
  "Paul Richter",
  "Paul Ritter",
  "Paul Rudd",
  "Paul Rust",
  "Paul Sabella",
  "Paul Sanchez",
  "Paul Satterfield",
  "Paul Schneider",
  "Paul Scofield",
  "Paul Shenar",
  "Paul Stewart",
  "Paul Terry",
  "Paul Villé",
  "Paul Walker",
  "Paul Walter Hauser",
  "Paul Wegener",
  "Paul Weitz",
  "Paul Winfield",
  "Paula Beer",
  "Paula Garcés",
  "Paula Patton",
  "Paula Prentiss",
  "Paulette Dubost",
  "Paulette Goddard",
  "Paulin Jaccoud",
  "Paulina Gaitan",
  "Paulina García",
  "Pauline Acquart",
  "Pauline Brunner",
  "Pauline Burlet",
  "Pauline Lau",
  "Paulo Costanzo",
  "Paulyn Sun",
  "Pavel Aseev",
  "Pavel Derevyanko",
  "Pavel Kadochnikov",
  "Pavel Ponomaryov",
  "Pawan Malhotra",
  "Payman Maadi",
  "Paz Vega",
  "Paz de la Huerta",
  "Pearl Chanda",
  "Pedram Amiri",
  "Pedro Armendáriz Jr.",
  "Pedro Cardoso",
  "Pedro Pascal",
  "Pegah Ahangarani",
  "Pegah Ferydoni",
  "Peggy Ashcroft",
  "Peggy Bussieck",
  "Peiqi Liu",
  "Pejman Bazeghi",
  "Pejman Jamshidi",
  "Pell James",
  "Pelle Hvenegaard",
  "Pelé",
  "Pembe Mutlu",
  "Penelope Allen",
  "Penelope Ann Miller",
  "Penelope Mitchell",
  "Penelope Wilton",
  "Peng Lin",
  "Penny Eizenga",
  "Penélope Cruz",
  "Pepa Charro",
  "Peppino Mazzotta",
  "Pepón Nieto",
  "Per Ragnar",
  "Per Scheel Krüger",
  "Perce Pearce",
  "Percelle Ascott",
  "Peri Baumeister",
  "Perico Cervantes",
  "Pernilla Allwin",
  "Pernilla August",
  "Perrey Reeves",
  "Perry King",
  "Peta Wilson",
  "Petcharat Chaiburi",
  "Pete Postlethwaite",
  "Peter Apostolopoulos",
  "Peter Berg",
  "Peter Billingsley",
  "Peter Boyle",
  "Peter Brocco",
  "Peter Bull",
  "Peter Capaldi",
  "Peter Cellier",
  "Peter Chatel",
  "Peter Coyote",
  "Peter Cushing",
  "Peter DaCunha",
  "Peter Dinklage",
  "Peter Dvorsky",
  "Peter Egan",
  "Peter Falk",
  "Peter Farrelly",
  "Peter Ferdinando",
  "Peter Finch",
  "Peter Firth",
  "Peter Franzén",
  "Peter Gantzler",
  "Peter Jessop",
  "Peter Kay",
  "Peter Kazungu",
  "Peter Krause",
  "Peter Kurth",
  "Peter Lanzani",
  "Peter Lohmeyer",
  "Peter Lorre",
  "Peter MacNicol",
  "Peter Marquardt",
  "Peter Miller",
  "Peter Mooney",
  "Peter Mullan",
  "Peter Mygind",
  "Peter O'Toole",
  "Peter Ostrum",
  "Peter Outerbridge",
  "Peter Pedrero",
  "Peter Ramsey",
  "Peter Riegert",
  "Peter Sallis",
  "Peter Sarsgaard",
  "Peter Sellers",
  "Peter Simonischek",
  "Peter Spence",
  "Peter Spierig",
  "Peter Stebbings",
  "Peter Stormare",
  "Peter Thorwarth",
  "Peter Ustinov",
  "Peter Vaughan",
  "Peter Weller",
  "Peter Wyngarde",
  "Peter van Eyck",
  "Peter-Hugo Daly",
  "Petr Kotlár",
  "Petr Ratimec",
  "Petr Romanov",
  "Petra Martínez",
  "Petra Schmidt-Schaller",
  "Petrônio Gontijo",
  "Pevita Pearce",
  "Peyman Ghassemkhani",
  "Peyton Kennedy",
  "Pheline Roggan",
  "Phellipe Haagensen",
  "Phil Buckman",
  "Phil Daniels",
  "Phil Davis",
  "Phil Harris",
  "Philip Akin",
  "Philip Bolden",
  "Philip Chan",
  "Philip Einstein Lipski",
  "Philip Keung",
  "Philip King",
  "Philip Ng",
  "Philip Seymour Hoffman",
  "Philipp Stennert",
  "Philippe Caubère",
  "Philippe Duquesne",
  "Philippe Katerine",
  "Philippe Lacheau",
  "Philippe Laudenbach",
  "Philippe Leroy",
  "Philippe Nahon",
  "Philippe Noiret",
  "Philippe Torreton",
  "Philippine Velge",
  "Phillip Glasser",
  "Phillip Terry",
  "Phillipa Soo",
  "Phoebe Cates",
  "Phoebe Fox",
  "Phoebe Legere",
  "Phylicia Rashad",
  "Phyllis Logan",
  "Pier-Luc Funk",
  "Piera Degli Esposti",
  "Pierce Brosnan",
  "Piercey Dalton",
  "Pierfrancesco Favino",
  "Pierre Barbaud",
  "Pierre Barouh",
  "Pierre Benezit",
  "Pierre Blaise",
  "Pierre Boudreau",
  "Pierre Boulanger",
  "Pierre Brasseur",
  "Pierre Brice",
  "Pierre Curzi",
  "Pierre Deladonchamps",
  "Pierre Fresnay",
  "Pierre Lebeau",
  "Pierre Mondy",
  "Pierre Niney",
  "Pierre Poirot",
  "Pierre Renoir",
  "Pierre Richard",
  "Pierre Tornade",
  "Pierre Watrin",
  "Pierre-Yves Cardinal",
  "Pietro Castellitto",
  "Pif",
  "Pilar López de Ayala",
  "Pili Groyne",
  "Pilou Asbæk",
  "Pino Van Lamsweerde",
  "Piotr Glowacki",
  "Piotr Machalica",
  "Piotr Zurawski",
  "Piper Laurie",
  "Piper Perabo",
  "Plan B",
  "Po-Chieh Wang",
  "Pol Monen",
  "Polly Bergen",
  "Polly Shannon",
  "Poppy Sovia",
  "Porter Hall",
  "Poulad Kimiayi",
  "Pouri Baneai",
  "Pouria Poursorkh",
  "Pouria Rahimi Sam",
  "Powers Boothe",
  "Prabhas",
  "Prakash Belawadi",
  "Prakash Raj",
  "Pranay Pachauri",
  "Prateik Babbar",
  "Preben Lerdorff Rye",
  "Predrag 'Miki' Manojlovic",
  "Preity Zinta",
  "Presley Chweneyagae",
  "Primo Reggiani",
  "Priscilla Lane",
  "Priscilla Presley",
  "Priscilla de Laforcade",
  "Priya Anand",
  "Priyanka Chopra Jonas",
  "Pruitt Taylor Vince",
  "Pupella Maggio",
  "Pushkar",
  "Putri Marino",
  "Pyotr Fyodorov",
  "Pyotr Mamonov",
  "Pyotr Skvortsov",
  "Pádraic Delaney",
  "Pål Sverre Hagen",
  "Péter Haumann",
  "Pîtâ",
  "Q'orianka Kilcher",
  "Qasem Zare",
  "Qianyuan Wang",
  "Qiu Yuen",
  "Quan Yuan",
  "Queen Latifah",
  "Quentin Tarantino",
  "Quim Gutiérrez",
  "Quinn Shephard",
  "Quinton Aaron",
  "R. Lee Ermey",
  "R.D. Reid",
  "R.G. Armstrong",
  "R.J. Kizer",
  "RJ Cyler",
  "RZA",
  "Rabah Nait Oufella",
  "Rachael Harris",
  "Rachael Lillis",
  "Rachael Taylor",
  "Rachel Amanda",
  "Rachel Bilson",
  "Rachel Brosnahan",
  "Rachel House",
  "Rachel Hurd-Wood",
  "Rachel McAdams",
  "Rachel Miner",
  "Rachel Mwanza",
  "Rachel Nichols",
  "Rachel Shelley",
  "Rachel Ticotin",
  "Rachel Weisz",
  "Rachel Wood",
  "Rachelle Lefevre",
  "Rade Serbedzija",
  "Radha Mitchell",
  "Radha Ravi",
  "Radhika Apte",
  "Radina Drandova",
  "Radivoje Bukvic",
  "Rae Dawn Chong",
  "Rae-won Kim",
  "Raf Vallone",
  "Rafael Edholm",
  "Rafael Spregelburd",
  "Rafe Spall",
  "Rafet El Roman",
  "Raffaëla Anderson",
  "Raffey Cassidy",
  "Rafi Gavron",
  "Raghuvir Joshi",
  "Raghuvir Yadav",
  "Ragip Savas",
  "Rahman Bagherian",
  "Raife Yenigül",
  "Raimundo de los Reyes",
  "Rain",
  "Rain Tolk",
  "Rainer Bock",
  "Rainer Werner Fassbinder",
  "Rainn Wilson",
  "Raj B. Shetty",
  "Raj Nidimoru",
  "Rajat Barmecha",
  "Rajesh Abhay",
  "Rajesh Khanna",
  "Rajisha Vijayan",
  "Rajit Kapoor",
  "Rajkummar Rao",
  "Rakshit Shetty",
  "Ral Agada",
  "Ralf Richter",
  "Ralf Schmitz",
  "Raline Shah",
  "Ralph Bellamy",
  "Ralph Berkin",
  "Ralph Brown",
  "Ralph Etter",
  "Ralph Fiennes",
  "Ralph Ineson",
  "Ralph Macchio",
  "Ralph Meeker",
  "Ralph Prosper",
  "Ralph Richardson",
  "Ralph Rieckermann",
  "Ram Charan Teja",
  "Ram Kapoor",
  "Ramachandra Raju",
  "Ramadan Huseini",
  "Ramesh Deo",
  "Rami Malek",
  "Ramon Bieri",
  "Ramon Fontserè",
  "Ramzy Bedia",
  "Ramón Barea",
  "Rana Daggubati",
  "Ranbir Kapoor",
  "Randy Harrison",
  "Randy Quaid",
  "Rani Mukerji",
  "Ranveer Singh",
  "Raoul Bova",
  "Raoul Max Trujillo",
  "Raphael",
  "Raphael Alejandro",
  "Raphael Fejtö",
  "Raphael Romand",
  "Raphaël Lenglet",
  "Raphaël Personnaz",
  "Raphaëlle Agogué",
  "Rashida Jones",
  "Rashmika Mandanna",
  "Rasim Öztekin",
  "Rasmus Botoft",
  "Rasmus Hardiker",
  "Ratna Riantiarno",
  "Raul Julia",
  "Raveena Tandon",
  "Ravi Raghavendra",
  "Ravshana Kurkova",
  "Rawle D. Lewis",
  "Ray Chase",
  "Ray Liotta",
  "Ray Lovelock",
  "Ray McAnally",
  "Ray Milland",
  "Ray Panthaki",
  "Ray Sahetapy",
  "Ray Stevenson",
  "Ray Walston",
  "Ray Winstone",
  "Ray Wise",
  "Rayan Sarlak",
  "Raymond Burlet",
  "Raymond Burr",
  "Raymond J. Barry",
  "Raymond Lam",
  "Raymond Massey",
  "Raymond Mearns",
  "Raymond Pellegrin",
  "Raúl Arévalo",
  "Raúl Castillo",
  "Raúl García",
  "Raúl Jiménez",
  "Re Lee",
  "Rebecca De Mornay",
  "Rebecca Ferguson",
  "Rebecca Gayheart",
  "Rebecca Griffiths",
  "Rebecca Hall",
  "Rebecca Johnson",
  "Rebecca Pidgeon",
  "Rebecca Robin",
  "Rebecca Romijn",
  "Rebel Wilson",
  "Red Madrell",
  "Reda Kateb",
  "Reece Thompson",
  "Reese Witherspoon",
  "Regina Casé",
  "Regina Hall",
  "Regina King",
  "Regina Torné",
  "Regina Wan",
  "Reginald Gardiner",
  "Reginald Owen",
  "Reginald VelJohnson",
  "Regine Nehy",
  "Regula Grauwiller",
  "Rei Sakuma",
  "Reika Hashimoto",
  "Reika Kirishima",
  "Reiko Dan",
  "Reiko Tajima",
  "Reina Triendl",
  "Reinhardt Wetrek",
  "Reizaburô Yamamoto",
  "Remo Girone",
  "Remy Sweeney",
  "Ren Ôsugi",
  "Rena Mandel",
  "Rena Niehaus",
  "Rena Owen",
  "Renate Reinsve",
  "Renate Westphal-Lorenz",
  "Renato Carpentieri",
  "Renato Salvatori",
  "Renato Scarpa",
  "Rene Bitorajac",
  "Rene Liu",
  "Rene Russo",
  "Reni Santoni",
  "Renia Louizidou",
  "Rentarô Mikuni",
  "Renzo Cesana",
  "René Clément",
  "René Dary",
  "René Hofschneider",
  "René Lefèvre",
  "René Rupnik",
  "René van 't Hof",
  "Renée Elise Goldsberry",
  "Renée Taylor",
  "Renée Zellweger",
  "Rex Everhart",
  "Rex Harrison",
  "Reza Akhlaghirad",
  "Reza Attaran",
  "Reza Behbudi",
  "Reza Kianian",
  "Reza Rahadian",
  "Reza Rooygari",
  "Reza Saeedi",
  "Reza Sixo Safai",
  "Rhea Perlman",
  "Rhona Mitra",
  "Rhonda Fleming",
  "Rhys Ifans",
  "Rhys Mcgowan",
  "Rica Matsumoto",
  "Ricardo Abarca",
  "Ricardo Curtis",
  "Ricardo Darín",
  "Ricardo Montalban",
  "Riccardo Cucciolla",
  "Riccardo Marino",
  "Riccardo Salvino",
  "Riccardo Scamarcio",
  "Riccardo Zinna",
  "Ricci Harnett",
  "Rich Moore",
  "Richa Chadha",
  "Richard Armitage",
  "Richard Attenborough",
  "Richard Basehart",
  "Richard Berry",
  "Richard Bohringer",
  "Richard Brake",
  "Richard Briers",
  "Richard Burton",
  "Richard Courcet",
  "Richard Coyle",
  "Richard Crenna",
  "Richard Donner",
  "Richard Dormer",
  "Richard Dreyfuss",
  "Richard Durden",
  "Richard E. Grant",
  "Richard Edson",
  "Richard Elfyn",
  "Richard Farnsworth",
  "Richard Gere",
  "Richard Graham",
  "Richard Griffiths",
  "Richard Harmon",
  "Richard Harris",
  "Richard Haydn",
  "Richard Holmes",
  "Richard Hunt",
  "Richard Jaeckel",
  "Richard Jenkins",
  "Richard Johnson",
  "Richard Kiel",
  "Richard Kind",
  "Richard LaGravenese",
  "Richard Lester",
  "Richard Lewis",
  "Richard Lynch",
  "Richard Madden",
  "Richard Marcus",
  "Richard Masur",
  "Richard McCabe",
  "Richard Mylan",
  "Richard O'Brien",
  "Richard Pryor",
  "Richard Romanus",
  "Richard Schiff",
  "Richard Starzak",
  "Richard Warwick",
  "Richard Webber",
  "Richard Widmark",
  "Richard Wilson",
  "Richard van Weyden",
  "Richenda Carey",
  "Rick Collins",
  "Rick Kavanian",
  "Rick Moranis",
  "Rickson Tevez",
  "Ricky Adelayitor",
  "Ricky Gervais",
  "Ricky Nelson",
  "Rider Strong",
  "Rie Kugimiya",
  "Rie Miyazawa",
  "Rie Rasmussen",
  "Rihanna",
  "Riisa Naka",
  "Rik Mayall",
  "Rikke Louise Andersson",
  "Rila Fukushima",
  "Riley Keough",
  "Rima Te Wiata",
  "Rin Seo",
  "Rin Takanashi",
  "Rinal Mukhametov",
  "Rinaldo Smordoni",
  "Ringo Starr",
  "Rinko Kikuchi",
  "Rio Dewanto",
  "Rio Kanno",
  "Rip Torn",
  "Rishab Shetty",
  "Rita Blanco",
  "Rita Karin",
  "Rita Lengyel",
  "Rita Macedo",
  "Rita Maiden",
  "Rita Wilson",
  "Ritchie Allen",
  "Ritu Arya",
  "Ritza Brown",
  "River Drosche",
  "River Phoenix",
  "Riz Ahmed",
  "Rizz Abbasi",
  "Rob Archer",
  "Rob Brydon",
  "Rob Corddry",
  "Rob Devaney",
  "Rob Jarvis",
  "Rob Lodermeier",
  "Rob Lowe",
  "Rob Maxey",
  "Rob Minkoff",
  "Rob Reiner",
  "Rob Riggle",
  "Rob Schneider",
  "Rob Tinkler",
  "Rob Zombie",
  "Robb Wells",
  "Robbie Amell",
  "Robbie Coltrane",
  "Robbie Daymond",
  "Robby Benson",
  "Robert Agengo",
  "Robert Alda",
  "Robert Arkins",
  "Robert Armstrong",
  "Robert Arthur",
  "Robert Ayres",
  "Robert B. Martin Jr.",
  "Robert Bathurst",
  "Robert Blake",
  "Robert Capron",
  "Robert Carlyle",
  "Robert Coote",
  "Robert Culp",
  "Robert Cummings",
  "Robert Daniel Sloan",
  "Robert Davi",
  "Robert De Niro",
  "Robert Donat",
  "Robert Downey Jr.",
  "Robert Dunham",
  "Robert Duvall",
  "Robert Englund",
  "Robert Finster",
  "Robert Forster",
  "Robert Foxworth",
  "Robert Gerard Larkin",
  "Robert Gulaczyk",
  "Robert Gustafsson",
  "Robert Hays",
  "Robert Helpmann",
  "Robert Hobbs",
  "Robert Hoffman",
  "Robert Hunger-Bühler",
  "Robert James-Collier",
  "Robert John Burke",
  "Robert Joy",
  "Robert Kerman",
  "Robert Knepper",
  "Robert Levon Been",
  "Robert Liensol",
  "Robert Manuel",
  "Robert Mitchum",
  "Robert Morley",
  "Robert Pastorelli",
  "Robert Paterson",
  "Robert Patrick",
  "Robert Pattinson",
  "Robert Plagnol",
  "Robert Preston",
  "Robert Prosky",
  "Robert Pugh",
  "Robert Redford",
  "Robert Rodriguez",
  "Robert Ryan",
  "Robert Sean Leonard",
  "Robert Shaw",
  "Robert Sheehan",
  "Robert Stack",
  "Robert Stadlober",
  "Robert Stephens",
  "Robert Stevenhagen",
  "Robert Strauss",
  "Robert Toupin",
  "Robert Van Loon",
  "Robert Wagner",
  "Robert Wahlberg",
  "Robert Walker",
  "Robert Warwick",
  "Robert Webber",
  "Robert Wieckiewicz",
  "Robert Wuhl",
  "Roberta Maxwell",
  "Roberto Aguire",
  "Roberto Benigni",
  "Roberto Cobo",
  "Roberto Farías",
  "Roberto Álamo",
  "Robin Gammell",
  "Robin Tunney",
  "Robin Williams",
  "Robin Wright",
  "Rocci Williams",
  "Rocco Fasano",
  "Rocco Gioffre",
  "Rocco Papaleo",
  "Rocco Siffredi",
  "Rochelle Aytes",
  "Rock Chen",
  "Rocky Morton",
  "Rod Culbertson",
  "Rod Steiger",
  "Rod Taylor",
  "Roddy Maude-Roxby",
  "Roddy McDowall",
  "Roderic Noble",
  "Rodger Bumpass",
  "Rodney A. Grant",
  "Rodney Rothman",
  "Rodolfo Domínguez",
  "Rodolfo Sancho",
  "Rodrigo Noya",
  "Rodrigo Santoro",
  "Rodrigo Sopeña",
  "Rodrigo Sorogoyen",
  "Rodrigo de la Serna",
  "Rodrigo dos Santos",
  "Roe-ha Kim",
  "Roger Allam",
  "Roger Barclay",
  "Roger Carel",
  "Roger Craig Smith",
  "Roger Dale Floyd",
  "Roger Daltrey",
  "Roger Duchesne",
  "Roger Elwin",
  "Roger Moore",
  "Roger Pierre",
  "Roger Príncep",
  "Roger Vadim",
  "Roger Wilson",
  "Rogheih Moharami",
  "Rogério Cardoso",
  "Rohini Hattangadi",
  "Rojan Hosseini",
  "Rokkô Toura",
  "Roland Bertin",
  "Roland Blanche",
  "Roland Giraud",
  "Roland Manookian",
  "Roland Monod",
  "Roland Møller",
  "Roland Topor",
  "Rolf Hoppe",
  "Rolf Lassgård",
  "Roma Gasiorowska",
  "Romain Duris",
  "Romain Guillermic",
  "Roman Bilyk",
  "Roman Griffin Davis",
  "Roman Kettner",
  "Roman Kolinka",
  "Roman Madyanov",
  "Roman Polanski",
  "Romane Bohringer",
  "Romane Libert",
  "Romany Malco",
  "Romola Garai",
  "Romolo Valli",
  "Romy Schneider",
  "Ron Crawford",
  "Ron Fazio",
  "Ron Funches",
  "Ron Leibman",
  "Ron Livingston",
  "Ron Moody",
  "Ron Perlman",
  "Ron Thompson",
  "Rona Hartner",
  "Ronak Singh Chadha Berges",
  "Ronald Allen",
  "Ronald Zehrfeld",
  "Ronan Collins",
  "Rondel Reynoldson",
  "Ronen Rubinstein",
  "Rongguang Yu",
  "Ronit Roy",
  "Ronjini Chakraborty",
  "Ronny Cox",
  "Rooney Mara",
  "Rorke Denver",
  "Rory J Saper",
  "Rory Kinnear",
  "Rosa Maria Sardà",
  "Rosa Salazar",
  "Rosa Valetti",
  "Rosalie Chiang",
  "Rosalind Russell",
  "Rosamund Kwan",
  "Rosamund Pike",
  "Rosanna Arquette",
  "Rosario Dawson",
  "Rosario Flores",
  "Roschdy Zem",
  "Roscoe Ates",
  "Roscoe Karns",
  "Rose Byrne",
  "Rose Leslie",
  "Rose McGowan",
  "Rosel Zech",
  "Roselyn Sanchez",
  "Rosemarie DeWitt",
  "Rosemary Harris",
  "Rosemary Leach",
  "Rosemary Murphy",
  "Roshan Seth",
  "Rosie Perez",
  "Rosie Shaw",
  "Rosmel Bustamante",
  "Rospo Pallenberg",
  "Ross Stewart",
  "Rossana Ghessa",
  "Rossana Podestà",
  "Rossano Brazzi",
  "Rossif Sutherland",
  "Rossy de Palma",
  "Rostislav Khait",
  "Rouhollah Zamani",
  "Roushan Karam Elmi",
  "Rowan Atkinson",
  "Roxane Duran",
  "Roxane Mesquida",
  "Roxanne Hart",
  "Roy Atwell",
  "Roy Dotrice",
  "Roy Dupuis",
  "Roy Hyatt",
  "Roy Kinnear",
  "Roy Scheider",
  "Roya Akbari",
  "Roya Taymourian",
  "Ruby Barnhill",
  "Ruby Dee",
  "Ruby Rose",
  "Ruby Stokes",
  "Ruddy Rodríguez",
  "Rudolf Klein-Rogge",
  "Rudolf Schündler",
  "Rudolf Waldemar Brem",
  "Rudy Youngblood",
  "Rufus",
  "Rufus Beck",
  "Rufus Sewell",
  "Rufus Wright",
  "Rujun Teng",
  "Rukiya Bernard",
  "Rumi Hiiragi",
  "Ruocheng Ying",
  "Rupert Evans",
  "Rupert Everett",
  "Rupert Friend",
  "Rupert Graves",
  "Rupert Grint",
  "Russ Banham",
  "Russ Tamblyn",
  "Russell Bobbitt",
  "Russell Brand",
  "Russell Crowe",
  "Russell Hornsby",
  "Russell Means",
  "Russell Tovey",
  "Rut Reygadas",
  "Ruta Gedmintas",
  "Rutanya Alda",
  "Rutger Hauer",
  "Ruth Díaz",
  "Ruth Gordon",
  "Ruth Hussey",
  "Ruth Marini",
  "Ruth Marshall",
  "Ruth Negga",
  "Ruth Nelson",
  "Ruth Ramos",
  "Ruth Roman",
  "Ruth Sheen",
  "Ruth Wilson",
  "Ruthie Ann Miles",
  "Ruy Guerra",
  "Ryan Cartwright",
  "Ryan Donowho",
  "Ryan Findley",
  "Ryan Fleck",
  "Ryan Gosling",
  "Ryan Guzman",
  "Ryan Hawley",
  "Ryan Hurst",
  "Ryan Kwanten",
  "Ryan McCluskey",
  "Ryan O'Neal",
  "Ryan Phillippe",
  "Ryan Potter",
  "Ryan Reynolds",
  "Ryan Robbins",
  "Ryan Zheng",
  "Ryeowon Jung",
  "Ryo Hiruma",
  "Ryo Ishibashi",
  "Ryoko Sano",
  "Ryu Jun-Yeol",
  "Ryuichi Sakamoto",
  "Ryô Horikawa",
  "Ryô Kase",
  "Ryô Narita",
  "Ryô Yoshizawa",
  "Ryôga Hayashi",
  "Ryôko Hirosue",
  "Ryôsuke Yamada",
  "Ryûdô Uzaki",
  "Ryûhei Matsuda",
  "Ryûichi Kijima",
  "Ryûnosuke Kamiki",
  "Réal Bossé",
  "Rémi Laurent",
  "Rémy Girard",
  "Rötger Feldmann",
  "Rüdiger Vogler",
  "Rüya Önal",
  "Saadet Aksoy",
  "Saadna Hamoud",
  "Saara Chaudry",
  "Saba Mubarak",
  "Saba Qamar",
  "Saber Abar",
  "Sabine Azéma",
  "Sabine Timoteo",
  "Sabrina De La Hoz",
  "Sabrina Ferilli",
  "Sabrina Impacciatore",
  "Sabrina Siani",
  "Sabu Kawahara",
  "Sacha Baron Cohen",
  "Sacha Pitoëff",
  "Sachiko Murase",
  "Sacide Tasaner",
  "Sadao Abe",
  "Sadegh Safaie",
  "Sadou Teymouri",
  "Sady Rebbot",
  "Saed Soheili",
  "Saeed Changizian",
  "Saeed Ebrahimifar",
  "Saeed Jaffrey",
  "Saeed Noorullahi",
  "Saeed Poursamimi",
  "Saeed Rad",
  "Saemi Nakamura",
  "Safa Aghajani",
  "Safak Sezer",
  "Safar Ali Moradi",
  "Saffron Burrows",
  "Sagamore Stévenin",
  "Sagarika Ghatge",
  "Sahar Dolatshahi",
  "Sahar Goldust",
  "Said Mohamadi",
  "Said Oveissi",
  "Saif Ali Khan",
  "Saifei He",
  "Sajjad Dolati",
  "Sakina Jaffrey",
  "Sakshi Tanwar",
  "Sakura Andô",
  "Sakura Kiryu",
  "Sal Mineo",
  "Salameh Feyzi",
  "Salih Kalyon",
  "Salim Kechiouche",
  "Sally Ann Howes",
  "Sally Field",
  "Sally Hawkins",
  "Sally Messham",
  "Sally Phillips",
  "Sally Yeh",
  "Salma Hayek",
  "Salman Khan",
  "Salvador Wood",
  "Salvatore Basile",
  "Salvatore D'Onofrio",
  "Salvatore Esposito",
  "Salvo Randone",
  "Sam Berry",
  "Sam Claflin",
  "Sam Derakhshani",
  "Sam Elliott",
  "Sam Fell",
  "Sam Hazeldine",
  "Sam Heughan",
  "Sam J. Jones",
  "Sam Jaffe",
  "Sam Neill",
  "Sam Reid",
  "Sam Riley",
  "Sam Robards",
  "Sam Rockwell",
  "Sam Shepard",
  "Sam Spruell",
  "Sam Troughton",
  "Sam Wanamaker",
  "Sam Waterston",
  "Sam Wood",
  "Sam Worthington",
  "Samaire Armstrong",
  "Samantha Eggar",
  "Samantha Isler",
  "Samantha Logan",
  "Samantha Mathis",
  "Samantha Morton",
  "Samara Weaving",
  "Sami Bouajila",
  "Sami Frey",
  "Sami Slimane",
  "Samia Kerbash",
  "Samir Decazza",
  "Samir Guesmi",
  "Samira Makhmalbaf",
  "Sammo Kam-Bo Hung",
  "Samuel Anderson",
  "Samuel Armstrong",
  "Samuel Bottomley",
  "Samuel Gauthier",
  "Samuel L. Jackson",
  "Samuel Le Bihan",
  "Samuel Leakey",
  "Samuel Roukin",
  "Samy Naceri",
  "Sanaa Lathan",
  "Sancho Gracia",
  "Sandahl Bergman",
  "Sandra Bernhard",
  "Sandra Bullock",
  "Sandra Escacena",
  "Sandra Hüller",
  "Sandra Milo",
  "Sandra Oh",
  "Sandra Toffolatti",
  "Sandrine Bonnaire",
  "Sandrine Kiberlain",
  "Sandrinna Michelle",
  "Sandro Dori",
  "Sandy Dennis",
  "Sangeetha Sringeri",
  "Sangili Murugan",
  "Sangjin Kim",
  "Sanjar Madi",
  "Sanjay Dutt",
  "Sanjay Mishra",
  "Sanjeev Bhaskar",
  "Sanjeev Kumar",
  "Sanming Han",
  "Santa Relli",
  "Santi Millán",
  "Santi Prego",
  "Santiago Armas Estevarena",
  "Santiago Cabrera",
  "Santiago Molero",
  "Santiago Segura",
  "Santo Silvestre",
  "Santos",
  "Sanya Malhotra",
  "Saoirse Ronan",
  "Saori Hayami",
  "Sapthami Gowda",
  "Sara Bahrami",
  "Sara Bernardo Aires",
  "Sara Canning",
  "Sara Casasnovas",
  "Sara Cosmi",
  "Sara Forestier",
  "Sara Kestelman",
  "Sara Ricci",
  "Sara Serraiocco",
  "Sara Sohn",
  "Sara Sommerfeld",
  "Sara Takatsuki",
  "Sarah Bolger",
  "Sarah Carter",
  "Sarah Dawn Pledge",
  "Sarah Gadon",
  "Sarah Goldberg",
  "Sarah Henderson",
  "Sarah Houbolt",
  "Sarah Jessica Parker",
  "Sarah Juel Werner",
  "Sarah Kazemy",
  "Sarah Lind",
  "Sarah Manninen",
  "Sarah McElligott",
  "Sarah Michelle Gellar",
  "Sarah Miles",
  "Sarah Natochenny",
  "Sarah Paulson",
  "Sarah Polley",
  "Sarah Roemer",
  "Sarah Silverman",
  "Sarah Wayne Callies",
  "Sarah-Jane Potts",
  "Sarain Boylan",
  "Sarala Kariyawasam",
  "Sareh Bayat",
  "Sari Lennick",
  "Sarita Choudhury",
  "Sarp Apak",
  "Sascha Alexander",
  "Sasha Lane",
  "Sasha Luss",
  "Saskia Mulder",
  "Saskia Rosendahl",
  "Satomi Hanamura",
  "Satomi Ishihara",
  "Satomi Kobayashi",
  "Satomi Tezuka",
  "Saturnin Fabre",
  "Saurabh Shukla",
  "Savas Dinçel",
  "Saveliy Kramarov",
  "Saverio Vallone",
  "Sawanee Utoomma",
  "Saxon Sharbino",
  "Sayaka Maeda",
  "Sayed Ahmad",
  "Saïd Taghmaoui",
  "Scarlett Johansson",
  "Scatman Crothers",
  "Scoot McNairy",
  "Scot Williams",
  "Scott Adkins",
  "Scott Adsit",
  "Scott Baio",
  "Scott Bakula",
  "Scott Eastwood",
  "Scott Glenn",
  "Scott H. Reiniger",
  "Scott Hazell",
  "Scott Hylands",
  "Scott MacDonald",
  "Scott Moore",
  "Scott Neal",
  "Scott Patterson",
  "Scott Porter",
  "Scott Schwartz",
  "Scott Speedman",
  "Scott Waugh",
  "Scott Weinger",
  "Scott Wilson",
  "Scott Wolf",
  "Se-Yeon Jin",
  "Se-beom Park",
  "Seamus Davey-Fitzpatrick",
  "Sean Astin",
  "Sean Bean",
  "Sean Biggerstaff",
  "Sean Bott",
  "Sean Bridgers",
  "Sean Chapman",
  "Sean Connery",
  "Sean Cw Johnson",
  "Sean Daniel",
  "Sean Harris",
  "Sean Kenin",
  "Sean Lynch",
  "Sean Patrick Flanery",
  "Sean Patrick Thomas",
  "Sean Penn",
  "Sean Pertwee",
  "Sean Rogerson",
  "Sean Sullivan",
  "Sean Young",
  "Seann William Scott",
  "Sebastian Armesto",
  "Sebastian Blomberg",
  "Sebastian Bull",
  "Sebastian Cabot",
  "Sebastian Jessen",
  "Sebastian Koch",
  "Sebastian Maniscalco",
  "Sebastian Pigott",
  "Sebastian Rudolph",
  "Sebastian Schipper",
  "Sebastian Stan",
  "Sebastian Stankiewicz",
  "Sebastiano Nardone",
  "Sebastien Sisak",
  "Sebastián Aguirre",
  "Sebnem Dönmez",
  "Secun de la Rosa",
  "Seda Bakan",
  "Sedigheh Kianfar",
  "Seema Biswas",
  "Sei Ashina",
  "Seidi Haarla",
  "Seiichi Tanabe",
  "Seiko Takuma",
  "Seinosuke Nagayoshi",
  "Sela Ward",
  "Selan Karay",
  "Selen Öztürk",
  "Selena Gomez",
  "Selim Demirdelen",
  "Selim Nasit",
  "Selma Blair",
  "Selton Mello",
  "Sema Özcan",
  "Semra Özdamar",
  "Semyon Svashenko",
  "Sener Sen",
  "Sennia Nanua",
  "Senta Berger",
  "Seo Dong-soo",
  "Seo Ji-hye",
  "Seo Ye-Ji",
  "Seo-hyun Ahn",
  "Seohyun",
  "Seok-Won Jeong",
  "Seol-Hyun Kim",
  "Seon-jin Lee",
  "Seong-Yeon Kang",
  "Seong-guk Choi",
  "Seong-hun Cheon",
  "Seong-hwan Jeon",
  "Seong-jin Kang",
  "Seong-won Ji",
  "Serafima Birman",
  "Serdar Orçin",
  "Serena Grandi",
  "Serenay Sarikaya",
  "Serge Hazanavicius",
  "Serge Jaswal",
  "Serge Kanyinda",
  "Serge Reggiani",
  "Serge Riaboukine",
  "Serge Sauvion",
  "Sergei Goroshko",
  "Sergey Agafonov",
  "Sergey Bezrukov",
  "Sergey Bodrov",
  "Sergey Bondarchuk",
  "Sergey Dreyden",
  "Sergey Filippov",
  "Sergey Garmash",
  "Sergey Gorobchenko",
  "Sergey Kempo",
  "Sergey Makovetskiy",
  "Sergey Puskepalis",
  "Sergey Yakovlev",
  "Sergey Yurskiy",
  "Sergi López",
  "Sergi Martin",
  "Sergio Albelli",
  "Sergio Castellanos",
  "Sergio Castellitto",
  "Sergio Corrieri",
  "Sergio Hernández",
  "Sergio Kleiner",
  "Sergio Rubini",
  "Serhan Ergören",
  "Serhat Mustafa Kiliç",
  "Serhat Tutumluer",
  "Serif Bozkurt",
  "Serif Erol",
  "Serif Sezer",
  "Serkan Keskin",
  "Sermin Hürmeriç",
  "Sermiyan Midyat",
  "Serra Yilmaz",
  "Sessue Hayakawa",
  "Setareh Pesyani",
  "Seth MacFarlane",
  "Seth Rogen",
  "Setsuko Hara",
  "Setsuko Wakayama",
  "Settar Tanriögen",
  "Setti Ramdane",
  "Seu Jorge",
  "Seung-Hoon Choi",
  "Seung-Hyun Choi",
  "Seung-Won Cha",
  "Seung-Wook Lee",
  "Seung-Yun Lee",
  "Seung-beom Ryu",
  "Seung-ryong Ryu",
  "Seung-su Ryu",
  "Seung-wan Ryu",
  "Seung-woo Kim",
  "Sevket Altug",
  "Seymour Cassel",
  "Sezai Paracikoglu",
  "Seána Kerslake",
  "Shabnam Moghadami",
  "Shabnam Toloui",
  "Shafik Ahmad",
  "Shaghayeh Djodat",
  "Shah Rukh Khan",
  "Shahab Ebrahimi",
  "Shahab Hosseini",
  "Shahid Kapoor",
  "Shahr Banou Sisizadeh",
  "Shahram Vaziri",
  "Shahrokh Foroutanian",
  "Shailene Woodley",
  "Shalom Harlow",
  "Shameik Moore",
  "Shamier Anderson",
  "Shane",
  "Shane Brolly",
  "Shane Kippel",
  "Shane West",
  "Shangqing Su",
  "Shani Wallis",
  "Shanice Banton",
  "Shannon Elizabeth",
  "Shannyn Sossamon",
  "Shaofeng Feng",
  "Shareefa Daanish",
  "Sharif Atkins",
  "Sharlto Copley",
  "Sharman Joshi",
  "Sharni Vinson",
  "Sharon Acker",
  "Sharon Gans",
  "Sharon Percy",
  "Sharon Small",
  "Sharon Stone",
  "Shaun Benson",
  "Shaun Evans",
  "Shaun Parkes",
  "Shaun Scott",
  "Shaun Sipos",
  "Shaun Toub",
  "Shauna Macdonald",
  "Shawn Ashmore",
  "Shawn C. Phillips",
  "Shawn Dou",
  "Shawn Driscoll",
  "Shawn Roberts",
  "Shawn Wayans",
  "Shawn Yue",
  "Shawnee Smith",
  "Shayesteh Irani",
  "Shea Adams",
  "Shea Whigham",
  "Sheb Wooley",
  "Sheetal Menon",
  "Shefali Shah",
  "Sheik Razak Shikur",
  "Sheila Kelley",
  "Sheila Vand",
  "Shelley Duvall",
  "Shelley Hack",
  "Shelley Long",
  "Shelley Thompson",
  "Shelley Winters",
  "Shengyi Huang",
  "Shenina Cinnamon",
  "Sheri Moon Zombie",
  "Sherilyn Fenn",
  "Shernaz Patel",
  "Sherry Miller",
  "Sheryl Lee",
  "Shi-hoo Park",
  "Shia LaBeouf",
  "Shigeo Ôsako",
  "Shigeru Muroi",
  "Shigesato Itoi",
  "Shilpa Shukla",
  "Shim Eun-kyung",
  "Shima Iwashita",
  "Shima Niavarani",
  "Shin Ha-kyun",
  "Shin Jeong-geun",
  "Shin Min-a",
  "Shin'ichi Himori",
  "Shin'ichi Tsutsumi",
  "Shin'ya Fukumatsu",
  "Shin'ya Tsukamoto",
  "Shinchô Kokontei",
  "Shinji Higuchi",
  "Shinji Takeda",
  "Shinnosuke Mitsushima",
  "Shinobu Terajima",
  "Shinpachi Tsuji",
  "Shinsuke Minami",
  "Shintarô Ôhata",
  "Shirin Yazdanbakhsh",
  "Shirley Eaton",
  "Shirley Henderson",
  "Shirley MacLaine",
  "Shirô Moritani",
  "Shirô Sano",
  "Shiv Panditt",
  "Shohreh Aghdashloo",
  "Shoja Azari",
  "Shouta Kitamoto",
  "Show Hayami",
  "Show Lo",
  "Shraddha Kapoor",
  "Shraddha Srinath",
  "Shu Qi",
  "Shulan Pan",
  "Shun Oguri",
  "Shôgen Nitta",
  "Shôhei Hino",
  "Shôji Yasui",
  "Shôsuke Tanihara",
  "Shôta Sometani",
  "Shôtarô Morikubo",
  "Shôzô Îzuka",
  "Shûe Matsubayashi",
  "Shûgo Oshinari",
  "Shûichirô Moriyama",
  "Shûkô Murase",
  "Si Won Choi",
  "Sia",
  "Siamak Adib",
  "Siamak Ansari",
  "Siamak Safari",
  "Siavash Cheraghi Pour",
  "Sibel Kekilli",
  "Sibel Tasçioglu",
  "Siddhant Chaturvedi",
  "Siddharth",
  "Sidede Onyulo",
  "Sidharth Malhotra",
  "Sidney Blackmer",
  "Sidney Flanigan",
  "Sidney Poitier",
  "Sidse Babett Knudsen",
  "Sidsel Siem Koch",
  "Siegfried Lowitz",
  "Sienna Guillory",
  "Sienna Miller",
  "Sifan Shao",
  "Sigfrit Steiner",
  "Sigi Wimala",
  "Sigourney Weaver",
  "Sigrid Neiiendam",
  "Silvana Corsini",
  "Silvana Mangano",
  "Silvero Pereira",
  "Silvia Calderoni",
  "Silvia Colloca",
  "Silvia Monti",
  "Silvia Pinal",
  "Silvia Rey",
  "Silvia Ryder",
  "Silvio Muccino",
  "Silvio Orlando",
  "Sima Mobarak-Shahi",
  "Simon Abkarian",
  "Simon Baker",
  "Simon Bird",
  "Simon Callow",
  "Simon Helberg",
  "Simon McBurney",
  "Simon Pegg",
  "Simon Rex",
  "Simon Reynolds",
  "Simon Russell Beale",
  "Simon Schwarz",
  "Simon Sears",
  "Simon Shepherd",
  "Simon Wells",
  "Simon Yam",
  "Simona Stasová",
  "Simone Bucio",
  "Simone Mattioli",
  "Simone Signoret",
  "Simone Simon",
  "Simone Susinna",
  "Simran",
  "Sinan Tuzcu",
  "Sinan Çaliskanoglu",
  "Sinéad Cusack",
  "Siobhan Fallon Hogan",
  "Siobhan Finneran",
  "Sirani Yankittikan",
  "Sirius Flatz",
  "Sirri Süreyya Önder",
  "Sisca Jessica",
  "Sisqó",
  "Sissy Priscillia",
  "Sissy Spacek",
  "Sistine Rose Stallone",
  "Siu Chung Mok",
  "Siu Ping-Lam",
  "Siu-Lung Leung",
  "Siu-Wong Fan",
  "Sixtine Murat",
  "Sizo Mahlangu",
  "Skandar Keynes",
  "Sky du Mont",
  "Skye McCole Bartusiak",
  "Skyler Shaye",
  "Slavko Labovic",
  "Slavko Stimac",
  "Slim Pickens",
  "Slim Twig",
  "Smith Seth",
  "Snoop Dogg",
  "So Ji-seob",
  "So-Hyun Gam",
  "Sofia Boutella",
  "Sofia Buenaventura",
  "Sofia Helin",
  "Sofia Milos",
  "Sofia Rosinsky",
  "Sofia Scalia",
  "Sofian Khammes",
  "Sofie Gråbøl",
  "Sofìa Carrillo",
  "Soghra Obeisi",
  "Soha Ali Khan",
  "Sohail Khan",
  "Soheil Parsa",
  "Sohum Shah",
  "Soia Lira",
  "Sol Kyung-gu",
  "Soledad Miranda",
  "Soledad Villamil",
  "Soleil McGhee",
  "Solomon Perel",
  "Solveig Dommartin",
  "Solvi Stubing",
  "Soma Chhaya",
  "Son Hyeon-ju",
  "Son Ye-jin",
  "Sonali Bendre",
  "Sonam Kapoor",
  "Sondra Locke",
  "Song Boo-gun",
  "Song Ji-Hyo",
  "Song Joong-ki",
  "Song Kang-ho",
  "Song Wok-suk",
  "Song Young-chang",
  "Songül Öden",
  "Sonia Bergamasco",
  "Sonia Braga",
  "Sonia Méndez",
  "Sonia Todd",
  "Sonja Richter",
  "Sonja Smits",
  "Sonoya Mizuno",
  "Sonsoles Aranguren",
  "Sonya Priss",
  "Soo Ae",
  "Soo-Ro Kim",
  "SooJin Jeong",
  "Sope Dirisu",
  "Sophia Ally",
  "Sophia Bush",
  "Sophia Di Martino",
  "Sophia Lillis",
  "Sophia Loren",
  "Sophia Myles",
  "Sophie Cattani",
  "Sophie Guillemin",
  "Sophie Kennedy Clark",
  "Sophie Marceau",
  "Sophie McShera",
  "Sophie Nélisse",
  "Sophie Okonedo",
  "Sophie Perry",
  "Sophie Rois",
  "Sophie Thatcher",
  "Sora Amamiya",
  "Sota Aoyama",
  "Soudabeh Beizaee",
  "Souheila Yacoub",
  "Spencer Locke",
  "Spencer Reinhard",
  "Spencer Rocco Lofranco",
  "Spencer Tracy",
  "Spencer Wilding",
  "Spike Lee",
  "Srdjan 'Zika' Todorovic",
  "Sridevi",
  "Srinidhi Shetty",
  "St. Vincent",
  "Stacey LaBerge",
  "Stacy Edwards",
  "Stacy Keach",
  "Stacy Martin",
  "Stan Collymore",
  "Stan Shaw",
  "Stanislas Carré de Malberg",
  "Stanislas Merhar",
  "Stanislav Bilyi",
  "Stanislav Lyubshin",
  "Stanislaw Cywka",
  "Stanislaw Tym",
  "Stanley Baker",
  "Stanley Holloway",
  "Stanley Tucci",
  "Stark Sands",
  "Stavros Psyllakis",
  "Steeve Léonard",
  "Stefan Czyzewski",
  "Stefan Fjeldmark",
  "Stefan Gebelhoff",
  "Stefan Güttler",
  "Stefan Konarske",
  "Stefan Larsson",
  "Stefan Velniciuc",
  "Stefania Casini",
  "Stefania D'Amario",
  "Stefania Iwinska",
  "Stefania LaVie Owen",
  "Stefania Rocca",
  "Stefania Sandrelli",
  "Stefanie Martini",
  "Stefanie Scott",
  "Stefano Accorsi",
  "Stefano Ambrogi",
  "Stefano Dionisi",
  "Stefanía Koessl",
  "Steffen Scheumann",
  "Steffen Zacharias",
  "Stella Dassas",
  "Stellan Skarsgård",
  "Stepan Krylov",
  "Stepan Shkurat",
  "Stephan James",
  "Stephanie Hsu",
  "Stephanie Lane",
  "Stephanie Leonidas",
  "Stephanie Moore",
  "Stephanie Morgenstern",
  "Stephanie Sigman",
  "Stephen Baldwin",
  "Stephen Boyd",
  "Stephen Campbell Moore",
  "Stephen Chow",
  "Stephen Dillane",
  "Stephen Dorff",
  "Stephen Eric McIntyre",
  "Stephen Fry",
  "Stephen Graham",
  "Stephen Hogan",
  "Stephen Knox",
  "Stephen Lack",
  "Stephen Lang",
  "Stephen McHattie",
  "Stephen McKinley Henderson",
  "Stephen Merchant",
  "Stephen O'Reilly",
  "Stephen Rea",
  "Stephen Root",
  "Stephen Tobolowsky",
  "Stephen Tompkinson",
  "Stephen Trask",
  "Stephen Young",
  "Sterling Hayden",
  "Sterling K. Brown",
  "Sterling Knight",
  "Steve Blum",
  "Steve Buscemi",
  "Steve Carell",
  "Steve Coogan",
  "Steve Howey",
  "Steve Martin",
  "Steve McQueen",
  "Steve Oram",
  "Steve Pemberton",
  "Steve Railsback",
  "Steve Whitmire",
  "Steve Zahn",
  "Steven Bauer",
  "Steven Berkoff",
  "Steven Foster",
  "Steven Hauck",
  "Steven Hoban",
  "Steven Kostanski",
  "Steven Mackintosh",
  "Steven Seagal",
  "Steven Strait",
  "Steven Vlahos",
  "Steven Yeun",
  "Stewart Porter",
  "Stine Fischer Christensen",
  "Stine Stengade",
  "Stipe Erceg",
  "Strathford Hamilton",
  "Strother Martin",
  "Stuart Graham",
  "Stuart Margolin",
  "Stuart Ortiz",
  "Stuart Robinson",
  "Stuart Townsend",
  "Stuart Wilson",
  "Stuart Wolfenden",
  "Stéfan Godin",
  "Stéfi Celma",
  "Stéphane Aubier",
  "Stéphane Audran",
  "Stéphane De Groodt",
  "Stéphane Foenkinos",
  "Stéphane Freiss",
  "Stéphane Rideau",
  "Stéphane Rousseau",
  "Stéphane Varupenne",
  "Stéphanie Lagarde",
  "Stéphanie Papanian",
  "Su Elliot",
  "Su-an Kim",
  "Subir Banerjee",
  "Sue Dahlman",
  "Sue Lyon",
  "Suki Waterhouse",
  "Sukku Son",
  "Suliane Brahim",
  "Sully Boyar",
  "Sumanth",
  "Sumi Mutoh",
  "Sumi Shimamoto",
  "Sumiko Sakamoto",
  "Sumita Sanyal",
  "Summer H. Howell",
  "Sun-yeong Ahn",
  "Sung Jun",
  "Sung-Jae Lee",
  "Sung-Ki Ahn",
  "Sung-Woo Bae",
  "Sung-Wook Eo",
  "Sung-chun Han",
  "Sung-jun Yoo",
  "Suniel Shetty",
  "Sunny Pang",
  "Sunny Pawar",
  "Sunnyi Melles",
  "Suraj Sharma",
  "Suriya",
  "Sus Noreen Jondahl Wilkins",
  "Susan Brown",
  "Susan Clark",
  "Susan Fleetwood",
  "Susan George",
  "Susan Harrison",
  "Susan Kelechi Watson",
  "Susan Loughnane",
  "Susan Lynch",
  "Susan Sarandon",
  "Susan Traylor",
  "Susana Abaitua",
  "Susanna Martinková",
  "Susanna Pasolini",
  "Susanne Lothar",
  "Susanne Wuest",
  "Sushant Singh Rajput",
  "Susi Sánchez",
  "Susumu Terajima",
  "Suzane Carvalho",
  "Suzanna Hamilton",
  "Suzanne Clément",
  "Suzanne Coote",
  "Suzanne Pleshette",
  "Suzu Hirose",
  "Suzy Delair",
  "Suzy Kendall",
  "Sven Pippig",
  "Sven-Olof Bern",
  "Sverre Anker Ousdal",
  "Sverrir Gudnason",
  "Svetlana Ivanova",
  "Svetlana Khodchenkova",
  "Svetlana Nemolyaeva",
  "Svetlana Pismichenko",
  "Svieta Mikalishina",
  "Swann Arlaud",
  "Swoosie Kurtz",
  "Sybil Danning",
  "Sybille Schmitz",
  "Sydney Park",
  "Sydney Pollack",
  "Sydney Wade",
  "Sylvain Chomet",
  "Sylvester Groth",
  "Sylvester Stallone",
  "Sylvia Chang",
  "Sylvia Hoeks",
  "Sylvia Kristel",
  "Sylvia Marriott",
  "Sylvia Miles",
  "Sylvia Soska",
  "Sylvie Testud",
  "Sylvie Van den Elsen",
  "Synnøve Macody Lund",
  "Syuleyman Alilov Letifov",
  "Szymon Piotr Warszawski",
  "Sébastien Huberdeau",
  "Sébastien Pouderoux",
  "Sô Yamamura",
  "Sôta Fukushi",
  "Søren Malling",
  "Søren Pilmark",
  "Søren Sætter-Lassen",
  "Sükran Ovali",
  "Süleyman Tekcan",
  "Sümer Tilmaç",
  "T. Hee",
  "T.I.",
  "T.J. Miller",
  "T.P. McKenna",
  "Taapsee Pannu",
  "Tabu",
  "Tadanobu Asano",
  "Tadashi Okuno",
  "Tadeusz Gwiazdowski",
  "Tadeusz Janczar",
  "Tadeusz Kondrat",
  "Tadeusz Lomnicki",
  "Tae-Hyun Cha",
  "Tae-goo Eom",
  "Tae-hwa Seo",
  "Tahar Rahim",
  "Tahmineh Normatova",
  "Taika Waititi",
  "Taito Ban",
  "Tak Sakaguchi",
  "Taka Guadalcanal",
  "Takahiro Sakurai",
  "Takahiro Satô",
  "Takahiro Yokokawa",
  "Takako Matsu",
  "Takao Osawa",
  "Takashi Fujii",
  "Takashi Miike",
  "Takashi Naitô",
  "Takashi Shimura",
  "Takashi Tachibana",
  "Takayuki Hamatsu",
  "Takayuki Yamada",
  "Takehiro Hira",
  "Takehiro Murata",
  "Takehito Koyasu",
  "Takeru Satoh",
  "Takeshi Aono",
  "Takeshi Kaneshiro",
  "Takeshi Kitano",
  "Takeshi Kusao",
  "Takeshi Nozue",
  "Takeshi Wakamatsu",
  "Takuya Ishida",
  "Takuya Kimura",
  "Takuya Satô",
  "Talat Bulut",
  "Talia Ryder",
  "Talia Shire",
  "Talisa Soto",
  "Talulah Riley",
  "Tamannaah Bhatia",
  "Tamao Satô",
  "Tamar Novas",
  "Tamara Arciuch",
  "Tamara Garina",
  "Tamara Yazbek",
  "Tameka Empson",
  "Tamer Hassan",
  "Tamer Karadagli",
  "Tamio Kawaji",
  "Tamio Ôki",
  "Tamiyo Kusakari",
  "Tammin Sursok",
  "Tammy Blanchard",
  "Tammy Davis",
  "Tamsin Egerton",
  "Tamás Lengyel",
  "Tana Schémbori",
  "Tandi Wright",
  "Taner Birsel",
  "Tang Wei",
  "Tanja Petrovsky",
  "Tanja Wenzel",
  "Tannaz Tabatabaei",
  "Tansu Biçer",
  "Tanya Roberts",
  "Tao Guo",
  "Tao Okamoto",
  "Tao Yin",
  "Tao Zhao",
  "Tara Basro",
  "Tara Elders",
  "Tara Ellis",
  "Tara Fitzgerald",
  "Tara Morice",
  "Tara Reid",
  "Taraji P. Henson",
  "Taraneh Alidoosti",
  "Tardu Flordun",
  "Tarek Boudali",
  "Tarek Zayat",
  "Tarik Akan",
  "Taron Egerton",
  "Tarun Arora",
  "Tasha de Vasconcelos",
  "Tate Donovan",
  "Tatiana Astengo",
  "Tatiana Maslany",
  "Tatjana Simic",
  "Tatjana Trieb",
  "Tatsuya Fuji",
  "Tatsuya Fujiwara",
  "Tatsuya Gashûin",
  "Tatsuya Mihashi",
  "Tatsuya Nakadai",
  "Tatsuyoshi Ehara",
  "Tatum O'Neal",
  "Tatyana Kanavka",
  "Tatyana Samoylova",
  "Taye Diggs",
  "Taylor Baruchel",
  "Taylor Kitsch",
  "Taylor Russell",
  "Taylor Schilling",
  "Taylor Swift",
  "Tchéky Karyo",
  "Tea Falco",
  "Tecla Scarano",
  "Ted Dubost",
  "Ted Ludzik",
  "Telly Savalas",
  "Temuera Morrison",
  "Tenoch Huerta",
  "Tensai Okamura",
  "Teo García",
  "Teo Planell",
  "Teo Yoo",
  "Teodor Corban",
  "Terele Pávez",
  "Terence Alexander",
  "Terence Hill",
  "Terence Stamp",
  "Teresa Ann Savoy",
  "Teresa Gimpera",
  "Teresa Izewska",
  "Teresa Mo",
  "Teresa Palmer",
  "Teresa Ruiz",
  "Teresa Saponangelo",
  "Teresa Weißbach",
  "Teresa Wright",
  "Teri Garr",
  "Teri Hatcher",
  "Terrence Howard",
  "Terry Crews",
  "Terry Farrell",
  "Terry Gilliam",
  "Terry Jones",
  "Terry Notary",
  "Terry O'Quinn",
  "Terry Pheto",
  "Terry Schonblum",
  "Terry Stone",
  "Teruyuki Kagawa",
  "Tess Amorim",
  "Tessa Bouché",
  "Tessa Ia",
  "Tessa Mitchell",
  "Tessa Thompson",
  "Tesshô Genda",
  "Tetsu Sawaki",
  "Tetsu Watanabe",
  "Tetsurô Araki",
  "Tetsurô Tanba",
  "Tetsuya Watari",
  "Tetta Sugimoto",
  "Teyonah Parris",
  "Thandiwe Newton",
  "Thardelly Lima",
  "The Great Gidayû",
  "The Philadelphia Orchestra",
  "The Vicious Brothers",
  "Thelma Ritter",
  "Thelma Tixou",
  "Theo James",
  "Theo Trebs",
  "Theodor Loos",
  "Theodore Liscinski",
  "Theresa Russell",
  "Therese Damsgaard",
  "Therese Giehse",
  "Thi Hai Yen Do",
  "Thibault Verhaeghe",
  "Thierry Lhermitte",
  "Thierry Neuvic",
  "Thierry van Werveke",
  "Thobias Thorwid",
  "Thom Hoffman",
  "Thomas Aquino",
  "Thomas Bo Larsen",
  "Thomas Brodie-Sangster",
  "Thomas D. Mahard",
  "Thomas Doret",
  "Thomas Fritsch",
  "Thomas Gioria",
  "Thomas Guard",
  "Thomas Jane",
  "Thomas Kretschmann",
  "Thomas Langmann",
  "Thomas Loibl",
  "Thomas M. Köppl",
  "Thomas M. Wright",
  "Thomas Mann",
  "Thomas Middleditch",
  "Thomas Mitchell",
  "Thomas Prenn",
  "Thomas Reiner",
  "Thomas Schmuckert",
  "Thomas Schubert",
  "Thomas Thieme",
  "Thomas Trabacchi",
  "Thomas Turgoose",
  "Thomasin McKenzie",
  "Thora Birch",
  "Thorbjørn Christoffersen",
  "Thorkild Roose",
  "Thure Lindhardt",
  "Thuy An Luu",
  "Théodore Pellerin",
  "Thérèse Liotard",
  "Tia Lynn Ballard",
  "Tia Texada",
  "Tian Jing",
  "Tianyuan Huang",
  "Tiffany Haddish",
  "Tiffany Paulsen",
  "Tijmen Govaerts",
  "Tijuana Ricks",
  "Tika Panggabean",
  "Tika Sumpter",
  "Tikhon Zhiznevskiy",
  "Til Schweiger",
  "Tilda Swinton",
  "Tim Allen",
  "Tim Blake Nelson",
  "Tim Curry",
  "Tim Eddis",
  "Tim Hill",
  "Tim Holt",
  "Tim Key",
  "Tim Matheson",
  "Tim McGraw",
  "Tim McInnerny",
  "Tim Oliver Schultz",
  "Tim Pigott-Smith",
  "Tim Robbins",
  "Tim Roth",
  "Tim Thomerson",
  "Timmy Xu",
  "Timo Lavikainen",
  "Timo Tjahjanto",
  "Timothy Bottoms",
  "Timothy Dalton",
  "Timothy Hutton",
  "Timothy Olyphant",
  "Timothy Radford",
  "Timothy Spall",
  "Timothy Van Patten",
  "Timothée Chalamet",
  "Timuçin Esen",
  "Tina Aumont",
  "Tina Fey",
  "Tina French",
  "Tina Ruland",
  "Tina Tamashiro",
  "Tino Carraro",
  "Tintrinai Thikhasuk",
  "Tiny Sandford",
  "Tinì Cansino",
  "Tio Pakusadewo",
  "Tippi Hedren",
  "Tisa Farrow",
  "Tisca Chopra",
  "Tissa Biani Azzahra",
  "Titi Kamal",
  "Titos Vandis",
  "Tituss Burgess",
  "Tiziana Lodato",
  "Tobey Maguire",
  "Tobias Mehler",
  "Tobias Moretti",
  "Tobias Schenke",
  "Tobin Bell",
  "Toby Froud",
  "Toby Hemingway",
  "Toby Jones",
  "Toby Kebbell",
  "Toby Leonard Moore",
  "Toby Stephens",
  "Todd Armstrong",
  "Todd Caldecott",
  "Todd Charmont",
  "Todd Field",
  "Todd Jensen",
  "Todd Louiso",
  "Todd Stashwick",
  "Togo Igawa",
  "Tokiko Katô",
  "Tolga Tekin",
  "Tolga Çevik",
  "Tom",
  "Tom Arnold",
  "Tom Bateman",
  "Tom Berenger",
  "Tom Burke",
  "Tom Cavanagh",
  "Tom Chapin",
  "Tom Conti",
  "Tom Costello",
  "Tom Courtenay",
  "Tom Cruise",
  "Tom Cullen",
  "Tom Davis",
  "Tom Everett Scott",
  "Tom Fisher",
  "Tom Georgeson",
  "Tom Glynn-Carney",
  "Tom Gramenz",
  "Tom Guiry",
  "Tom Hanks",
  "Tom Hardy",
  "Tom Helmore",
  "Tom Hiddleston",
  "Tom Holland",
  "Tom Hollander",
  "Tom Hopper",
  "Tom Hughes",
  "Tom Hulce",
  "Tom Kenny",
  "Tom McCamus",
  "Tom Mison",
  "Tom Murray",
  "Tom Noonan",
  "Tom Prior",
  "Tom Schilling",
  "Tom Sizemore",
  "Tom Skerritt",
  "Tom Sturridge",
  "Tom Tykwer",
  "Tom Waits",
  "Tom Welling",
  "Tom Wilkinson",
  "Tom Wilson",
  "Tom Wlaschiha",
  "Toma Cuzin",
  "Tomas Arana",
  "Tomas Milian",
  "Tomas Villum Jensen",
  "Tomasz Karolak",
  "Tomasz Kot",
  "Tomasz Mandes",
  "Tomasz Schuchardt",
  "Tomasz Zietek",
  "Tomer Sisley",
  "Tomisaburô Wakayama",
  "Tommaso Di Cola",
  "Tommaso Ragno",
  "Tommi Eronen",
  "Tommy Chong",
  "Tommy Lee Jones",
  "Tomoka Kurotani",
  "Tomokazu Miura",
  "Tomokazu Seki",
  "Tomokazu Sugita",
  "Tomoko Mayama",
  "Tomoko Yamaguchi",
  "Tomorô Taguchi",
  "Tomotaka Shibayama",
  "Tomoya Nagase",
  "Tomoyo Kurosawa",
  "Toni Collette",
  "Toni Servillo",
  "Toni Sevilla",
  "Tonpei Hidari",
  "Tony Chiu-Wai Leung",
  "Tony Curran",
  "Tony Curtis",
  "Tony D'Amario",
  "Tony Dalton",
  "Tony Goldwyn",
  "Tony Hale",
  "Tony Jaa",
  "Tony Ka Fai Leung",
  "Tony Leblanc",
  "Tony Lo Bianco",
  "Tony Moran",
  "Tony Musante",
  "Tony Roberts",
  "Tony Shalhoub",
  "Tony Todd",
  "Tony Wager",
  "Topher Grace",
  "Topol",
  "Torbjörn Fahlström",
  "Torge Oelrich",
  "Torkel Petersson",
  "Torsten Künstler",
  "Tory Kittles",
  "Toshie Negishi",
  "Toshiko Fujita",
  "Toshio Furukawa",
  "Toshio Masuda",
  "Toshirô Mifune",
  "Toshirô Yanagiba",
  "Toshiyuki Morikawa",
  "Toshiyuki Nishida",
  "Toshiyuki Tonomura",
  "Toshiyuki Tsuru",
  "Toshizo Fujiwara",
  "Totò",
  "Toulou Kiki",
  "Tracey Ullman",
  "Tracy-Ann Oberman",
  "Travis Fimmel",
  "Travis Fine",
  "Traylor Howard",
  "Treat Williams",
  "Trevante Rhodes",
  "Trevor Blumas",
  "Trevor Howard",
  "Trevor Jack Brooks",
  "Trevor Matthews",
  "Trey Parker",
  "Tricia Vessey",
  "Trine Dyrholm",
  "Trish Van Devere",
  "Trisha Krishnan",
  "Tristan Göbel",
  "Tristan Risk",
  "Tristán Ulloa",
  "Troels Lyby",
  "Troilo",
  "Troy Kotsur",
  "Trystan Pütter",
  "Tsubasa Honda",
  "Tsugumi Nagasawa",
  "Tsukie Matsuura",
  "Tsunehiko Kamijô",
  "Tsutomu Tatsumi",
  "Tsutomu Yamazaki",
  "Tsuyoshi Ihara",
  "Tsuyoshi Ujiki",
  "Tugba Sunguroglu",
  "Tugce Altug",
  "Tugçe Kazaz",
  "Tuli Kupferberg",
  "Tumpal Tampubolon",
  "Tuna Orhan",
  "Tuncel Kurtiz",
  "Tung Cho 'Joe' Cheung",
  "Tupac Shakur",
  "Tuppence Middleton",
  "Turan Mehrzad",
  "Turgay Tanülkü",
  "Turi Ferro",
  "Tuva Novotny",
  "Ty Jones",
  "Ty Shelton",
  "Ty Simpkins",
  "Tye Sheridan",
  "Tyler Hoechlin",
  "Tyler Labine",
  "Tyler Perry",
  "Tyler Young",
  "Tyrese Gibson",
  "Tyron Leitso",
  "Tyrone Power",
  "Téa Leoni",
  "Tôko Miura",
  "Tôru Emori",
  "Tôru Furuya",
  "Tülin Özen",
  "Türkan Soray",
  "Uberto Paolo Quintavalle",
  "Uche Agada",
  "Uday Chopra",
  "Udo Kier",
  "Udo Samel",
  "Ufuk Bayraktar",
  "Ugo Tognazzi",
  "Ugur Yücel",
  "Uhm Ji-won",
  "Uhm Junghwa",
  "Ulla Jacobsson",
  "Ulli Lommel",
  "Ulrich Matthes",
  "Ulrich Mühe",
  "Ulrich Thomsen",
  "Ulrich Tukur",
  "Ulrike Beimpold",
  "Uma Thurman",
  "Umay Shahab",
  "Umut Kurt",
  "Un-taek Jeong",
  "Unax Ugalde",
  "Unshô Ishizuka",
  "Urbano Barberini",
  "Urs Rechn",
  "Ursina Lardi",
  "Ursula Andress",
  "Ursula Ratasepp",
  "Ursula Strauss",
  "Usvaldo Panameno",
  "Uta Franz",
  "Uta Hagen",
  "Utkarsh Ambudkar",
  "Uvin Wang",
  "Uwe Kockisch",
  "Uwe Ochsenknecht",
  "Uxía Blanco",
  "Vaani Kapoor",
  "Vadim Glowna",
  "Vahina Giocante",
  "Vakhtang Kikabidze",
  "Val Kilmer",
  "Val Tasso",
  "Valentin Ganev",
  "Valentin Goshev",
  "Valentin Zubkov",
  "Valentina Bellè",
  "Valentina Carnelutti",
  "Valentina Cortese",
  "Valentina Lodovini",
  "Valentina Visconti",
  "Valentine Camax",
  "Valeria Bertuccelli",
  "Valeria Bilello",
  "Valeria Bruni Tedeschi",
  "Valeria Eisenbart",
  "Valeria Golino",
  "Valeria Solarino",
  "Valerie Faris",
  "Valerie Hobson",
  "Valerie Pachner",
  "Valeriia Karaman",
  "Valerio Aprea",
  "Valerio Mastandrea",
  "Valeriy Nikolaev",
  "Valeriy Zolotukhin",
  "Valérie Benguigui",
  "Valérie Bodson",
  "Valérie Lagrange",
  "Valérie Lemercier",
  "Van Johnson",
  "Vanesha Prescilla",
  "Vanessa Aleksander",
  "Vanessa Angel",
  "Vanessa Branch",
  "Vanessa Hudgens",
  "Vanessa Kirby",
  "Vanessa Paradis",
  "Vanessa Redgrave",
  "Vanessa Williams",
  "Vanna Urbino",
  "Vanni Corbellini",
  "Vanni Materassi",
  "Varun Sharma",
  "Vas Blackwood",
  "Vasilisa Perelygina",
  "Vasiliy Merkurev",
  "Vasiliy Stepanov",
  "Vedat Erincin",
  "Veerendra Saxena",
  "Veerle Baetens",
  "Venantino Venantini",
  "Veneta Fragnova",
  "Vera Alentova",
  "Vera Farmiga",
  "Vera Miles",
  "Verena Lehbauer",
  "Verna Bloom",
  "Verne Troyer",
  "Vernon Dobtcheff",
  "Veronica Cartwright",
  "Veronica Lake",
  "Veronica Lazar",
  "Veronica Taylor",
  "Veronika Franz",
  "Very Tri Yulisman",
  "Verónica Echegui",
  "Verónica Forqué",
  "Verónica Llinás",
  "Vicco von Bülow",
  "Vicente Romero",
  "Vicente Vergara",
  "Vicki Pepperdine",
  "Vicky Jenson",
  "Vicky Kaushal",
  "Vicky Krieps",
  "Vicky McClure",
  "Victoire Du Bois",
  "Victor Banerjee",
  "Victor Boichev",
  "Victor Buono",
  "Victor Carles",
  "Victor Garber",
  "Victor Jory",
  "Victor Poletti",
  "Victor Polster",
  "Victor Sjöström",
  "Victor Slezak",
  "Victor Verhaeghe",
  "Victoria Abril",
  "Victoria Haralabidou",
  "Victoria Hill",
  "Victoria Horne",
  "Vidya Balan",
  "Vidya Malvade",
  "Viggo Mortensen",
  "Vijay Raaz",
  "Vijay Sethupathi",
  "Vik Sahay",
  "Viktor Bychkov",
  "Viktor Dobronravov",
  "Viktor Hennemann",
  "Viktor Klimenko",
  "Viktor Sukhorukov",
  "Viktor Verzhbitskiy",
  "Viktor Zhdanov",
  "Viktoria Miroshnichenko",
  "Viktoriya Isakova",
  "Vildan Atasever",
  "Ville Haapasalo",
  "Ville Virtanen",
  "Vilma Szécsi",
  "Vimala Pons",
  "Vin Diesel",
  "Vince Edwards",
  "Vince Vaughn",
  "Vince Vieluf",
  "Vincent Cassel",
  "Vincent Claude",
  "Vincent Colombe",
  "Vincent D'Onofrio",
  "Vincent Desagnat",
  "Vincent Ebrahim",
  "Vincent Elbaz",
  "Vincent Gallo",
  "Vincent Gardenia",
  "Vincent Gauthier",
  "Vincent Lindon",
  "Vincent Macaigne",
  "Vincent Martin",
  "Vincent Messina",
  "Vincent Pastore",
  "Vincent Patar",
  "Vincent Perez",
  "Vincent Price",
  "Vincent Risterucci",
  "Vincent Schiavelli",
  "Vincent Schmitt",
  "Vincent Spano",
  "Vincenzo Amato",
  "Vincenzo Antonucci",
  "Vincenzo Crocitti",
  "Vincenzo Natali",
  "Vincenzo Nemolato",
  "Vinette Robinson",
  "Ving Rhames",
  "Vinicio Marchioni",
  "Vinnie Jones",
  "Vino G. Bastian",
  "Vinzenz Kiefer",
  "Vinícius de Oliveira",
  "Viola Davis",
  "Violante Placido",
  "Vipin Sharma",
  "Vir Das",
  "Virgilio Riento",
  "Virginia Cherrill",
  "Virginia Cristina Ariza",
  "Virginia Gardner",
  "Virginia Madsen",
  "Virginia Mayo",
  "Virginie Efira",
  "Virginie Ledoyen",
  "Vishka Asayesh",
  "Vishnu Vishal",
  "Vithaya Pansringarm",
  "Vito Annichiarico",
  "Vitor Julian",
  "Vittorio De Sica",
  "Vittorio Gassman",
  "Vittorio Sanipoli",
  "Vivaan Shah",
  "Viveik Kalra",
  "Vivek Ghamande",
  "Vivian Hsu",
  "Vivian Pickles",
  "Vivien Leigh",
  "Vlad Ivanov",
  "Vladas Bagdonas",
  "Vladimir Barskiy",
  "Vladimir Basov",
  "Vladimir Chuprikov",
  "Vladimir Cruz",
  "Vladimir Garin",
  "Vladimir Gostyukhin",
  "Vladimir Ilin",
  "Vladimir Khrulyov",
  "Vladimir Kulich",
  "Vladimir Mashkov",
  "Vladimir Menshov",
  "Vladimir Vdovichenkov",
  "Vladimir Zamanskiy",
  "Vladislav Dvorzhetskiy",
  "Vladislav Komarov",
  "Vladislav Vetrov",
  "Volker Albers",
  "Volker Prechtel",
  "Volker Spengler",
  "Vondie Curtis-Hall",
  "Vonetta McGee",
  "Vyacheslav Dovzhenko",
  "Vyacheslav Tikhonov",
  "Véra Clouzot",
  "Víctor Sosa",
  "Waclaw Zastrzezynski",
  "Wagner Moura",
  "Wah Yuen",
  "Waldemar Kobus",
  "Walki Cuevas",
  "Wallace Beery",
  "Wallace Ford",
  "Walter Brennan",
  "Walter Catlett",
  "Walter Connolly",
  "Walter Green",
  "Walter Hampden",
  "Walter Huston",
  "Walter Janssen",
  "Walter Ladengast",
  "Walter Levine",
  "Walter Matthau",
  "Walter Salles",
  "Walton Goggins",
  "Waltraud Witte",
  "Ward Bond",
  "Warren Beatty",
  "Warren Christie",
  "Warren Clarke",
  "Warren Jacquin",
  "Warren Lipka",
  "Warren Oates",
  "Wash Westmoreland",
  "Wasyl Wasylik",
  "Wataru Takagi",
  "Waël Sersoub",
  "Wei Dong",
  "Wei Fan",
  "Wei Li",
  "Wei Zhao",
  "Wen Jiang",
  "Wendee Lee",
  "Wendell Corey",
  "Wendy Crewson",
  "Wendy Hiller",
  "Wendy Makkena",
  "Wendy Nottingham",
  "Wenkang Yuan",
  "Wentworth Miller",
  "Wenzhuo Zhao",
  "Werner Krauss",
  "Weronika Ksiazkiewicz",
  "Wes Bentley",
  "Wes Chatham",
  "Wes Craven",
  "Wesley Addy",
  "Wesley Snipes",
  "Whitney Able",
  "Whoopi Goldberg",
  "Wi Ha-joon",
  "Wienczyslaw Glinski",
  "Wieslaw Michnikowski",
  "Wiktor Zborowski",
  "Wiktoria Gasiewska",
  "Wil Johnson",
  "Wil Wheaton",
  "Wiley Wiggins",
  "Wilford Brimley",
  "Wilfred Jackson",
  "Wilfrid Hyde-White",
  "Wilhelm von Homburg",
  "Wilhelmenia Fernandez",
  "Will Adamsdale",
  "Will Arnett",
  "Will Buie Jr.",
  "Will Dalton",
  "Will Ferrell",
  "Will Forte",
  "Will Merrick",
  "Will More",
  "Will Patton",
  "Will Poulter",
  "Will Sampson",
  "Will Sasso",
  "Will Smith",
  "Will Speck",
  "Will Yun Lee",
  "Willem Dafoe",
  "Willi Thomczyk",
  "William 'Bo' Jim",
  "William Allen Young",
  "William Atherton",
  "William B. Davis",
  "William Baldwin",
  "William Daniels",
  "William De Vito",
  "William Demarest",
  "William H. Lynn",
  "William H. Macy",
  "William Harrigan",
  "William Hickey",
  "William Holden",
  "William Hurt",
  "William Keighley",
  "William Moseley",
  "William Powell",
  "William Sadler",
  "William Schallert",
  "William Shatner",
  "William Shimell",
  "William Squire",
  "William Sylvester",
  "William Tubbs",
  "Williams Belle",
  "Willy Semmelrogge",
  "Wilmer Valderrama",
  "Wilt Chamberlain",
  "Wim Snape",
  "Winona Ryder",
  "Winston Chao",
  "Witold Pyrkosz",
  "Wladimir Yordanoff",
  "Wladyslaw Kowalski",
  "Wojciech Pszoniak",
  "Wolfgang Hess",
  "Wolfgang Lukschy",
  "Wolfgang Novogratz",
  "Wolfgang Reichmann",
  "Wolfgang Reitherman",
  "Won Bin",
  "Won Seo",
  "Won-Jin Ahn",
  "Woo Seo",
  "Woo-Seok Byeon",
  "Woo-Yeol Han",
  "Woo-hee Chun",
  "Woo-jae Choi",
  "Woo-seong Kam",
  "Wood Harris",
  "Woody Allen",
  "Woody Harrelson",
  "Woody Norman",
  "Wotan Wilke Möhring",
  "Wu Jiang",
  "Wunmi Mosaku",
  "Wyatt Knight",
  "Wyatt Russell",
  "Xabier Elorriaga",
  "Xan Cejudo",
  "Xavier Beauvois",
  "Xavier Dolan",
  "Xavier Martín",
  "Xavier Palud",
  "Xavier Samuel",
  "Xenia Valderi",
  "Xiaodong Guo",
  "Xiaoguang Hu",
  "Xiaohe Lü",
  "Xiaoming Huang",
  "Xiaoxing Yi",
  "Ximena Lamadrid",
  "Xin Xin Xiong",
  "Xingtong Yao",
  "Xinyi Zhang",
  "Xolani Mali",
  "Xuebing Wang",
  "Xueming Angelina Chen",
  "Xueqi Wang",
  "Xun Zhou",
  "Xuna Primus",
  "Yacef Saadi",
  "Yagmur Atacan",
  "Yagmur Taylan",
  "Yahya Abdul-Mateen II",
  "Yalcin Cakmak",
  "Yalitza Aparicio",
  "Yami Gautam",
  "Yan Tang",
  "Yang Ik-joon",
  "Yang Yang",
  "Yangzom Brauen",
  "Yann Collette",
  "Yann Hnautra",
  "Yanti Somer",
  "Yanting Lü",
  "Yaphet Kotto",
  "Yasaka Chaisorn",
  "Yasar Abravaya",
  "Yash",
  "Yasiin Bey",
  "Yasuhiro Takemoto",
  "Yasuko Matsui",
  "Yasuko Matsuyuki",
  "Yasuko Sawaguchi",
  "Yasuo Yamada",
  "Yasuomi Umetsu",
  "Yavuz Bingöl",
  "Yayan Ruhian",
  "Ye Liu",
  "Ye Zhou",
  "Ye-Won Mun",
  "Ye-won Kang",
  "Yekaterina Golubeva",
  "Yekaterina Rednikova",
  "Yelena Maksimova",
  "Yella Rottländer",
  "Yeo Jin-gu",
  "Yeo-reum Han",
  "Yeon Je Hyung",
  "Yeong-ae Kim",
  "Yeong-cheol Kim",
  "Yeong-hie Seo",
  "Yeong-ju Seo",
  "Yeong-ran Lee",
  "Yeong-seo Park",
  "Yeri Han",
  "Yesim Salkim",
  "Yetide Badaki",
  "Yetkin Dikinciler",
  "Yi Huang",
  "Yi Lu",
  "Yi Zhang",
  "Yibo Wang",
  "Yigit Özsener",
  "Yildiray Sahinler",
  "Yildiz Kültür",
  "Yilmaz Erdogan",
  "Yilmaz Güney",
  "Yiwei Zhou",
  "Yixing Zhang",
  "Yoann-Karl Whissell",
  "Yoga Pratama",
  "Yoko Honna",
  "Yolande Moreau",
  "Yon González",
  "Yong Dong",
  "Yong-goo Do",
  "Yong-joon Cho",
  "Yoo Ah-in",
  "Yoo Je-Yoon",
  "Yoo Ji-tae",
  "Yoo Seung-ho",
  "Yoo-ram Bae",
  "Yoon Kyesang",
  "Yordanos Shiferaw",
  "Yori Bertin",
  "Yoshiaki Kawajiri",
  "Yoshiaki Umegaki",
  "Yoshie Shimamura",
  "Yoshihiko Hakamada",
  "Yoshikazu Fujiki",
  "Yoshiko Kuga",
  "Yoshiko Shinohara",
  "Yoshiko Tanaka",
  "Yoshimi Jô",
  "Yoshimi Kondou",
  "Yoshimitsu Banno",
  "Yoshino Kimura",
  "Yoshio Tsuchiya",
  "Yoshitaka Takeuchi",
  "Yoshitaka Zushi",
  "Yoshitsugu Matsuoka",
  "Yoshiyuki Okada",
  "Yoshiyuki Takei",
  "Yoson An",
  "You Ge",
  "Youn Yuh-jung",
  "Young Sik Uhm",
  "Yousef Khodaparast",
  "Youssef Habashi",
  "Youssef Hamid",
  "Yu Xia",
  "Yu Zhang",
  "Yu-seok Kim",
  "Yuanyuan Gao",
  "Yuchang Peng",
  "Yui Ichikawa",
  "Yui Ishikawa",
  "Yui Natsukawa",
  "Yuka",
  "Yuka Imoto",
  "Yuka Terasaki",
  "Yuki Matsuoka",
  "Yuki Sakurai",
  "Yukie Nakama",
  "Yukihide Benny",
  "Yukiko Kobayashi",
  "Yukiko Shimazaki",
  "Yukito Nishii",
  "Yukiyo Fujii",
  "Yukiyoshi Ozawa",
  "Yul Brynner",
  "Yulia Snigir",
  "Yulian Zhao",
  "Yuliya Aug",
  "Yuliya Burova",
  "Yuliya Mayarchuk",
  "Yuliya Peresild",
  "Yuliya Solntseva",
  "Yuliya Vysotskaya",
  "Yum Jung-ah",
  "Yuma Uchida",
  "Yumeji Tsukioka",
  "Yun Jeong-hie",
  "Yun Lin",
  "Yun Qu",
  "Yun Tang",
  "Yun-shik Baek",
  "Yun-tae Kim",
  "Yunjin Kim",
  "Yuqi Zhang",
  "Yuri Chinen",
  "Yuriko Hoshi",
  "Yuriko Ishida",
  "Yuriko Yamaguchi",
  "Yuriko Yamamoto",
  "Yuriy Borisov",
  "Yuriy Kuznetsov",
  "Yuriy Nikulin",
  "Yuriy Solomin",
  "Yuriy Tsurilo",
  "Yuriy Yakovlev",
  "Yusuke Sekiguchi",
  "Yutaka Hayashi",
  "Yutaka Sada",
  "Yutaka Takenouchi",
  "Yuuki Luna",
  "Yuzuki Akiyama",
  "Yvan Attal",
  "Yves Afonso",
  "Yves Beneyton",
  "Yves Montand",
  "Yves Ory",
  "Yvette Feuer",
  "Yvette Kaplan",
  "Yvonne Clech",
  "Yvonne Furneaux",
  "Yvonne Maalouf",
  "Yô Ôizumi",
  "Yôji Matsuda",
  "Yôko Hikasa",
  "Yôko Maki",
  "Yôko Naitô",
  "Yôko Tsukasa",
  "Yôsuke Eguchi",
  "Yôsuke Natsuki",
  "Yû Aoi",
  "Yû Fujiki",
  "Yû Kashii",
  "Yû Koyanagi",
  "Yûji Kishi",
  "Yûka",
  "Yûki Kaji",
  "Yûki Kudô",
  "Yûko Gotô",
  "Yûko Miyamura",
  "Yûko Sanpei",
  "Yûko Takeuchi",
  "Yûko Tanaka",
  "Yûnosuke Itô",
  "Yûrei Yanagi",
  "Yûsaku Yara",
  "Yûsuke Iseya",
  "Yûsuke Kawazu",
  "Yûta Sone",
  "Yûya Yagira",
  "Yûzô Kayama",
  "Yücel Yolcu",
  "Zabit Arslan",
  "Zabou Breitman",
  "Zac Efron",
  "Zac Mattoon O'Brien",
  "Zach Aguilar",
  "Zach Braff",
  "Zach Galifianakis",
  "Zach Grenier",
  "Zachary Bennett",
  "Zachary Gordon",
  "Zachary Levi",
  "Zachary Quinto",
  "Zachary Scott",
  "Zack Norman",
  "Zack Ward",
  "Zackary Arthur",
  "Zafer Algöz",
  "Zahra Anderson",
  "Zahra Naderi",
  "Zaide Silvia Gutiérrez",
  "Zain Al Rafeea",
  "Zakieh Behbahani",
  "Zamira Saunders",
  "Zar Amir-Ebrahimi",
  "Zarah Jane McKenzie",
  "Zarifeh Shiva",
  "Zawe Ashton",
  "Zazie Beetz",
  "Zaïd Errougui-Demonsant",
  "Zbigniew Cybulski",
  "Zbigniew Zamachowski",
  "Zbigniew Zapasiewicz",
  "Zdenek Sverák",
  "Zeek Earl",
  "Zeinab Shabani",
  "Zeki Alasya",
  "Zeki Alpan",
  "Zemyna Asmontaite",
  "Zendaya",
  "Zeppo Marx",
  "Zerrin Tekindor",
  "Zeynep Tokus",
  "Zhang Wen",
  "Zhang Yi",
  "Zhenda Tian",
  "Zheng Xu",
  "Zhila Shahi",
  "Zhiwen Wang",
  "Zhizhong Huang",
  "Zhou Lan",
  "Zhou Sun",
  "Zhuo Tan",
  "Zhuocheng Wang",
  "Zhusheng Yin",
  "Zi Xi",
  "Ziba Karamali",
  "Zijian Dong",
  "Zina Blahusova",
  "Zinedine Soualem",
  "Ziyi Zhang",
  "Zlatko Buric",
  "Zo In-sung",
  "Zoe Kazan",
  "Zoe Margaret Colletti",
  "Zoe McLellan",
  "Zoe Saldana",
  "Zoey Deutch",
  "Zofia Wichlacz",
  "Zohar Wexler",
  "Zooey Deschanel",
  "Zora Kerova",
  "Zorion Eguileor",
  "Zouzou",
  "Zoé De Grand Maison",
  "Zoé Félix",
  "Zoé Héran",
  "Zoë Bell",
  "Zoë Kravitz",
  "Zuhal Gencer",
  "Zulay Henao",
  "Zulfanny",
  "Zygmunt Malanowicz",
  "Zélie Boulant",
  "riria.",
  "Àlex Casanovas",
  "Àlex Monner",
  "Àlex Pastor",
  "Álex Angulo",
  "Álex García",
  "Álex González",
  "Álvaro Cervantes",
  "Álvaro Guerrero",
  "Álvaro Morte",
  "Ángel Aranda",
  "Ángel de Andrés López",
  "Ángela Molina",
  "Çagatay Ulusoy",
  "Çaglar Çorumlu",
  "Çetin Altay",
  "Çetin Tekindor",
  "Élodie Bouchez",
  "Élodie Fontan",
  "Émile Vallée",
  "Émilie Dequenne",
  "Émilien Néron",
  "Éric Berger",
  "Éric Caravaca",
  "Éric Lartigau",
  "Éric Toledano",
  "Éric Warin",
  "Íker Sánchez Solano",
  "Ólafur Darri Ólafsson",
  "Óscar Casas",
  "Óscar Ferreiro",
  "Óscar Jaenada",
  "Óscar Zafra",
  "Óscar de la Fuente",
  "Ömer Faruk Sorak",
  "Öner Erkan",
  "Özcan Deniz",
  "Özge Borak",
  "Özge Özberk",
  "Özge Özpirinçci",
  "Özgü Namal",
  "Özgür Emre Yildirim",
  "Özgür Eren Koç",
  "Özkan Ugur",
  "Özlem Tekin",
  "Öznur Kula",
  "Ørjan Gamst",
  "Øyvind B. Fabricius Holm",
  "Øyvind Hagen-Traberg",
  "Ümit Ünal",
  "Ümmü Putgül",
];
