export const languages = [
                     'Akan',             'American Sign Language',
    'Arabic',            'Armenian',         'Bosnian',
    'Cantonese',         'Chinese',          'Czech',
    'Danish',            'Dutch',            'English',
    'English, Mandarin', 'English, Spanish', 'Estonian',
    'Filipino',          'Finnish',          'Flemish',
    'French',            'Georgian',         'German',
    'Greek',             'Hebrew',           'Hindi',
    'Hungarian',         'Icelandic',        'Indonesian',
    'Italian',           'Japanese',         'Kannada',
    'Kazakh',            'Korean',           'Kurdish',
    'Latin',             'Lithuanian',       'Malayalam',
    'Mandarin',          'Maya',             'None',
    'Norwegian',         'Persian',          'Polish',
    'Portuguese',        'Romanian',         'Romany',
    'Russian',           'Saami',            'Serbian',
    'Serbo-Croatian',    'Spanish',          'Swedish',
    'Tamil',             'Telugu',           'Thai',
    'Turkish',           'Ukrainian',        'Wayuu',
    'Zulu'
  ]
  